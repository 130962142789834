import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { listarPermisos, ValidarPermisos, LIST_SCREENS } from './../utils/utils'

class bodyDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permisos: []
        };

    }
    componentDidMount() {
        listarPermisos().then((permisos) => {
            // console.log(permisos);
            this.setState({ permisos: permisos });            
        });
        // console.log(LIST_SCREENS);        
    }

    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="row mt-3">
                        {/* Elementos */}

                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_PROGRAM, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro" >
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }} >
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-file-excel fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                        </div>
                                        <Link to="/programacion" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Programación</Link>
                                    </div>
                                </div>
                                : <></>
                        }

                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-globe-europe fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                        </div>
                                        <Link to="/monitoreo" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Monitoreo</Link>
                                    </div>
                                </div>
                                : <></>
                        }

                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BUSCAR, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-search fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                        </div>
                                        <Link to="/buscar" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Buscar</Link>
                                    </div>
                                </div>
                                : <></>
                        }

                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ALMACEN, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-warehouse fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                        </div>
                                        <Link to="/almacen" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Almacén</Link>
                                    </div>
                                </div>
                                : <></>
                        }

                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-tools fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                        </div>
                                        <Link to="/configuracion" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Configuración</Link>
                                    </div>
                                </div>
                                : <></>
                        }

                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ESTADISTICA, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-chart-line fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                        </div>
                                        <Link to="/estadistica" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Estadisticas</Link>
                                    </div>
                                </div>
                                : <></>
                        }
                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BUSCAR_UBICACION, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-search-location fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                        </div>
                                        <Link to="/ubicaciones" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Ubicaciones</Link>
                                    </div>
                                </div>
                                : <></>
                        }
                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MAPA, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-street-view fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                        </div>                                        
                                        <Link to="/mapa" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Mapa</Link>
                                    </div>
                                </div>
                                : <></>
                        }
                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MAPA_REORDENAMIENTO, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-sort-amount-up-alt fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                            {/* <i class="fas fa-sort-amount-up-alt"></i> */}
                                        </div>                                        
                                        <Link to="/mapa_ordenamiento" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Ordenar</Link>
                                    </div>
                                </div>
                                : <></>
                        }

{
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CARGAR_GPS, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fa fa-upload fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>                                                                      
                                            {/* <i class="fas fa-sort-amount-up-alt"></i> */}
                                        </div>                                        
                                        <Link to="/cargar_gps" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Cargar GPS</Link>
                                    </div>
                                </div>
                                : <></>
                        }

                    </div>
                </div>
            </Fragment>
        );
    }
}

export default bodyDashboard;