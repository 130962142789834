import React, { Component,Fragment } from 'react';

class spinner extends Component {
    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="row   justify-content-center align-items-center" style={{ height: '200px' }}>
                        <div className=" text-center">
                            <div className="spinner-border text-secondary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div>
                                <span>Cargando ...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default spinner;