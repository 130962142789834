import React, { Component, Fragment, createRef } from 'react';
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, LIST_SCREENS, listarPermisos } from '../utils/utils'
import NavBar from '../dashboard/navBar';
import TituloPagina from '../dashboard/headerTitle';
import moment from 'moment';
import { Link, Switch } from 'react-router-dom';
// import socketIOClient from 'socket.io-client';

import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Modal } from 'react-bootstrap';

import LoadingModal from './../utils/loadModal'
import SpinnerCarga from './../utils/spinner'
import config from './../../config/config';

import { CreateJsonToExcel } from './../utils/utils'
//Crear zip y guardar
import { saveAs } from 'file-saver';
var zip = require('jszip')();

class monitoreo extends Component {
    //Variables globales     
    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            colorIcon: '',
            MostrarModalCambiarTrabajador: false,
            MostrarModalDescargar: false,
            MostrarModalReporteFechas: false,
            MostrarSnackDescargaFotos: false,
            mensajeDescargaFotos: '',
            fechaprogramacion: '',
            LoadData: false,
            ListaProgramacion: [],
            ProgramacionDetalleDia:[],
            ListaPaginacion: [],
            valorEstadoBusqueda: 'TODOS',
            ListaBusqueda: [],
            ListaSuministrosProgramadoTecnico: [],
            ListaTrabajadoresActivos: [],
            ListaSuministrosSeleccionados: [],
            TrabajoProgramadoSeleccionado: '',
            // TipoTrabajos: [],
            TiposTrabajoFechaProgramada: [],
            cantidadElementosSeleccionados: 0,
            cantidadElementos: 0,
            filasPorPagina: 50,
            pagina: 1,
            sizeWidth: 0,
            tipofiltro: 'Todos',
            valorBuscar: '',
            ProgramacionActualizada: 0,
            ProgramacionError: 0,
            mensaCargaActualizacion: '',
            MostrarPorcentajeCarga: false
        };
        // Referencias
        this.inputfechaProgramacion = createRef();
        this.selectTipoFiltro = createRef();
        this.inputBuscarFiltro = createRef();
        this.selectTipoTrabajo = createRef();

        // Referencias Modal Cambiar Trabajador
        this.inputNombreTrabajador = createRef();
        this.inputElegirTrabajador = createRef();
        this.inputChecktodosLosElementos = createRef();
        this.listarFotosProgramacion = [];

        //Referencias modal Descargar
        this.selectTipoTrabajoDescargar = createRef();

        //Referencias Modal Reporte Fechas
        this.inputFechaInicioReporteFecha = createRef();
        this.inputFechaFinReporteFecha = createRef();


        this.tableBodySuministros = createRef();
        this.headCells = [
            { id: 'Ver' },
            { id: 'Edit.' },
            { id: 'JUO' },
            { id: 'Supervisor' },
            { id: 'Area' },
            // { id: 'Tipo' },
            { id: 'Estado' },
            { id: 'Avance' },
            { id: 'Inicio' },
            { id: 'Fin' },
            { id: 'Descarga' },
            // { id: 'Fin.' },
        ];
        //Inicializar Valores de Servidor Socket
        // this.socket=socketIOClient(config.endPoint,{transports: ['websocket', 'polling', 'flashsocket']});      

    }


    async componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO, permisos)) {
                    // Llenar los cuadros y llamar a la APi    
                    let fechaStorage = await localStorage.getItem(config.storageDateName);
                    let tipoTrabajoStorage = await localStorage.getItem(config.storageFilterTrabajo);

                    if (tipoTrabajoStorage) {
                        // console.log(tipoTrabajoStorage);
                        this.selectTipoTrabajo.current.value = tipoTrabajoStorage;
                        this.setState({ TrabajoProgramadoSeleccionado: `(${tipoTrabajoStorage})` })
                        // console.log(this.selectTipoTrabajo.current.value);
                    }

                    else {
                        this.setState({ TrabajoProgramadoSeleccionado: `` })
                    }

                    if (fechaStorage) {
                        this.inputfechaProgramacion.current.value = moment(fechaStorage).format('YYYY-MM-DD');
                        await this.setState({ fechaprogramacion: fechaStorage });
                    } else {
                        this.inputfechaProgramacion.current.value = moment().format('YYYY-MM-DD');
                        await this.setState({ fechaprogramacion: this.inputfechaProgramacion.current.value });
                    }

                    await this.BuscarProgramacionfecha();
                    // await this.SocketConcect();

                } else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/login');
                    }, 1000);
                }

            });


        }
        else {
            //Redireccionando a Login
            this.props.history.push('/login');
        }
    }
    // SocketConcect = () => {
    //     //Escuchar Servidor Socket
    //     this.socket.open();
    //     //Variable controlador  Socket
    //     this.socket.on("connect", () => {
    //         console.log("Conectado con Servidor socket");
    //     });

    //     this.socket.on("ActualizarTrabajosSuministro", (Data) => {
    //         //Actualizar la Lista de Trabajos
    //         console.log(`socket : ActualizarTrabajosuministro ${Data}`);            
    //         this.BuscarProgramacionfecha();
    //     });
    // }

    // componentWillUnmount() {
    //     this.socket.close();        
    // }

    BuscarProgramacionfecha =async () => {
        if (ValidarUsuario()) {

            // let fecha = moment(this.inputfechaProgramacion.current.value).format('YYYY-MM-DD');
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            let urlp=`${config.endPoint}api/trabajosuministro/ProgramacionDia_v2/${this.state.fechaprogramacion}`;
            let url = `${config.endPoint}api/trabajosuministro/listarProgramacionDia_v2/${this.state.fechaprogramacion}`;

            if (this.state.valorBuscar.trim() !== '') {
                switch (this.state.tipofiltro) {
                    case 'Trabajador':
                        let valorTrabajador = String(this.state.valorBuscar);
                        if (valorTrabajador.trim() !== '') {
                            url = `${config.endPoint}api/trabajosuministro/listarProgramacionDia/trabajador_v2/${this.state.fechaprogramacion}/${this.state.valorBuscar}`;
                        }
                        break;
                    case 'Supervisor':
                        // Buscar Lista de Supervisores                    
                        let valorsupervisor = String(this.state.valorBuscar);
                        if (valorsupervisor.trim() !== '') {
                            url = `${config.endPoint}api/trabajosuministro/listarProgramacionDia/supervisor/${this.state.fechaprogramacion}/${this.state.valorBuscar}`;
                        }
                        break;
                    default:
                    // Por Default todos
                    // url = `${config.endPoint}api/trabajosuministro/listarProgramacionDia/${this.state.fechaprogramacion}`;
                }
            }
            await fetch(urlp, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {                                        
                    await this.setState({ ProgramacionDetalleDia: data.content});                                       
                }
                else {
                    this.setState({ ListaProgramacion: [], cantidadElementos: 0, LoadData: true });
                }
            }).catch((error) => {                
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    // console.log(data.content);
                    await this.setState({ ListaProgramacion: data.content, cantidadElementos: data.content.length });                   
                    await this.filtarTipoTrabajo();
                    if (this.state.valorEstadoBusqueda !== 'TODOS') {
                        await this.filtrarEstado();
                    }
                    await this.Paginacion();
                }
                else {
                    this.setState({ ListaProgramacion: [], cantidadElementos: 0, LoadData: true });
                }
            }).catch((error) => {
                // console.log(error);
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
            this.buscarTiposTrabajosFechaProgramada();

        }
        else {
            this.props.history.push('/login');
        }
    }


    filtarTipoTrabajo = async () => {
        // console.log(this.state.ListaProgramacion);
        let tipoTrabajoStorage = await localStorage.getItem(config.storageFilterTrabajo);
        let arrayFiltro = [];
        if (tipoTrabajoStorage) {
            if (tipoTrabajoStorage !== "Todos") {
                arrayFiltro = await this.state.ListaProgramacion.filter(X => X.Area === tipoTrabajoStorage);
                // console.log(arrayFiltro);
                this.setState({ ListaProgramacion: arrayFiltro, cantidadElementos: arrayFiltro.length });
            }
        }
    }

    filtrarEstado = async () => {
        //filtrar tipos de Trabajo
        let nuevoArray = [];
        // console.log(this.state.valorEstadoBusqueda);   
        // console.log(this.state.ListaProgramacion); 
        switch (this.state.valorEstadoBusqueda) {
            case 'TODOS':
                nuevoArray = await this.state.ListaProgramacion;
                break;
            case 'PROGRAMADO':
                nuevoArray = await this.state.ListaProgramacion.filter(X => X.avance === 0);
                break;
            case 'EJECUTADO':
                nuevoArray = await this.state.ListaProgramacion.filter(X => X.avance > 0);
                break;
        }
        await this.setState({ ListaProgramacion: nuevoArray });
    }

    elegirTipoTrabajoProgramado = async () => {
        //Almacenar Ajustes en LocalStorage
        await localStorage.setItem(config.storageFilterTrabajo, this.selectTipoTrabajo.current.value);
        this.setState({ TrabajoProgramadoSeleccionado: `(${this.selectTipoTrabajo.current.value})`,LoadData:false });
        //Buscar Lista Trabajos
        this.BuscarProgramacionfecha();
    }

    buscarTiposTrabajosFechaProgramada = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/tipotrabajo/listarProgramadp/${this.state.fechaprogramacion}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ TiposTrabajoFechaProgramada: data.content });
                }
                else {
                    this.setState({ TiposTrabajoFechaProgramada: [] });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    // ********************************
    // Listar suministros programados por trabajador
    // ********************************
    filtrarEstadoTrabajo = async (valor) => {
        await this.setState({ valorEstadoBusqueda: valor, LoadData: false });
        this.BuscarProgramacionfecha();
    }

    ListarsuministrosProgramados = (idtrabajadorasignado) => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajosuministro/listarSuministrosProgramados/trabajador/${this.state.fechaprogramacion}/${idtrabajadorasignado}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ ListaSuministrosProgramadoTecnico: data.content });
                    // console.log(data.content);
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarListaTrabajadoresActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajador/listar/ACTIVO`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaTrabajadoresActivos: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaTrabajadoresActivos: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    //Descargar Fotos 

    // BuscarListaTipoTrabajo = () => {
    //     if (ValidarUsuario()) {
    //         let url = `${config.endPoint}api/tipotrabajo/listar`;

    //         let UsuarioStorage = ObtenerUsuario();
    //         UsuarioStorage = JSON.parse(UsuarioStorage);
    //         let headers = {
    //             method: 'GET',
    //             headers: {
    //                 'Authorization': 'Bearer ' + UsuarioStorage.token,
    //             }
    //         };
    //         fetch(url, headers).then((respuesta) => {
    //             return respuesta.json();
    //         }).then((data) => {
    //             if (data.content) {
    //                 this.setState({ TipoTrabajos: data.content });
    //             }
    //             else {
    //                 this.setState({ TipoTrabajos: [] });
    //                 this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
    //             }
    //         }).catch(() => {
    //             this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
    //         });
    //     }
    //     else {
    //         this.props.history.push('/login');
    //     }
    // }

    DescargarFotosProgramacion = async () => {
        if (ValidarUsuario()) {
            //window.confirm('¿Estas seguro que deseas descargar todas las fotos?')   
            if (this.selectTipoTrabajoDescargar.current.value.trim() !== "") {
                this.selectTipoTrabajoDescargar.current.classList.remove('is-invalid');
                let url = ";"
                if (this.selectTipoTrabajoDescargar.current.value === "TODOS") {
                    url = `${config.endPoint}api/movil/listafotos/${this.state.fechaprogramacion}`;
                }
                else {
                    url = `${config.endPoint}api/movil/listafotosTipoTrabajo/${this.state.fechaprogramacion}/${this.selectTipoTrabajoDescargar.current.value}`;
                }
                //Ejecutar buscqueda de Fotos                
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                let cantidad = 0;

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        // console.log(data.content);
                        if (data.content.length > 0) {
                            this.CerrarModalDescargarFotos();
                            await this.setState({ OcultarLoading: false, MostrarSnackDescargaFotos: true, mensajeDescargaFotos: `${cantidad}/${data.content.length} Fotos Descargadas` });
                            for (let x = 0; x < data.content.length; x++) {
                                await fetch(`${config.endPoint}api/foto/DownloadFileServer/${data.content[x].foto_url}`).then(async (respuesta) => {
                                    cantidad++;
                                    await this.setState({ mensajeDescargaFotos: `${cantidad}/${data.content.length} Fotos Descargadas` });
                                    const arraybuffer = await respuesta.arrayBuffer();
                                    zip.file(`${data.content[x].fechaProgramacion.trim()}/${data.content[x].tipotrabajo.trim()}/${data.content[x].trabajadorejecuta !== null ? data.content[x].trabajadorejecuta.trim() : data.content[x].trabajadorprogramado.trim()}/${data.content[x].suministro.trim()}/${data.content[x].foto_url}`, arraybuffer);
                                }
                                ).catch((error) => {
                                    //console.log(error);
                                });
                            }
                            //Generar el Archivo Zip
                            await zip.generateAsync({ type: 'blob' }).then((contenido) => {
                                saveAs(contenido, `Fotos_${this.state.fechaprogramacion}.zip`);
                            });
                            //Reiniciar el zip
                            zip = require('jszip')();
                            await this.setState({ OcultarLoading: true, MostrarSnackDescargaFotos: false });
                        } else {
                            this.setState({ abrirToast: true, mensajeToast: "No se encontraron archivos para este filtro.", tipotoast: 'info', OcultarLoading: true, MostrarSnackDescargaFotos: false });
                        }
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning', OcultarLoading: true, MostrarSnackDescargaFotos: false });
                    }
                }).catch((error) => {
                    // console.log(error);
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSnackDescargaFotos: false });
                });

            }
            else {
                this.selectTipoTrabajoDescargar.current.classList.add('is-invalid');
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    //****************************************** */
    //MODAL DESCARGAR FOTOS
    //****************************************** */
    AbrirModalDescargarFotos = () => {
        this.buscarTiposTrabajosFechaProgramada();
        this.setState({ MostrarModalDescargar: true });
    }
    CerrarModalDescargarFotos = () => {
        this.setState({ MostrarModalDescargar: false });
    }
    DescargarReporteProgramacion = async () => {
        //Descargar Informacion
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajosuministro/reporte/${this.state.fechaprogramacion}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    //this.setState({ ListaSuministrosProgramadoTecnico: data.content });
                    //console.log(data.content);
                    if (data.content.length > 0) {
                        await CreateJsonToExcel(data.content);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    // **********************************
    // Modal Reporte Rango Fechas
    // **********************************
    AbrirModalReporteRangoFechas = () => {
        this.setState({ MostrarModalReporteFechas: true });
    }
    CerrarModalReporteRangoFechas = () => {
        this.setState({ MostrarModalReporteFechas: false });
    }

    ValidarFormularioReporteRangoFechas = () => {
        let errores = 0;
        if (this.inputFechaInicioReporteFecha.current.value !== null && this.inputFechaInicioReporteFecha.current.value !== '') {
            this.inputFechaInicioReporteFecha.current.classList.remove('is-invalid');
        } else {
            this.inputFechaInicioReporteFecha.current.classList.add('is-invalid');
            errores++;
        }

        if (this.inputFechaFinReporteFecha.current.value !== null && this.inputFechaFinReporteFecha.current.value !== '') {
            this.inputFechaFinReporteFecha.current.classList.remove('is-invalid');
        }
        else {
            this.inputFechaFinReporteFecha.current.classList.add('is-invalid');
            errores++;
        }
        if (errores > 0) {
            return false;
        }
        else {
            return true;
        }
    }

    DescargarReporteProgramacionFechas = () => {

        if (this.ValidarFormularioReporteRangoFechas()) {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajosuministro/reporte/${this.inputFechaInicioReporteFecha.current.value}/${this.inputFechaFinReporteFecha.current.value}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        if (data.content.length > 0) {
                            await CreateJsonToExcel(data.content);
                            this.CerrarModalReporteRangoFechas();
                        }
                        else {
                            this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                        }
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });

            }
            else {
                this.props.history.push('/login');
            }
        }
    }


    DescargarReporteMateriales = async () => {
        //Descargar Informacion
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajosuministro/reporteMateriales/${this.state.fechaprogramacion}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    //this.setState({ ListaSuministrosProgramadoTecnico: data.content });
                    //console.log(data.content);
                    if (data.content.length > 0) {
                        await CreateJsonToExcel(data.content);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    // ********************************
    // Modal CambiarTrabajador
    // ********************************
    AbrirModalCambiarTrabajador = async (trabajador) => {
        // BuscarLista de Trabajos
        await this.setState({ OcultarLoading: false });
        await this.ListarsuministrosProgramados(trabajador.idpersonalasignado);
        await this.BuscarListaTrabajadoresActivos().then().catch((error) => {
            if (error) {
                this.props.history.push('/login');
            }
        });
        await this.setState({ MostrarModalCambiarTrabajador: true, OcultarLoading: true })
        this.inputNombreTrabajador.current.value = trabajador.nombretrabajador;
    }
    CerrarModalCambiarTrabajador = () => {
        this.setState({ MostrarModalCambiarTrabajador: false })
    }
    SeleccionarTodosSuministros = async (event) => {
        // console.log(this.tableBodySuministros.current.children.length);
        let cant = this.tableBodySuministros.current.children.length;
        for (let x = 0; x < cant; x++) {
            this.tableBodySuministros.current.children[x].children[0].children[0].checked = event.target.checked
        }
        if (event.target.checked) {
            // Agregar Todos a Array de Seleccionados
            await this.setState({ ListaSuministrosSeleccionados: [] });
            await this.setState({ ListaSuministrosSeleccionados: this.state.ListaSuministrosProgramadoTecnico, cantidadElementosSeleccionados: this.state.ListaSuministrosProgramadoTecnico.length });
        }
        else {
            await this.setState({ ListaSuministrosSeleccionados: [], cantidadElementosSeleccionados: 0 });
        }
    }
    SeleccionarElemento = async (event, suministro) => {
        // console.log(suministro);
        let listaSuministros = [];
        if (event.target.checked) {
            // Agregar Elemento
            listaSuministros = this.state.ListaSuministrosSeleccionados;
            listaSuministros.push(suministro);
            await this.setState({ ListaSuministrosSeleccionados: listaSuministros, cantidadElementosSeleccionados: listaSuministros.length });
        }
        else {
            if (this.inputChecktodosLosElementos.current.checked) {
                this.inputChecktodosLosElementos.current.checked = false;
            }
            // Eliminar elemento(Si existiera)
            listaSuministros = this.state.ListaSuministrosSeleccionados.filter(x => x !== suministro);
            await this.setState({ ListaSuministrosSeleccionados: listaSuministros, cantidadElementosSeleccionados: listaSuministros.length });
        }
    }
    ValidarModalReasignarTrabajador = () => {
        if (this.inputElegirTrabajador.current.value.trim() === "") {
            this.setState({ mensajeToast: "Debe elegir un Trabajador de la lista.", abrirToast: true, tipotoast: 'warning' });
            this.inputElegirTrabajador.current.classList.add('is-invalid');
            return false;
        }
        else {
            this.inputElegirTrabajador.current.classList.remove('is-invalid');
        }

        if (this.state.ListaSuministrosSeleccionados.length === 0) {
            this.setState({ mensajeToast: "No se ha seleccionado ningun Suministro de  la Lista", abrirToast: true, tipotoast: 'warning' });
            return false;
        }

        return true;
    }
    BuscarIdTrabajadorReasignado = async (trabajadorReasignado) => {
        for await (const trabajador of this.state.ListaTrabajadoresActivos) {
            if (trabajadorReasignado.indexOf(trabajador.nombretrabajador) > -1) {
                return trabajador.idtrabajador;
            }
        }
        return null;
    }

    ReasignarSuministroATrabajador = async () => {
        if (ValidarUsuario()) {
            if (this.ValidarModalReasignarTrabajador()) {
                let IdTrabajadorReasignado = await this.BuscarIdTrabajadorReasignado(this.inputElegirTrabajador.current.value);
                if (IdTrabajadorReasignado) {

                    await this.setState({ OcultarLoading: false });

                    this.inputElegirTrabajador.current.classList.remove('is-invalid');
                    // Reasignar a los suministros Seleccionados
                    let url = `${config.endPoint}api/trabajosuministro/programacionreasignar/idTrabajador`;
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let nTrabajoSuministro = {
                        idpersonalasignado: IdTrabajadorReasignado,
                        ListaSuministros: this.state.ListaSuministrosSeleccionados
                    }
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(nTrabajoSuministro)
                    };
                    await fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then((data) => {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'info' });
                        this.BuscarProgramacionfecha();
                        this.CerrarModalCambiarTrabajador();
                    }).catch(() => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });
                    await this.setState({ OcultarLoading: true });

                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "Elija un nombre de la lista de trabajadores", tipotoast: 'warning' });
                    this.inputElegirTrabajador.current.value = '';
                    this.inputElegirTrabajador.current.classList.add('is-invalid');
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }

    CambiarFecha = async () => {
        this.selectTipoFiltro.current.value = "Todos";
        await this.setState({ fechaprogramacion: this.inputfechaProgramacion.current.value, LoadData: false, tipofiltro: this.selectTipoFiltro.current.value, TrabajoProgramadoSeleccionado: '' });
        await localStorage.setItem(config.storageDateName, this.state.fechaprogramacion);
        this.selectTipoTrabajo.current.value = 'Todos';
        await localStorage.removeItem(config.storageFilterTrabajo);
        await this.BuscarProgramacionfecha();
    }
    CambioSelectTipoFiltro = async () => {

        await this.setState({ LoadData: false, tipofiltro: this.selectTipoFiltro.current.value, valorBuscar: '' });
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let headers = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            }
        };

        switch (this.state.tipofiltro) {
            case 'Trabajador':
                // Buscar Lista de Trabajadores
                let urlTrabajadores = `${config.endPoint}api/trabajosuministro/listarNombres/trabajador/${this.state.fechaprogramacion}`;
                fetch(urlTrabajadores, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaBusqueda: data.content });
                    }
                });
                this.inputBuscarFiltro.current.value = "";
                break;
            case 'Supervisor':
                // Buscar Lista de Supervisores
                let urlSupervisores = `${config.endPoint}api/trabajosuministro/listarNombres/supervisor/${this.state.fechaprogramacion}`;
                fetch(urlSupervisores, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaBusqueda: data.content });
                    }
                });
                this.inputBuscarFiltro.current.value = "";
                break;
            case 'Estado':
                let nuevoArray = [];
                for (let x = 0; x < this.state.ListaProgramacion.length; x++) {
                    let existe = false;
                    for (let y = 0; y < nuevoArray.length; y++) {
                        if (this.state.ListaProgramacion[x].estado === nuevoArray[y].nombretrabajador) {
                            existe = true;
                            break;
                        }
                    }
                    if (!existe) {
                        nuevoArray.push({ nombretrabajador: this.state.ListaProgramacion[x].estado });
                    }
                }
                this.setState({ ListaBusqueda: nuevoArray });
                this.inputBuscarFiltro.current.value = "";
                break;
            default:
                this.setState({ ListaBusqueda: [] });
        }
        await this.BuscarProgramacionfecha();
    }

    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.setState({ LoadData: false, pagina: 1, valorBuscar: this.inputBuscarFiltro.current.value });
            await this.BuscarProgramacionfecha();
        }
    }
    cambioFiltroBuscar = async (ev) => {
        if (ev.target.value === "") {
            await this.setState({ LoadData: false, pagina: 1, valorBuscar: this.inputBuscarFiltro.current.value });
            await this.BuscarProgramacionfecha();
        }
    }
    //*************************************************
    //Funciones para Paginacion
    //*************************************************
    Paginacion = async () => {
        let filainicio = 0;
        if (this.state.ListaProgramacion.length > 0) {
            filainicio = parseInt(this.state.filasPorPagina) * (parseInt(this.state.pagina) - 1);
        }
        let filafin = filainicio + this.state.filasPorPagina;
        let nuevoArray = [];
        for (let x = filainicio; x < this.state.ListaProgramacion.length; x++) {
            if (x < filafin) {
                nuevoArray.push(this.state.ListaProgramacion[x]);
            }
        }
        await this.setState({ ListaPaginacion: nuevoArray, LoadData: true });
    }
    CambioFilasPorPagina = async (event) => {
        await this.setState({ LoadData: false, filasPorPagina: parseInt(event.target.value), pagina: 1 });
        await this.BuscarProgramacionfecha();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ LoadData: false, pagina: (parseInt(nuevaPagina) + 1) });
        await this.BuscarProgramacionfecha();
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    ActualizarSocket = () => {

        this.socket.emit("TrabajoAgregado");
        console.log("Emit Socket");
        // this.BuscarProgramacionfecha();
    }

    render() {

        return (
            <Fragment>
                <Snackbar
                    open={this.state.MostrarPorcentajeCarga}
                    message={this.state.mensaCargaActualizacion}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>
                {/* ************************************************* */}
                {/* Snack Carga */}
                {/* ************************************************* */}
                <Snackbar
                    open={this.state.MostrarSnackDescargaFotos}
                    message={this.state.mensajeDescargaFotos}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                />

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo={`MONITOREO ${this.state.TrabajoProgramadoSeleccionado}`} regresar={() => { this.props.history.push('/') }}></TituloPagina>

                {/* Contenedor de Datos */}
                <div className="container border mt-1">
                    <div className="row mt-1">
                        <div className="col-md-6 col-lg-3">
                            <input onChange={() => { this.CambiarFecha() }} className="form-control form-control-sm p-1" type="date" placeholder="Fecha" ref={this.inputfechaProgramacion}></input>
                        </div>
                        <div className=" col-md-6 col-lg-3 mt-1 mt-md-0">
                            {/* <input onKeyPress={(ev) => { this.elegirTipoTrabajoProgramado() }} ref={this.selectTipoTrabajo} className="form-control form-control-sm" list="listaTiposTrabajoProgramado" placeholder={`Buscar ${this.state.tipofiltro}`}></input>
                            <datalist id="listaTiposTrabajoProgramado">
                                {
                                    this.state.TiposTrabajoFechaProgramada.map((row, index) => {
                                        return (<option key={index} value={row.tipotrabajo} />)
                                    })
                                }
                            </datalist> */}

                            <select onChange={() => { this.elegirTipoTrabajoProgramado() }} ref={this.selectTipoTrabajo} className="form-select form-select-sm" >
                                <option value="Todos">Todas las Areas</option>
                                {
                                    this.state.TiposTrabajoFechaProgramada.map((row, index) => {
                                        return (<option key={index} value={row.tipotrabajo} >{row.tipotrabajo}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className=" col-md-6 col-lg-3 mt-1 mt-md-0">

                            <select onChange={() => { this.CambioSelectTipoFiltro() }} ref={this.selectTipoFiltro} className="form-select form-select-sm" >
                                <option value="Todos">Todos los Trabajadores</option>
                                <option value="Trabajador">Nombre Trabajador</option>
                                <option value="Supervisor">Supervisor</option>
                                {/* <option value="Estado">Estado</option> */}
                            </select>
                        </div>

                        {
                            this.state.tipofiltro !== "Todos" ?
                                <div className="col-md-6 col-lg-3 mt-1 mt-md-0">
                                    <input onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} onChange={(ev) => { this.cambioFiltroBuscar(ev) }} ref={this.inputBuscarFiltro} className="form-control form-control-sm" list="listaTrabajadoresProgramados" placeholder={`Buscar ${this.state.tipofiltro}`}></input>
                                    <datalist id="listaTrabajadoresProgramados">
                                        {
                                            this.state.ListaBusqueda.map((row, index) => {
                                                return (<option key={index} value={row.nombretrabajador} />)
                                            })
                                        }
                                    </datalist>
                                </div>
                                : <>
                                </>
                        }

                        <div className={this.state.tipofiltro !== "Todos" ? "col-md-12 text-end mt-1 mt-md-0" : "col-md-6 col-lg-3 text-end mt-1 mt-md-0"} >
                            <div className="dropdown">
                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                    Opciones
                                </button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                    <li onClick={() => { this.filtrarEstadoTrabajo('TODOS') }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Ver todos</small></li>
                                    <li onClick={() => { this.filtrarEstadoTrabajo('EJECUTADO') }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Ver Ejecutados</small></li>
                                    <li onClick={() => { this.filtrarEstadoTrabajo('PROGRAMADO') }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Ver Faltantes</small></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li onClick={() => { this.AbrirModalDescargarFotos() }}><small className="dropdown-item" ><i className="fas fa-download mx-2"></i>Descargar Fotos</small></li>
                                    <li onClick={() => { this.DescargarReporteProgramacion() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Descargar Data</small></li>
                                    <li onClick={() => { this.AbrirModalReporteRangoFechas() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Descargar Data (Rango Fecha)</small></li>
                                    {/* <li onClick={() => { this.DescargarReporteMateriales() }}><small className="dropdown-item" ><i className="fas fa-file-excel mx-2"></i>Descargar Data Materiales</small></li>                                     */}
                                    <li><hr className="dropdown-divider" /></li>
                                    <li>
                                        <Link to={{ pathname: `/monitoreo/mapa/${this.state.fechaprogramacion}` }} className="dropdown-item" ><small><i className="fas fa-globe-americas mx-2"></i>Ver En Mapa</small></Link>
                                    </li>
                                    <li onClick={() => { this.ActualizarSocket() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Actualizar</small></li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row p-1">
                        {
                            this.state.LoadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer style={{maxHeight: window.outerHeight<760?430:650}}>
                                        <Table size='small' stickyHeader>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    {
                                                        this.headCells.map((head, index) => {
                                                            return (
                                                                <TableCell 
                                                                // style={{ color: 'aliceblue' }} 
                                                                key={index}>{head.id}</TableCell>
                                                            );
                                                        })
                                                    }

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.ListaPaginacion.map((row, index) => {
                                                    // Buscar Cantidad Programada Por trabajador
                                                    let TrabajosProgramados=this.state.ProgramacionDetalleDia.filter(x=>x.idpersonalasignado=== row.idpersonalasignado);
                                                    // Buscar Cantidad ejecutada por Trabajador
                                                    let TrabajosEjecutados=TrabajosProgramados.filter(x=>x.estado!=='PROGRAMADO' && x.estado!=='NO EJECUTADO');
                                                    // Buscar Cantidad no ejecutada por Trabajador
                                                    let TrabajosNoEjecutados=TrabajosProgramados.filter(x=>x.estado==='NO EJECUTADO');

                                                    //Buscar Fecha Inicio
                                                    let MayorFecha={};
                                                    if(TrabajosEjecutados.length>0){
                                                        MayorFecha=TrabajosEjecutados.reduce((prev,current)=>{
                                                            return (prev.hora_ejecucion>current.hora_ejecucion)?prev:current
                                                        });
                                                    }
                                                    
                                                    // Buscar Fecha Fin     
                                                    let MenorFecha={};
                                                    if(TrabajosEjecutados.length>0){
                                                        MenorFecha=TrabajosEjecutados.reduce((prev,current)=>{
                                                            return (current.hora_ejecucion<prev.hora_ejecucion)?current:prev
                                                        });
                                                    }


                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                                {/* Enviar el ID */}
                                                                <Link to={{ pathname: `/monitoreo/trabajador/${this.state.fechaprogramacion}/${row.idpersonalasignado}` }}>
                                                                    {/* <Link to={{pathname:`/monitoreo/trabajador/${this.fechaProgramacion}/${index}`,state:{nombreTrabajador:row.JUO,tipotrabajo:row.Tipo,fechaprogramacion:this.state.fechaprogramacion}}}> */}
                                                                    <i title="Ver" className="fas fa-eye iconoAcciones" ></i>
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>
                                                                <i onClick={() => { this.AbrirModalCambiarTrabajador(row) }} title="Modificar" className="fas fa-edit  iconoAcciones" ></i>
                                                            </TableCell>

                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.nombretrabajador}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.supervisor}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{row.Area}</TableCell>                                                            

                                                            {/* <TableCell style={{ fontSize: '0.75em' }}>
                                                                {
                                                                    row.tipoProgramacion === 'VERIFICACION' ? <i title="Revisión de suministro" class="fas fa-glasses fa-2x" style={{ color: 'rgb(2017,18,116)' }}></i> :
                                                                        <i title="Ejecución de Trabajo" class="fas fa-hammer fa-2x" style={{ color: 'rgb(0,75,160)' }}></i>
                                                                }
                                                            </TableCell> */}
                                                            {/* {row.tipoProgramacion} */}

                                                            <TableCell style={{ fontSize: '0.75em' }}>{
                                                                TrabajosEjecutados.length + TrabajosNoEjecutados.length === TrabajosProgramados.length ?
                                                                    <label className="fw-bold ">FINALIZADO</label>
                                                                    : (TrabajosEjecutados.length > 0 ? (TrabajosNoEjecutados.length === TrabajosProgramados.length ?
                                                                        <label className='fw-bold text-success'>COMPLETO</label> :
                                                                        <label className='text-primary'>EN EJECUCION</label>) :
                                                                        <label >PROGRAMADO</label>)
                                                            }</TableCell>

                                                            <TableCell style={{ fontSize: '0.75em' }}>{`${TrabajosEjecutados.length}/${TrabajosProgramados.length} (${Math.round((TrabajosEjecutados.length / TrabajosProgramados.length) * 100)}%)`}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{MenorFecha.hora_ejecucion}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{MayorFecha.hora_ejecucion}</TableCell>
                                                            <TableCell style={{ fontSize: '0.75em' }}>{TrabajosProgramados[0].fechaDescarga}</TableCell>
                                                            {/* <TableCell style={{ fontSize: '0.75em' }}>
                                                                <i onClick={() => { window.confirm(`¿Estas seguro de finalizar los trabajos del Trabajador ${row.nombretrabajador}?`) }} title="Cerrar Tabajo" className="fas fa-check-square  iconoAcciones" ></i>
                                                            </TableCell> */}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 15, 25, 50]}
                                        component="div"
                                        count={this.state.cantidadElementos}
                                        rowsPerPage={this.state.filasPorPagina}
                                        page={this.state.pagina - 1}
                                        onChangePage={this.CambiarPagina}
                                        onChangeRowsPerPage={this.CambioFilasPorPagina}
                                        labelRowsPerPage={"Filas Por Pagina"}
                                    />
                                </>
                                :
                                <SpinnerCarga />

                        }
                    </div>

                </div>

                {/* Modales */}

                <Modal
                    show={this.state.MostrarModalCambiarTrabajador}
                    onHide={() => { this.CerrarModalCambiarTrabajador() }}
                    keyboard={false}
                    centered >

                    <Modal.Header>
                        <Modal.Title>Cambiar Trabajador</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <small className="text-primary">Trabajador</small>
                                    <input ref={this.inputNombreTrabajador} className="form-control" placeholder="Nombre Trabajador" disabled></input>
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col">
                                    <small className="text-primary">Asignar a trabajador ...</small>
                                    <input ref={this.inputElegirTrabajador} required type="" className="form-control " list="listaTrabajadores" placeholder="Elija el Trabajador"></input>
                                    <datalist id="listaTrabajadores">
                                        {
                                            this.state.ListaTrabajadoresActivos.map((row, index) => {
                                                return (<option key={index} value={row.nombretrabajador} />)
                                            })
                                        }
                                    </datalist>
                                </div>
                            </div>
                            {/* <hr/> */}
                            <div className="row">
                                <div className="col">
                                    <small className="text-primary">Seleccione los suministros a Reasignar el Trabajador</small>
                                </div>
                                {/* Tabla de Datos */}
                                <TableContainer style={{ maxHeight: '300px' }}>
                                    <Table size='small' stickyHeader  >
                                        <TableHead >
                                            <TableRow >
                                                <TableCell><input ref={this.inputChecktodosLosElementos} onClick={(ev) => { this.SeleccionarTodosSuministros(ev) }} type="checkbox" className="form-check-input"></input></TableCell>
                                                <TableCell>Suministro</TableCell>
                                                <TableCell>Circuito</TableCell>
                                                {/* <TableCell>Distrito</TableCell> */}
                                                <TableCell>Tipo</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody ref={this.tableBodySuministros}>
                                            {this.state.ListaSuministrosProgramadoTecnico.map((suministro, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell><input onChange={(ev) => { this.SeleccionarElemento(ev, suministro) }} type="checkbox" className="form-check-input"></input></TableCell>
                                                        <TableCell>{suministro.suministro}</TableCell>
                                                        {/* <TableCell>{suministro.distrito}</TableCell> */}
                                                        <TableCell>{suministro.circuito}</TableCell>
                                                        <TableCell>{suministro.tipo}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <span className="text-primary">{`${this.state.cantidadElementosSeleccionados} seleccionados de ${this.state.ListaSuministrosProgramadoTecnico.length}`}</span>
                                </div>
                                <div className="col mt-1 text-end">
                                    <button onClick={() => { this.ReasignarSuministroATrabajador() }} className=" btn  btn-outline-secondary btn-sm" >
                                        <i className="fas fa-exchange-alt p-1"></i>
                                        <span>Asignar Trabajador</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.CerrarModalCambiarTrabajador() }} className="btn btn-outline-secondary mx-1">Cerrar</button>
                        </div>
                    </Modal.Footer>
                </Modal>


                {/* Modal Elegir Archivos Descarga */}
                <Modal
                    show={this.state.MostrarModalDescargar}
                    onHide={() => {
                        this.CerrarModalDescargarFotos()
                    }}
                    keyboard={false}
                    centered >
                    <Modal.Header>
                        <Modal.Title>{`Elegir Descarga`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">
                            {/* TIPOS DE TRABAJO */}
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Tipos de Trabajo</small>
                                    <select onChange={() => { }} ref={this.selectTipoTrabajoDescargar} className="form-select" aria-label="Default select example">
                                        <option value="TODOS">Todos</option>
                                        {this.state.TiposTrabajoFechaProgramada.map((row, index) => {
                                            return (<option key={index} value={row.tipotrabajo}>{row.tipotrabajo}</option>)
                                        })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-muted">Elije el tipo de trabajo de las fotos a Descargar. Si elije TODOS se descargarán las fotos de todos los tiempos de trabajo existentes para esta fecha.</small>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.DescargarFotosProgramacion() }} className="btn btn-outline-primary mx-1">Descargar</button>
                        </div>
                    </Modal.Footer>

                </Modal>



                {/* Modal Reporte Rango Fecha */}
                <Modal
                    show={this.state.MostrarModalReporteFechas}
                    onHide={() => {
                        this.CerrarModalReporteRangoFechas()
                    }}
                    keyboard={false}
                    centered >
                    <Modal.Header>
                        <Modal.Title>{`Elegir Fechas`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">
                            {/* TIPOS DE TRABAJO */}
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Fecha Inicio</small>
                                    <input ref={this.inputFechaInicioReporteFecha} className="form-control" type="date"></input>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Fecha Fin</small>
                                    <input ref={this.inputFechaFinReporteFecha} className="form-control" type="date"></input>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.DescargarReporteProgramacionFechas() }} className="btn btn-outline-primary mx-1">Descargar</button>
                        </div>
                    </Modal.Footer>

                </Modal>

            </Fragment>
        );



    }
}

export default monitoreo;