import React, { Component, Fragment, createRef } from 'react';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import { Link } from 'react-router-dom'
// import socketIOClient from 'socket.io-client';


import { Modal, Carousel } from 'react-bootstrap';



import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import SpinnerCarga from './../utils/spinner'
import LoadingModal from './../utils/loadModal'
import config from './../../config/config'
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS } from '../utils/utils'


//Crear zip y guardar
import { saveAs } from 'file-saver';
var zip = require('jszip')();

class monitoreoTrabajador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mostarfotos: false,
            mostrarModalDetalleSuministro: false,
            suministroActual: {},
            fechaprogramacion: '',
            idtrabajador: '',
            DatosTrabajador: { nombretrabajador: 'Cargando...', Tipo: '...', fechaProgramacion: '...' },
            loadData: false,
            tipofiltro: 'Todos',
            valorBuscar: '',
            ListaBusqueda: [],
            ListaSuministros: [],
            ListaFotosTrabajoSuministro: [],
            cantidadElementos: 0,
            filasPorPagina: 50,
            pagina: 1,
            OcultarLoading: true,
            MostrarSnackDescargaFotos: false,
            mensajeDescargaFotos: ''
        };
        this.headCells = [
            { id: 'Acciones' },
            { id: 'Suministro' },
            { id: 'Cliente' },
            { id: 'Dirección' },
            { id: 'Tipo' },
            { id: 'Estado' },
            { id: 'Foto ' },
            { id: 'Ejecutado' },
        ];

        // Referencias 
        this.selectTipoFiltro = createRef();
        this.inputBuscarFiltro = createRef();
        this.selectFiltroEstados = createRef();

        //Referencias Suministro


        //Variable controlador  Socket
        // this.socket=socketIOClient(config.endPoint,{transports: ['websocket', 'polling', 'flashsocket']});


    }

    async componentDidMount() {
        // Tamaño de Pantalla
        console.log(window.outerHeight);
        if (ValidarUsuario()) {
            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO, permisos)) {
                    await this.setState({ fechaprogramacion: this.props.match.params.fecha, idtrabajador: this.props.match.params.idtrabajador })
                    await this.BuscarDetalleTrabajo();
                    await this.BuscarSuministrosProgramados();
                    // await this.SocketConect();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }

            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    // SocketConect = () => {
    //     //Escuchar Servidor Socket
    //     this.socket.open();
    //     //Variable controlador  Socket
    //     this.socket.on("connect", () => {
    //         console.log("Conectado con Servidor socket");
    //     });

    //     this.socket.on("ActualizarTrabajosSuministro", (Data) => {
    //         //Actualizar la Lista de Trabajos
    //         console.log(`socket : ActualizarTrabajosuministro ${Data}`);            
    //         this.BuscarSuministrosProgramados();
    //     });
    // }

    // componentWillUnmount() {
    //     this.socket.close();        
    // }

    //Descargar Fotos
    DescargarFotosProgramacion = async () => {
        if (ValidarUsuario()) {
            //window.confirm('¿Estas seguro que deseas descargar todas las fotos?')            

            let url = `${config.endPoint}api/movil/listafotos/${this.state.fechaprogramacion}/${this.state.idtrabajador}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            let cantidad = 0;

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    await this.setState({ OcultarLoading: false, MostrarSnackDescargaFotos: true, mensajeDescargaFotos: `${cantidad}/${data.content.length} Fotos Descargadas` });

                    for (let x = 0; x < data.content.length; x++) {
                        await fetch(`${config.endPoint}api/foto/DownloadFileServer/${data.content[x].foto_url}`).then(async (respuesta) => {
                            cantidad++;
                            await this.setState({ mensajeDescargaFotos: `${cantidad}/${data.content.length} Fotos Descargadas` });
                            const arraybuffer = await respuesta.arrayBuffer();
                            zip.file(`${data.content[x].fechaProgramacion.trim()}/${data.content[x].tipotrabajo.trim()}/${data.content[x].trabajadorejecuta !== null ? data.content[x].trabajadorejecuta.trim() : data.content[x].trabajadorprogramado.trim()}/${data.content[x].suministro.trim()}/${data.content[x].foto_url}`, arraybuffer);
                        }
                        ).catch((error) => {
                            //console.log(error);
                        });
                    }
                    //Generar el Archivo Zip
                    await zip.generateAsync({ type: 'blob' }).then((contenido) => {
                        saveAs(contenido, `Fotos_${this.state.fechaprogramacion}.zip`);
                    });
                    //Reiniciar el zip
                    zip = require('jszip')();
                    await this.setState({ OcultarLoading: true, MostrarSnackDescargaFotos: false });
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning', OcultarLoading: true, MostrarSnackDescargaFotos: false });
                }
            }).catch((error) => {
                // console.log(error);
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true, MostrarSnackDescargaFotos: false });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    BuscarDetalleTrabajo = () => {
        let url = `${config.endPoint}api/trabajosuministro/detallejornada/trabajador/${this.state.fechaprogramacion}/${this.state.idtrabajador}`
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let headers = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            }
        };
        fetch(url, headers).then((respuesta) => {
            return respuesta.json();
        }).then(async (data) => {
            if (data.content.length > 0) {
                await this.setState({ DatosTrabajador: data.content[0] });
            }
        }).catch(() => {
            this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
        });
    }
    BuscarSuministrosProgramados = () => {
        if (ValidarUsuario()) {
            let url = "";
            if (this.state.valorBuscar.trim() !== '') {
                switch (this.state.tipofiltro) {
                    case "Todos":
                        url = `${config.endPoint}api/trabajosuministro/listar/trabajos/${this.state.fechaprogramacion}/${this.state.idtrabajador}}`;
                        break;
                    case "Suministro":
                        url = `${config.endPoint}api/trabajosuministro/listar/trabajosfiltroSuministro/${this.state.fechaprogramacion}/${this.state.idtrabajador}/${this.state.valorBuscar}`
                        break;
                }
            }
            else {
                url = `${config.endPoint}api/trabajosuministro/listar/trabajos/${this.state.fechaprogramacion}/${this.state.idtrabajador}`;
            }

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    // console.log(data.content);
                    await this.setState({ ListaSuministros: data.content.rows, cantidadElementos: data.content.rows.length, LoadData: true });
                }
                else {
                    await this.setState({ ListaSuministros: [], cantidadElementos: 0, LoadData: true });
                }
                await this.filtroEstadosTrabajos();
                await this.Paginacion();
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    filtroEstadosTrabajos = () => {
        // console.log(this.state.ListaSuministros);
        let arrayTemp = [];
        switch (this.selectFiltroEstados.current.value) {
            case 'EJECUTADO':
                arrayTemp = this.state.ListaSuministros.filter(x => x.estado !== 'PROGRAMADO' && x.estado !== 'NO EJECUTADO');
                this.setState({ ListaSuministros: arrayTemp, cantidadElementos: arrayTemp.length });
                break;
            case 'NOEJECUTADO':
                arrayTemp = this.state.ListaSuministros.filter(x => x.estado === 'NO EJECUTADO');
                this.setState({ ListaSuministros: arrayTemp, cantidadElementos: arrayTemp.length });
                break;
            case 'PENDIENTES':
                arrayTemp = this.state.ListaSuministros.filter(x => x.estado === 'PROGRAMADO');
                this.setState({ ListaSuministros: arrayTemp, cantidadElementos: arrayTemp.length });
                break;
            case 'IMPEDIMENTO':
                arrayTemp = this.state.ListaSuministros.filter(x => x.estado === 'IMPEDIMENTO');
                this.setState({ ListaSuministros: arrayTemp, cantidadElementos: arrayTemp.length });
                break;
        }

    }
    Paginacion = async () => {
        let filainicio = 0;
        if (this.state.ListaSuministros.length > 0) {
            filainicio = parseInt(this.state.filasPorPagina) * (parseInt(this.state.pagina) - 1);
        }
        let filafin = filainicio + this.state.filasPorPagina;
        let nuevoArray = [];
        for (let x = filainicio; x < this.state.ListaSuministros.length; x++) {
            if (x < filafin) {
                nuevoArray.push(this.state.ListaSuministros[x]);
            }
        }
        await this.setState({ ListaSuministros: nuevoArray, LoadData: true });
    }

    CambioSelectTipo = async () => {
        await this.setState({ tipofiltro: this.selectTipoFiltro.current.value });
        // await this.BuscarSuministrosProgramados();
    }
    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.setState({ LoadData: false, pagina: 1, valorBuscar: this.inputBuscarFiltro.current.value });
            await this.BuscarSuministrosProgramados();
        }
    }
    changefiltrobuscar = async (ev) => {
        if (ev.target.value === '') {
            console.log("vacio");
            await this.setState({ LoadData: false, pagina: 1, valorBuscar: this.inputBuscarFiltro.current.value });
            this.BuscarSuministrosProgramados();
        }
    }
    elegirEstadoSuministro = async () => {
        await this.setState({ LoadData: false, pagina: 1 });
        this.BuscarSuministrosProgramados()
    }

    finalizarJornadaTrabajo = () => {
        if (window.confirm('Todos los trabajos pendietes pasaran a estado NO EJECUTADO\n¿Estas Seguro de Finalizar y cerrar todos los trabajos para esta fecha de programación?')) {
            // console.log("Confirmacion finalizar trabajos");
            let url = `${config.endPoint}api/trabajosuministro/finalizarjornada/${this.state.fechaprogramacion}/${this.state.idtrabajador}`
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                // console.log(data);
                if (data.content) {
                    this.setState({ mensajeToast: `Se finalizaron ${data.content} trabajos.`, abrirToast: true, tipotoast: 'success' });
                    await this.setState({ LoadData: false, pagina: 1 });
                    this.BuscarSuministrosProgramados();
                } else {
                    this.setState({ mensajeToast: `No actualizado,`, abrirToast: true, tipotoast: 'info' });
                }
            }).catch((error) => {
                console.log(error);
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
    }
    // Ventanas Modales

    AbrirModalFotos = async (trabajoSuministro) => {
        await this.setState({ ListaFotosTrabajoSuministro: [] });
        if (trabajoSuministro.t_fotos.length > 0) {
            // Llenar Lista de fotos
            await this.setState({ ListaFotosTrabajoSuministro: trabajoSuministro.t_fotos });
            await this.setState({ mostarfotos: true });
        }
        else {
            this.setState({ mensajeToast: "No se encontraron fotos para este suministro", abrirToast: true, tipotoast: 'info' });
        }
    }
    CerrarModalFotos = () => {
        this.setState({ mostarfotos: false });
    }
    // Modal Detalle suministro
    AbrirModalDetalleSuministro = (trabajosuministro) => {        
        this.setState({ mostrarModalDetalleSuministro: true, suministroActual: trabajosuministro });
    }
    CerrarModalDetalleSuministro = () => {
        this.setState({ mostrarModalDetalleSuministro: false });
    }
    //*************************************************
    //Funciones para Paginacion
    //*************************************************
    CambioFilasPorPagina = async (event) => {
        await this.setState({ LoadData: false, filasPorPagina: parseInt(event.target.value), pagina: 1 });
        await this.BuscarSuministrosProgramados();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ LoadData: false, pagina: (parseInt(nuevaPagina) + 1) });
        await this.BuscarSuministrosProgramados();
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    render() {
        return (
            <Fragment>
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                {/* ************************************************* */}
                {/* Snack Carga */}
                {/* ************************************************* */}
                <Snackbar
                    open={this.state.MostrarSnackDescargaFotos}
                    message={this.state.mensajeDescargaFotos}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                />

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo="MONITOREO-TRABAJADOR" regresar={() => { this.props.history.push('/monitoreo') }}></TituloPagina>

                {/* Contenedor de Datos */}
                <div className="container border mt-1">
                    <div className="row bg-secondary text-light mx-1 mt-1 py-1 rounded">
                        <div className="col-md-8 ">
                            <span >{`${this.state.DatosTrabajador.nombretrabajador} - (${this.state.DatosTrabajador.Tipo})`}</span>
                        </div>
                        <div className="col-md-4 text-lg-end">
                            <span>{`Programación : ${this.state.DatosTrabajador.fechaProgramacion}`}</span>
                        </div>
                    </div>

                    {/* Opciones de busqueda */}
                    <div className="row mt-1">
                        <div className=" col-md-6 col-lg-3 mt-1 mt-md-0">
                            <select ref={this.selectFiltroEstados} onChange={() => { this.elegirEstadoSuministro() }} className="form-select form-select-sm" >
                                <option value="TODOS">Todos los Estados</option>
                                <option value="EJECUTADO">Ejecutados</option>
                                <option value="NOEJECUTADO">No Ejecutados</option>
                                <option value="PENDIENTES">Pendientes</option>
                                <option value="IMPEDIMENTO">Impedimentos</option>
                            </select>
                        </div>
                        <div className=" col-md-6  col-lg-3 mt-1 mt-md-0">
                            <select ref={this.selectTipoFiltro} onChange={() => { this.CambioSelectTipo() }} className="form-select form-select-sm" >
                                <option value="Todos">Todos los Suministros</option>
                                <option value="Suministro">Numero de Suministro</option>
                                {/* <option value="Supervisor">Supervisor</option>
                                            <option value="Estado">Estado</option> */}
                            </select>
                        </div>
                        <div className="col-md-6 col-lg-3 mt-1 mt-lg-0">
                            {
                                this.state.tipofiltro !== "Todos" ?
                                    <>
                                        <input onChange={(ev) => { this.changefiltrobuscar(ev) }} onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} ref={this.inputBuscarFiltro} className="form-control form-control-sm" list="listaTrabajadoresProgramados" placeholder={`Buscar ${this.state.tipofiltro}`}></input>
                                    </>
                                    : <></>
                            }

                        </div>
                        {/* Opciones */}
                        <div className="col-md-6 col-lg-3 text-end mt-1 mt-lg-0">
                            <div className="dropdown">
                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                    Opciones
                                </button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                    <li onClick={() => { this.finalizarJornadaTrabajo() }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Finalizar Jornada</small></li>
                                    <li onClick={() => { this.DescargarFotosProgramacion() }}><small className="dropdown-item" ><i className="fas fa-download mx-2"></i>Descargar Fotos</small></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    {/* <li onClick={() => { window.confirm('¿Ver sum sin foto?') }}><small className="dropdown-item" ><i className="fas fa-exclamation-circle mx-2"></i>Mostrar Suministros Sin Foto</small></li>
                                    <li onClick={() => { window.confirm('¿Ver suministros con fotos faltantes ?') }}><small className="dropdown-item" ><i className="fas fa-photo-video mx-2"></i>Mostrar Suministros Falta Foto</small></li> */}
                                    {/* <li><hr className="dropdown-divider" /></li> */}
                                    <li>
                                        <Link to={{ pathname: `/monitoreo/trabajador/mapa/${this.state.fechaprogramacion}/${this.state.idtrabajador}`, state: { nombretrabajador: this.state.DatosTrabajador.nombretrabajador } }} className="dropdown-item" ><small><i className="fas fa-globe-americas mx-2"></i>Ver En Mapa</small></Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Aqui inicia la Tabla */}

                    <div className="row p-1">
                        {
                            this.state.LoadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer style={{maxHeight: window.outerHeight<760?430:650}}>
                                        <Table size='small' stickyHeader >
                                            <TableHead 
                                            // className="fondoTituloTabla"
                                            >
                                                <TableRow>
                                                    {
                                                        this.headCells.map((head, index) => {
                                                            return (
                                                                <TableCell 
                                                                // style={{ color: 'aliceblue' }}
                                                                 key={index}>{head.id}</TableCell>
                                                            );
                                                        })
                                                    }

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.ListaSuministros.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell style={{ fontSize: '0.8em' }}>
                                                                {/* <Link to={{
                                                                    pathname: `/monitoreo/suministro/${row.idTrabajosuministro}`,
                                                                    state: {
                                                                        DatosTrabajadorSuministro: this.state.DatosTrabajador
                                                                    }
                                                                }}>
                                                                    <i title={`Ver Suministro ${row.suministro}`} className="fas fa-eye  mx-2 iconoAcciones" style={{ fontSize: '1.5em' }}></i>
                                                                </Link> */}
                                                                <i onClick={() => { this.AbrirModalDetalleSuministro(row) }} title={`Ver Suministro ${row.suministro}`} className="fas fa-eye  mx-2 iconoAcciones" style={{ fontSize: '1.5em' }}></i>
                                                            </TableCell >
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.suministro}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.nombreCliente}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.direccionCliente}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.tipo}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{
                                                                row.estado === 'NO EJECUTADO' || row.estado === 'IMPEDIMENTO' ?
                                                                    <label className="text-danger">{row.estado}</label> :
                                                                    (row.estado === 'PROGRAMADO' ?
                                                                        <label >{row.estado}</label> :
                                                                        <label className="fw-bold text-success">{row.estado}</label>)

                                                            }
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}><i onClick={() => { this.AbrirModalFotos(row) }} className="fas fa-camera iconoAcciones"></i>{`${row.t_fotos.length}/${row.fotosTomadas}`}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{`${row.fecha_ejecucion ? row.fecha_ejecucion : ''} ${row.hora_ejecucion ? row.hora_ejecucion : ''}`}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 30, 50]}
                                        component="div"
                                        count={this.state.cantidadElementos}
                                        rowsPerPage={this.state.filasPorPagina}
                                        page={this.state.pagina - 1}
                                        onChangePage={(ev, npage) => { this.CambiarPagina(ev, npage) }}
                                        onChangeRowsPerPage={(ev) => { this.CambioFilasPorPagina(ev) }}
                                        labelRowsPerPage={"Filas Por Pagina"}
                                    />

                                </>
                                : <SpinnerCarga />
                        }

                    </div>
                </div>

                {/* Modal Fotos*/}

                <Modal
                    show={this.state.mostarfotos}
                    onHide={() => { this.CerrarModalFotos() }}
                    keyboard={false}
                    size="lg"
                    centered >
                    {/* <Modal.Header>
                        <Modal.Title>Fotos</Modal.Title>
                    </Modal.Header> */}

                    <Modal.Body style={{ backgroundColor: 'rgb(33,37,41)' }}>
                        <div className="container" >
                            <div className="row" style={{ maxHeight: '600px' }}>
                                <Carousel wrap={false} >
                                    {
                                        this.state.ListaFotosTrabajoSuministro.map((foto, index) => {
                                            return (
                                                <Carousel.Item key={index} >
                                                    <div >
                                                        <img
                                                            className="d-block m-auto"
                                                            src={`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`}
                                                            alt={`Imagen ${index + 1}`}
                                                            height='550'
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`, '_blank'); }}
                                                        />
                                                        <div className="row ">
                                                            <div className="col-6 col-md-6 text-light">
                                                                <span>{`Foto ${index + 1} de ${this.state.ListaFotosTrabajoSuministro.length}`}</span>
                                                            </div>
                                                            <div className="col-6 col-md-6 text-end text-light">
                                                                <span>{`${foto.fecha} ${foto.hora}`}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Carousel.Item>

                                            );
                                        })
                                    }
                                </Carousel>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Modal Detalle Suministro*/}

                <Modal
                    show={this.state.mostrarModalDetalleSuministro}
                    onHide={() => { this.CerrarModalDetalleSuministro() }}
                    keyboard={false}
                    size="lg"
                    centered >
                    <Modal.Header>
                        <Modal.Title>
                            <div>
                                <small className="text-primary">Suministro/Contrato: </small>
                                <label>{this.state.suministroActual.suministro}</label>
                            </div>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        {/* Datos de Cliente */}
                        <div className="container fluid">
                            <div className=" border rounded p-1">
                                <div className="row">
                                    <div className="col">
                                        <small className="text-primary">Cliente</small>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label>{this.state.suministroActual.nombreCliente}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <small className="text-primary">Dirección</small>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label>{this.state.suministroActual.direccionCliente}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <small className="text-primary">SED</small>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label>{this.state.suministroActual.subEstacion}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <small className="text-primary">Suministro/Ruta</small>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label>{this.state.suministroActual.suministro_ruta}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <small className="text-primary">Serie Medidor</small>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label>{this.state.suministroActual.serie}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <small className="text-primary">Fecha de Pago</small>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label>{this.state.suministroActual.fechaDePago}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Datos de Trabajo */}
                            <div className="border rounded p-1 mt-1">
                                <div className="row">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <small className="text-primary">OT</small>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label>{this.state.suministroActual.ot}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <small className="text-primary">Orden Trabajo</small>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label>{this.state.suministroActual.ordenTrabajo}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <small className="text-primary">Detalle Trabajo</small>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label>{this.state.suministroActual.detalle}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <small className="text-primary">Tipo Trabajo</small>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label>{this.state.suministroActual.tipo}</label>
                                    </div>
                                </div>
                            </div>

                            {/* Detalle ejecucion Trabajo */}
                            <div className="border rounded border-success rounted p-1 mt-1">
                                <div className="row">
                                    <div className="row">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <small className="text-primary">Estado</small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label>{this.state.suministroActual.estado}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <small className="text-primary">Fecha Ejecución</small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label>{this.state.suministroActual.fecha_ejecucion} {this.state.suministroActual.hora_ejecucion}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <small className="text-primary">Observación Ejecución</small>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <label>{this.state.suministroActual.observacion_ejecucion}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </Fragment>
        );
    }
}

export default monitoreoTrabajador;