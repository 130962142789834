import React, { createRef, Fragment, useState } from 'react';
import NavBar from './../dashboard/navBar';

// React LeafLet
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, Tooltip, Circle } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';
//Boton flotante
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
//Iconos Personalizados
import { IconoPinRojo, IconoPinAzul, IconoPinVerde, IconoMarkerRojo, PinUbicacion, PinUbicacionRojo, PinUbicacionVerde, PinUbicacionNaranja } from './../../config/icono'
//Locate control
import SpinnerCarga from './../utils/spinner'
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS } from './../utils/utils';
import config from './../../config/config';

import { Alert } from '@material-ui/lab'
import { Snackbar } from '@material-ui/core'
import LoadingModal from './../utils/loadModal'

import { Modal } from 'react-bootstrap';
import { isNumber } from '@turf/turf';

// function LocationMarker() {
//     const [position, setPosition] = useState(null)

//     const map = useMapEvents({
//         click() {
//             map.locate()
//         },
//         load() {
//             map.locate()
//         },
//         locationfound(e) {
//             setPosition(e.latlng)
//             map.flyTo(e.latlng, map.getZoom())
//         },
//     })

//     return position === null ? null : (
//         <Marker position={position} icon={IconoPinRojo}>
//             <Popup>You are here</Popup>
//         </Marker>
//     )
// }


class BuscarUbicacion extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            OcultarLoading: true,
            MostrarModalElegirOrigen: false,
            MostrarModalElegirLibro: false,
            mensajeToast: '',
            abrirToast: false,
            tipotoast: 'warning',
            posicionUsuario: null,
            presicionGPs: 10,
            loadData: false,
            centro: {
                lat: -16.398821,
                lng: -71.536862
            },
            mapRef: null,
            zoomMapa: 13,
            geolizationStatus: false,
            listaGPS: [],
            listaBusqueda: [],
            ListaZonasSector: [],
            ListaLibros: [],
            EstaMapeadoLibro: false,
            zonaelegida: "",
            listaSuministrosLibro: [],
            deshabiliarCombosOrigen: true,
            opcionElegirGPS: '',
            idcampaniaElegida: '',
            mensajeEstadoGps: '',
            opcionElegida: '',
            tiempoRecargaGPs: 0

        };
        this.navbarref = createRef();
        this.divref = createRef();
        // Referencias
        this.selectBuscar = createRef();
        this.inputBuscar = createRef();
        this.divelElegirOrigen = createRef();

        //Referencia elegir Libro
        this.selectElegirZona = createRef();
        this.selectElegirSector = createRef();
        this.selectElegirLibro = createRef();

    }

    async componentDidMount() {
        // *************************************************
        // Verificar Si esta activado la Geolicalizacio
        // *************************************************
        this.SolicitarAccesoGeolication();
        this.setState({ tiempoRecargaGPs: config.tiempoRecargaGPs });

        if (ValidarUsuario()) {
            listarPermisos().then((permisos) => {
                // console.log("Permisos de usuario");
                // console.log(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BUSCAR_UBICACION, permisos)) {
                    // mostrar Mapa
                    this.setState({ loadData: true });
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }
            });
        } else {
            this.props.history.push('/login');
        }
    }
    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    BuscarUbicaciones = async () => {
        await this.setState({ OcultarLoading: false });

        if (this.inputBuscar.current.value !== '') {
            this.inputBuscar.current.classList.remove('is-invalid');
            await this.setState({ listaGPS: [], listaBusqueda: [] });

            if (ValidarUsuario()) {
                let url = '';
                if (this.selectBuscar.current.value == "suministro") {
                    url = `${config.endPoint}api/basesuministros/buscarGPS/${this.inputBuscar.current.value}`
                } else {
                    url = `${config.endPoint}api/basesuministros/buscarGPS_nim/${this.inputBuscar.current.value}`
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                await fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    // console.log(data);
                    if (data.content) {
                        if (data.content.length > 0) {
                            let gps = [];
                            gps.push(data.content[0]);
                            this.setState({ listaBusqueda: data.content, listaGPS: gps });
                            this.MoverMapa(data.content[0].lat, data.content[0].lng, 15);
                        }
                        else {
                            this.setState({ mensajeToast: "No se encontraron ubicaciones GPs para esta busqueda.", abrirToast: true, tipotoast: 'warning' });
                        }
                        await this.setState({ OcultarLoading: true });
                    }
                    else {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                    }
                }).catch((error) => {
                    // this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                    this.setState({ mensajeToast: error.message, abrirToast: true, tipotoast: 'error', OcultarLoading: true });
                });
            }
            else {
                this.props.history.push('/login');
            }

        } else {
            this.setState({ mensajeToast: "Ingrese los datos de busqueda", abrirToast: true, tipotoast: 'error', OcultarLoading: true });
            this.inputBuscar.current.classList.add('is-invalid');
        }
    }

    habiliarComboElegir = (e) => {
        this.setState({ deshabiliarCombosOrigen: false, opcionElegirGPS: e.target.value });
    }
    habiliarComboTodos = (e) => {
        this.setState({ deshabiliarCombosOrigen: true, opcionElegirGPS: e.target.value });
    }
    elegirOpcionOrigen = (e) => {
        this.setState({ idcampaniaElegida: e.target.value });
    }

    AplicarFiltroElegirOrigen = () => {
        // console.log(this.state.opcionElegirGPS);
        // console.log(this.state.idcampaniaElegida);  
        switch (this.state.opcionElegirGPS) {
            case "mapear":
                // Mapear todos los  GPS encontrados
                this.setState({ listaGPS: this.state.listaBusqueda, MostrarModalElegirOrigen: false });
                break;
            case "elegirorigen":
                // Elegir origen de GPS
                if (this.state.idcampaniaElegida !== "") {
                    // Buscar Identificador
                    let elemento = this.state.listaBusqueda.filter(e => e.idBaseSuministro == this.state.idcampaniaElegida);
                    // console.log(elemento);
                    this.setState({ listaGPS: elemento, MostrarModalElegirOrigen: false });

                } else {
                    this.setState({ mensajeToast: "Elija El origen del punto GPS .", abrirToast: true, tipotoast: 'warning' });
                }
                break;
        }

    }

    SolicitarAccesoGeolication = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({ geolizationStatus: true, mensajeEstadoGps: 'Geolocation GRANTED' });
                this.setState({ posicionUsuario: { lat: position.coords.latitude, lng: position.coords.longitude } });

                // Habilitar actualizacion de GPS
                this.intervalId = setInterval(this.ActualizarPosicionGPs, this.state.tiempoRecargaGPs);

            }, (error) => {
                this.setState({ geolizationStatus: false, mensajeEstadoGps: 'Geolocation DENIED' });
                this.setState({ abrirToast: true, mensajeToast: error.message, tipotoast: 'warning' });
            });
        }
        else {
            this.setState({ geolizationStatus: false, mensajeEstadoGps: 'This browser not suport GEOLOCATION' });
            this.setState({ abrirToast: true, mensajeToast: "Este navegador no acepta Geolocalizacion", tipotoast: 'warning' });
        }
    }

    ActualizarPosicionGPs = () => {

        if (this.state.geolizationStatus) {
            navigator.geolocation.getCurrentPosition((position) => {
                let presicion = position.coords.accuracy;
                this.setState({ posicionUsuario: { lat: position.coords.latitude, lng: position.coords.longitude } });
                if (isNumber(presicion)) {
                    this.setState({ presicionGPs: presicion });
                }
                // console.log("Actualizando Posicion");
                // console.log("Impresion prueba");
                // console.log(this.state.posicionUsuario);
                // console.log("Presicion");
                // console.log(presicion);

            }, (error) => {
                this.setState({ geolizationStatus: false });
                // Terminar intervalo                
                clearInterval(this.intervalId);
            });
        } else {
            clearInterval(this.intervalId);
        }
    }

    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            this.BuscarUbicaciones()
        }
    }
    MoverMapa = (lat, lng, zoom) => {
        const { mapRef } = this.state;
        mapRef.flyTo([lat, lng], zoom);
    }
    AbrirMaps = (lat, lng) => {
        let _lat = parseFloat(lat);
        let _lng = parseFloat(lng);

        // console.log(_lat);
        if (_lat !== 0 && !isNaN(_lat)) {
            let url = `http://maps.google.com/?q=${_lat},${_lng}`;
            window.open(url, '_blank');
        } else {
            this.setState({ mensajeToast: "Ubicacion GPS no valida.", abrirToast: true, tipotoast: 'warning' });
        }

    }
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    AbrirModalElegirOrigen = () => {
        this.setState({ MostrarModalElegirOrigen: true, opcionElegirGPS: '', idcampaniaElegida: '', deshabiliarCombosOrigen: true });
        // console.log(this.state.listaBusqueda);
    }
    CerrarModalElegirOrigen = () => {
        this.setState({ MostrarModalElegirOrigen: false });
    }

    ElegirOpcion = (e) => {
        // console.log(e.target.value);
        this.setState({ opcionElegida: e.target.value, listaGPS: [], listaSuministrosLibro: [] });
        // Activar Intervalo
        clearInterval(this.intervalId);
        this.intervalId = setInterval(this.ActualizarPosicionGPs, config.tiempoRecargaGPs);

    }

    AbrirModalElegirLibro = () => {
        this.setState({ OcultarLoading: false, ListaZonas: [], ListaZonasSector: [], listaSuministrosLibro: [] });
        this.BuscarZonaSector().then(() => {
            // Llenar Lista de Zonas
            if (this.state.ListaZonasSector) {
                // console.log(this.state.ListaZonasSector);

                // let arrayZonas=[];
                // for( let x=0;x<this.state.ListaZonasSector.length;x++){
                //     let existe=false;
                //     for(let y=0;y<arrayZonas.length;y++){
                //         if(arrayZonas[y]===this.state.ListaZonasSector[x].zona){
                //             existe=true;
                //             break;
                //         }
                //     }
                //     if(!existe){
                //         arrayZonas.push(this.state.ListaZonasSector[x].zona)
                //     }
                // }
                // console.log(arrayZonas);
                this.setState({ MostrarModalElegirLibro: true, OcultarLoading: true });
            }
            else {
                this.setState({ abrirToast: true, mensajeToast: "No se encotraron datos para cargar.", tipotoast: 'warning' });
            }
        }).catch(() => {
            this.setState({ OcultarLoading: false });
        });
    }

    CerrarModalElegirLibro = () => {
        this.setState({ MostrarModalElegirLibro: false });
    }
    BuscarZonaSector = () => {

        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/basesuministrosreparto/listaSectores`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        this.setState({ ListaZonasSector: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaZonasSector: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }

    ElegirSector = async () => {
        // console.log(this.selectElegirZona.current.value);
        await this.setState({ ListaLibros: [], listaSuministrosLibro: [] })

        if (this.selectElegirZona.current.value != "") {
            this.selectElegirZona.current.classList.remove('is-invalid');

            let url = `${config.endPoint}api/basesuministrosreparto/listaLibros`;
            let UsuarioStorage = ObtenerUsuario();

            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ zonasector: this.selectElegirZona.current.value })
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    // console.log(data.content);
                    this.setState({ ListaLibros: data.content });
                    // resolve(true);
                }
                else {
                    this.setState({ ListaLibros: [] })
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    // resolve(false);
                }

            }).catch((errr) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                // resolve(false);
            });

        } else {
            this.selectElegirZona.current.classList.add('is-invalid');
        }
    }

    BuscarSuministrosSectorLibro = async () => {
        return new Promise((resolve, reject) => {
            let url = `${config.endPoint}api/basesuministrosreparto/listasuministrosSectorLibro`;
            let UsuarioStorage = ObtenerUsuario();

            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(
                    {
                        zonasector: this.selectElegirZona.current.value,
                        libro: this.selectElegirLibro.current.value
                    }
                )
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    // console.log(data.content);
                    if (data.content.length > config.cantidadMaximaMArcadores) {
                        // console.log("Incrementar Intervalo");                        
                        clearInterval(this.intervalId);
                        this.intervalId = setInterval(this.ActualizarPosicionGPs, 1800000);
                    } else {
                        // console.log("Activar Intervalo");
                        // this.setState({tiempoRecargaGPs:config.tiempoRecargaGPs});
                        clearInterval(this.intervalId);
                        this.intervalId = setInterval(this.ActualizarPosicionGPs, config.tiempoRecargaGPs);
                    }

                    this.setState({ listaSuministrosLibro: data.content });
                    if (data.content.length > 0) {
                        this.MoverMapa(data.content[0].lat, data.content[0].lng, 15);
                    }
                    resolve(true);
                }
                else {
                    this.setState({ listaSuministrosLibro: [] })
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    resolve(false);
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                resolve(false);
            });

        })
    }

    ElegirLibro = () => {
        if (this.selectElegirLibro.current.value != "") {
            this.selectElegirLibro.current.classList.remove('is-invalid');

        } else {
            this.selectElegirLibro.current.classList.add('is-invalid');
        }
    }

    DibujarSuministrosLibro = async () => {
        // Validar selecciones
        await this.setState({ listaSuministrosLibro: [], OcultarLoading: false, zonaelegida: "", EstaMapeadoLibro: false });

        if (this.selectElegirZona.current.value != "") {
            this.selectElegirZona.current.classList.remove('is-invalid');
            if (this.selectElegirLibro.current.value != "") {
                this.selectElegirLibro.current.classList.remove('is-invalid');
                // Buscar lista de suministros por zona y libro
                this.BuscarSuministrosSectorLibro().then(() => {
                    this.setState({ OcultarLoading: true, zonaelegida: `${this.selectElegirZona.current.value} - Libro:${this.selectElegirLibro.current.value}`, EstaMapeadoLibro: true });

                    // console.log(this.state.listaSuministrosLibro);
                    this.CerrarModalElegirLibro();
                }).catch(() => {
                    
                    this.setState({ OcultarLoading: true });
                });
            } else {
                this.selectElegirLibro.current.classList.add('is-invalid');
            }

        } else {
            this.selectElegirZona.current.classList.add('is-invalid');
        }

    }


    render() {
        return (
            <Fragment>
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar />
                {/* <TituloPagina titulo="BUSCAR" regresar={() => { this.props.history.push('/') }} /> */}
                <div className='container' ref={this.divref}>
                    <div className='row mt-1'>
                        <div className='col-12 col-lg-4 '>
                            <select onChange={(e) => { this.ElegirOpcion(e) }} ref={this.selectBuscar} className="form-select form-select-sm" aria-label="Default select example">
                                <option value="suministro">Por Suministro</option>
                                <option value="nim">Por NIM</option>
                                <option value="libro">Por Libro</option>
                            </select>
                        </div>
                        {
                            this.state.opcionElegida === "libro" ?
                                <></>
                                :
                                <div className='col-12 col-lg-4 mt-1 m-lg-0'>
                                    <input onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} ref={this.inputBuscar} className="form-control form-control-sm" placeholder="Buscar"></input>
                                </div>
                        }

                        {
                            this.state.opcionElegida === "libro" ?
                                <div className='col-12 col-lg-4 d-grid gap-2 mt-1 m-lg-0'>
                                    <button onClick={() => { this.AbrirModalElegirLibro() }} className='btn btn-sm btn-primary'>Elegir Libro</button>
                                </div>
                                : <></>
                        }
                        {
                            this.state.opcionElegida === "libro" ?
                                <></>
                                :
                                <div className='col-12 col-lg-4 d-grid gap-2 mt-1 m-lg-0'>
                                    <button onClick={this.BuscarUbicaciones} className='btn btn-sm btn-primary'>Buscar</button>
                                </div>
                        }
                        {
                            this.state.opcionElegida === "libro" ?
                                <div className='col-12 col-lg-4 d-grid gap-2 mt-1 m-lg-0'>
                                    <small className='text-primary'>{`${this.state.zonaelegida} - ${this.state.listaSuministrosLibro.length} Suministros`}</small>
                                </div>
                                :
                                <></>
                        }

                    </div>
                </div>
                <div className="container-fluid mt-1 " style={{ height: `80vh` }}>
                    {
                        this.state.loadData ?
                            <>
                                <MapContainer whenCreated={(map) => { this.setState({ mapRef: map }) }} center={this.state.centro} zoom={this.state.zoomMapa} style={{ height: `80vh` }}>
                                    {/* <TileLayer
                                        url='https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png'
                                        maxZoom={20}
                                        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                                    /> */}
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"

                                    />
                                    {/* <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://a.tile.openstreetmap.de/${z}/${x}/${y}.png"

                                    /> */}

                                    {/* <LocationMarker /> */}
                                    {
                                        this.state.listaGPS.map((row, index) => {

                                            // console.log(row);
                                            let offset = index * 15;
                                            let origen = row.origen.toUpperCase();
                                            if (origen.includes('INSPECCION')) {
                                                return (
                                                    <Marker key={index} position={[row.lat, row.lng]} icon={IconoPinVerde}>
                                                        <Popup>
                                                            <div>
                                                                <label className="text-primary">Suministro :</label><span>{row.contrato}</span>
                                                                <label className="text-primary mx-1">Cliente :</label><span>{row.nombres}</span>
                                                                <label className="text-primary mx-1">Dirección :</label><span>{row.direccion}</span>
                                                                <label className="text-primary mx-1">SED :</label><span>{row.sed}</span>
                                                                <label className="text-primary mx-1">NIM :</label><span>{row.nim}</span>
                                                            </div>
                                                            <div>
                                                                <label className="text-primary">Zona :</label><span>{row.zona}</span>
                                                                <label className="text-primary mx-1">Sector :</label><span>{row.sector}</span>
                                                                <label className="text-primary mx-1">Libro :</label><span>{row.libro}</span>
                                                                <label className="text-primary mx-1">Hoja :</label><span>{row.hoja}</span>
                                                            </div>
                                                            <div>
                                                                <label className='text-primary'>Origen GPS:</label><span>{row.origen + row.campania}</span>
                                                            </div>

                                                            <div onClick={() => { this.AbrirMaps(row.lat, row.lng) }} className='text-end'>
                                                                <small className="link-primary" style={{ cursor: 'pointer', textDecoration: 'underline' }}>Ir a Maps <img src='img/maps.svg' height={25} /> </small>
                                                            </div>
                                                        </Popup>
                                                        <Tooltip opacity={0.8} offset={[15, 20]}>
                                                            <div>
                                                                <strong>Contrato:</strong><span>{row.contrato}</span>
                                                            </div>
                                                            <div>
                                                                <strong>Origen:</strong><span>{row.origen + row.campania}</span>
                                                            </div>
                                                        </Tooltip>
                                                    </Marker>
                                                )
                                            }
                                            else {
                                                return (
                                                    <Marker key={index} position={[row.lat, row.lng]} icon={IconoPinAzul}>
                                                        {/* <Tooltip permanent opacity={0.6} offset={[0, offset]}>
                                                            <span>{row.contrato}</span>                                                            
                                                        </Tooltip> */}
                                                        <Popup>
                                                            <div>
                                                                <label className="text-primary">Suministro :</label><span>{row.contrato}</span>
                                                                <label className="text-primary mx-1">Cliente :</label><span>{row.nombres}</span>
                                                                <label className="text-primary mx-1">Dirección :</label><span>{row.direccion}</span>
                                                                <label className="text-primary mx-1">SED :</label><span>{row.sed}</span>
                                                                <label className="text-primary mx-1">NIM :</label><span>{row.nim}</span>
                                                            </div>
                                                            <div>
                                                                <label className="text-primary">Zona :</label><span>{row.zona}</span>
                                                                <label className="text-primary mx-1">Sector :</label><span>{row.sector}</span>
                                                                <label className="text-primary mx-1">Libro :</label><span>{row.libro}</span>
                                                                <label className="text-primary mx-1">Hoja :</label><span>{row.hoja}</span>
                                                            </div>
                                                            <div>
                                                                <label className='text-primary'>Origen GPS:</label><span>{row.origen + row.campania}</span>
                                                            </div>

                                                            <div onClick={() => { this.AbrirMaps(row.lat, row.lng) }} className='text-end'>
                                                                <small className="link-primary" style={{ cursor: 'pointer', textDecoration: 'underline' }}>Ir a Maps <img src='img/maps.svg' height={25} /> </small>
                                                            </div>
                                                        </Popup>
                                                        <Tooltip opacity={0.9} offset={[15, 20]}>
                                                            <div>
                                                                <strong>Contrato:</strong><span>{row.contrato}</span>
                                                            </div>
                                                            <div>
                                                                <strong>Origen:</strong><span>{row.origen + row.campania}</span>
                                                            </div>
                                                        </Tooltip>
                                                    </Marker>
                                                )
                                            }
                                        })
                                    }

                                    {/* Dubujar Suministros de Libro */}

                                    {
                                        // this.state.EstaMapeadoLibro?
                                        // <></>
                                        // :
                                        this.state.listaSuministrosLibro.map((row, index) => {
                                            // console.log(`Renderizando ${row.id}`); 
                                            if (row.muestra != "") {
                                                return (
                                                    <Marker key={row.id} position={[row.lat, row.lng]} icon={PinUbicacionRojo}>
                                                        <Popup>
                                                            <div>
                                                                <label className="text-primary">Suministro :</label><span>{row.contrato}</span>
                                                                <label className="text-primary mx-1">Cliente :</label><span>{row.nombres}</span>
                                                                <label className="text-primary mx-1">Dirección Entrega :</label><span>{row.direccionentrega}</span>
                                                            </div>
                                                            <div>
                                                                <label className="text-primary">Tipo :</label><span>{row.muestra}</span>
                                                            </div>
                                                        </Popup>

                                                        <Tooltip opacity={0.8} >
                                                            <small>{row.contrato}</small>
                                                        </Tooltip>
                                                    </Marker>

                                                )

                                            } else {
                                                return (
                                                    <Marker key={row.id} position={[row.lat, row.lng]} icon={PinUbicacionNaranja}>
                                                        <Popup>
                                                            <div>
                                                                <label className="text-primary">Suministro :</label><span>{row.contrato}</span>
                                                                <label className="text-primary mx-1">Cliente :</label><span>{row.nombres}</span>
                                                                <label className="text-primary mx-1">Dirección Entrega :</label><span>{row.direccionentrega}</span>
                                                            </div>
                                                            <div>
                                                                <label className="text-primary">Tipo :</label><span>{row.muestra}</span>
                                                                <label className="text-primary">Recibo :</label><span>{row.recibo}</span>
                                                            </div>
                                                        </Popup>

                                                        <Tooltip opacity={0.8} >
                                                            <small>{row.contrato}</small>
                                                        </Tooltip>
                                                    </Marker>

                                                )

                                            }

                                        })
                                    }

                                    {
                                        this.state.posicionUsuario == null ?
                                            <></>
                                            :
                                            <>
                                                <Marker position={this.state.posicionUsuario} icon={PinUbicacion}>
                                                    <Popup>Posicion Usuario</Popup>
                                                </Marker>
                                                {/* Circulo de Exactitud Posicion */}
                                                {/* <Circle
                                                    center={this.state.posicionUsuario}
                                                    fillColor="blue"
                                                    // color="blue"
                                                    radius={this.state.presicionGPs} /> */}
                                            </>
                                    }

                                </MapContainer>

                            </>
                            :
                            <></>
                        // <SpinnerCarga />

                    }
                </div>

                {/* ************************* */}
                {/* Boton Flotante  */}
                {/* ************************* */}
                {
                    this.state.listaGPS.length > 0 ?
                        <Fab alwaysShowTitle={true}
                            icon={<i className="fas fa-plus"></i>}
                            mainButtonStyles={{ backgroundColor: 'rgb(230,83,97)', outline: 'none' }}
                            style={{ bottom: 10, right: 10 }}>
                            {/* <Action text="Ver Suministros Ejecutados"
                                onClick={() => { }}
                                style={{ backgroundColor: 'rgb(25,118,210)' }}
                            >
                                <i className="fas fa-street-view "></i>
                            </Action>
                            <Action text="Ver Suministros no Ejecutados"
                                style={{ backgroundColor: 'rgb(25,118,210)' }}
                                onClick={() => { }}>
                                <i className="fas fa-draw-polygon"></i>
                            </Action> */}

                            <Action text="Elegir Origen GPs"
                                onClick={() => { this.AbrirModalElegirOrigen() }}
                                style={{ backgroundColor: 'rgb(25,118,210)' }}>
                                <i className="fas fa-thumbtack"></i>
                            </Action>
                        </Fab>

                        :
                        <></>
                }


                {/* ************************* */}
                {/* Boton Flotante  */}
                {/* ************************* */}
                {
                    this.state.listaSuministrosLibro.length > config.cantidadMaximaMArcadores ?
                        <Fab alwaysShowTitle={true}
                            icon={<i className="fas fa-sync"></i>}
                            mainButtonStyles={{ backgroundColor: 'rgb(230,83,97)', outline: 'none' }}
                            style={{ bottom: 10, right: 10 }}
                            onClick={() => { this.ActualizarPosicionGPs() }}
                        >
                            {/* <Action text="Ver Suministros Ejecutados"
                                onClick={() => { }}
                                style={{ backgroundColor: 'rgb(25,118,210)' }}
                            >
                                <i className="fas fa-street-view "></i>
                            </Action>
                            <Action text="Ver Suministros no Ejecutados"
                                style={{ backgroundColor: 'rgb(25,118,210)' }}
                                onClick={() => { }}>
                                <i className="fas fa-draw-polygon"></i>
                            </Action> */}

                            {/* <Action text="Actualizar Posicion"
                                onClick={() => { this.ActualizarPosicionGPs() }}
                                style={{ backgroundColor: 'rgb(25,118,210)' }}>                                
                                <i className="fas fa-sync"></i>
                            </Action> */}
                        </Fab>
                        :
                        <></>
                }

                {/* Modal Elegir Origen */}
                <Modal
                    show={this.state.MostrarModalElegirOrigen}
                    onHide={() => { this.CerrarModalElegirOrigen() }}
                    keyboard={false}
                    centered
                    size='md'
                // backdrop="static"
                >
                    <Modal.Body>
                        <div>
                            <small className='text-primary'>Elegir Puntos GPS</small>
                            <div className="form-check">
                                <input onChange={(e) => { this.habiliarComboTodos(e) }} className="form-check-input" type="radio" name="opciones" value="mapear" id="radioelegirmapear" />
                                <label className="form-check-label" htmlFor="radioelegirmapear">
                                    Mapear Todos los Puntos
                                </label>
                            </div>
                            <div className="form-check">
                                <input onChange={(e) => { this.habiliarComboElegir(e) }} className="form-check-input" type="radio" name="opciones" value="elegirorigen" id="radioelegirorigen" />
                                <label className="form-check-label" htmlFor="radioelegirorigen">
                                    Elegir Origen
                                </label>
                            </div>

                            <div className='border rounded p-1 ms-5' ref={this.divelElegirOrigen}>
                                <small className='text-primary'>Elegir Origen</small>
                                {
                                    this.state.listaBusqueda.map((row, index) => {
                                        return (
                                            <div key={index} className="form-check" >
                                                <input id={row.idBaseSuministro} onChange={(e) => { this.elegirOpcionOrigen(e) }} className="form-check-input" type="radio" name="origen" value={row.idBaseSuministro} disabled={this.state.deshabiliarCombosOrigen} />
                                                <label className="form-check-label" htmlFor={row.idBaseSuministro}>
                                                    {row.origen + row.campania}
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="row">
                                <div className="d-flex justify-content-between">
                                    <div >

                                        <button onClick={() => { this.CerrarModalElegirOrigen() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                    </div>

                                    <div >
                                        <button onClick={() => { this.AplicarFiltroElegirOrigen() }} className="btn btn-sm btn-primary mx-1">Aplicar</button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </Modal.Footer>

                </Modal>


                {/* Modal Elegir Libro*/}
                <Modal
                    show={this.state.MostrarModalElegirLibro}
                    onHide={() => { this.CerrarModalElegirLibro() }}
                    keyboard={false}
                    centered
                    size='sm'
                // backdrop="static"
                >
                    <Modal.Body>
                        <div>
                            <small className='text-primary'>Elegir Zona-Sector</small>
                            <select class="form-select" ref={this.selectElegirZona} onChange={() => { this.ElegirSector() }}>
                                <option value="" selected>Elegir Zona</option>
                                {
                                    this.state.ListaZonasSector.map((row, index) => {
                                        return (
                                            <option key={index} value={row.zonasector}>{row.zonasector}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        {/* <div>
                            <small className='text-primary'>Elegir Sector</small>
                            <select class="form-select"  ref={this.selectElegirSector}>
                                <option selected>Elegir Libro</option>
                                <option value="1">1</option>
                                <option value="2">1</option>
                                <option value="3">3</option>
                            </select>
                        </div> */}

                        <div>
                            <small className='text-primary'>Elegir Libro</small>
                            <select class="form-select" ref={this.selectElegirLibro} onChange={() => { this.ElegirLibro() }}>
                                <option selected value="">Elegir Libro</option>
                                {
                                    this.state.ListaLibros.map((row, index) => {
                                        return (
                                            <option key={index} value={row.libro}>{row.libro}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="row">
                                <div className="d-flex justify-content-between">
                                    <div >

                                        <button onClick={() => { this.CerrarModalElegirLibro() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                    </div>

                                    <div >
                                        <button onClick={() => { this.DibujarSuministrosLibro() }} className="btn btn-sm btn-primary mx-1">Aplicar</button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </Modal.Footer>

                </Modal>
                {
                    this.state.geolizationStatus ?
                        <div className="fixed-bottom ms-2 fw-lighter text-success">
                            <small>{this.state.mensajeEstadoGps}</small>
                        </div>
                        :
                        <div className="fixed-bottom ms-2 fw-lighter text-danger">
                            <small>{this.state.mensajeEstadoGps}</small>
                        </div>
                }
            </Fragment>
        );
    }
}

BuscarUbicacion.propTypes = {};

export default BuscarUbicacion;
