import React, { Component, Fragment } from 'react';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle'
import socketIOClient from 'socket.io-client';

import { Modal, Carousel } from 'react-bootstrap';
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS } from '../utils/utils'
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import config from './../../config/config';
import SpinnerCarga from './../utils/spinner'

class monitoreoSuministro extends Component {
    constructor(props) {
        super(props)
        this.state = {
            DatosTrabajador: {},
            trabajoSuministro: {},
            ListaMateriales: [],
            ListaMaterialesUsar: [],
            ListaFotosTrabajoSuministro: [],
            MostrarModalFotos: false,
            loadData: false
        }
        //Variable controlador  Socket
        // this.socket=socketIOClient(config.endPoint,{transports: ['websocket', 'polling', 'flashsocket']});       
    }
    async componentDidMount() {
        try {
            if (ValidarUsuario()) {

                listarPermisos().then(async (permisos) => {
                    if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO, permisos)) {
                        await this.setState({
                            DatosTrabajador: this.props.location.state.DatosTrabajadorSuministro
                        })
                        await this.BuscarTrabajoSuministro();
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                        setTimeout(() => {
                            this.props.history.push('/');
                        }, 1000);
                    }
                });
            }
            else {
                this.props.history.push('/login');
            }
        } catch (error) {
            this.setState({ abrirToast: true, mensajeToast: "No se encontraron los recursos.", tipotoast: 'warning' });
            setTimeout(() => {
                this.props.history.push('/');
            }, 1000);
        }
    }

    // componentWillUnmount(){
    //     this.socket.close();
    // }

    BuscarTrabajoSuministro = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/trabajosuministro/${this.props.match.params.idtrabajosuministro}`

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    await this.setState({ trabajoSuministro: data.content, loadData: true });
                    await this.BuscarListaMateriales();
                    //Buscar los materiales a utilizar 
                    await this.BuscarListaMaterialesUsar();
                }
                else {
                    await this.setState({ trabajoSuministro: {}, loadData: false });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarListaMateriales = async () => {

        let url = `${config.endPoint}api/material/listar/${this.props.match.params.idtrabajosuministro}`

        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let headers = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            }
        };
        fetch(url, headers).then((respuesta) => {
            return respuesta.json();
        }).then(async (data) => {
            if (data.content) {
                await this.setState({ ListaMateriales: data.content });
                // console.log(this.state.ListaMateriales);
            }
            else {
                await this.setState({ ListaMateriales: [], });
            }
        }).catch(() => {
            this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
        });


    }
    BuscarListaMaterialesUsar = async () => {

        let url = `${config.endPoint}api/materialUsar/listar/${this.props.match.params.idtrabajosuministro}`

        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let headers = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            }
        };
        fetch(url, headers).then((respuesta) => {
            return respuesta.json();
        }).then(async (data) => {
            if (data.content) {
                await this.setState({ ListaMaterialesUsar: data.content });
                // console.log(this.state.ListaMateriales);
            }
            else {
                await this.setState({ ListaMaterialesUsar: [], });
            }
        }).catch(() => {
            this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
        });


    }

    AbrirModalFotos = async () => {
        // this.setState({ MostrarModalFotos: true });        
        await this.setState({ ListaFotosTrabajoSuministro: [] });
        if (this.state.trabajoSuministro.t_fotos.length > 0) {
            //Limpiar Fotos Duplicadas
            // let arrayfotos=this.state.trabajoSuministro.t_fotos;
            // let arrayFotosNoDuplicadas=[];
            // for(let x=0;x<arrayfotos.length;x++){              
            //     var elEncontrado=arrayFotosNoDuplicadas.filter( el => el.foto_url===arrayfotos[x].foto_url);                
            //     if(elEncontrado.length<=0){                    
            //         arrayFotosNoDuplicadas.push(arrayfotos[x]);
            //     }
            // } 
            // Llenar Lista de fotos
            await this.setState({ ListaFotosTrabajoSuministro: this.state.trabajoSuministro.t_fotos });
            // await this.setState({ ListaFotosTrabajoSuministro: arrayFotosNoDuplicadas });
            await this.setState({ MostrarModalFotos: true });
        }
        else {
            this.setState({ mensajeToast: "No se encontraron fotos para este suministro", abrirToast: true, tipotoast: 'info' });
        }
    }
    CerrarModalFotos = () => {
        this.setState({ MostrarModalFotos: false });
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    render() {
        return (
            <Fragment>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo={`MONITOREO-TRABAJADOR / SUMINISTRO`} regresar={() => { this.props.history.push(`/monitoreo/trabajador/${this.state.DatosTrabajador.fechaProgramacion}/${this.state.DatosTrabajador.idpersonalasignado}`) }}></TituloPagina>

                {/* Contenedor de Datos */}
                <div className="container border my-1">
                    <div className="row bg-secondary text-light mx-1 mt-1 py-1 rounded">
                        <div className="col-md-6 ">
                            <span >{`${this.state.DatosTrabajador.nombretrabajador} - ${this.state.DatosTrabajador.Tipo} `}</span>
                        </div>
                        <div className="col-md-6 text-md-end">
                            <span>{`Programación : ${this.state.DatosTrabajador.fechaProgramacion}`}</span>
                        </div>
                    </div>
                    {/* Contendor de datos de Suministro */}

                    {
                        this.state.loadData ?
                            <>
                                <div className="row my-1">
                                    <div className="col">

                                        <div className="container border mt-1">
                                            <div className="row mt-1 rounded" style={{ backgroundColor: 'rgb(34,134,195)' }}>
                                                <div className="col-md3 py-1">
                                                    <span className="text-light">Suministro <strong>{this.state.trabajoSuministro.suministro}</strong></span>
                                                </div>
                                            </div>
                                            {/* Nombre Cliente */}
                                            <div className="row border-bottom my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Cliente </label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <label className="">{this.state.trabajoSuministro.nombreCliente}</label>
                                                </div>
                                            </div>
                                            {/* Direccion */}
                                            <div className="row border-bottom my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Dirección</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <label className="">{this.state.trabajoSuministro.direccionCliente}</label>
                                                </div>
                                            </div>
                                            {/* Distrito */}
                                            <div className="row border-bottom my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Distrito</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <label className="">{this.state.trabajoSuministro.distrito}</label>
                                                </div>
                                            </div>
                                            {/* SED */}
                                            <div className="row border-bottom my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">SED</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <label className="">{this.state.trabajoSuministro.subEstacion}</label>
                                                </div>
                                            </div>
                                            {/* Circuito */}
                                            <div className="row border-bottom my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Circuito</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <label className="">{this.state.trabajoSuministro.circuito}</label>
                                                </div>
                                            </div>
                                            {/* Medidor / Serie */}
                                            {/* <div className="row border-bottom my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Medidor / Serie</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <label className="">{this.state.trabajoSuministro.medidor}</label>
                                                </div>
                                            </div> */}

                                            {/* Trabajo Programado */}
                                            {/* <div className="row border-bottom my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Trabajo Programado</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <label className="">{this.state.trabajoSuministro.tipoOt}</label>
                                                </div>
                                            </div> */}


                                            {/* Tipo Trabajo Programado */}
                                            <div className="row border-bottom my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Tipo Trabajo Programado</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    {
                                                        this.state.trabajoSuministro.tipoProgramacion === 'VERIFICACION' ?
                                                            <label className="">{`INSPECCION PREVIA DE SUMINISTRO, DETERMINAR MATERIALES A UTILIZAR PARA NORMALIZACION U EJECUCION`}</label>
                                                            : <label className="">{'EJECUCION DE TRABAJO CON MATERIALES PRE-CARGADOS'}</label>
                                                    }
                                                </div>
                                            </div>

                                            {/* Ot */}
                                            <div className="row border-bottom my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">OT</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <label className="">{this.state.trabajoSuministro.ot}</label>
                                                </div>
                                            </div>
                                            {/* Potencia */}
                                            <div className="row border-bottom my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Potencia</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <label className="">{this.state.trabajoSuministro.potencia}</label>
                                                </div>
                                            </div>
                                            {/* Acometida */}
                                            <div className="row border-bottom my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Acometida</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <label className="">{this.state.trabajoSuministro.acometida}</label>
                                                </div>
                                            </div>
                                            {/* Sum Ref */}
                                            <div className="row border-bottom my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Suministro Ref.</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <label className="">{this.state.trabajoSuministro.sumReferencia}</label>
                                                </div>
                                            </div>
                                            {/* Detalle Trabajo */}
                                            <div className="row border-bottom my-1">
                                                <div className="col-4  col-md-3 ">
                                                    <label className="fw-bold">Detalle Trabajo</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <label className="">{this.state.trabajoSuministro.detalle}</label>
                                                </div>
                                            </div>
                                            {/* dias */}
                                            <div className="row my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Dias</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <label className="">{this.state.trabajoSuministro.dias}</label>
                                                </div>
                                            </div>



                                            <div className="row mt-1 rounded" style={{ backgroundColor: 'rgb(34,134,195)' }}>
                                                <div className="col py-1">
                                                    <span className="text-light">Trabajo (Estado) <strong className="text-light">{this.state.trabajoSuministro.estado}</strong></span>
                                                </div>
                                            </div>
                                            {/* Ejecucion Observacion */}
                                            <div className="row border-bottom my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Observación</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <label className="">{this.state.trabajoSuministro.observacion_ejecucion}</label>
                                                </div>
                                            </div>

                                            {/* Ejecucion Actividades */}
                                            {/* <div className="row  my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Actividades Ejecutadas</label>
                                                </div>
                                            </div>
                                            <div className="row border-bottom my-1 ">
                                                <div className="col-12 col-8 col-md-9 my-2 text-center">
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableHead className="fondoTituloTabla">
                                                                <TableRow>
                                                                    <TableCell style={{ color: 'aliceblue' }} >Actividad</TableCell>
                                                                    <TableCell style={{ color: 'aliceblue' }} >Cantidad</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    this.state.trabajoSuministro.t_actividads.map((actividad, index) => {
                                                                        return (
                                                                            <TableRow key={index}>
                                                                                <TableCell style={{ fontSize: '0.8em' }}>{actividad.descripcion}</TableCell>
                                                                                <TableCell style={{ fontSize: '0.8em' }}>{actividad.cantidad}</TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    })
                                                                }

                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            </div> */}

                                            {/* <div className="row border-bottom my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Actividades</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    {
                                                        this.state.trabajoSuministro.t_actividads.map((actividad, index) => {
                                                            return (<div key={index}>
                                                                <label className="" >{`${actividad.cantidad}-${actividad.descripcion}`}</label>
                                                            </div>);
                                                        })
                                                    }
                                                </div>
                                            </div> */}

                                            {/* Ejecucion MaterialesUsar SEAL*/}
                                            {
                                                this.state.trabajoSuministro.tipoProgramacion === 'VERIFICACION' ?
                                                    <>
                                                        <div className="row  my-1">
                                                            <div className="col-4 col-md-3 ">
                                                                <label className="fw-bold">Materiales a Utilizar SEAL</label>
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom my-1 ">
                                                            <div className="col-12 col-8 col-md-9 my-2 text-center">
                                                                <TableContainer>
                                                                    <Table size='small'>
                                                                        <TableHead className="fondoTituloTabla">
                                                                            <TableRow>
                                                                                <TableCell style={{ color: 'aliceblue' }} >Material</TableCell>
                                                                                <TableCell style={{ color: 'aliceblue' }} >Cantidad</TableCell>
                                                                                <TableCell style={{ color: 'aliceblue' }} >Unidad</TableCell>
                                                                                {/* <TableCell style={{ color: 'aliceblue' }} >Acciones</TableCell> */}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {
                                                                                this.state.ListaMaterialesUsar.filter(mat => mat.t_basematerial.tipo === "SEAL").map((material, index) => {
                                                                                    return (
                                                                                        <TableRow key={index}>
                                                                                            <TableCell style={{ fontSize: '0.8em' }}>{material.descripcion}</TableCell>
                                                                                            <TableCell style={{ fontSize: '0.8em' }}>{`${material.cantidad} ${material.unidad}`}</TableCell>
                                                                                            <TableCell style={{ fontSize: '0.8em' }}>{material.unidad}</TableCell>
                                                                                            {/* <TableCell style={{ fontSize: '0.8em' }}>
                                                                                                <div className="container-fluid">
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-6 ">
                                                                                                            <i title="Modificar" onClick={() => { }} className="fas fa-trash-alt iconoAcciones "></i>
                                                                                                        </div>
                                                                                                        <div className="col-md-6">
                                                                                                            <i title="Eliminar" onClick={() => { }} className="fas fa-trash-alt iconoAcciones "></i>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </TableCell> */}
                                                                                        </TableRow>
                                                                                    );
                                                                                })
                                                                            }

                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <></>
                                            }

                                            {/* Ejecucion MaterialesUsar Empresa*/}
                                            {
                                                this.state.trabajoSuministro.tipoProgramacion === 'VERIFICACION' ?
                                                    <>
                                                        <div className="row  my-1">
                                                            <div className="col-4 col-md-3 ">
                                                                <label className="fw-bold">Materiales a Utilizar EMPRESA</label>
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom my-1 ">
                                                            <div className="col-12 col-8 col-md-9 my-2 text-center">
                                                                <TableContainer>
                                                                    <Table size='small'>
                                                                        <TableHead className="fondoTituloTabla">
                                                                            <TableRow>
                                                                                <TableCell style={{ color: 'aliceblue' }} >Material</TableCell>
                                                                                <TableCell style={{ color: 'aliceblue' }} >Cantidad</TableCell>
                                                                                <TableCell style={{ color: 'aliceblue' }} >Unidad</TableCell>
                                                                                {/* <TableCell style={{ color: 'aliceblue' }} >Acciones</TableCell> */}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {
                                                                                this.state.ListaMaterialesUsar.filter(mat => mat.t_basematerial.tipo === "EMPRESA").map((material, index) => {
                                                                                    return (
                                                                                        <TableRow key={index}>
                                                                                            <TableCell style={{ fontSize: '0.8em' }}>{material.descripcion}</TableCell>
                                                                                            <TableCell style={{ fontSize: '0.8em' }}>{`${material.cantidad} ${material.unidad}`}</TableCell>
                                                                                            <TableCell style={{ fontSize: '0.8em' }}>{material.unidad}</TableCell>
                                                                                            {/* <TableCell style={{ fontSize: '0.8em' }}>
                                                                                                <div className="container-fluid">
                                                                                                    <div className="row">
                                                                                                        <div className="col-md-6 ">
                                                                                                            <i title="Modificar" onClick={() => { }} className="fas fa-trash-alt iconoAcciones "></i>
                                                                                                        </div>
                                                                                                        <div className="col-md-6">
                                                                                                            <i title="Eliminar" onClick={() => { }} className="fas fa-trash-alt iconoAcciones "></i>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </TableCell> */}
                                                                                        </TableRow>
                                                                                    );
                                                                                })
                                                                            }

                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <></>
                                            }


                                            {/* Ejecucion Materiales */}
                                            {
                                                this.state.trabajoSuministro.tipoProgramacion === 'VERIFICACION' ?
                                                    <>                                                  
                                                    
                                                    </> :
                                                    <>
                                                        <div className="row  my-1">
                                                            <div className="col-4 col-md-3 ">
                                                                <label className="fw-bold">Materiales Entregado / Utilizado</label>
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom my-1 ">
                                                            <div className="col-12 col-8 col-md-9 my-2 text-center">
                                                                <TableContainer>
                                                                    <Table size='small'>
                                                                        <TableHead className="fondoTituloTabla">
                                                                            <TableRow>
                                                                                <TableCell style={{ color: 'aliceblue' }} >Material</TableCell>
                                                                                <TableCell style={{ color: 'aliceblue' }} >Entregado</TableCell>
                                                                                <TableCell style={{ color: 'aliceblue' }} >Utilizado</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {
                                                                                this.state.ListaMateriales.filter(mat => mat.t_basematerial.tipo === "EMPRESA").map((material, index) => {
                                                                                    return (
                                                                                        <TableRow key={index}>
                                                                                            <TableCell style={{ fontSize: '0.8em' }}>{material.descripcion}</TableCell>
                                                                                            <TableCell style={{ fontSize: '0.8em' }}>{`${material.cantidadEntregada} ${material.unidad}`}</TableCell>
                                                                                            <TableCell style={{ fontSize: '0.8em' }}>{material.cantidad}</TableCell>
                                                                                        </TableRow>
                                                                                    );
                                                                                })
                                                                            }

                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </div>
                                                        </div>
                                                    </>
                                            }


                                            {/* Ejecucion Materiales(Retirados) */}
                                            {
                                                this.state.trabajoSuministro.tipoProgramacion === 'VERIFICACION' ?
                                                    <></> :
                                                    <>
                                                        <div className="row  my-1">
                                                            <div className="col-4 col-md-3 ">
                                                                <label className="fw-bold">Materiales Retirados</label>
                                                            </div>
                                                        </div>
                                                        <div className="row border-bottom my-1 ">
                                                            <div className="col-12 col-8 col-md-9 my-2 text-center">
                                                                <TableContainer>
                                                                    <Table size='small'>
                                                                        <TableHead className="fondoTituloTabla">
                                                                            <TableRow>
                                                                                <TableCell style={{ color: 'aliceblue' }} >Material</TableCell>
                                                                                <TableCell style={{ color: 'aliceblue' }} >Cantidad</TableCell>
                                                                                <TableCell style={{ color: 'aliceblue' }} >Serie</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {
                                                                                this.state.ListaMateriales.filter(mat => mat.t_basematerial.subtipo === "RETIRO").map((material, index) => {
                                                                                    return (
                                                                                        <TableRow key={index}>
                                                                                            <TableCell style={{ fontSize: '0.8em' }}>{material.descripcion}</TableCell>
                                                                                            <TableCell style={{ fontSize: '0.8em' }}>{material.cantidad}</TableCell>
                                                                                            <TableCell style={{ fontSize: '0.8em' }}>{material.observacion}</TableCell>
                                                                                        </TableRow>
                                                                                    );
                                                                                })
                                                                            }

                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </div>
                                                        </div>
                                                    </>
                                            }

                                            {/* Ejecucion Fotografias */}
                                            <div className="row  my-1">
                                                <div className="col-4 col-md-3 ">
                                                    <label className="fw-bold">Fotografias</label>
                                                </div>
                                                <div className="col-8 col-md-9">
                                                    <div onClick={() => { this.AbrirModalFotos() }}>
                                                        <i className="fas fa-camera iconoAcciones"></i>
                                                        <label className=" text-primary px-1 text-decoration-underline pointer-link">{`${this.state.trabajoSuministro.t_fotos.length} Fotografias Tomadas`}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </> : <SpinnerCarga />
                    }

                </div>
                <div style={{ height: '20' }}>
                    <label></label>
                </div>
                {/* Modal Fotos*/}

                <Modal
                    show={this.state.MostrarModalFotos}
                    onHide={() => { this.CerrarModalFotos() }}
                    keyboard={false}
                    size="lg"
                    centered >
                    <Modal.Body style={{ backgroundColor: 'rgb(33,37,41)' }}>
                        <div className="container" >
                            <div className="row" style={{ maxHeight: '600px' }}>
                                <Carousel wrap={false} >
                                    {this.state.ListaFotosTrabajoSuministro.map((foto, index) => {
                                        return (
                                            <Carousel.Item key={index} >
                                                <div >
                                                    <img
                                                        className="d-block m-auto"
                                                        src={`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`}
                                                        alt={`Imagen ${index + 1}`}
                                                        height='550'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${foto.foto_url}`, '_blank'); }}
                                                    />
                                                    <div className="row ">
                                                        <div className="col-6 col-md-6 text-light">
                                                            <span>{`Foto ${index + 1} de ${this.state.trabajoSuministro.t_fotos.length}`}</span>
                                                        </div>
                                                        <div className="col-6 col-md-6 text-end text-light">
                                                            <span>{`${foto.fecha} ${foto.hora}`}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Carousel.Item>

                                        );
                                    })}
                                </Carousel>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
            </Fragment>



        );
    }
}

export default monitoreoSuministro;