import React, { Component, createRef, Fragment } from 'react';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SpinnerCarga from './../utils/spinner';
import LoadingModal from './../utils/loadModal';
// Bootstrap
import { Modal } from 'react-bootstrap';

import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS } from './../utils/utils'
import config from './../../config/config'

class configRecetaMaterial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Receta: null,
            OcultarLoading: true,
            MostrarModalAgregar: false,
            MostrarModalAgregarMaterial: false,
            MostrarModalEditarMaterialReceta: false,
            MostrarAgregarMaterial: false,
            ListaRecetas: [],
            ListaMateriales: [],
            ListaTiposMaterial: [],
            ListaMaterialesReceta: [],
            ListaTiposTrabajo: [],
            cantidadElementos: 0,
            AccionReceta: "",
            abrirToast: false,
            loadData: false,
            nombrebuscar: '',
            pagina: 1,
            filasPorPagina: 15,
            idmaterialreceta: 0

        };
        this.headCells = [
            { id: 'Acciones' },
            { id: 'Descripción' },
            { id: 'Tipo Trabajo' },
            { id: 'Validador' },
            { id: 'Materiales' },
            { id: 'Estado' }
        ];

        //Agregar Receta
        this.inputDescripcionReceta = createRef();
        this.inputTipoTrabajo = createRef();
        this.selectTipoMaterial1 = createRef();
        this.selectTipoMaterial2 = createRef();
        this.selectTipoMaterial3 = createRef();
        this.selectEstadoReceta = createRef();

        //Botones Receta
        this.buttonGuardarReceta = createRef();
        this.buttonmodificarReceta = createRef();

        //Agregar Material
        this.inputDetalleRecetaCabecera = createRef();
        this.inputRecetaCabecera = createRef();
        this.inputMateriales = createRef();
        this.inputCantidadReceta = createRef();
        this.inputmultiploReceta = createRef();

        this.buttonAgregarMaterialReceta = createRef();

        //Editar Material Receta
        this.inputDescripcionMaterialReceta = createRef();
        this.inputCantidadMaterialReceta = createRef();
        this.inputUnidadMaterialReceta = createRef();
        this.inputMultiploMaterialReceta = createRef();
        this.selectEstadoMaterialReceta = createRef();

    }
    async componentDidMount() {
        if (ValidarUsuario()) {

            listarPermisos().then(async (permisos) => {
                // console.log(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_RECETA, permisos)) {
                    await this.BuscarRecetas();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarRecetas = () => {
        if (ValidarUsuario()) {
            let url;
            if (this.state.nombrebuscar.trim() !== '') {
                // url = `${config.endPoint}api/basematerial/listar/paginacion/${this.state.filasPorPagina}/${this.state.pagina}/${this.state.nombrebuscar}`;
            } else {
                url = `${config.endPoint}api/receta/listarpaginacion/${this.state.filasPorPagina}/${this.state.pagina}`;
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {

                if (data.content) {
                    this.setState({ ListaRecetas: data.content, cantidadElementos: data.count, loadData: true });
                }
                else {
                    this.setState({ ListaRecetas: [], cantidadElementos: data.count, loadData: true });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarTipoMaterialSEAL = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/basematerial/subtipomaterial/seal`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaTiposMaterial: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaTiposMaterial: null });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });

            } else {
                reject(true);
            }
        });
    }

    BuscarReceta = (idreceta) => {
        //console.log(idreceta);
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/receta/buscar/${idreceta}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        await this.setState({ Receta: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ Receta: null });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(true)
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    BuscarListaTipoTrabajo = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/tipotrabajo/listar`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaTiposTrabajo: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaTiposTrabajo: [] });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
                // this.props.history.push('/login');
            }
        });
    }

    DeshabilitarControlesModal = (valor) => {
        this.inputDescripcionReceta.current.readOnly = valor;
        this.inputTipoTrabajo.current.disabled = valor;
        this.selectTipoMaterial1.current.disabled = valor;
        this.selectTipoMaterial2.current.disabled = valor;
        this.selectTipoMaterial3.current.disabled = valor;
        this.selectEstadoReceta.current.disabled = valor
    }
    //Agregar Modificar RECETA
    ModificarReceta = () => {
        this.DeshabilitarControlesModal(false);
        this.buttonGuardarReceta.current.disabled = false;
        this.buttonmodificarReceta.current.disabled = true;
    }
    GuardarReceta = (ev) => {
        ev.preventDefault();
        //console.log(this.state.AccionReceta);
        switch (this.state.AccionReceta) {
            case 'Ver':
                this.ActualizarReceta();
                break;
            case 'Agregar':
                this.AgregarReceta();
                break;
            default:
        }
    }
    ExisteReceta = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/receta/listarrecetas`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                let _listaSubtiposSeleccionados = [];
                let listasubtipos = [];
                let _subtipolongitud = 0;
                let _existeMaterial = false;

                //LlenarDatos
                if (this.inputTipoTrabajo.current.value.trim() !== "") {
                    _listaSubtiposSeleccionados.push(this.inputTipoTrabajo.current.value);
                }

                if (this.selectTipoMaterial1.current.value.trim() !== "") {
                    _listaSubtiposSeleccionados.push(this.selectTipoMaterial1.current.value);
                }
                if (this.selectTipoMaterial2.current.value.trim() !== "") {
                    _listaSubtiposSeleccionados.push(this.selectTipoMaterial2.current.value);
                }
                if (this.selectTipoMaterial3.current.value.trim() !== "") {
                    _listaSubtiposSeleccionados.push(this.selectTipoMaterial3.current.value);
                }

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content.length > 0) {
                        //existeReceta                          

                        for (let x = 0; x < _listaSubtiposSeleccionados.length; x++) {
                            if (x === 0) {
                                _subtipolongitud = _listaSubtiposSeleccionados[x].length;
                                listasubtipos = data.content.filter(item => item.tiposmaterial.indexOf(_listaSubtiposSeleccionados[x]) !== -1);
                                // console.log("Primera Busqeuda");
                                // console.log(listasubtipos);
                                if (listasubtipos.length === 0) {
                                    _existeMaterial = false;
                                    break;
                                }
                                else {
                                    _existeMaterial = true;
                                }
                            }
                            else {
                                _subtipolongitud = _subtipolongitud + _listaSubtiposSeleccionados[x].length;
                                listasubtipos = listasubtipos.filter(item => item.tiposmaterial.indexOf(_listaSubtiposSeleccionados[x]) !== -1);
                                // console.log(`Vuelta n! ${x+1}`);
                                // console.log(listasubtipos);
                                if (listasubtipos.length === 0) {
                                    _existeMaterial = false;
                                    break;
                                }
                                else {
                                    _existeMaterial = true;
                                }
                            }
                        }

                        if (_existeMaterial) {
                            //Verificar que no existan duplicados
                            _existeMaterial = false;
                            for (let y = 0; y < listasubtipos.length; y++) {
                                if (listasubtipos[y].tiposmaterial.length === _subtipolongitud) {
                                    _existeMaterial = true;
                                    break;
                                }
                            }
                        }
                    }
                    resolve(_existeMaterial);
                });
            }
            else {
                reject(true);
            }
        });
    }

    AgregarReceta = async () => {
        if (ValidarUsuario()) {
            let _existeReceta = false;
            await this.ExisteReceta().then((respuesta) => {
                _existeReceta = respuesta;
            });

            console.log(_existeReceta);
            if (!_existeReceta) {

                let url = `${config.endPoint}api/receta/agregar`
                let nuevaReceta = {
                    tipo_trabajo: this.inputTipoTrabajo.current.value,
                    descripcion: this.inputDescripcionReceta.current.value,
                    tipo_material_1: this.selectTipoMaterial1.current.value,
                    tipo_material_2: this.selectTipoMaterial2.current.value.toUpperCase(),
                    tipo_material_3: this.selectTipoMaterial3.current.value.toUpperCase(),
                    estado: this.selectEstadoReceta.current.value.toUpperCase()
                }
                //console.log(nuevaReceta);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(nuevaReceta)
                };

                fetch(url, headers).then((resp) => {
                    return resp.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ abrirToast: true, mensajeToast: "Receta Agregada Correctamente", tipotoast: 'success' });
                        this.CerrarModalAgregar();
                        this.BuscarRecetas();
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
            }
            else {
                this.setState({ mensajeToast: "La convinación de Tipos de Material ya existe.", abrirToast: true, tipotoast: 'warning' });
            }

        }
        else {
            this.props.history.push('/login');
        }
    }
    //********************************************* */
    ActualizarReceta = async () => {
        if (ValidarUsuario()) {

            // let _existeReceta = false;
            // await this.ExisteReceta().then((respuesta) => {
            //     _existeReceta = respuesta;
            // });
            // if (!_existeReceta) {
            let url = `${config.endPoint}api/receta/modificar`
            let nuevaReceta = {
                idreceta: this.state.Receta.idreceta,
                tipo_trabajo: this.inputTipoTrabajo.current.value,
                descripcion: this.inputDescripcionReceta.current.value,
                tipo_material_1: this.selectTipoMaterial1.current.value,
                tipo_material_2: this.selectTipoMaterial2.current.value.toUpperCase(),
                tipo_material_3: this.selectTipoMaterial3.current.value.toUpperCase(),
                estado: this.selectEstadoReceta.current.value.toUpperCase()
            }
            //console.log(nuevaReceta);
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevaReceta)
            };
            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Receta Actualizada Correctamente", tipotoast: 'success' });
                    this.CerrarModalAgregar();
                    this.BuscarRecetas();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

            // } else {
            //     this.setState({ mensajeToast: "No Actualizado, la convinacion ya existe.", abrirToast: true, tipotoast: 'warning' });
            // }
        } else {
            this.props.history.push('/login');
        }

    }
    AbrirModalAgregar = async (receta, accion) => {
        //        this.setState({MostrarModalAgregar:true,AccionReceta:Accion});
        if (ValidarUsuario()) {
            await this.setState({ OcultarLoading: false });

            await this.BuscarTipoMaterialSEAL().then(async () => {
            }).catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });
            await this.BuscarListaTipoTrabajo().then();

            await this.setState({ MostrarModalAgregar: true, AccionReceta: accion });
            if (this.state.AccionReceta === "Ver") {

                await this.BuscarReceta(receta.idreceta).then();
                // console.log(this.state.Receta);
                this.inputTipoTrabajo.current.value = this.state.Receta.tipo_trabajo;
                this.inputDescripcionReceta.current.value = this.state.Receta.descripcion;
                this.selectTipoMaterial1.current.value = this.state.Receta.tipo_material_1;
                this.selectTipoMaterial2.current.value = this.state.Receta.tipo_material_2;
                this.selectTipoMaterial3.current.value = this.state.Receta.tipo_material_3;
                this.selectEstadoReceta.current.value = this.state.Receta.estado.toUpperCase();

                this.DeshabilitarControlesModal(true);
                this.buttonGuardarReceta.current.disabled = true;
                this.setState({ OcultarLoading: true });
            }
            else {
                this.buttonmodificarReceta.current.hidden = true;
                this.setState({ OcultarLoading: true });
            }

        }
        else {
            this.props.history.push('/login');
        }

    }
    CerrarModalAgregar = () => {
        this.setState({ AccionReceta: '', MostrarModalAgregar: false });
    }
    //**************************************************** */
    BuscarListaMateriales = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/basematerial/buscarlista/EMPRESA`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        //console.log(data.content);
                        this.setState({ ListaMateriales: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaMateriales: [] });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }

    BuscarListaMaterialReceta = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/materialreceta/listar/${this.state.Receta.idreceta}}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaMaterialesReceta: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaMaterialesReceta: [] });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }

    buscarCodigoMaterialLista = (descripcion) => {
        for (const material of this.state.ListaMateriales) {
            if (descripcion.indexOf(material.descripcion) > -1) {
                return material;
            }
        }
        return null;
    }
    AgregarMaterialReceta = async () => {
        // Buscar codigo de  Material en lista de Materiales
        if (ValidarUsuario()) {

            if (this.inputMateriales.current.value.trim() !== '') {
                this.inputMateriales.current.classList.remove('is-invalid');
                let _MaterialBase = await this.buscarCodigoMaterialLista(this.inputMateriales.current.value)

                if (_MaterialBase !== null) {

                    let url = `${config.endPoint}api/materialreceta/agregar`;
                    let body_materialReceta = {
                        idBaseMaterial: _MaterialBase.idBaseMaterial,
                        codigo: _MaterialBase.codigoMaterial,
                        descripcion: _MaterialBase.descripcion,
                        unidad: _MaterialBase.unidad,
                        cantidad_receta: this.inputCantidadReceta.current.value,
                        multiplo: this.inputmultiploReceta.current.value,
                        idreceta: this.state.Receta.idreceta,
                        estado: 'ACTIVO'
                    }
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(body_materialReceta)
                    };

                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            //console.log("Data Agregada");
                            this.inputMateriales.current.value = '';
                            this.inputCantidadReceta.current.value = '1';
                            this.inputmultiploReceta.current.value = '0';
                            this.setState({ abrirToast: true, mensajeToast: "Material agregado a esta Receta.", tipotoast: 'success' });
                            await this.BuscarListaMaterialReceta().then();
                        }
                        else {
                            this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        }

                    }).catch(() => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });
                }
                else {
                    this.inputMateriales.current.classList.add('is-invalid');
                    this.inputMateriales.current.value = '';
                    this.setState({ abrirToast: true, mensajeToast: "Debe elegir un elemento de la lista de Materiales.", tipotoast: 'warning' });
                }
            }
            else {
                this.inputMateriales.current.classList.add('is-invalid');
                this.setState({ abrirToast: true, mensajeToast: "Elija un elemento de la lista de Materiales.", tipotoast: 'warning' });
            }
        }
        else {
            this.props.history.push('/login');
        }

    }

    EliminarMaterialReceta = (idmaterialreceta, descripcion) => {
        if (ValidarUsuario()) {
            if (window.confirm(`¿Estas seguro de Eliminar el material ${descripcion}?`)) {
                let url = `${config.endPoint}api/materialreceta/eliminar`;
                let bodyActividadTipoTrabajo = {
                    idmateriareceta: idmaterialreceta
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(bodyActividadTipoTrabajo)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ abrirToast: true, mensajeToast: "Material quitado de esta Receta", tipotoast: 'success' });
                        this.BuscarListaMaterialReceta().then();
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }

    }
    ModificarEstadoMaterialReceta = (idmaterialreceta, estado) => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/materialreceta/modificar`;
            let bodyMaterialTipoTrabajo = {
                idmateriareceta: idmaterialreceta,
                estado: estado
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(bodyMaterialTipoTrabajo)
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Estado de Material Actualizado.", tipotoast: 'success' });
                    this.BuscarListaMaterialReceta().then();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        } else {
            this.props.history.push('/login');
        }

    }

    AbrirModalAgregarMaterial = async (receta) => {

        await this.setState({ ListaMateriales: [], OcultarLoading: false });
        await this.BuscarReceta(receta.idreceta).then();

        //Materiales para agregar a drowpDownMaterial
        await this.BuscarListaMateriales().then().catch((error) => {
            if (error) {
                this.props.history.push('/login');
            }
        });

        // Buscar Lista de Materiales Correspondientes a Receta de Materiales
        await this.BuscarListaMaterialReceta().then().catch((error) => {
            if (error) {
                this.props.history.push('/login');
            }
        });

        await this.setState({ MostrarAgregarMaterial: true });
        this.inputDetalleRecetaCabecera.current.value = this.state.Receta.descripcion;
        this.inputRecetaCabecera.current.value = `${this.state.Receta.tipo_material_1};${this.state.Receta.tipo_material_2};${this.state.Receta.tipo_material_3}`;
        this.inputCantidadReceta.current.value = 1;
        this.inputmultiploReceta.current.value = 0;
        this.setState({ OcultarLoading: true });
    }
    CerrarModalAgregarMaterial = () => {
        this.setState({ MostrarAgregarMaterial: false });
        this.BuscarRecetas();
    }

    //*************************************************
    //Funciones Modal EditarMaterial Receta
    //*************************************************
    AbrirModalEditarMaterialReceta = async (materialreceta, descripcion) => {
        await this.setState({ MostrarModalEditarMaterialReceta: true, idmaterialreceta: materialreceta.idmateriareceta });

        this.inputDescripcionMaterialReceta.current.value = descripcion;
        this.inputCantidadMaterialReceta.current.value = materialreceta.cantidad_receta;
        this.inputMultiploMaterialReceta.current.value = materialreceta.multiplo;
        this.selectEstadoMaterialReceta.current.value = materialreceta.estado;
    }

    CerrarModalEditarMaterialReceta = async () => {
        this.setState({ MostrarModalEditarMaterialReceta: false });
        await this.BuscarListaMaterialReceta().then();
    }
    EditarEstadoMaterialReceta = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/materialreceta/modificar`;

            let bodyMaterialTipoTrabajo = {
                'idmateriareceta': this.state.idmaterialreceta,
                'cantidad_receta': this.inputCantidadMaterialReceta.current.value,
                'multiplo': this.inputMultiploMaterialReceta.current.value,
                'unidad': this.inputUnidadMaterialReceta.current.value,
                'estado': this.selectEstadoMaterialReceta.current.value
            }

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(bodyMaterialTipoTrabajo)
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Estado de Material Actualizado.", tipotoast: 'success' });
                    await this.BuscarListaMaterialReceta().then();
                    this.CerrarModalEditarMaterialReceta();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        } else {
            this.props.history.push('/login');
        }

    }

    //*************************************************
    //Funciones para Paginacion
    //*************************************************
    CambioFilasPorPagina = async (event) => {
        await this.setState({ loadData: false, filasPorPagina: parseInt(event.target.value), pagina: 1 });
        await this.BuscarRecetas();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ loadData: false, pagina: (parseInt(nuevaPagina) + 1) });
        await this.BuscarRecetas();
    }

    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }


    render() {
        return (
            <Fragment>
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>
                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>


                <NavBar />
                <TituloPagina titulo="CONFIGURACIÓN - RECETA" regresar={() => { this.props.history.push('/configuracion') }} />
                {/* Contenedor de Datos */}
                <div className="container border mt-1">

                    <div className="row mt-1">

                        <div className=" col-8 col-md-4">
                            <input onChange={() => { }} onKeyPress={(ev) => { }} ref={this.inputBuscar} className="form-control form-control-sm" placeholder="Buscar Receta"></input>
                        </div>
                        <div className="col-4 col-md-4 ">
                            <button onClick={() => { }} className="btn btn-sm btn-outline-secondary"><i className="fas fa-search p-1"></i>Buscar</button>
                        </div>

                        <div className="col-12 col-md-4 text-md-end mt-md-0 mt-1">
                            <button onClick={() => { this.AbrirModalAgregar(null, "Agregar") }} className=" btn btn-sm btn-outline-secondary " style={{ width: '130px' }}><i className="fas fa-upload mx-1"></i> Agregar</button>
                        </div>
                    </div>
                    <div className="row p-1">
                        {
                            this.state.loadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    {
                                                        this.headCells.map((head, index) => {
                                                            return (
                                                                <TableCell style={{ color: 'aliceblue' }} key={index}>{head.id}</TableCell>
                                                            );
                                                        })
                                                    }

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.ListaRecetas.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <i onClick={() => { this.AbrirModalAgregar(row, "Ver") }} title="Ver" className="fas fa-eye  mx-2 iconoAcciones" ></i>
                                                            </TableCell>
                                                            <TableCell>{row.descripcion}</TableCell>
                                                            <TableCell>{row.tipo_trabajo}</TableCell>
                                                            <TableCell>{`${row.tipo_material_1};${row.tipo_material_2};${row.tipo_material_3}`}</TableCell>

                                                            <TableCell onClick={() => { this.AbrirModalAgregarMaterial(row) }} className="iconoAcciones">
                                                                {<i title="Materiales Incluidos" className="fas fa-screwdriver iconoAcciones"></i>}
                                                                <span >{`(${row.t_materialesreceta.length}) Material(es) Incluido(s)`}</span>
                                                            </TableCell>

                                                            <TableCell>
                                                                {row.estado.toUpperCase() === 'ACTIVO' ?
                                                                    <span className="estadoActivo">{row.estado.toUpperCase()}</span> :
                                                                    <span className="estadoInactivo">{row.estado.toUpperCase()}</span>}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {/* <div className="mt-3">
                                        <small >{`${this.state.ListaMateriales.length} Elemento(s) Encontrado(s)`}</small>
                                    </div> */}
                                    <TablePagination
                                        rowsPerPageOptions={[15, 25, 50]}
                                        component="div"
                                        count={this.state.cantidadElementos}
                                        rowsPerPage={this.state.filasPorPagina}
                                        page={this.state.pagina - 1}
                                        onChangePage={this.CambiarPagina}
                                        onChangeRowsPerPage={this.CambioFilasPorPagina}
                                        labelRowsPerPage={"Filas Por Pagina"}
                                    />

                                </>
                                : <SpinnerCarga />
                        }

                    </div>
                </div>

                {/* Modal Agregar Receta*/}
                <Modal
                    show={this.state.MostrarModalAgregar}
                    onHide={() => { this.CerrarModalAgregar() }}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <form onSubmit={(ev) => { this.GuardarReceta(ev) }}>
                        <Modal.Header>
                            <Modal.Title>Receta - {this.state.AccionReceta}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="container">
                                <div className="row my-1">
                                    <div className="col">
                                        <small className="text-primary">Descripción</small>
                                        <input ref={this.inputDescripcionReceta} required type="" className="form-control form-control-sm" placeholder="Ingrese una Descripción"></input>
                                    </div>
                                </div>


                                {/* Tipo de trabajo */}
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Tipo de Trabajo</small>
                                        <select ref={this.inputTipoTrabajo} required className="form-select form-select-sm" >
                                            <option value="">Ninguno</option>
                                            {
                                                this.state.ListaTiposTrabajo.map((row, index) => {
                                                    return (
                                                        <option key={index} value={row.tipotrabajo}>{row.tipotrabajo}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/* Tipo de Material 1 */}
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Tipo Material 1</small>
                                        <select ref={this.selectTipoMaterial1} required className="form-select form-select-sm" >
                                            <option value="">Ninguno</option>
                                            {
                                                this.state.ListaTiposMaterial.map((row, index) => {
                                                    return (
                                                        <option key={index} value={row.subtipo}>{row.subtipo}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/* Tipo de Material 2 */}
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Tipo Material 2</small>
                                        <select ref={this.selectTipoMaterial2} className="form-select form-select-sm" >
                                            <option value="">Ninguno</option>
                                            {
                                                this.state.ListaTiposMaterial.map((row, index) => {
                                                    return (
                                                        <option key={index} value={row.subtipo}>{row.subtipo}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                {/* Tipo de Material 3 */}
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Tipo Material 3</small>
                                        <select ref={this.selectTipoMaterial3} className="form-select form-select-sm" >
                                            <option value="">Ninguno</option>
                                            {
                                                this.state.ListaTiposMaterial.map((row, index) => {
                                                    return (
                                                        <option key={index} value={row.subtipo}>{row.subtipo}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Estado</small>
                                        <select ref={this.selectEstadoReceta} required className="form-select form-select-sm" >
                                            <option value="ACTIVO">Activo</option>
                                            <option value="INACTIVO">Inactivo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>

                            <div className="container-fluid">
                                <div className="row">

                                    <div className="col-5 col-md-6">
                                        <button ref={this.buttonmodificarReceta} onClick={() => { this.ModificarReceta() }} className="btn btn-sm btn-outline-primary">Modificar</button>
                                    </div>

                                    <div className="col-7 col-md-6 text-end">
                                        <button onClick={() => { this.CerrarModalAgregar() }} className="btn btn-sm btn-outline-secondary mx-1">Cancelar</button>
                                        <button ref={this.buttonGuardarReceta} type="submit" className="btn btn-sm btn-primary mx-1">Guardar</button>
                                    </div>
                                </div>

                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>

                {/* Modal Agregar Material a RECETA*/}

                <Modal
                    show={this.state.MostrarAgregarMaterial}
                    onHide={() => { this.CerrarModalAgregarMaterial() }}
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <form onSubmit={(ev) => { ev.preventDefault() }}>
                        <Modal.Header>
                            <Modal.Title>Agregar Material</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                            <div className="container">
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Descripción</small>
                                        <input ref={this.inputDetalleRecetaCabecera} type="" className="form-control form-control-sm" placeholder="Ingrese una Descripcion" disabled></input>
                                    </div>
                                    <div className="col-md-6">
                                        <small className="text-primary">Receta</small>
                                        <input ref={this.inputRecetaCabecera} type="" className="form-control form-control-sm" placeholder="Descripcion de Receta" disabled></input>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col-md-12">
                                        <small className="text-primary">Material</small>
                                        <input ref={this.inputMateriales} type="" className="form-control form-control-sm" list="listaOpciones" placeholder="Ingrese el Material"></input>
                                        <datalist id="listaOpciones">
                                            {
                                                this.state.ListaMateriales.map((row, index) => {
                                                    return (<option key={index} value={`[${row.codigoMaterial}] -> ${row.descripcion}`} />)
                                                })
                                            }
                                        </datalist>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col-md-4">
                                        <small className="text-primary">Cantidad Receta</small>
                                        <input ref={this.inputCantidadReceta} className="form-control form-control-sm" placeholder="Ingrese una Cantidad" ></input>
                                    </div>
                                    <div className="col-md-4">
                                        <small className="text-primary">Multiplo(*)</small>
                                        {/* <input ref={this.inputmultiploReceta} type="number" className="form-control form-control-sm" placeholder="Ingrese un Multiplo" ></input> */}
                                        <select ref={this.inputmultiploReceta} required className="form-select form-select-sm" >
                                            <option value="0">No</option>
                                            <option value="1">Si</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4 d-flex flex-row-reverse align-items-end mt-md-0 mt-1">
                                        <button onClick={() => { this.AgregarMaterialReceta() }} ref={this.buttonAgregarMaterialReceta} className="btn btn-outline-secondary"><i className="fas fa-plus mx-1"></i>Agregar</button>
                                    </div>
                                </div>
                            </div>
                            {/* Tabla de Actividades aqui */}
                            <TableContainer style={{ maxHeight: '300px' }}>
                                <Table size='small' stickyHeader>
                                    <TableHead>
                                        <TableRow className="fondoTituloTabla border">
                                            <TableCell style={{ textAlign: 'center' }}>Acciones</TableCell>
                                            <TableCell>Unidad</TableCell>
                                            <TableCell>Cantidad</TableCell>
                                            <TableCell>Material</TableCell>
                                            <TableCell>Editar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.ListaMaterialesReceta.map((row, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell className="">
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-6 ">
                                                                    <i title="Eliminar" onClick={() => { this.EliminarMaterialReceta(row.idmateriareceta, row.t_basematerial.descripcion) }} title="Eliminar" className="fas fa-trash-alt iconoAcciones "></i>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className=" form-check form-switch " style={{ width: '15px' }}>
                                                                        {
                                                                            (row.estado === 'ACTIVO') ?
                                                                                <input title="Habilitar/Deshabilitar" onClick={() => { this.ModificarEstadoMaterialReceta(row.idmateriareceta, 'INACTIVO') }} className="form-check-input" type="checkbox" defaultChecked /> :
                                                                                <input title="Habilitar/Deshabilitar" onClick={() => { this.ModificarEstadoMaterialReceta(row.idmateriareceta, 'ACTIVO') }} className="form-check-input" type="checkbox" />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>{row.unidad}</TableCell>
                                                    <TableCell>{row.cantidad_receta}</TableCell>
                                                    <TableCell>{row.t_basematerial.descripcion}</TableCell>
                                                    <TableCell><i title="Editar" onClick={() => { this.AbrirModalEditarMaterialReceta(row, row.t_basematerial.descripcion) }} className="fas fa-pen iconoAcciones"></i></TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>


                        </Modal.Body>
                        <Modal.Footer>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-6 col-md-6">
                                        <small>{`${this.state.ListaMaterialesReceta.length} Elementos`}</small>
                                    </div>
                                    <div className="col-6 col-md-6 text-end">
                                        <button onClick={() => { this.CerrarModalAgregarMaterial() }} className="btn btn-outline-secondary mx-1">Cerrar</button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>


                {/* Modal Editar MaterialReceta */}
                <Modal
                    show={this.state.MostrarModalEditarMaterialReceta}
                    onHide={() => { this.CerrarModalEditarMaterialReceta() }}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <form onSubmit={(ev) => { ev.preventDefault() }}>
                        <Modal.Header>
                            <Modal.Title>Material Receta</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="container">
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Descripción</small>
                                        <input ref={this.inputDescripcionMaterialReceta} required type="" className="form-control form-control-sm" placeholder="Ingrese una Descripción" disabled></input>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Cantidad</small>
                                        <input ref={this.inputCantidadMaterialReceta} required className="form-control form-control-sm" placeholder="Ingrese una cantidad"></input>
                                    </div>
                                    <div className="col-md-6">
                                        <small className="text-primary">Unidad</small>
                                        <input ref={this.inputUnidadMaterialReceta} required className="form-control form-control-sm" placeholder="Ingrese una Unidad (Ej. Kg.,Mts... )"></input>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Multiplo (*)</small>
                                        {/* <input ref={this.inputMultiploMaterialReceta} required type="" className="form-control form-control-sm" placeholder="Ingrese una cantidad"></input> */}
                                        <select ref={this.inputMultiploMaterialReceta} required className="form-select form-select-sm" >
                                            <option value="0">No</option>
                                            <option value="1">Si</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <small className="text-primary">Estado</small>
                                        <select ref={this.selectEstadoMaterialReceta} required className="form-select form-select-sm" >
                                            <option value="ACTIVO">Activo</option>
                                            <option value="INACTIVO">Inactivo</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                        </Modal.Body>
                        <Modal.Footer>

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="text-end">
                                        <button onClick={() => { this.CerrarModalEditarMaterialReceta() }} className="btn btn-sm btn-outline-secondary mx-1">Cancelar</button>
                                        <button onClick={() => { this.EditarEstadoMaterialReceta() }} type="submit" className="btn btn-sm btn-primary mx-1">Guardar</button>
                                    </div>
                                </div>

                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>



            </Fragment>
        );
    }
}

export default configRecetaMaterial;