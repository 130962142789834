
import { Icon } from 'leaflet';


// Pines Marker
export const Pin1 = new Icon({
  iconUrl: 'img/pin1.svg',
  iconSize: [40, 40]
});

export const Pin20 = new Icon({
  iconUrl: 'img/pin20.svg',
  iconSize: [40, 40]
});

export const Pin19 = new Icon({
  iconUrl: 'img/pin19.svg',
  iconSize: [40, 40]
});

export const Pin18 = new Icon({
  iconUrl: 'img/pin18.svg',
  iconSize: [40, 40]
});

export const Pin17 = new Icon({
  iconUrl: 'img/pin17.svg',
  iconSize: [40, 40]
});

export const Pin16 = new Icon({
  iconUrl: 'img/pin16.svg',
  iconSize: [40, 40]
});

export const Pin15 = new Icon({
  iconUrl: 'img/pin15.svg',
  iconSize: [40, 40]
});

export const Pin14 = new Icon({
  iconUrl: 'img/pin14.svg',
  iconSize: [40, 40]
});

export const Pin13 = new Icon({
  iconUrl: 'img/pin13.svg',
  iconSize: [40, 40]
});

export const Pin12 = new Icon({
  iconUrl: 'img/pin12.svg',
  iconSize: [40, 40]
});

export const Pin11 = new Icon({
  iconUrl: 'img/pin11.svg',
  iconSize: [40, 40]
});

export const Pin10 = new Icon({
  iconUrl: 'img/pin10.svg',
  iconSize: [40, 40]
});

export const Pin9 = new Icon({
  iconUrl: 'img/pin9.svg',
  iconSize: [40, 40]
});

export const Pin8 = new Icon({
  iconUrl: 'img/pin8.svg',
  iconSize: [40, 40]
});

export const Pin7 = new Icon({
  iconUrl: 'img/pin7.svg',
  iconSize: [40, 40]
});

export const Pin6 = new Icon({
  iconUrl: 'img/pin6.svg',
  iconSize: [40, 40]
});

export const Pin5 = new Icon({
  iconUrl: 'img/pin5.svg',
  iconSize: [40, 40]
});

export const Pin4 = new Icon({
  iconUrl: 'img/pin4.svg',
  iconSize: [40, 40]
});

export const Pin3 = new Icon({
  iconUrl: 'img/pin3.svg',
  iconSize: [40, 40]
});

export const Pin2 = new Icon({
  iconUrl: 'img/pin2.svg',
  iconSize: [40, 40]
});


export const PinUbicacion = new Icon({
  iconUrl: 'img/pinubicacion.svg',
  // iconRetinaUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
  iconSize: [20, 20]
});

export const PinUbicacionRojo = new Icon({
  iconUrl: 'img/pinUbicacionRojo.svg',  
  iconSize: [18, 18]
});

export const PinUbicacionVerde = new Icon({
  iconUrl: 'img/pinUbicacionVerde.svg',  
  iconSize: [15, 15]
});

export const PinUbicacionNaranja = new Icon({
  iconUrl: 'img/pinUbicacionNaranja.svg',  
  iconSize: [15, 15]
});



export const IconoRojo = new Icon({
  iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
  iconRetinaUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
  iconSize: [35, 35]
});
export const IconoAzul = new Icon({
  iconUrl: 'https://cdn1.iconfinder.com/data/icons/ecommerce-61/48/eccomerce_-_location-256.png',
  iconSize: [50, 50]
});

export const IconoVerde = new Icon({
  iconUrl: 'https://cdn2.iconfinder.com/data/icons/gpsmapicons/green/gpsmapicons01.png',
  iconSize: [40, 40]
});

export const IconoElectric = new Icon({
  iconUrl: 'https://cdn0.iconfinder.com/data/icons/citycons/150/Citycons_power-256.png',
  iconSize: [40, 40]
});

export const IconoPinAzul = new Icon({
  iconUrl: 'https://cdn3.iconfinder.com/data/icons/softwaredemo/PNG/256x256/DrawingPin1_Blue.png',
  iconSize: [40, 40]
});
export const IconoPinVerde = new Icon({
  iconUrl: 'https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_PushPin_Right_Chartreuse.png',
  iconSize: [40, 40]
});

export const IconoPinRojo = new Icon({
  iconUrl: 'https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_PushPin_Right_Pink.png',
  iconSize: [40, 40]
});



export const IconoFind = new Icon({
  iconUrl: 'https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-01-256.png',
  iconSize: [40, 40]
});
export const IconoWorkFree = new Icon({
  // iconUrl: 'img/pinWorkFree.svg',
  // iconUrl: 'img/pinWorkFree2.svg',
  iconUrl: 'img/pinWorkFree3.svg',
  // iconUrl: 'https://cdn2.iconfinder.com/data/icons/electric-transportation-glyph/74/Untitled-182-27-256.png',  
  iconSize: [50, 50]
});

// iconUrl: 'https://cdn2.iconfinder.com/data/icons/locations-10/64/Electric_Charging_Station-Charging-Ev-Pin-Location-256.png',

export const IconoWorkSelect = new Icon({
  // iconUrl: 'https://cdn1.iconfinder.com/data/icons/logistics-delivery-set-3/512/29-256.png',
  iconUrl:'https://cdn1.iconfinder.com/data/icons/orientation-2/32/location-add-128.png',
  // iconUrl: 'https://cdn1.iconfinder.com/data/icons/vibrancie-map/30/map_028-location-marker-checkmark-pin-256.png',
  iconSize: [30, 30]
});


export const IconoMarkerRojo = new Icon({
  iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-256.png',
  iconSize: [30, 30]
});









