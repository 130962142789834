import React, { Component, Fragment } from 'react';

import { ValidarUsuario, BorrarStorage, listarPermisos, ValidarPermisos, LIST_SCREENS } from './../utils/utils'
import config from './../../config/config'

import NavBar from './../dashboard/navBar';
import BodyDashboard from './../dashboard/bodyDashboard';

class dashboard extends Component {

    componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then((permisos) => {
                // console.log("Permisos de usuario");
                // console.log(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_HOME, permisos)) {
                } else {
                    localStorage.removeItem(config.storageName);
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/login');
                    }, 1000);
                }
            });
        }
        else {
            //Redireccionando a Login
            this.props.history.push('/login');
        }
    }
    CerrarSesion = () => {
        // console.log(config.storageName);
        BorrarStorage(config.storageName);
        this.props.history.push('/login');
    }
    render() {
        return (
            <Fragment >
                {/* titulo */}
                <NavBar CerrarSesion={this.CerrarSesion} />
                <BodyDashboard />
            </Fragment>

        );
    }
}

export default dashboard;