import React, { Component, createRef, Fragment } from 'react';
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS } from '../utils/utils'
import NavBar from '../dashboard/navBar';
import TituloPagina from '../dashboard/headerTitle';
import moment from 'moment';

import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Modal } from 'react-bootstrap';

import LoadingModal from './../utils/loadModal'
import SpinnerCarga from './../utils/spinner'
import config from './../../config/config';

import { CreateJsonToExcel } from './../utils/utils'


class AlmacenMaterialProgramado extends Component {

    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            fechaprogramacion: '',
            LoadData: false,
            cantidadElementos: 0,
            filasPorPagina: 20,
            pagina: 1,
            valorBuscar: '',
            MostrarModalMateriales: false,
            MostrarModalAgregarMaterial: false,
            MostrarModalModificarProgramado: false,
            MostrarModalMaterialesRetirados: false,
            MostrarPorcentajeCarga: false,
            ListaProgramacion: [],
            trabajadorElegido: {},
            materialActual: {},
            ListaMaterialesProgramados: [],
            ListaMaterialesProgramadosfiltro: [],
            ListaMaterialesFerreteria: [],
            ListaMaterialesRetirados: [],
            ListaMaterialesRetiradosFiltro: [],
            ListaTrabajadoresProgramados: [],

        };

        //Referencias

        this.inputfechaProgramacion = createRef()
        this.inputBuscarFiltro = createRef();
        //ReferenciasModalMateriales
        this.inputnombreTrabajador = createRef();
        this.inputBuscarMaterial = createRef();

        //Referencias Modal Agregar Material
        this.inputDescripcionAgregar = createRef();
        this.inputCantidadAgregar = createRef();

        //Referencias Modal Modificar CAntidad Material
        this.inputCodigoModificarMaterial = createRef();
        this.inputDescripcionModificarMaterial = createRef();
        this.inputCantidadProgramadaModificar = createRef();
        this.inputCantidadModificar = createRef();

        //Modal Material Retirado
        this.inputnombreTrabajadorRetirado = createRef();

    }
    async componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async(permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ALMACEN_RESUMEN,permisos)) {
                    // Llenar los cuadros y llamar a la APi    
                    let fechaStorage = await localStorage.getItem(config.storageDateName);
                    if (fechaStorage) {
                        this.inputfechaProgramacion.current.value = moment(fechaStorage).format('YYYY-MM-DD');
                        await this.setState({ fechaprogramacion: fechaStorage });
                    } else {
                        this.inputfechaProgramacion.current.value = moment().format('YYYY-MM-DD');
                        await this.setState({ fechaprogramacion: this.inputfechaProgramacion.current.value });
                    }
                    await this.BuscarProgramacionfecha();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }

            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    BuscarProgramacionfecha = async () => {
        if (ValidarUsuario()) {

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            let url = `${config.endPoint}api/trabajosuministro/resumenalmacen/${this.state.fechaprogramacion}`;

            if (this.state.valorBuscar.trim() !== '') {
                url = `${config.endPoint}api/trabajosuministro/resumenalmacen/${this.state.fechaprogramacion}/${this.state.valorBuscar.trim()}`;
            }

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    await this.setState({ ListaProgramacion: data.content, cantidadElementos: data.content.length });
                    await this.Paginacion();
                }
                else {
                    this.setState({ ListaProgramacion: [], cantidadElementos: 0, LoadData: true });
                }
            }).catch((error) => {
                // console.log(error);
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarListaMateriales = (idpersonalasignado) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajosuministroAlmacen/materialentregado/${idpersonalasignado}/${this.state.fechaprogramacion}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        this.setState({ ListaMaterialesProgramados: data.content, ListaMaterialesProgramadosfiltro: [] });

                        resolve(true);
                    }
                    else {
                        this.setState({ ListaMaterialesProgramados: [], ListaMaterialesProgramadosfiltro: [] });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        //this.BuscarFiltroMaterialProgramado();
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }

    AbrirModalMateriales = async (row) => {
        if (ValidarUsuario()) {
            await this.setState({ OcultarLoading: false, trabajadorElegido: row });
            await this.BuscarListaMateriales(this.state.trabajadorElegido.idpersonalasignado).then(async () => {
            }).catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });
            await this.setState({ MostrarModalMateriales: true });
            this.BuscarFiltroMaterialProgramado();
            this.inputnombreTrabajador.current.value = row.nombretrabajador;
            this.setState({ OcultarLoading: true });
        }
        else {
            this.props.history.push('/login');
        }
    }

    BuscarFiltroMaterialProgramado = async () => {
        if (this.inputBuscarMaterial.current.value.trim !== "") {
            let narray = this.state.ListaMaterialesProgramados.filter(item => item.descripcion.toUpperCase().indexOf(this.inputBuscarMaterial.current.value.toUpperCase()) !== -1);
            await this.setState({ ListaMaterialesProgramadosfiltro: narray });
            //console.log(narray);
        }
        else {
            await this.setState({ ListaMaterialesProgramadosfiltro: this.state.ListaMaterialesProgramados });
        }
    }
    CerrarModalMateriales = () => {
        this.setState({ MostrarModalMateriales: false });
    }

    //************************************ */
    //Modal Materiales Retirados
    //*************************************** */
    AbrirModalMaterialesRetirados = async (row) => {
        if (ValidarUsuario()) {
            await this.setState({ OcultarLoading: false, trabajadorElegido: row });
            await this.BuscarListaMaterialesRetirados(this.state.trabajadorElegido.idpersonalasignado).then(async () => {
            }).catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });
            if (this.state.ListaMaterialesRetirados.length > 0) {
                await this.setState({ MostrarModalMaterialesRetirados: true });
                // this.BuscarFiltroMaterialProgramado();
                this.inputnombreTrabajadorRetirado.current.value = row.nombretrabajador;
            }
            else {
                this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'info' });

            }
            this.setState({ OcultarLoading: true });
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarListaMaterialesRetirados = (idpersonalasignado) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajosuministroAlmacen/materialretirado/${idpersonalasignado}/${this.state.fechaprogramacion}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        //console.log(data.content);
                        this.setState({ ListaMaterialesRetirados: data.content, ListaMaterialesRetiradosFiltro: [] });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaMaterialesRetirados: [], ListaMaterialesRetiradosFiltro: [] });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        //this.BuscarFiltroMaterialProgramado();
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    CerrarModalMaterialesRetirados = () => {
        this.setState({ MostrarModalMaterialesRetirados: false })
    }


    // Modal AgregarMaterial
    AbrirModalAgregarMaterial = async () => {
        await this.BuscarListaMaterialesFerreteria().then();
        this.setState({ MostrarModalAgregarMaterial: true });
    }
    CerrarModalAgregarMaterial = () => {
        this.setState({ MostrarModalAgregarMaterial: false });
    }

    BuscarListaMaterialesFerreteria = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/basematerial/listarFerreteria`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaMaterialesFerreteria: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaMaterialesFerreteria: [] });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    buscarCodigoMaterialLista = (descripcion_material) => {
        for (const material of this.state.ListaMaterialesFerreteria) {
            if (descripcion_material.indexOf(material.descripcion) > -1) {
                return material;
            }
        }
        return null;
    }
    AgregarMaterial = async () => {
        if (ValidarUsuario()) {
            //Validar Que material no Exista

            if (this.inputDescripcionAgregar.current.value.trim() !== '') {
                this.inputDescripcionAgregar.current.classList.remove('is-invalid');
                let material = await this.buscarCodigoMaterialLista(this.inputDescripcionAgregar.current.value)
                let _cantidad = parseFloat(this.inputCantidadAgregar.current.value);

                // console.log(material);
                // console.log(this.state.trabajadorElegido);

                if (Number.isNaN(_cantidad)) {
                    this.inputCantidadAgregar.current.classList.add('is-invalid');
                    this.inputCantidadAgregar.current.value = '';
                    this.setState({ abrirToast: true, mensajeToast: "Debe ingresar una cantidad valida", tipotoast: 'warning' });
                } else {
                    this.inputCantidadAgregar.current.classList.remove('is-invalid');
                    this.inputCantidadAgregar.current.value = _cantidad;

                    if (material !== null) {
                        let url = `${config.endPoint}api/material/agregar`;
                        let body_materialtipoTrabajo = {
                            codigo: material.codigoMaterial,
                            descripcion: material.descripcion,
                            unidad: material.unidad,
                            cantidadEntregada: this.inputCantidadAgregar.current.value,
                            cantidad: 0,
                            observacion: "MATERIAL-MANUAL",
                            estado: 'ACTIVO',
                            idbasematerial: material.idBaseMaterial,
                            idtrabajador: this.state.trabajadorElegido.idpersonalasignado,
                            fechaProgramada: this.state.fechaprogramacion
                        }

                        // console.log(body_materialtipoTrabajo);
                        let UsuarioStorage = ObtenerUsuario();
                        UsuarioStorage = JSON.parse(UsuarioStorage);
                        let headers = {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + UsuarioStorage.token,
                            },
                            body: JSON.stringify(body_materialtipoTrabajo)
                        };
                        fetch(url, headers).then((respuesta) => {
                            return respuesta.json();
                        }).then((data) => {
                            if (data.content) {
                                this.setState({ abrirToast: true, mensajeToast: "Material Agregado ", tipotoast: 'success' });
                                //Cerrar Modal Agregar
                                this.CerrarModalAgregarMaterial();
                                this.BuscarListaMateriales(this.state.trabajadorElegido.idpersonalasignado).then();
                            }
                            else {
                                this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                            }

                        }).catch(() => {
                            this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                        });
                    }
                    else {
                        this.inputDescripcionAgregar.current.classList.add('is-invalid');
                        this.inputDescripcionAgregar.current.value = '';
                        this.setState({ abrirToast: true, mensajeToast: "Debe elegir un elemento de la lista de materiales.", tipotoast: 'warning' });
                    }
                }
            }
            else {
                this.inputDescripcionAgregar.current.classList.add('is-invalid');
                this.setState({ abrirToast: true, mensajeToast: "Elija un elemento de la lista de materiales.", tipotoast: 'warning' });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }

    // Modal Actualizar Cantidad Programada
    AbrirModalActualizarCantidad = async (row) => {
        await this.setState({ AbrirModalActualizarCantidad: true, materialActual: row });
        this.inputCodigoModificarMaterial.current.value = row.codigo
        this.inputDescripcionModificarMaterial.current.value = row.descripcion;
        this.inputCantidadProgramadaModificar.current.value = row.entregado;

    }

    ActualizarMaterial = async () => {
        if (ValidarUsuario()) {
            //Validar Que material no Exista      
            let _cantidad = parseFloat(this.inputCantidadModificar.current.value);
            let _cantidadProgramda = parseFloat(this.inputCantidadProgramadaModificar.current.value);

            // console.log(material);
            // console.log(this.state.trabajadorElegido);
            if (Number.isNaN(_cantidad)) {
                this.inputCantidadModificar.current.classList.add('is-invalid');
                this.inputCantidadModificar.current.value = '';
                this.setState({ abrirToast: true, mensajeToast: "Debe ingresar una cantidad valida", tipotoast: 'warning' });
            } else {
                this.inputCantidadModificar.current.classList.remove('is-invalid');
                this.inputCantidadModificar.current.value = _cantidad;

                let cantidad_actualizada = _cantidad - _cantidadProgramda;


                let url = `${config.endPoint}api/material/agregar`;
                let body_materialtipoTrabajo = {
                    codigo: this.state.materialActual.codigo,
                    descripcion: this.state.materialActual.descripcion,
                    unidad: this.state.materialActual.unidad,
                    cantidadEntregada: cantidad_actualizada,
                    cantidad: 0,
                    observacion: "MATERIAL-MANUAL",
                    estado: 'ACTIVO',
                    idbasematerial: this.state.materialActual.idbasematerial,
                    idtrabajador: this.state.trabajadorElegido.idpersonalasignado,
                    fechaProgramada: this.state.fechaprogramacion
                }
                //console.log(body_materialtipoTrabajo);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(body_materialtipoTrabajo)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        this.setState({ abrirToast: true, mensajeToast: "Registro Actualizado", tipotoast: 'success' });
                        //Cerrar Modal Agregar
                        await this.BuscarListaMateriales(this.state.trabajadorElegido.idpersonalasignado).then();
                        this.BuscarFiltroMaterialProgramado();
                        this.CerrarModalActualizarCantidad();
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });

            }

        }
        else {
            this.props.history.push('/login');
        }
    }

    CerrarModalActualizarCantidad = () => {
        this.setState({ AbrirModalActualizarCantidad: false });
    }


    CambiarFecha = async () => {
        //this.selectTipoFiltro.current.value = "Todos";
        await this.setState({ fechaprogramacion: this.inputfechaProgramacion.current.value, LoadData: false });
        await localStorage.setItem(config.storageDateName, this.state.fechaprogramacion);
        await this.BuscarProgramacionfecha();
    }

    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.setState({ LoadData: false, pagina: 1, valorBuscar: this.inputBuscarFiltro.current.value });
            await this.BuscarProgramacionfecha();
        }
    }
    //*************************************************
    //Funciones para Paginacion
    //*************************************************
    Paginacion = async () => {
        let filainicio = 0;
        if (this.state.ListaProgramacion.length > 0) {
            filainicio = parseInt(this.state.filasPorPagina) * (parseInt(this.state.pagina) - 1);
        }
        let filafin = filainicio + this.state.filasPorPagina;
        let nuevoArray = [];
        for (let x = filainicio; x < this.state.ListaProgramacion.length; x++) {
            if (x < filafin) {
                nuevoArray.push(this.state.ListaProgramacion[x]);
            }
        }
        await this.setState({ ListaPaginacion: nuevoArray, LoadData: true });
    }
    CambioFilasPorPagina = async (event) => {
        await this.setState({ LoadData: false, filasPorPagina: parseInt(event.target.value), pagina: 1 });
        await this.BuscarProgramacionfecha();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ LoadData: false, pagina: (parseInt(nuevaPagina) + 1) });
        await this.BuscarProgramacionfecha();
    }
    /****************************** */
    //Reportes
    /******************************** */
    DescargarReporteMateriales = async () => {
        //Descargar Informacion
        if (ValidarUsuario()) {
            await this.setState({OcultarLoading:false});
            let url = `${config.endPoint}api/trabajosuministro/reporteMateriales/${this.state.fechaprogramacion}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    //this.setState({ ListaSuministrosProgramadoTecnico: data.content });
                    //console.log(data.content);
                    if (data.content.length > 0) {
                        await CreateJsonToExcel(data.content, `Reporte Materiales ${this.state.fechaprogramacion}`, `Materiales_${this.state.fechaprogramacion}`);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
                this.setState({OcultarLoading:true});

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error',OcultarLoading:true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    DescargarReporteMaterialesTecnico = async () => {
        if (ValidarUsuario()) {
            await this.setState({OcultarLoading:false});
            let url = `${config.endPoint}api/trabajosuministro/reporteMaterialesTrabajador/${this.state.fechaprogramacion}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    //this.setState({ ListaSuministrosProgramadoTecnico: data.content });
                    //console.log(data.content);
                    if (data.content.length > 0) {
                        await CreateJsonToExcel(data.content, `Reporte Materiales Trabajador ${this.state.fechaprogramacion}`, `ResumenMateriales_${this.state.fechaprogramacion}`);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
                this.setState({OcultarLoading:true});
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error',OcultarLoading:true });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    DescargarReporteResumenDia = async () => {
        if (ValidarUsuario()) {
            await this.setState({OcultarLoading:false});
            let url = `${config.endPoint}api/trabajosuministro/reporteresumenMateriales/${this.state.fechaprogramacion}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    console.log(data.content);
                    //this.setState({ ListaSuministrosProgramadoTecnico: data.content });
                    //console.log(data.content);
                    if (data.content.length > 0) {
                        await CreateJsonToExcel(data.content, `Resumen Materiales ${this.state.fechaprogramacion}`, `Resumen_${this.state.fechaprogramacion}`);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
                this.setState({OcultarLoading:true});

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error',OcultarLoading:true });
            });
        }
        else {
            this.props.history.push('/login');
        }

    }
    DescargarMaterialRetiradoDia = async () => {
        if (ValidarUsuario()) {
            await this.setState({OcultarLoading:false});

            let url = `${config.endPoint}api/trabajosuministro/reporteRetirado/${this.state.fechaprogramacion}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {                
                if (data.content) {
                    //this.setState({ ListaSuministrosProgramadoTecnico: data.content });
                    //console.log(data.content);
                    if (data.content.length > 0) {
                        await CreateJsonToExcel(data.content, `Resumen Material Retirado ${this.state.fechaprogramacion}`, `Retiro_${this.state.fechaprogramacion}`);
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para mostrar.", tipotoast: 'warning' });
                    }
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
                this.setState({OcultarLoading:true});

            }).catch(() => {                
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error',OcultarLoading:true });
            });
        }
        else {
            this.props.history.push('/login');
        }

    }

    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    render() {
        return (
            <Fragment>

                <Snackbar
                    open={this.state.MostrarPorcentajeCarga}
                    message={this.state.mensaCargaActualizacion}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo="ALMACÉN - MATERIAL PROGRAMADO" regresar={() => { this.props.history.push('/almacen') }}></TituloPagina>

                {/* Contenedor de Datos */}
                <div className="container border mt-1">
                    <div className="row mt-1">
                        <div className="col-md-3 ">
                            <input onChange={() => { this.CambiarFecha() }} className="form-control form-control-sm p-1" type="date" placeholder="Fecha" ref={this.inputfechaProgramacion}></input>
                        </div>
                        <div className="col-md-6 mt-1 mt-md-0">
                            <input onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} ref={this.inputBuscarFiltro} className="form-control form-control-sm" list="listaTrabajadoresProgramados" placeholder="Buscar JUO"></input>
                            <datalist id="listaTrabajadoresProgramados">
                                {
                                    this.state.ListaTrabajadoresProgramados.map((row, index) => {
                                        return (<option key={index} value={row.nombretrabajador} />)
                                    })
                                }
                            </datalist>
                        </div>
                        <div className="col-md-3 text-end mt-1 mt-md-0">
                            <div className="dropdown">
                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                    Opciones
                            </button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                    <li onClick={() => { this.DescargarReporteMateriales() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Descargar Data Material </small></li>
                                    <li onClick={() => { this.DescargarReporteMaterialesTecnico() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Descargar Programación Materiales Trabajador</small></li>
                                    <li onClick={() => { this.DescargarReporteResumenDia() }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Descargar Resumen Dia </small></li>
                                    <li onClick={() => { this.DescargarMaterialRetiradoDia() }}><small className="dropdown-item" ><i className="fas fa-file-excel mx-2"></i>Descargar Data Materiales Retirados</small></li>

                                    {/* <li><hr className="dropdown-divider" /></li>
                                    <li onClick={() => { }}><small className="dropdown-item" ><i className="fas fa-clipboard mx-2"></i>Actualizar</small></li> */}

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row p-1">
                        {
                            this.state.LoadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    <TableCell style={{ color: 'aliceblue' }} >Tipo Trabajo</TableCell>
                                                    <TableCell style={{ color: 'aliceblue' }} >JUO</TableCell>
                                                    <TableCell style={{ color: 'aliceblue' }} >Supervisor</TableCell>
                                                    <TableCell style={{ color: 'aliceblue' }} >Material</TableCell>
                                                    <TableCell style={{ color: 'aliceblue' }} >Material Retirado</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.ListaPaginacion.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.tipotrabajo}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.nombretrabajador}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }}>{row.supervisor}</TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }} onClick={() => { this.AbrirModalMateriales(row) }} className="iconoAcciones">
                                                                {<i title="Materiales " className="fas fa-screwdriver iconoAcciones"></i>}
                                                                {/* <span >{`(${row.material}) Material(es) Incluido(s)`}</span> */}
                                                                <span >{`Ver Materiales`}</span>
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '0.8em' }} onClick={() => { this.AbrirModalMaterialesRetirados(row) }} className="iconoAcciones">
                                                                {<i title="Materiales " className="fas fa-recycle iconoAcciones"></i>}
                                                                {/* <span >{`(${row.material}) Material(es) Incluido(s)`}</span> */}
                                                                <span >{`Ver Retirado`}</span>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[20, 30, 50]}
                                        component="div"
                                        count={this.state.cantidadElementos}
                                        rowsPerPage={this.state.filasPorPagina}
                                        page={this.state.pagina - 1}
                                        onChangePage={this.CambiarPagina}
                                        onChangeRowsPerPage={this.CambioFilasPorPagina}
                                        labelRowsPerPage={"Filas Por Pagina"}
                                    />
                                </>
                                :
                                <SpinnerCarga />

                        }
                    </div>

                </div>

                {/* Modal Materiales*/}

                <Modal
                    show={this.state.MostrarModalMateriales}
                    onHide={() => { this.CerrarModalMateriales() }}
                    keyboard={false}
                    centered
                    size="lg"
                >
                    <Modal.Header>
                        <Modal.Title>Materiales</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <small className="text-primary">Trabajador</small>
                                    <input ref={this.inputnombreTrabajador} className="form-control form-control-sm" placeholder="Nombre Trabajador" disabled></input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <small className="text-primary">Buscar Material</small>
                                    <input ref={this.inputBuscarMaterial} onChange={() => { this.BuscarFiltroMaterialProgramado() }} className="form-control form-control-sm" placeholder="Buscar Material" ></input>
                                </div>
                                <div className="col-md-6">
                                    <button onClick={this.AbrirModalAgregarMaterial} className="btn btn-sm btn-outline-secondary mx-1">agregar</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <small className="text-primary">Lista de Materiales</small>
                                </div>
                                {/* Tabla de Datos */}
                                <TableContainer style={{ maxHeight: '300px' }}>
                                    <Table size='small' stickyHeader  >
                                        <TableHead >
                                            <TableRow >
                                                <TableCell>Descripción</TableCell>
                                                <TableCell>Programado</TableCell>
                                                <TableCell>Utilizado</TableCell>
                                                <TableCell>Resto</TableCell>
                                                <TableCell>Acciones</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {this.state.ListaMaterialesProgramadosfiltro.map((material, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell style={{ fontSize: '0.8em' }}>{material.descripcion}</TableCell>
                                                        <TableCell style={{ fontSize: '0.8em' }}>{parseFloat(material.entregado).toFixed(2)}</TableCell>
                                                        <TableCell style={{ fontSize: '0.8em' }}>{parseFloat(material.utilizado).toFixed(2)}</TableCell>
                                                        <TableCell style={{ fontSize: '0.8em' }}>{parseFloat(material.utilizado - material.entregado).toFixed(2)}</TableCell>
                                                        <TableCell onClick={() => { this.AbrirModalActualizarCantidad(material) }} className="iconoAcciones">
                                                            {<i title="Modificar" className="far fa-edit iconoAcciones"></i>}
                                                            {/* <span>Editar</span> */}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.CerrarModalMateriales() }} className="btn btn-outline-secondary mx-1">Cerrar</button>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Modal Materiales Retirados*/}

                <Modal
                    show={this.state.MostrarModalMaterialesRetirados}
                    onHide={() => { this.CerrarModalMaterialesRetirados() }}
                    keyboard={false}
                    centered
                    size="lg"
                >
                    <Modal.Header>
                        <Modal.Title>Materiales Retirados</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <small className="text-primary">Trabajador</small>
                                    <input ref={this.inputnombreTrabajadorRetirado} className="form-control form-control-sm" placeholder="Nombre Trabajador" disabled></input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <small className="text-primary">Lista de Materiales</small>
                                </div>
                                {/* Tabla de Datos */}
                                <TableContainer style={{ maxHeight: '300px' }}>
                                    <Table size='small' stickyHeader  >
                                        <TableHead >
                                            <TableRow >
                                                {/* <TableCell>Tipo</TableCell>                                                
                                                <TableCell>OT</TableCell>                                                 */}
                                                <TableCell>Suministro</TableCell>
                                                <TableCell>Material</TableCell>
                                                <TableCell>Serie/Obs.</TableCell>
                                                <TableCell>Cantidad</TableCell>
                                                {/* <TableCell>Acciones</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {this.state.ListaMaterialesRetirados.map((material, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        {/* <TableCell style={{ fontSize: '0.8em' }}>{material.tipotrabajo}</TableCell>                                                        
                                                        <TableCell style={{ fontSize: '0.8em' }}>{material.ot}</TableCell>      */}
                                                        <TableCell style={{ fontSize: '0.8em' }}>{material.suministro}</TableCell>
                                                        <TableCell style={{ fontSize: '0.8em' }}>{material.descripcion}</TableCell>
                                                        <TableCell style={{ fontSize: '0.8em' }}>{material.Serie}</TableCell>
                                                        <TableCell style={{ fontSize: '0.8em' }}>{material.Cantidad}</TableCell>
                                                        {/* <TableCell onClick={() => { }} className="iconoAcciones">
                                                            {<i title="Modificar" className="far fa-edit iconoAcciones"></i>}                                                            
                                                        </TableCell> */}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.CerrarModalMaterialesRetirados() }} className="btn btn-outline-secondary mx-1">Cerrar</button>
                        </div>
                    </Modal.Footer>
                </Modal>


                {/* Modal Adicionar Material */}
                <Modal
                    show={this.state.MostrarModalAgregarMaterial}
                    onHide={() => { this.CerrarModalAgregarMaterial() }}
                    keyboard={false}
                    backdrop="static"
                    centered
                >

                    <Modal.Header>
                        <Modal.Title>Agregar Materiales</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <small className="text-primary">Material</small>
                                    <input ref={this.inputDescripcionAgregar} type="" className="form-control " list="ListaMaterialFerreteria" placeholder="Ingrese el Material"></input>
                                    <datalist id="ListaMaterialFerreteria">
                                        {
                                            this.state.ListaMaterialesFerreteria.map((row, index) => {
                                                return (<option key={index} value={`${row.descripcion}`} />)
                                            })
                                        }
                                    </datalist>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <small className="text-primary">Cantidad</small>
                                    <input ref={this.inputCantidadAgregar} className="form-control " placeholder="Ingrese una cantidad" ></input>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.CerrarModalAgregarMaterial() }} className="btn btn-outline-primary mx-1">Cerrar</button>
                            <button onClick={() => { this.AgregarMaterial() }} className="btn  btn-outline-primary"><i className="fas fa-plus mx-1"></i>Agregar</button>
                        </div>
                    </Modal.Footer>
                </Modal>


                {/* Modal Editar Cantidad Entregada*/}

                <Modal
                    show={this.state.AbrirModalActualizarCantidad}
                    onHide={() => { this.CerrarModalActualizarCantidad() }}
                    keyboard={false}
                    backdrop="static"
                    size="sm"
                    centered                >

                    <Modal.Header>
                        <Modal.Title>Modificar Cantidades</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <small className="text-primary">Material</small>
                                    <input ref={this.inputDescripcionModificarMaterial} type="" className="form-control form-control-sm" placeholder="Material" disabled></input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <small className="text-primary">Código</small>
                                    <input ref={this.inputCodigoModificarMaterial} className="form-control form-control-sm" placeholder="Cantidad Programada" disabled></input>
                                </div>
                                <div className="col-md-6">
                                    <small className="text-primary">Cantidad (Actual)</small>
                                    <input ref={this.inputCantidadProgramadaModificar} className="form-control " placeholder="Cantidad Programada" disabled></input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <small className="text-primary">Cantidad a Modificar ({this.state.materialActual.unidad})</small>
                                    <input ref={this.inputCantidadModificar} className="form-control form-control-sm" placeholder="Ingrese una cantidad" ></input>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.CerrarModalActualizarCantidad() }} className="btn btn-sm btn-outline-primary mx-1">Cerrar</button>
                            <button onClick={() => { this.ActualizarMaterial() }} className="btn btn-sm  btn-outline-primary"><i className="fas fa-plus mx-1"></i>Actualizar</button>
                        </div>
                    </Modal.Footer>
                </Modal>


            </Fragment>
        );
    }
}

export default AlmacenMaterialProgramado;