import React, { Component, createRef, Fragment } from 'react';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import LoadingModal from './../utils/loadModal'
import SpinnerCarga from './../utils/spinner'
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, Snackbar } from '@material-ui/core'

import { Alert } from '@material-ui/lab'
import { Modal } from 'react-bootstrap';

import config from './../../config/config'

import { ValidarUsuario, ObtenerUsuario, ReadExcelfileToJson, ValidarPermisos, LIST_SCREENS, listarPermisos, LIST_COLORES } from './../utils/utils'

class programacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            valorSelect: "Todos",
            colorIcon: '',
            loadSumEliminar: true,
            MostrarModalCambiarTrabajador: false,
            MostrarModalAsignarSupervisor: false,
            MostrarModalMensajes: false,
            MostrarModalElegirTipoProgramacion: false,
            MostrarModalEliminar: false,
            MostrarModalProgramarMapa: false,
            MostrarModalAsignarColorTrabajo: false,
            pantallaCompleta: true,
            listaProgramacion: [],
            TipoTrabajos: [],
            ListaObservacionTecnico: [],
            CantidadObservacionesTecnico: 0,
            mostrarTodosJuoObservacion: false,
            ListaObservacionesSupervisor: [],
            CantidadObservacionesSupervisor: 0,
            mostrarTodosSupervisorObservacion: false,
            ListaTrabajadoresActivos: [],
            ListaObservacionSupervisor: [],
            ListaObservacionesPrioridad: [],
            ListaSuministrosSinGps: [],
            ListaSuministrosEliminar: [],
            ListaTecnicosTipoTrabajo: [],
            filasPorPagina: 10,
            pagina: 1,
            cantidadElementos: 0,
            loadData: false,
            fechaProgramacion: '',
            abrirToast: false,
            ListaProgramcionTecnicos: [],
            ListaProgramacionSupervisores: [],
            ListaMaterialesReceta: [],
            ListaMaterialSEAL: [],
            MostrarPorcentajeCarga: false,
            mensaCargaProgramacion: '',
            ProgramacionCargada: 0,
            ProgramacionNoCargada: 0,
            ProgramacionError: 0,
            TotalArchivos: 0,
            ListaErrores: '',
            FilasError: [],
            TextoMensajesModal: '',
            TituloMensajeModal: '',
            TextoMensajesModalPregunta: '',
            DataCargar: [],
            CodigosNoEncontrados: [],
            RecetasNoEncontradas: [],
            RecetasNoEncontradasDetalle: [],
            tipotrabajoDetalle: '',
            tipoCargaProgramacionTrabajo: 'DESDE-ARCHIVO',
            tipoProgramacionTrabajo: '',
            color: 'rgb(255,255,255)',
            colorText: 'black',
        };
        this.headCells = [
            { id: 'Obs.' },
            { id: 'Programación' },
            { id: 'Prioridad' },
            { id: 'Tipo' },
            { id: 'Suministro' },
            // { id: 'Dirección' },
            // { id: 'Circuito' },
            { id: 'JUO Asignado' },
            { id: 'JUO Programado' },
            { id: 'Superv. Asignado' },
            { id: 'Superv. Programado' },
        ];
        // Referencias
        this.inputFechaProgramacion = createRef();
        this.inputFileSubirArchivo = createRef();
        this.selectTipoTrabajo = createRef();
        this.buttonSubirData = createRef();
        this.buttomBuscar = createRef();
        //Referencias Modal Asignar Tecnico
        this.inputChekMostrarTodosTecnicos = createRef();
        this.selectListaJUOEncontrados = createRef();
        this.inputReasignarTrabajador = createRef();
        // Referencias Modal AsignatSupervisor
        this.inputCheckBoxMostrarTodosSupervisores = createRef();
        this.selectTrabajadoresSupervisorAsignado = createRef();
        this.selectSupervisorEncontrados = createRef();
        this.inputReasignarSupervidor = createRef();

        //Referencias modalEliminar
        this.selectTipoEliminar = createRef();
        this.selectTecnicoEliminar = createRef();

        //Referencia Radiobutton
        this.selectElegirTipoProgramacion = createRef();

        //referencias Selecc color
        this.selectElegirColorTrabajo = createRef();
        this.selectPrioridades = createRef();
    }
    async componentDidMount() {
        if (ValidarUsuario()) {

            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_PROGRAM, permisos)) {

                    this.inputFechaProgramacion.current.value = moment().format('YYYY-MM-DD');
                    await this.setState({ fechaProgramacion: this.inputFechaProgramacion.current.value });
                    await this.BuscarProgramacionPorFecha();
                    await this.BuscarListaTipoTrabajo();

                } else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }

            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    // ***************************
    // Progrmacion de Trabajos
    // ***************************
    BuscarProgramacionPorFecha = async () => {
        if (ValidarUsuario()) {

            // FormatearFecha
            await this.setState({ loadData: false });
            let url = '';
            if (this.selectTipoTrabajo.current.value !== "TODOS") {
                url = `${config.endPoint}api/trabajosuministro/programacion/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}/${this.state.filasPorPagina}/${this.state.pagina}`;
            }
            else {
                url = `${config.endPoint}api/trabajosuministro/programacion/${this.state.fechaProgramacion}/${this.state.filasPorPagina}/${this.state.pagina}`;
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    await this.BuscarListaObservacionTecnicos().then(async () => {
                        //Buscar Cantidad de elementos con error                        
                        await this.ContarObservacionesTecnico();
                    }).catch((error) => {
                        if (error) {
                            this.props.history.push('/login');
                        }
                    });

                    await this.BuscarListaObservacionSupervisor().then(async () => {
                        await this.ContarObservacionesSupervisor();
                    }).catch((error) => {
                        if (error) {
                            this.props.history.push('/login');
                        }
                    });

                    await this.BuscarListaSuministrosSinGPs().then();

                    this.setState({ listaProgramacion: data.content, cantidadElementos: data.count, loadData: true });

                    // console.log(data.content);
                    // if (this.state.CantidadObservacionesTecnico === 0 && this.state.CantidadObservacionesSupervisor === 0) {                        
                    //     this.setState({ abrirToast: true, mensajeToast: "No se encontraron observaciones de Tecnico o Supervisor sin asingar.", tipotoast: 'info' });
                    // }
                }
                else {
                    this.setState({ listaProgramacion: [], cantidadElementos: data.count, loadData: true })
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
            await this.setState({ OcultarLoading: true });
        }
        else {
            this.props.history.push('/login');
        }
    }
    // ***************************
    // Observaciones GPS
    // ***************************
    BuscarListaSuministrosSinGPs = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {

                let url = '';
                if (this.selectTipoTrabajo.current.value !== "TODOS") {
                    url = `${config.endPoint}api/trabajosuministro/listarProgramacionSinGPS/${this.state.fechaProgramacion}/${this.selectTipoTrabajo.current.value}`;
                }
                else {
                    url = `${config.endPoint}api/trabajosuministro/listarProgramacionSinGPS/${this.state.fechaProgramacion}`;
                }

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaSuministrosSinGps: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaSuministrosSinGps: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }

        });

    }
    // ***************************
    // Listado Programacion Trabajadores
    // ***************************
    BuscarListaObservacionTecnicos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = '';
                if (this.selectTipoTrabajo.current.value !== "TODOS") {
                    url = `${config.endPoint}api/trabajosuministro/programacionalertas/trabajador/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`
                }
                else {
                    url = `${config.endPoint}api/trabajosuministro/programacionalertas/trabajador/${this.state.fechaProgramacion}`
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaObservacionTecnico: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaObservacionTecnico: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }

        });
    }
    ContarObservacionesTecnico = async () => {
        let cant = 0;
        for await (const elem of this.state.ListaObservacionTecnico) {
            if (elem.nombretrabajador === null) {
                cant++;
            }
        }
        this.setState({ CantidadObservacionesTecnico: cant });
    }
    BuscarListaProgramacionTecnicos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = '';
                if (this.selectTipoTrabajo.current.value !== "TODOS") {
                    url = `${config.endPoint}api/trabajosuministro/programado/trabajador/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`
                }
                else {
                    url = `${config.endPoint}api/trabajosuministro/programado/trabajador/${this.state.fechaProgramacion}`
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaProgramcionTecnicos: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaProgramcionTecnicos: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }

        });
    }
    // ***************************
    // Listado Programacion Supervisores
    // ***************************
    BuscarListaObservacionSupervisor = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = '';
                if (this.selectTipoTrabajo.current.value !== "TODOS") {
                    url = `${config.endPoint}api/trabajosuministro/programacionalertas/supervisor/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`
                }
                else {
                    url = `${config.endPoint}api/trabajosuministro/programacionalertas/supervisor/${this.state.fechaProgramacion}`
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaObservacionSupervisor: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaObservacionSupervisor: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }

        });
    }
    // ***************************
    // Listado Pbservaciones Prioridad
    // ***************************
    BuscarListaObservacionesPrioridad = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajosuministro/programacionalertas/prioridad/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaObservacionesPrioridad: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaObservacionesPrioridad: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }
        });
    }
    validarModalAsignarcolorTrabajos = () => {
        let contador = 0;
        // if (this.selectPrioridades.current.value === '') {
        //     this.selectPrioridades.current.classList.add('is-invalid');
        //     contador++;
        // }
        // else {
        //     this.selectPrioridades.current.classList.remove('is-invalid');
        // }

        if (this.selectElegirColorTrabajo.current.value.trim() === '') {
            this.selectElegirColorTrabajo.current.classList.add('is-invalid');
            contador++;
        }
        else {
            this.selectElegirColorTrabajo.current.classList.remove('is-invalid');
        }
        if (contador > 0) {
            return false;
        }
        else {
            return true;
        }
    }
    ReasignarColorTrabajoSuministro = async () => {
        if (ValidarUsuario()) {
            if (this.validarModalAsignarcolorTrabajos()) {

                let url = `${config.endPoint}api/trabajosuministro/programacion/cambiarpincolor`;
                let updateTrabajoSuministro = {
                    fechaProgramacion: this.state.fechaProgramacion,
                    idtipotrabajo: this.selectTipoTrabajo.current.value,
                    pinColor: this.selectElegirColorTrabajo.current.value,
                    prioridad: this.selectPrioridades.current.value
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(updateTrabajoSuministro)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        await this.setState({ abrirToast: true, mensajeToast: "Color asignado correctamente.", tipotoast: 'success' });
                    }
                    else {
                        data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'warning' }) :
                            this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }
                }).catch((error) => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });

            }
        }
        else {
            this.props.history.push('/login');
        }
    }


    ContarObservacionesSupervisor = async () => {
        let cant = 0;
        for await (const elem of this.state.ListaObservacionSupervisor) {
            if (elem.nombretrabajador === null) {
                cant++;
            }
        }
        this.setState({ CantidadObservacionesSupervisor: cant });
    }
    BuscarListaProgramacionSupervisor = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = '';
                if (this.selectTipoTrabajo.current.value !== "TODOS") {
                    url = `${config.endPoint}api/trabajosuministro/programado/supervisor/${this.selectTipoTrabajo.current.value}/${this.state.fechaProgramacion}`
                }
                else {
                    url = `${config.endPoint}api/trabajosuministro/programado/supervisor/${this.state.fechaProgramacion}`
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaProgramacionSupervisores: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaProgramacionSupervisores: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }

        });
    }
    // ***************************
    // Subir Programacion de Excel
    // ***************************
    ValidarDatosProgramacion = () => {
        let errores = 0;
        let listaErrores = '';
        if (this.inputFileSubirArchivo.current.files.length > 0) {
            this.inputFileSubirArchivo.current.classList.remove('is-invalid');
        }
        else {
            this.inputFileSubirArchivo.current.classList.add('is-invalid');
            listaErrores += 'Ejija el archivo a procesar. ;'
            errores++;
        }

        if (this.selectTipoTrabajo.current.value !== 'TODOS') {
            this.selectTipoTrabajo.current.classList.remove('is-invalid');
        }
        else {
            this.selectTipoTrabajo.current.classList.add('is-invalid');
            listaErrores += 'Seleccione el tipo de programación. ;'
            errores++;
        }
        if (moment(this.state.fechaProgramacion).isValid()) {
            // let fecha_hoy=moment();
            // let fechaprogra=moment(this.state.fechaProgramacion);            
            // console.log(fecha_hoy.diff(fechaprogra,'minute'));
            // console.log(fechaprogra.diff(fecha_hoy,'minute'));
            this.inputFechaProgramacion.current.classList.remove('is-invalid');
        } else {
            this.inputFechaProgramacion.current.classList.add('is-invalid');
            listaErrores += 'La fecha establecida no es un formato valido. ;'
            errores++;

        }
        if (errores > 0) {
            this.setState({ abrirToast: true, mensajeToast: listaErrores, tipotoast: 'warning' });
            return false;
        }
        else {
            return true;
        }
    }

    ObtenerRecetaMateriales = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/materialreceta/listarMaterialesRecetaActivos`

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaMaterialesReceta: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaMaterialesReceta: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }

        });

    }
    ObtenerListaMaterialesSEAL = async () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/basematerial/listamateriales/seal`

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaMaterialSEAL: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaMaterialSEAL: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                })
            }
            else {
                reject(true);
            }

        })
    }
    CargarProgramacion = async () => {
        if (ValidarUsuario()) {
            if (this.ValidarDatosProgramacion()) {
                try {

                    console.log("/**********************************/")
                    console.log("/SiscomerWeb Script version 1.1    /")
                    console.log("/**********************************/")
                    //Reiniciar Valores                    
                    await this.setState({ DataCargar: [], CodigosNoEncontrados: [], RecetasNoEncontradas: [] });
                    await this.setState({ OcultarLoading: false });
                    let DataExcel = null;

                    await ReadExcelfileToJson(this.inputFileSubirArchivo.current.files[0]).then(async (respuesta) => {                        
                        DataExcel = respuesta;
                        // console.log(respuesta);
                    }).catch((error) => {
                        console.log(error);
                        DataExcel = [];
                        this.setState({ mensajeToast: "Error al cargar el archivo.", abrirToast: true, tipotoast: 'error' });
                    });

                    //Validar Que se aya encontrado datos
                    if (DataExcel.length > 0) {
                        //Buscar Materiales de Receta
                        await this.ObtenerRecetaMateriales().then();//Retorna ListaMaterialesReceta
                        //Buscar Recetas Para el tipo de Trabajo
                        let RecetasTipoTrabajo = this.state.ListaMaterialesReceta.filter(receta => receta.tipo_trabajo === this.state.tipotrabajoDetalle);
                        await this.setState({ ListaMaterialesReceta: RecetasTipoTrabajo });
                        // console.log(this.state.ListaMaterialesReceta);

                        await this.ObtenerListaMaterialesSEAL().then();//Retorna ListaMaterialSEAL Activos SEAL           

                        // if (this.state.ListaMaterialesReceta.length > 0) {
                            //Recorrer el Arreglo de Filas Excel
                            let listaCodigosNoExisten = [];
                            let listaRecetasNoEncontradas = [];
                            let DataCargar = [];

                            let ListaRecetaDetalle = [];
                            let listaRecetasNoEncontradasDetalle = [];

                            for (let x = 0; x < DataExcel.length; x++) {
                                // console.log(`***********************`);
                                // console.log(`Elemento Excel ${x+1}`);
                                // console.log(`***********************`);
                                let nuevaFila = {};
                                let ListaReceta = [];
                                //Buscar Valores que no sean cabeceras                     
                                for (var clave in DataExcel[x]) {
                                    //Cargar Tipo Trabajo elegido                                    
                                    nuevaFila.tipoProgramacion = this.state.tipoProgramacionTrabajo;

                                    //Verificando Clave Existe
                                    if (DataExcel[x].hasOwnProperty(clave)) {
                                        //Verificando si la Clave es Cabecera o Material 
                                        switch (clave) {
                                            case 'Tipo':
                                                nuevaFila.tipo = DataExcel[x][clave];
                                                break;
                                            case 'Prioridad':
                                                nuevaFila.prioridad = DataExcel[x][clave];
                                                break;
                                            case 'Suministro/Ruta':
                                                nuevaFila.suministro_ruta = DataExcel[x][clave] ;
                                                break;
                                            case 'Serie':
                                                nuevaFila.serie = DataExcel[x][clave] ;
                                                break;
                                            case 'Fecha de Pago':
                                                let fecha=DataExcel[x][clave];
                                                let nFecha=moment(fecha).format('YYYY-MM-DD')
                                                let nHora=moment(fecha).format('hh:mm a')                                                
                                                // nuevaFila.fechaDePago = DataExcel[x][clave] ;
                                                nuevaFila.fechaDePago = nFecha + " " + nHora ;
                                                break;
                                            case 'Orden Trabajo':
                                                nuevaFila.ordenTrabajo = DataExcel[x][clave] ;
                                                break;
                                            // case '':
                                            //     nuevaFila.observacion = DataExcel[x][clave] ;
                                            //     break;
                                            case 'Nota de Salida':
                                                nuevaFila.notasalida = DataExcel[x][clave];
                                                break;
                                            case 'Distrito':
                                                nuevaFila.distrito = DataExcel[x][clave];
                                                break;
                                            case 'Suministro / Contrato':
                                                nuevaFila.suministro = DataExcel[x][clave];
                                                break;
                                            case 'SED':
                                                nuevaFila.subEstacion = DataExcel[x][clave];
                                                break;
                                            case 'Circuito':
                                                nuevaFila.circuito = DataExcel[x][clave];
                                                break;
                                            case 'Tablero':
                                                nuevaFila.tablero = DataExcel[x][clave];
                                                break;
                                            case 'Nombre':
                                                nuevaFila.nombreCliente = DataExcel[x][clave];
                                                break;
                                            case 'Dirección':
                                                nuevaFila.direccionCliente = DataExcel[x][clave];
                                                break;
                                            case 'Detalle':
                                                nuevaFila.detalle = DataExcel[x][clave];
                                                break;
                                            case 'D':
                                                nuevaFila.dias = DataExcel[x][clave];
                                                break;
                                            case 'FECHA FORMATO':
                                                nuevaFila.fechaFormato = DataExcel[x][clave];
                                                break;
                                            case 'HACER NORMA':
                                                nuevaFila.hacerNorma = DataExcel[x][clave];
                                                break;
                                            case 'Tipo OT':
                                                nuevaFila.tipoOt = DataExcel[x][clave] ;
                                                break;                                        
                                            case 'OT':
                                                nuevaFila.ot = DataExcel[x][clave];
                                                break;
                                            case 'JUO':
                                                nuevaFila.JUOProgramacion = DataExcel[x][clave];
                                                break;
                                            case 'Supervisor':
                                                nuevaFila.supervisor = DataExcel[x][clave].trim() === "" ? "X" : DataExcel[x][clave];
                                                break;
                                            // case '':
                                            //     nuevaFila.fechaRecepcionOt = DataExcel[x][clave] ;
                                            //     break;
                                            case 'X':
                                                nuevaFila.lat_inicio = DataExcel[x][clave];
                                                break;
                                            case 'Y':
                                                nuevaFila.lng_inicio = DataExcel[x][clave];
                                                break;
                                            case 'Potencia':
                                                nuevaFila.potencia = DataExcel[x][clave];
                                                break;
                                            case 'Acometida':
                                                nuevaFila.acometida = DataExcel[x][clave];
                                                break;
                                            case 'Suministro Referencia':
                                                nuevaFila.sumReferencia = DataExcel[x][clave];
                                                break;
                                            case 'Acometida':
                                                nuevaFila.acometida = DataExcel[x][clave];
                                                break;
                                            case 'Fases':
                                                nuevaFila.fases = DataExcel[x][clave];
                                                break;

                                            default:
                                                //Buscar el valor en lista de Receta Materiales 
                                                let valor = this.state.ListaMaterialSEAL.filter(material => material.codigoGotop === clave);
                                                if (valor.length > 0) {
                                                    //Existe Material y Receta
                                                    let recetaExiste = ListaReceta.filter(receta => receta === valor[0].subtipo);
                                                    // if (recetaExiste.length === 0) {                                                    
                                                    ListaReceta.push(valor[0].subtipo);
                                                    ListaRecetaDetalle.push(valor[0]);
                                                    // }
                                                } else {
                                                    //"Verificar Si Clave ya Existe en lista de Codigos no encontrados"
                                                    let codigoExistente = listaCodigosNoExisten.filter(codigo => codigo === clave);
                                                    if (codigoExistente.length === 0) {
                                                        console.log("Codigo de receta sub naterial");
                                                        console.log(clave);
                                                        listaCodigosNoExisten.push(clave);
                                                    }
                                                }
                                                break;
                                        }
                                    }
                                }

                                //Buscar Recetas
                                let MaterialesReceta = [];
                                let MaterialesProgramar = []
                                let arraytemporal = [];
                                // console.log(`Recetas Iniciales ${x+1}`);                                
                                // console.log(ListaReceta);

                                //************************************************ */
                                //VALIDAR RECETAS CONVINADAS
                                //************************************************ */
                                let _tempListaReceta = [];
                                // console.log("Recetas para item Excel");
                                // console.log(ListaReceta);

                                for (let n = 0; n < ListaReceta.length; n++) {
                                    // console.log("Receta Buscada");
                                    // console.log(ListaReceta[n]);

                                    let nMaterialReceta = this.state.ListaMaterialesReceta.filter((plantillaReceta) => {
                                        return (plantillaReceta.tipo_material_1 === ListaReceta[n] || plantillaReceta.tipo_material_2 === ListaReceta[n] || plantillaReceta.tipo_material_3 === ListaReceta[n]);
                                    });
                                    // console.log(`Materiañes Receta item ${n+1}`);
                                    // console.log(nMaterialReceta);

                                    if (nMaterialReceta.length > 1) {
                                        // console.log("recetas encontradas");
                                        // console.log(nMaterialReceta);
                                        //existe una Convinacion

                                        let indexMarch = -1;
                                        let _tempMaterialReceta = [];
                                        let cantMatchSup = 0;
                                        // console.log(nMaterialReceta);
                                        for (let w = 0; w < nMaterialReceta.length; w++) {
                                            let cantMatch = 0;
                                            _tempMaterialReceta = [];
                                            // console.log(nMaterialReceta[w]);
                                            if (nMaterialReceta[w].tipo_material_1 !== '') {
                                                _tempMaterialReceta.push(nMaterialReceta[w].tipo_material_1);
                                            }
                                            if (nMaterialReceta[w].tipo_material_2 !== '') {
                                                _tempMaterialReceta.push(nMaterialReceta[w].tipo_material_2);
                                            }
                                            if (nMaterialReceta[w].tipo_material_3 !== '') {
                                                _tempMaterialReceta.push(nMaterialReceta[w].tipo_material_3);
                                            }
                                            // console.log(_tempMaterialReceta);
                                            for (let z = 0; z < _tempMaterialReceta.length; z++) {
                                                let respRecetas = ListaReceta.filter(item => item === _tempMaterialReceta[z]);
                                                if (respRecetas.length > 0) {
                                                    cantMatch++;
                                                }
                                            }
                                            if (_tempMaterialReceta.length === cantMatch) {
                                                //Verificar si el Valor es mayor al march Anterior
                                                if (cantMatch > cantMatchSup) {
                                                    cantMatchSup = cantMatch;
                                                    indexMarch = w;
                                                }
                                            }
                                        }
                                        // console.log("Material con mas de una receta adjunta");                                          
                                        // console.log(nMaterialReceta[indexMarch]);
                                        if (indexMarch > -1) {
                                            //No se encontro
                                            if (_tempListaReceta.filter(item => item === nMaterialReceta[indexMarch].tipo_material_1.concat(nMaterialReceta[indexMarch].tipo_material_2, nMaterialReceta[indexMarch].tipo_material_3)).length === 0) {
                                                _tempListaReceta.push(nMaterialReceta[indexMarch].tipo_material_1.concat(nMaterialReceta[indexMarch].tipo_material_2, nMaterialReceta[indexMarch].tipo_material_3));
                                            }
                                        } else {
                                            //Material no encontrado                                                                                        
                                            let _recetaNoExistente = listaRecetasNoEncontradas.filter(rne => rne === ListaReceta[n]);
                                            if (_recetaNoExistente.length === 0) {
                                                listaRecetasNoEncontradas.push(ListaReceta[n]);
                                                listaRecetasNoEncontradasDetalle.push(ListaRecetaDetalle[n]);
                                            }
                                        }
                                    }
                                    else {
                                        if (nMaterialReceta.length > 0) {
                                            //Se encontro solo una receta para este material
                                            _tempListaReceta.push(nMaterialReceta[0].tipo_material_1.concat(nMaterialReceta[0].tipo_material_2, nMaterialReceta[0].tipo_material_3));
                                        } else {
                                            //No existe Receta de Materiales: Agregar a lista de No existentes
                                            let _recetaNoExistente = listaRecetasNoEncontradas.filter(rne => rne === ListaReceta[n]);
                                            if (_recetaNoExistente.length === 0) {
                                                listaRecetasNoEncontradas.push(ListaReceta[n]);
                                                listaRecetasNoEncontradasDetalle.push(ListaRecetaDetalle[n]);
                                            }
                                        }
                                    }
                                }
                                ListaReceta = _tempListaReceta;
                                // console.log("Recetas Revisada");
                                // console.log(ListaReceta);

                                //************************************************ */
                                //BUSCAR RECETA SEGUN CONVINACION(ID)
                                //************************************************ */
                                for (let m = 0; m < ListaReceta.length; m++) {
                                    // console.log(`Receta ${x+1}`);
                                    // console.log(ListaReceta[x]);
                                    let nMaterialReceta = this.state.ListaMaterialesReceta.filter(plantillaReceta => plantillaReceta.tipo_material_1.concat(plantillaReceta.tipo_material_2, plantillaReceta.tipo_material_3) === ListaReceta[m]);
                                    // console.log(nMaterialReceta);
                                    if (nMaterialReceta.length > 0) {
                                        MaterialesReceta = arraytemporal.concat(nMaterialReceta[0].t_materialesreceta);
                                        arraytemporal = MaterialesReceta;
                                    }
                                    else {
                                        //No existe Plantilla para la Receta                                    
                                        let _recetaNoExistente = listaRecetasNoEncontradas.filter(rne => rne === ListaReceta[m]);
                                        if (_recetaNoExistente.length === 0) {
                                            listaRecetasNoEncontradas.push(ListaReceta[m]);
                                            listaRecetasNoEncontradasDetalle.push(ListaRecetaDetalle[m]);
                                        }
                                    }
                                }
                                // console.log("Lista Materiales Inicial (Sin revisar)");
                                // console.log(MaterialesReceta);


                                //Agregando Materiales      
                                //Acumular Materiales Existentes                                    
                                for (let z = 0; z < MaterialesReceta.length; z++) {
                                    // console.log(nMaterialReceta[0].t_materialesreceta[z]);                                                                
                                    let existe = false;
                                    let indiceExiste = -1;
                                    for (let w = 0; w < MaterialesProgramar.length; w++) {
                                        // console.log(MaterialesProgramar[w]);
                                        if (MaterialesProgramar[w].codigo === MaterialesReceta[z].codigo) {
                                            existe = true;
                                            indiceExiste = w;
                                            break;
                                        }
                                    }
                                    if (existe) {
                                        //Acumular Material
                                        MaterialesProgramar[indiceExiste].cantidad_receta = parseFloat(MaterialesProgramar[indiceExiste].cantidad_receta) + parseFloat(MaterialesReceta[z].cantidad_receta);
                                    } else {
                                        //agregar Material
                                        if (parseFloat(MaterialesReceta[z].cantidad_receta) > 0) {
                                            MaterialesProgramar.push(JSON.parse(JSON.stringify(MaterialesReceta[z])));
                                        }
                                        // else{
                                        //     console.log("No Agregado sin Cantidad");
                                        //     console.log(MaterialesReceta[z]);
                                        // }
                                    }
                                }
                                // console.log("Materiales para trabajo(Agrupado)");
                                // console.log(MaterialesProgramar);

                                nuevaFila.listaMateriales = MaterialesProgramar;
                                DataCargar.push(nuevaFila);
                            }
                            await this.setState({ DataCargar: DataCargar, CodigosNoEncontrados: listaCodigosNoExisten, RecetasNoEncontradas: listaRecetasNoEncontradas, RecetasNoEncontradasDetalle: listaRecetasNoEncontradasDetalle });
                            this.comprobarRecetas();

                        // }
                        // else {
                        //     await this.setState({
                        //         abrirToast: true, mensajeToast: `No se encontro Recetas de Materiales para el tipo de Trabajo ${this.state.tipotrabajoDetalle},
                        //     Genere la Plantilla  y vuelva a intentarlo`, tipotoast: 'warning', OcultarLoading: true
                        //     });
                        // }
                        // await  this.setState({MostrarPorcentajeCarga:false})                                    
                        //Cargos los GPs de los suministros segun el filtro actual
                        // this.CargarGPS();

                    } else {
                        this.setState({ mensajeToast: "No se encontraron registros en el archivo", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
                    }

                } catch (error) {
                    // console.log(error);
                    await this.setState({ abrirToast: true, mensajeToast: error.toString(), tipotoast: 'warning', OcultarLoading: true });
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    comprobarRecetas = async () => {
        console.log("Data enviar********************")
        // console.log(this.state.DataCargar);
        // console.log(this.state.CodigosNoEncontrados);
        // console.log(this.state.RecetasNoEncontradas);
        // console.log(this.state.RecetasNoEncontradasDetalle);
        // console.log("Send Data Test...")
        // console.log(this.state.DataCargar);

        let enviardata = true;
        let mensajeAlertas = "";
        if (this.state.CodigosNoEncontrados.length > 0) {
            //aqui se Enviaran los Datos al servidor para ser almacenados 
            enviardata = false;
            mensajeAlertas = `Codigo de material no Existe en base de Materiales :[${this.state.CodigosNoEncontrados.toString()}]`;
        }
        if (this.state.RecetasNoEncontradas.length > 0) {
            enviardata = false;
            mensajeAlertas = mensajeAlertas + ` Tipos de  material sin Receta : [${this.state.RecetasNoEncontradas.toString()}]`;
        }
        if (enviardata === false) {
            //Mostrar mensaje para confirmar el tipo de programacion
            await this.setState({ MostrarModalMensajes: true, TextoMensajesModal: mensajeAlertas, TextoMensajesModalPregunta: '¿Desea cargar la programacion sin tomar en cuenta estas advertencias?', TituloMensajeModal: `Aviso` });
        }
        else {
            this.GuardarRecetas();
        }
    }
    GuardarRecetas = async () => {
        if (this.state.DataCargar.length > 0) {
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let url = `${config.endPoint}api/trabajosuministro/cargarprogramacion`;
            let programacionTrabajos = {
                dataProgramacion: this.state.DataCargar,
                fechaprogramacion: this.state.fechaProgramacion,
                idtipotrabajo: this.selectTipoTrabajo.current.value
            }
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(programacionTrabajos)
            };

            await fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    await this.BuscarProgramacionPorFecha();
                    await this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'success' });
                }
                else {
                    data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'error' }) :
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'error' });
                }
            }).catch(async (error) => {
                //Error de conexion                                                
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        } else {
            // await this.setState({ MostrarModalMensajes: true, TextoMensajesModal: mensajeAlertas, TituloMensajeModal: `Corrija las Advertencias` });
            this.setState({ mensajeToast: "No se encontraron datos para Cargar Programación", abrirToast: true, tipotoast: 'error' });
        }
        await this.setState({ OcultarLoading: true });
    }
    ValidarDatosCargarGPS = () => {
        let errores = 0;
        let listaErrores = '';

        if (this.selectTipoTrabajo.current.value !== 'TODOS') {
            this.selectTipoTrabajo.current.classList.remove('is-invalid');
        }
        else {
            this.selectTipoTrabajo.current.classList.add('is-invalid');
            listaErrores += 'Seleccione el tipo de programación. ;'
            errores++;
        }
        if (moment(this.state.fechaProgramacion).isValid()) {
            // let fecha_hoy=moment();
            // let fechaprogra=moment(this.state.fechaProgramacion);            
            // console.log(fecha_hoy.diff(fechaprogra,'minute'));
            // console.log(fechaprogra.diff(fecha_hoy,'minute'));
            this.inputFechaProgramacion.current.classList.remove('is-invalid');
        } else {
            this.inputFechaProgramacion.current.classList.add('is-invalid');
            listaErrores += 'La fecha establecida no es un formato valido. ;'
            errores++;

        }
        if (errores > 0) {
            this.setState({ abrirToast: true, mensajeToast: listaErrores, tipotoast: 'warning' });
            return false;
        }
        else {
            return true;
        }

    }
    CargarGPS = async () => {
        if (this.ValidarDatosCargarGPS()) {

            await this.BuscarListaSuministrosSinGPs().then();
            // console.log(this.state.ListaSuministrosSinGps);
            if (this.state.ListaSuministrosSinGps.length > 0) {
                await this.setState({ OcultarLoading: false });

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let url = `${config.endPoint}api/trabajosuministro/cargarGps`;
                let programacionTrabajos = {
                    fecha: this.state.fechaProgramacion,
                    idtipotrabajo: this.selectTipoTrabajo.current.value
                }
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(programacionTrabajos)
                };
                // console.log("send " +elem.suministro );
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    this.setState({ mensajeToast: "Los GPS estan siendo cargados en el servidor esto puede demorar, espere un momento y actualice esta pagina.", abrirToast: true, tipotoast: 'info' });
                }).catch((error) => {
                    this.setState({ mensajeToast: error.toString, abrirToast: true, tipotoast: 'warning' });
                });

            } else {
                this.setState({ mensajeToast: `Todos los suministros de esta programación tienen GPS asignado.`, abrirToast: true, tipotoast: 'info' });
            }
            this.setState({ OcultarLoading: true });
        }
    }
    // ***************************
    // Funciones adicioonales
    // ***************************
    BuscarListaTipoTrabajo = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/tipotrabajo/listar`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ TipoTrabajos: data.content });
                }
                else {
                    this.setState({ TipoTrabajos: [] });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    BuscarListaTrabajadoresActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajador/listar/ACTIVO`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaTrabajadoresActivos: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaTrabajadoresActivos: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    BuscarIdTrabajadorReasignado = async (trabajadorReasignado) => {
        for await (const trabajador of this.state.ListaTrabajadoresActivos) {
            if (trabajadorReasignado.indexOf(trabajador.nombretrabajador) > -1) {
                return trabajador.idtrabajador;
            }
        }
        return null;
    }
    // ***************************
    // Reasignar Trabajador
    // ***************************    
    validarModalReasignarTrabajador = () => {
        let contador = 0;
        if (this.selectListaJUOEncontrados.current.value === '') {
            this.selectListaJUOEncontrados.current.classList.add('is-invalid');
            contador++;
        }
        else {
            this.selectListaJUOEncontrados.current.classList.remove('is-invalid');
        }
        if (this.inputReasignarTrabajador.current.value.trim() === '') {
            this.inputReasignarTrabajador.current.classList.add('is-invalid');
            contador++;
        }
        else {
            this.inputReasignarTrabajador.current.classList.remove('is-invalid');
        }
        if (contador > 0) {
            return false;
        }
        else {
            return true;
        }
    }
    ReasignarTecnico = () => {
        if (this.state.CantidadObservacionesTecnico > 0) {
            this.ReasignarProgramacionTecnico();
        }
        else {
            this.ReasignarID_Tecnico();
        }

    }
    ReasignarProgramacionTecnico = async () => {
        if (ValidarUsuario()) {
            if (this.validarModalReasignarTrabajador()) {
                this.inputReasignarTrabajador.current.classList.remove('is-invalid');
                // FormatearFecha
                let IdTrabajadorReasignado = await this.BuscarIdTrabajadorReasignado(this.inputReasignarTrabajador.current.value);
                if (IdTrabajadorReasignado) {
                    let url = '';
                    if (this.selectTipoTrabajo.current.value !== "TODOS") {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignar/Trabajador/fecha/tipotrabajo`;
                    }
                    else {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignar/Trabajador/fecha`;
                    }
                    let updateTrabajoSuministro = {
                        idpersonalasignado: IdTrabajadorReasignado,
                        JUOProgramacion: this.selectListaJUOEncontrados.current.value,
                        fechaProgramacion: this.state.fechaProgramacion,
                        idtipotrabajo: this.selectTipoTrabajo.current.value
                    }
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(updateTrabajoSuministro)
                    };
                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            await this.BuscarListaObservacionTecnicos().then(() => {
                                //Buscar Cantidad de elementos con error
                                this.ContarObservacionesTecnico();
                            }).catch((error2) => {
                                if (error2) {
                                    this.props.history.push('/login');
                                }
                            });
                            this.inputReasignarTrabajador.current.value = '';
                            await this.setState({ abrirToast: true, mensajeToast: "JUO reasignado correctamente.", tipotoast: 'success' });
                        }
                        else {
                            data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'warning' }) :
                                this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        }
                    }).catch((error) => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "Elija un nombre de la lista de trabajadores", tipotoast: 'warning' });
                    this.inputReasignarTrabajador.current.value = '';
                    this.inputReasignarTrabajador.current.classList.add('is-invalid');
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    ReasignarID_Tecnico = async () => {
        if (ValidarUsuario()) {
            if (this.validarModalReasignarTrabajador()) {
                this.inputReasignarTrabajador.current.classList.remove('is-invalid');
                // FormatearFecha
                let IdTrabajadorReasignado = await this.BuscarIdTrabajadorReasignado(this.inputReasignarTrabajador.current.value);
                if (IdTrabajadorReasignado) {
                    let url = '';
                    if (this.selectTipoTrabajo.current.value !== "TODOS") {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignarid/Trabajador/fecha/tipotrabajo`;
                    }
                    else {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignarid/Trabajador/fecha`;
                    }
                    let updateTrabajoSuministro = {
                        idtrabajadorreasignado: IdTrabajadorReasignado,
                        idpersonalasignado: this.selectListaJUOEncontrados.current.value,
                        fechaProgramacion: this.state.fechaProgramacion,
                        idtipotrabajo: this.selectTipoTrabajo.current.value
                    }
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(updateTrabajoSuministro)
                    };
                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            await this.BuscarListaProgramacionTecnicos().then().catch(() => {
                                this.props.history.push('/login');
                            });
                            this.inputReasignarTrabajador.current.value = '';
                            await this.setState({ abrirToast: true, mensajeToast: "Progracion JUO reasignado correctamente.", tipotoast: 'success' });
                        }
                        else {
                            data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'warning' }) :
                                this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        }
                    }).catch((error) => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });

                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "Elija un nombre de la lista de trabajadores", tipotoast: 'warning' });
                    this.inputReasignarTrabajador.current.value = '';
                    this.inputReasignarTrabajador.current.classList.add('is-invalid');
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    // ***************************
    // Reasignar Supervisor
    // ***************************
    validarModalReasignarSupervisor = () => {
        let contador = 0;
        if (this.selectSupervisorEncontrados.current.value === '') {
            this.selectSupervisorEncontrados.current.classList.add('is-invalid');
            contador++;
        }
        else {
            this.selectSupervisorEncontrados.current.classList.remove('is-invalid');
        }
        if (this.inputReasignarSupervidor.current.value.trim() === '') {
            this.inputReasignarSupervidor.current.classList.add('is-invalid');
            contador++;
        }
        else {
            this.inputReasignarSupervidor.current.classList.remove('is-invalid');
        }
        if (contador > 0) {
            return false;
        }
        else {
            return true;
        }
    }
    ReasignarSupervisor = () => {
        if (this.state.CantidadObservacionesSupervisor > 0) {
            this.ReasignarProgramacionSupervisor();
        }
        else {
            this.ReasignarID_Supervisor();
        }
    }

    ReasignarProgramacionSupervisor = async () => {
        if (ValidarUsuario()) {
            if (this.validarModalReasignarSupervisor()) {
                // FormatearFecha
                let IdTrabajadorReasignado = await this.BuscarIdTrabajadorReasignado(this.inputReasignarSupervidor.current.value);
                if (IdTrabajadorReasignado) {
                    this.inputReasignarSupervidor.current.classList.remove('is-invalid');
                    let url = '';
                    if (this.selectTipoTrabajo.current.value !== "TODOS") {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignar/Supervisor/fecha/tipotrabajo`;
                    }
                    else {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignar/Supervisor/fecha`;
                    }
                    let updateTrabajoSuministro = {
                        idpersonalasignado: IdTrabajadorReasignado,
                        supervisor: this.selectSupervisorEncontrados.current.value,
                        fechaProgramacion: this.state.fechaProgramacion,
                        idtipotrabajo: this.selectTipoTrabajo.current.value
                    }
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(updateTrabajoSuministro)
                    };
                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            await this.BuscarListaObservacionSupervisor().then(() => {
                                //Buscar Cantidad de elementos con error
                                this.ContarObservacionesSupervisor();
                            }).catch((error2) => {
                                if (error2) {
                                    this.props.history.push('/login');
                                }
                            });
                            this.inputReasignarSupervidor.current.value = '';
                            await this.setState({ abrirToast: true, mensajeToast: "Supervisor reasignado correctamente.", tipotoast: 'success' });
                        }
                        else {
                            data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'warning' }) :
                                this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        }
                    }).catch((error) => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    })
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "Elija un nombre de la lista de trabajadores", tipotoast: 'warning' });
                    this.inputReasignarSupervidor.current.value = '';
                    this.inputReasignarSupervidor.current.classList.add('is-invalid');
                }

            }
        }
        else {
            this.props.history.push('/login');
        }

    }
    ReasignarID_Supervisor = async () => {
        if (ValidarUsuario()) {
            if (this.validarModalReasignarSupervisor()) {
                this.inputReasignarSupervidor.current.classList.remove('is-invalid');
                // FormatearFecha
                let IdTrabajadorReasignado = await this.BuscarIdTrabajadorReasignado(this.inputReasignarSupervidor.current.value);
                if (IdTrabajadorReasignado) {
                    let url = '';
                    if (this.selectTipoTrabajo.current.value !== "TODOS") {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignarid/supervisor/fecha/tipotrabajo`;
                    }
                    else {
                        url = `${config.endPoint}api/trabajosuministro/programacionreasignarid/supervisor/fecha`;
                    }
                    let updateTrabajoSuministro = {
                        idtrabajadorreasignado: IdTrabajadorReasignado,
                        idpersonalasignado: this.selectSupervisorEncontrados.current.value,
                        fechaProgramacion: this.state.fechaProgramacion,
                        idtipotrabajo: this.selectTipoTrabajo.current.value
                    }
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(updateTrabajoSuministro)
                    };
                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            await this.BuscarListaProgramacionSupervisor().then(() => {
                                //Buscar Cantidad de elementos con error                            
                            }).catch((error2) => {
                                if (error2) {
                                    this.props.history.push('/login');
                                }
                            });
                            this.inputReasignarSupervidor.current.value = '';
                            await this.setState({ abrirToast: true, mensajeToast: "Supervisor reasignado correctamente.", tipotoast: 'success' });
                        }
                        else {
                            data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'warning' }) :
                                this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        }
                    }).catch((error) => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });

                }

                else {
                    this.setState({ abrirToast: true, mensajeToast: "Elija un nombre de la lista de trabajadores", tipotoast: 'warning' });
                    this.inputReasignarSupervidor.current.value = '';
                    this.inputReasignarSupervidor.current.classList.add('is-invalid');
                }
            }
        }
        else {
            this.props.history.push('/login');
        }

    }
    //*************************************************
    //Actualizar Fecha, Cambiar Tipo de Trabajo
    //*************************************************
    ActualizarFecha = async () => {
        let nuevaFecha = moment(this.inputFechaProgramacion.current.value).format('YYYY-MM-DD');
        await this.setState({ fechaProgramacion: nuevaFecha, loadData: false });
        await this.BuscarProgramacionPorFecha();
    }
    buscarTipoTrabajoDetalle = async () => {
        let detalleTrabajo = "";
        for (let x = 0; x < this.state.TipoTrabajos.length; x++) {
            if (this.selectTipoTrabajo.current.value.trim() !== "") {
                if (this.state.TipoTrabajos[x].idTipotrabajo.toString() === this.selectTipoTrabajo.current.value.toString()) {
                    detalleTrabajo = this.state.TipoTrabajos[x].tipotrabajo
                    break;
                }
            }
        }
        this.setState({ tipotrabajoDetalle: detalleTrabajo });
    }

    CambioTipoTrabajo = async () => {
        await this.setState({ loadData: false });
        await this.BuscarProgramacionPorFecha();
        await this.buscarTipoTrabajoDetalle();
    }
    CambioCheckMostrarTodosTecnicos = async () => {
        await this.setState({ mostrarTodosJuoObservacion: this.inputChekMostrarTodosTecnicos.current.checked });
    }
    CambioCheckMostrarTodosSupervisores = async () => {
        await this.setState({ mostrarTodosSupervisorObservacion: this.inputCheckBoxMostrarTodosSupervisores.current.checked });
    }
    //*************************************************
    //VENTANA MODAL CAMBIAR TRABAJADOR
    //*************************************************
    AbrirModalCambiarTrabajador = async () => {
        await this.setState({ MostrarModalCambiarTrabajador: true, OcultarLoading: false });
        if (this.state.CantidadObservacionesTecnico > 0) {
            await this.BuscarListaObservacionTecnicos().then(() => {
                //Buscar Cantidad de elementos con error
                this.ContarObservacionesTecnico();
            }).catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });
        }
        else {
            await this.BuscarListaProgramacionTecnicos().then().catch(() => {
                this.props.history.push('/login');
            });
        }

        await this.BuscarListaTrabajadoresActivos().then().catch((error) => {
            if (error) {
                this.props.history.push('/login');
            }
        })
        await this.setState({ OcultarLoading: true });
    }
    CerrarModalCambiarTrabajador = async () => {
        this.setState({ MostrarModalCambiarTrabajador: false });
        await this.BuscarProgramacionPorFecha();
    }
    //*************************************************
    //VENTANA MODAL ASIGNAR SUPERVISOR
    //*************************************************
    AbrirModalAsignarSupervisor = async () => {
        await this.setState({ MostrarModalAsignarSupervisor: true, OcultarLoading: false });
        if (this.state.CantidadObservacionesSupervisor > 0) {
            await this.BuscarListaObservacionSupervisor().then(() => {
                this.ContarObservacionesSupervisor();

            }).catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });

        }
        else {
            await this.BuscarListaProgramacionSupervisor().then().catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });
        }

        await this.BuscarListaTrabajadoresActivos().then().catch((error) => {
            if (error) {
                this.props.history.push('/login');
            }
        })
        await this.setState({ OcultarLoading: true });
    }
    CerrarModalAsignarSupervidor = async () => {
        this.setState({ MostrarModalAsignarSupervisor: false });
        await this.BuscarProgramacionPorFecha();
    }
    //*************************************************
    //Funciones para Paginacion
    //*************************************************
    CambioFilasPorPagina = async (event) => {
        await this.setState({ loadData: false, filasPorPagina: parseInt(event.target.value), pagina: 1 });
        await this.BuscarProgramacionPorFecha();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ loadData: false, pagina: (parseInt(nuevaPagina) + 1) });
        await this.BuscarProgramacionPorFecha();
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    //Modal Mensajes
    MostrarModalMensajes = () => {
        this.setState({ MostrarModalMensajes: true });
    }
    CerrarModalMensajes = () => {
        this.setState({ MostrarModalMensajes: false });
    }

    //Modal Elegir Tipo Programacion
    MostrarModalElegirTipoProgramacion = () => {

        this.setState({ MostrarModalElegirTipoProgramacion: true });
    }
    CerrarModalElegirTipoProgramacion = () => {
        this.setState({ MostrarModalElegirTipoProgramacion: false });
    }

    //Modal Eliminar suministros
    MostrarModalEliminar = async () => {
        //Buscar Lista de Trabajos
        await this.BuscarListaTipoTrabajo();
        this.setState({ MostrarModalEliminar: true, ListaTecnicosTipoTrabajo: [], ListaSuministrosEliminar: [] });
    }
    //Modal Asignar Color Trabajo
    MostrarModalAsignarColorTrabajo = async () => {
        if (this.ValidarDatosCargarGPS()) {
            await this.BuscarListaObservacionesPrioridad();
            // console.log(this.state.ListaObservacionesPrioridad);
            this.setState({ MostrarModalAsignarColorTrabajo: true });
        }
    }
    CerrarModalAsignarColorTrabajo = async  () => {
        this.setState({ MostrarModalAsignarColorTrabajo: false });
        await this.BuscarProgramacionPorFecha();
    }

    CambiarColorTrabajo = () => {
        // console.log(this.selectElegirColorTrabajo.current.value);        
        let bcolor = LIST_COLORES.filter(x => x.nombre === this.selectElegirColorTrabajo.current.value);
        this.setState({ color: bcolor[0].color, colorText: bcolor[0].textColor });
    }

    BuscarColorFondo = (color) => {
        let bcolor = LIST_COLORES.filter(x => x.nombre === color);
        return bcolor[0].color;
    }
    BuscarColorTexto = (color) => {
        let bcolor = LIST_COLORES.filter(x => x.nombre === color);
        return bcolor[0].textColor;
    }

    BuscarListaProgramacionTecnicosFecha = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajosuministro/programado/trabajador/${this.selectTipoEliminar.current.value}/${this.state.fechaProgramacion}`

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    // console.log(data);
                    if (data.content) {
                        this.setState({ ListaTecnicosTipoTrabajo: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaTecnicosTipoTrabajo: [] })
                        resolve(false);
                    }

                }).catch(() => {
                    resolve(false);
                })
            }
            else {
                reject(true);
            }
        });
    }

    BuscarListaProgramacionTecnicosFechaTipoTrabajo = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajosuministro/listarSuministrosEliminar/${this.state.fechaProgramacion}/${this.selectTipoEliminar.current.value}/${this.selectTecnicoEliminar.current.value}`;

                // console.log(url);
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaSuministrosEliminar: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaSuministrosEliminar: [] })
                        resolve(false);
                    }

                }).catch(() => {
                    resolve(false);
                })
            }
            else {
                reject(true);
            }
        });
    }
    SelectTipoTrabajoEliminar = async () => {
        //Limpiar datos de 
        this.setState({ ListaSuministrosEliminar: [], ListaTecnicosTipoTrabajo: [] });
        //Buscar Lista de Tecnicos
        if (this.selectTipoEliminar.current.value !== "TODOS") {
            await this.BuscarListaProgramacionTecnicosFecha().then();
            // console.log(this.state.ListaTecnicosTipoTrabajo);
        }
        else {

        }
    }
    SeleccionarJuoEliminar = async () => {
        this.setState({ loadSumEliminar: false });
        await this.BuscarListaProgramacionTecnicosFechaTipoTrabajo().then();
        this.setState({ loadSumEliminar: true });
        // console.log(this.state.ListaSuministrosEliminar);
    }
    // EliminarProgramacion = async () => {
    //     if (ValidarUsuario()) {

    //         if (window.confirm(`¿Estas seguro que deseas eliminar los trabajos de fecha ${this.state.fechaProgramacion} de ${this.selectTipoTrabajo.current.selectedOptions[0].innerText}?`)) {
    //             let url = '';
    //             if (this.selectTipoTrabajo.current.value !== "TODOS") {
    //                 url = `${config.endPoint}api/trabajosuministro/programacioneliminar/fecha/tipotrabajo`;
    //             }
    //             else {
    //                 url = `${config.endPoint}api/trabajosuministro/programacioneliminar/fecha`;
    //             }

    //             let EliminarTrabajoSuministro = {
    //                 fechaProgramacion: this.state.fechaProgramacion,
    //                 idtipotrabajo: this.selectTipoTrabajo.current.value
    //             }
    //             let UsuarioStorage = ObtenerUsuario();
    //             UsuarioStorage = JSON.parse(UsuarioStorage);

    //             let headers = {
    //                 method: 'POST',
    //                 headers: {
    //                     'Accept': 'application/json',
    //                     'Content-Type': 'application/json',
    //                     'Authorization': 'Bearer ' + UsuarioStorage.token,
    //                 },
    //                 body: JSON.stringify(EliminarTrabajoSuministro)
    //             };
    //             fetch(url, headers).then((respuesta) => {
    //                 return respuesta.json();
    //             }).then(async (data) => {
    //                 if (data.content) {
    //                     await this.BuscarProgramacionPorFecha();
    //                     await this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'success' });
    //                 }
    //                 else {
    //                     data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'warning' }) :
    //                         this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
    //                 }
    //             }).catch((error) => {
    //                 this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
    //             });
    //         }
    //     }
    //     else {
    //         this.props.history.push('/login');
    //     }

    // }
    EliminarSuministrosFiltrados = () => {
        if (ValidarUsuario()) {
            if (this.selectTipoEliminar.current.value !== "TODOS") {
                this.selectTipoEliminar.current.classList.remove('is-invalid');
                if (window.confirm(`¿Estas seguro que deseas eliminar los trabajos filtrados actualmente ?`)) {
                    let EliminarTrabajoSuministro = {};
                    let url = '';
                    //Verificar si se eliminaran Todos los JUO
                    if (this.selectTecnicoEliminar.current.value === "TODOS") {
                        url = `${config.endPoint}api/trabajosuministro/programacioneliminar/fecha/tipotrabajo`;
                        EliminarTrabajoSuministro = {
                            fechaProgramacion: this.state.fechaProgramacion,
                            idtipotrabajo: this.selectTipoEliminar.current.value
                        }
                    } else {
                        url = `${config.endPoint}api/trabajosuministro/programacioneliminar/fecha/tipotrabajo/trabajador`;
                        EliminarTrabajoSuministro = {
                            fechaProgramacion: this.state.fechaProgramacion,
                            idtipotrabajo: this.selectTipoEliminar.current.value,
                            idpersonalasignado: this.selectTecnicoEliminar.current.value
                        }
                    }

                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(EliminarTrabajoSuministro)
                    };
                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then(async (data) => {
                        if (data.content) {
                            await this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'success' });
                            //Buscar Lista de Tecnicos para tipo de trabajo
                            this.SelectTipoTrabajoEliminar();
                            //Actualizar Lista Suministros
                            await this.BuscarProgramacionPorFecha();
                            await this.BuscarListaTipoTrabajo();
                        }
                        else {
                            data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'warning' }) :
                                this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        }
                    }).catch((error) => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });
                }
            }
            else {
                this.selectTipoEliminar.current.classList.add('is-invalid');
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    CerrarModalEliminarSuministros = () => {
        this.setState({ MostrarModalEliminar: false });
    }
    //Revisar TipoProgramacion
    ElegirTipoCargaProgramacion = (ev) => {
        this.setState({ tipoCargaProgramacionTrabajo: ev.target.value });
    }

    //Elegir Tipo Programacion
    CargarProgramacionTipo = async () => {
        //Default 'PROGRAMACION'
        console.log(this.selectElegirTipoProgramacion.current);

        if (this.selectElegirTipoProgramacion.current) {
            if (this.selectElegirTipoProgramacion.current.value === 'Elija una Programación') {
                await this.setState({ tipoProgramacionTrabajo: '' });
                this.selectElegirTipoProgramacion.current.classList.add('is-invalid');
            }
            else {
                //Cargar Segun la Programacion Elegida(Cambiar el Estado de la Programacion por el tipo de trabajo)
                await this.setState({ tipoProgramacionTrabajo: this.selectElegirTipoProgramacion.current.value });
                this.CerrarModalElegirTipoProgramacion();
                this.CargarProgramacion()
            }

        } else {
            await this.setState({ tipoProgramacionTrabajo: 'PROGRAMACION' });
            this.CargarProgramacion();
        }
    }


    MostrarModalProgramarMapa = async () => {
        //Buscar Lista de Trabajos        
        this.setState({ MostrarModalProgramarMapa: true });
    }
    CerrarModalProgramarMapa = () => {
        this.setState({ MostrarModalProgramarMapa: false });
    }

    render() {
        return (
            <Fragment>
                <Snackbar
                    open={this.state.MostrarPorcentajeCarga}
                    message={this.state.mensaCargaProgramacion}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />

                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo="PROGRAMACIÓN" regresar={() => { this.props.history.push('/') }}></TituloPagina>
                {/* ***************************** */}
                {/* Alerts */}
                {/* ***************************** */}

                <div className="container mt-1">
                    {
                        this.state.CantidadObservacionesTecnico > 0 ?
                            <div className="row mx-1">
                                <div className="alert alert-sm alert-danger alert-dismissible fade show" role="alert">
                                    <strong onClick={() => { this.AbrirModalCambiarTrabajador() }} className="pointer-link link-danger text-decoration-underline " >Corregir</strong>
                                    <span classList="mx-1"> {`Los nombres de ${this.state.CantidadObservacionesTecnico} JUO asignados en la programacion no coinciden con la base de trabajadores. Reasigne los JUO a los nombres que existan en la base de Trabajadores.`}</span>
                                    {/* <strong onClick={this.MostrarModalProgramarMapa} className="pointer-link link-danger text-decoration-underline ">Abrir Modal Mapa</strong> */}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                            </div>
                            : <></>
                    }
                    {
                        this.state.CantidadObservacionesSupervisor > 0 ?
                            <div className="row mx-1">
                                <div className="alert alert-sm alert-warning alert-dismissible fade show" role="alert">
                                    <strong onClick={() => { this.AbrirModalAsignarSupervisor() }} className="pointer-link link-warning text-decoration-underline " >Corregir</strong>
                                    <span classList="mx-1">{` Los nombres de ${this.state.CantidadObservacionesSupervisor} SUPERVISOR(ES) asignados en la programacion no coinciden con la base de trabajadores. Reasigne los SUPERVISORES a los nombres que existan en la base de Trabajadores.`}</span>
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                            </div>
                            : <></>
                    }

                    {
                        this.state.ListaSuministrosSinGps.length > 0 ?
                            <div className="row mx-1">
                                <div className="alert alert-sm alert-danger alert-dismissible fade show" role="alert">
                                    <strong onClick={() => { this.CargarGPS() }} className="pointer-link link-danger text-decoration-underline " >Corregir</strong>
                                    <span classList="mx-1">{`  ${this.state.ListaSuministrosSinGps.length} SUMINISTRO(S) sin ubicacion GPS.`}</span>
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                            </div>
                            : <></>
                    }

                    {/* <div className="row mx-1">
                        <div className="alert alert-sm alert-success alert-dismissible fade show" role="alert">
                            <span>Programacion <strong>NUEVOS SUMINISTROS</strong> para la fecha <strong>2020-10-15</strong> cargada correctamente.!! </span>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                    </div> */}
                </div>
                {/* ***************************** */}
                {/* Inicio de Controles */}
                {/* ***************************** */}

                {/* Contenedor de Datos */}


                <div className="container border mt-1">
                    {/* <div className="row my-1">
                        <div className="col-md-6">
                            <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked/>
                            <small className="form-check-label" for="flexSwitchCheckDefault">Programacion desde Archivo</small>
                            </div>
                        </div>
                        <div >
                            <div className="form-check form-check-inline">
                                <input checked={this.state.tipoCargaProgramacionTrabajo === 'DESDE-ARCHIVO' ? true : false} onChange={this.ElegirTipoCargaProgramacion} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="DESDE-ARCHIVO" />
                                <label className="form-check-small" htmlFor="inlineRadio1">Programar Desde Archivo</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input checked={this.state.tipoCargaProgramacionTrabajo === 'INSPECCION-PREVIA' ? true : false} onChange={this.ElegirTipoCargaProgramacion} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="INSPECCION-PREVIA" />
                                <label className="form-check-label" htmlFor="inlineRadio2">Programar desde Inspeccion Previa</label>
                            </div>
                        </div>
                    </div> */}

                    {
                        this.state.tipoCargaProgramacionTrabajo === 'DESDE-ARCHIVO' ?
                            //
                            <>
                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <input ref={this.inputFileSubirArchivo} className="form-control form-control-sm" type="file" id="formFile" />
                                    </div>
                                    <div className="col-md-3 d-grid gap-2 mt-1 mt-sm-1 mt-md-0">
                                        <button onClick={() => {
                                            this.CargarProgramacionTipo();
                                            // this.MostrarModalElegirTipoProgramacion()
                                        }} className=" btn btn-sm btn-outline-secondary  ">
                                            <i className="fas fa-file-upload p-1"></i>
                                            <span>Cargar Archivo</span>
                                        </button>

                                    </div>
                                </div>

                                <div className="row  my-1 ">
                                    <div className="col-md-3 mt-1">
                                        <input onChange={() => { this.ActualizarFecha() }} className="form-control form-control-sm p-1" type="date" ref={this.inputFechaProgramacion} ></input>
                                    </div>
                                    <div className="col-md-3 mt-1">
                                        <select onChange={() => { this.CambioTipoTrabajo() }} ref={this.selectTipoTrabajo} className="form-select form-select-sm "  >
                                            <option value="TODOS">Tipo Trabajo (Todos)</option>
                                            {
                                                this.state.TipoTrabajos.map((row, index) => {
                                                    return (
                                                        <option key={index} value={row.idTipotrabajo}>{row.tipotrabajo}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className=" col-sm-12 col-md-3  d-grid gap-2 mt-1">
                                        <button onClick={() => { this.CambioTipoTrabajo() }} className=" btn btn-sm btn-outline-secondary ">
                                            <i className="fas fa-search p-1"></i>
                                            <span>Buscar</span>
                                        </button>
                                    </div>
                                    <div className="col-md-3 text-end mt-1">
                                        <div className="dropdown">
                                            <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                                Opciones
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                                {
                                                    this.state.CantidadObservacionesTecnico <= 0 ?
                                                        <li onClick={() => { this.AbrirModalCambiarTrabajador() }}><span className="dropdown-item" >Reasignar JUO Programado</span></li>
                                                        : <></>
                                                }
                                                <li><Link to="/programacionMapa" className="dropdown-item">Asignar Trabajador desde Mapa</Link></li>
                                                {
                                                    this.state.CantidadObservacionesSupervisor <= 0 ?
                                                        <li onClick={() => { this.AbrirModalAsignarSupervisor() }}><span className="dropdown-item" >Reasignar Supervisor Programado</span></li>
                                                        : <></>
                                                }
                                                <li onClick={() => { this.MostrarModalAsignarColorTrabajo() }}><span className="dropdown-item" >Asignar color a Trabajo</span></li>

                                                <li><hr className="dropdown-divider" /></li>
                                                <li onClick={() => {
                                                    // this.EliminarProgramacion()
                                                    this.MostrarModalEliminar();
                                                }}><span className="dropdown-item" >Eliminar Programación Actual</span></li>
                                                <li onClick={() => { this.CargarGPS() }}><span className="dropdown-item" >Cargar GPS</span></li>
                                            </ul>
                                        </div>
                                    </div>


                                    {/* <div className="col-sm-12 col-md-12 mt-2" style={{ display: '' }}>
                            <label className='form-label text-danger '>{`${this.state.CantidadObservacionesListaProgramacion} alertas de ${this.state.cantidadElementos} Suministros Cargados`}</label>
                        </div> */}
                                </div>
                                <hr />

                                <div className="row mx-1">

                                    {
                                        this.state.loadData ?
                                            <>
                                                {/* Tabla Aqui */}
                                                <TableContainer>
                                                    <Table size='small'>
                                                        <TableHead className='fondoTituloTabla'>
                                                            <TableRow>
                                                                {
                                                                    this.headCells.map((head, index) => {
                                                                        return (
                                                                            <TableCell style={{ fontSize: '0.9em', color: 'aliceblue' }} key={index} >{head.id}</TableCell>
                                                                        );
                                                                    })
                                                                }

                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.listaProgramacion.map((row, index) => {
                                                                let estadoFila = '';
                                                                if (row.TrabajadorProgramado === null && row.TrabajadorSupervisa === null) {
                                                                    estadoFila = 'Reasignar JUO, Asignar Supervisor';
                                                                } else {
                                                                    if (row.TrabajadorProgramado !== null && row.TrabajadorSupervisa !== null) {
                                                                        estadoFila = 'Correcto';
                                                                    }
                                                                    else {
                                                                        if (row.TrabajadorProgramado === null) {
                                                                            estadoFila = 'Reasignar JUO';
                                                                        }
                                                                        else {
                                                                            estadoFila = 'Asignar Supervisor';
                                                                        }

                                                                    }
                                                                }

                                                                let colorfondo=this.BuscarColorFondo(row.pinColor);
                                                                let colortexto=this.BuscarColorTexto(row.pinColor);                                                               

                                                                return (
                                                                    <TableRow key={index} style={{ fontSize: '0.9em' }}>
                                                                        <TableCell style={{ fontSize: '0.8em' }}>{estadoFila !== 'Correcto' ? <span className='text-danger'>{estadoFila}</span> :
                                                                            <span className='text-success'>{estadoFila}</span>}</TableCell>
                                                                        <TableCell style={{ fontSize: '0.8em' }} >{row.tipotrabajo}</TableCell>
                                                                        <TableCell style={{ fontSize: '0.8em' ,backgroundColor:colorfondo,color:colortexto}}>{row.prioridad}</TableCell>
                                                                        <TableCell style={{ fontSize: '0.8em' }}>{row.tipo}</TableCell>
                                                                        <TableCell style={{ fontSize: '0.8em' }}>{row.suministro}</TableCell>
                                                                        {/* <TableCell style={{ fontSize: '0.8em' }}>{row.direccionCliente}</TableCell> */}
                                                                        {/* <TableCell style={{ fontSize: '0.8em' }}>{row.circuito}</TableCell> */}
                                                                        <TableCell style={{ fontSize: '0.8em' }}>{row.JUOProgramacion}</TableCell>
                                                                        {row.TrabajadorProgramado === null ? <TableCell style={{ fontSize: '0.8em' }} className="bg-warning-light">{row.TrabajadorProgramado}</TableCell> : <TableCell>{row.TrabajadorProgramado}</TableCell>}
                                                                        <TableCell>{row.supervisor}</TableCell>
                                                                        {row.TrabajadorSupervisa === null ? <TableCell style={{ fontSize: '0.8em' }} className="bg-warning-light">{row.TrabajadorSupervisa}</TableCell> : <TableCell>{row.TrabajadorSupervisa}</TableCell>}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 20, 30, 50]}
                                                    component="div"
                                                    count={this.state.cantidadElementos}
                                                    rowsPerPage={this.state.filasPorPagina}
                                                    page={this.state.pagina - 1}
                                                    onChangePage={this.CambiarPagina}
                                                    onChangeRowsPerPage={this.CambioFilasPorPagina}
                                                    labelRowsPerPage={"Filas Por Pagina"}
                                                />
                                            </>
                                            : <SpinnerCarga />
                                    }
                                </div>

                            </> :
                            <>
                                Formulario En construcción
                            </>

                    }

                </div>

                {/* Modales */}
                {/* Modal Cambiar Nombre de Tecnico */}
                <Modal
                    show={this.state.MostrarModalCambiarTrabajador}
                    onHide={() => { this.CerrarModalCambiarTrabajador() }}
                    keyboard={false}
                    centered >

                    <Modal.Header>
                        <Modal.Title>Reasignar Trabajador</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="container">
                            {/* Elegir Programar desde Mapa */}
                            <div className="row">
                                <div className="text-end">
                                    {/* <button onClick={this.MostrarModalProgramarMapa} className="btn btn-sm btn-outline-primary"><i className="fas fa-map-signs px-2 text-primary" ></i> Asignar desde Mapa</button> */}
                                    <Link to="/programacionMapa" className="btn btn-sm btn-outline-primary"><i className="fas fa-map-signs px-2 " ></i> Asignar desde Mapa</Link>
                                </div>
                            </div>
                            {
                                this.state.CantidadObservacionesTecnico > 0 ?
                                    <div className="row my-1">
                                        <div className="col">
                                            <div className="form-check">
                                                <input onClick={() => { this.CambioCheckMostrarTodosTecnicos() }} ref={this.inputChekMostrarTodosTecnicos} className="form-check-input" type="checkbox" id="checkMostrarJUO" />
                                                <label className="form-check-label" htmlFor="checkMostrarJUO">
                                                    Mostrar Todos los JUO
                                                </label>
                                            </div>
                                        </div>
                                    </div> :
                                    <></>
                            }
                            <div className="row">
                                <div className="col">
                                    {
                                        this.state.CantidadObservacionesTecnico > 0 ?
                                            <>
                                                <small className="text-primary">JUO Encontrado en Archivo</small>
                                                <select ref={this.selectListaJUOEncontrados} className="form-select "  >
                                                    {
                                                        this.state.mostrarTodosJuoObservacion ?
                                                            this.state.ListaObservacionTecnico.map((row, index) => {
                                                                return (<option key={index} value={row.JUOProgramacion}>{row.JUOProgramacion}</option>)
                                                            }) :
                                                            this.state.ListaObservacionTecnico.filter(el => el.nombretrabajador === null).map((row, index) => {
                                                                return (<option key={index} value={row.JUOProgramacion}>{row.JUOProgramacion}</option>)
                                                            })
                                                    }
                                                </select>
                                                {
                                                    this.state.CantidadObservacionesTecnico > 0 ?
                                                        <small className="text-danger">{`${this.state.CantidadObservacionesTecnico} tecnicos no coincide con Base.`}</small> :
                                                        <small className="text-success">No se encontraron JUO para la busqueda actual.</small>
                                                }
                                            </> :
                                            <>
                                                <small className="text-primary">JUO Programados</small>
                                                <select ref={this.selectListaJUOEncontrados} className="form-select "  >
                                                    {
                                                        this.state.ListaProgramcionTecnicos.map((row, index) => {
                                                            return (<option key={index} value={row.idpersonalasignado}>{row.nombretrabajador}</option>)
                                                        })
                                                    }
                                                </select>
                                            </>
                                    }

                                </div>


                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Reasignar a trabajador</small>
                                    <input ref={this.inputReasignarTrabajador} required type="" className="form-control " list="listaTrabajadores" placeholder="Elija el nombre del trabajador"></input>
                                    <datalist id="listaTrabajadores">
                                        {
                                            this.state.ListaTrabajadoresActivos.map((row, index) => {
                                                return (<option key={index} value={row.nombretrabajador} />)
                                            })
                                        }
                                    </datalist>
                                    <small className="text-secondary">Si no encuentra el trabajador en esta lista probablemente no este agregado a la Base de Trabajadores o su estado sea "INACTIVO"</small>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col  text-end">
                                    <button onClick={() => { this.ReasignarTecnico() }} className=" btn btn-outline-primary " >
                                        <i className="fas fa-exchange-alt p-1"></i>
                                        <span>Reasignar</span>
                                    </button>

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.CerrarModalCambiarTrabajador() }} className="btn btn-outline-secondary mx-1">Cerrar</button>
                        </div>
                    </Modal.Footer>

                </Modal>

                {/* Modal AsignarSupervisor */}
                <Modal
                    show={this.state.MostrarModalAsignarSupervisor}
                    onHide={() => { this.CerrarModalAsignarSupervidor() }}
                    keyboard={false}
                    centered >

                    <Modal.Header>
                        <Modal.Title>Reasignar Supervisor</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="container">
                            {
                                this.state.CantidadObservacionesSupervisor > 0 ?
                                    <div className="row">
                                        <div className="form-check">
                                            <input onClick={() => { this.CambioCheckMostrarTodosSupervisores() }} ref={this.inputCheckBoxMostrarTodosSupervisores} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                Mostrar todos los Supervisores
                                            </label>
                                        </div>
                                    </div>
                                    : <></>
                            }
                            <div className="row my-1">
                                {/* Verificar El tipo de Cambio */}
                                <div>
                                    {
                                        this.state.CantidadObservacionesSupervisor > 0 ?
                                            <>
                                                <small className="text-primary">Supervisores encontrados en Programación</small>
                                                <select ref={this.selectSupervisorEncontrados} className="form-select "  >
                                                    {
                                                        this.state.mostrarTodosSupervisorObservacion ?
                                                            this.state.ListaObservacionSupervisor.map((row, index) => {
                                                                return (<option key={index} value={row.supervisor}>{row.supervisor}</option>)
                                                            }) :
                                                            this.state.ListaObservacionSupervisor.filter(el => el.nombretrabajador === null).map((row, index) => {
                                                                return (<option key={index} value={row.supervisor}>{row.supervisor}</option>)
                                                            })
                                                    }
                                                </select>
                                                {
                                                    this.state.CantidadObservacionesSupervisor > 0 ?
                                                        <small className="text-danger">{`${this.state.CantidadObservacionesSupervisor} Supervisores no coinciden con la Base de Trabajadores.`}</small> :
                                                        <small className="text-success">No se encontraron Obs. para la busqueda actual.</small>
                                                }
                                            </> :
                                            <>
                                                <small className="text-primary">Supervisores Programados</small>
                                                <select ref={this.selectSupervisorEncontrados} className="form-select "  >
                                                    {
                                                        this.state.ListaProgramacionSupervisores.map((row, index) => {
                                                            return (<option key={index} value={row.idpersonalsupervisa}>{row.nombretrabajador}</option>)
                                                        })
                                                    }
                                                </select>
                                            </>
                                    }

                                </div>

                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Asignar a Trabajador</small>
                                    <input ref={this.inputReasignarSupervidor} type="" className="form-control " list="listaSupervisores" placeholder="Elija el supervisor"></input>
                                    <datalist id="listaSupervisores">
                                        {
                                            this.state.ListaTrabajadoresActivos.map((row, index) => {
                                                return (<option key={index} value={row.nombretrabajador} />)
                                            })
                                        }
                                    </datalist>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col mt-1 text-end">
                                    <button onClick={() => { this.ReasignarSupervisor() }} className=" btn  btn-outline-secondary " >
                                        <i className="fas fa-exchange-alt p-1"></i>
                                        <span>Asignar Superv.</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.CerrarModalAsignarSupervidor() }} className="btn btn-outline-secondary mx-1">Cerrar</button>
                        </div>
                    </Modal.Footer>

                </Modal>

                {/* Modal Mensajes */}
                <Modal
                    show={this.state.MostrarModalMensajes}
                    onHide={() => {
                        this.setState({ OcultarLoading: true });
                        this.CerrarModalMensajes()
                    }}
                    backdrop="static"
                    keyboard={false}
                    centered >
                    <Modal.Header>
                        <Modal.Title>{this.state.TituloMensajeModal}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="text-primary">
                            <small>{this.state.TextoMensajesModal}</small>
                        </div>

                        <div className="text-danger">
                            <small>{this.state.TextoMensajesModalPregunta}</small>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => {
                                this.setState({ OcultarLoading: true });
                                this.CerrarModalMensajes()
                            }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                        </div>
                        <div className="">
                            <button onClick={() => {
                                this.CerrarModalMensajes();
                                this.GuardarRecetas();
                            }} className="btn btn-sm btn-outline-primary mx-1">Cargar de Todas Formas</button>
                        </div>
                    </Modal.Footer>

                </Modal>

                {/* Modal ELegir el Tipo de Programacion a Cargar  */}
                {/* Modal Mensajes */}
                <Modal
                    show={this.state.MostrarModalElegirTipoProgramacion}
                    onHide={() => {
                        this.setState({ OcultarLoading: true });
                        this.CerrarModalElegirTipoProgramacion()
                    }}
                    backdrop="static"
                    keyboard={false}
                    centered >
                    <Modal.Header>
                        <Modal.Title>{`Elija el tipo de Programación`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="mb-2">
                            <small className="text-primary ">Elija el Tipo de Programación para los Suministros Cargados</small>
                            <select ref={this.selectElegirTipoProgramacion} className="form-select form-select-sm">
                                <option defaultValue>Elija una Programación</option>
                                <option value="PROGRAMACION">EJECUCION</option>
                                <option value="VERIFICACION">INSPECCION</option>
                            </select>
                            <div className="mt-2">
                                <small className="text-secondary">De acuerdo al tipo de trabajo que va a ejecutar elija el tipo de programación. </small>
                            </div>
                            <ul>
                                <li>
                                    <small className="text-secondary"><strong>EJECUCION</strong>: Ejecucion de trabajo</small>
                                </li>
                                <li>
                                    <small className="text-secondary"><strong>INSPECCION</strong>: Verificar y determinar materiales a utilizar</small>
                                </li>
                            </ul>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => {
                                this.setState({ OcultarLoading: true });
                                this.CerrarModalElegirTipoProgramacion()
                            }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                        </div>
                        <div className="">
                            <button onClick={() => {
                                this.CargarProgramacionTipo();
                            }} className="btn btn-sm btn-outline-primary mx-1">ELegir</button>
                        </div>
                    </Modal.Footer>

                </Modal>

                {/* Modal Eliminar */}
                <Modal
                    show={this.state.MostrarModalEliminar}
                    onHide={() => {
                        this.setState({ OcultarLoading: true });
                        this.CerrarModalEliminarSuministros()
                    }}
                    keyboard={false}
                    centered >
                    <Modal.Header>
                        <Modal.Title>{`Elimininar Suministros`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">
                            {/* TIPOS DE TRABAJO */}
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Tipo Trabajo</small>
                                    <select onChange={() => { this.SelectTipoTrabajoEliminar() }} ref={this.selectTipoEliminar} className="form-select" aria-label="Default select example">
                                        <option value="TODOS">Todos</option>
                                        {this.state.TipoTrabajos.map((row, index) => {
                                            return (<option key={index} value={row.idTipotrabajo}>{row.tipotrabajo}</option>)
                                        })
                                        }
                                    </select>
                                </div>
                            </div>

                            {/* TECNICO */}
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Tecnico (JUO)</small>
                                    <select onChange={() => { this.SeleccionarJuoEliminar() }} ref={this.selectTecnicoEliminar} className="form-select" aria-label="Default select example">
                                        <option value="TODOS">Todos los JUO</option>
                                        {this.state.ListaTecnicosTipoTrabajo.map((row, index) => {
                                            return (<option key={index} value={row.idpersonalasignado}>{row.nombretrabajador}</option>)
                                        })
                                        }
                                    </select>
                                </div>
                            </div>

                            {/* <div className="row my-1">
                                <div className="col">
                                    <div className="form-check">
                                        <input onClick={() => { }} className="form-check-input" type="checkbox" id="checkMostrarJUOEliminar" />
                                        <small className="text-secondary" htmlFor="checkMostrarJUOEliminar">Mostrar JUO Programado</small>
                                    </div>
                                </div>

                            </div> */}
                            <div className="row">
                                <div className="col">
                                    <small className="text-primary">{`${this.state.ListaSuministrosEliminar.length} Suministros Encontrados`}</small>
                                </div>
                                {/* Tabla de Datos */}
                                <TableContainer style={{ maxHeight: '300px' }}>
                                    <Table size='small' stickyHeader  >
                                        <TableHead >
                                            <TableRow >
                                                {/* <TableCell><input onClick={(ev) => {
                                                    // this.SeleccionarTodosSuministros(ev)
                                                }} type="checkbox" className="form-check-input"></input></TableCell> */}
                                                <TableCell>Suministro</TableCell>
                                                <TableCell>Circuito</TableCell>
                                                <TableCell>Tipo</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {
                                            this.state.loadSumEliminar ?
                                                <TableBody ref={this.tableBodySuministros}>
                                                    {this.state.ListaSuministrosEliminar.map((suministro, index) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                {/* <TableCell><input onChange={(ev) => { this.SeleccionarElemento(ev, suministro) }} type="checkbox" className="form-check-input"></input></TableCell> */}
                                                                <TableCell>{suministro.suministro}</TableCell>
                                                                {/* <TableCell>{suministro.distrito}</TableCell> */}
                                                                <TableCell>{suministro.circuito}</TableCell>
                                                                <TableCell>{suministro.tipo}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>

                                                : <div className="text-center">
                                                    <SpinnerCarga />
                                                </div>
                                        }
                                    </Table>
                                </TableContainer>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => {
                                this.setState({ OcultarLoading: true });
                                this.CerrarModalEliminarSuministros()
                            }} className="btn btn-outline-secondary mx-1">Cerrar</button>
                        </div>
                        <div className="">
                            <button onClick={() => {
                                this.EliminarSuministrosFiltrados();

                            }} className="btn btn-outline-primary mx-1">Eliminar Suministros Filtrados</button>
                        </div>
                    </Modal.Footer>

                </Modal>


                {/* Modal Asignar color a Trabajo*/}
                <Modal
                    show={this.state.MostrarModalAsignarColorTrabajo}
                    onHide={() => { this.CerrarModalAsignarColorTrabajo() }}
                    keyboard={false}
                    centered >

                    <Modal.Header>
                        <Modal.Title>Asignar Color</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="container">

                            <div className="row">
                                <div className="col">
                                    <small className="text-primary">Observacion de Prioridad</small>
                                    <select ref={this.selectPrioridades} className="form-select "  >
                                        {
                                            this.state.ListaObservacionesPrioridad.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.prioridad}>{row.prioridad}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {
                                        this.state.ListaObservacionesPrioridad.length > 0 ?
                                            <></>
                                            :
                                            <small className="text-danger">No se encontró observaciones para este tipo de trabajo.</small>
                                    }
                                </div>

                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Asignar Color</small>
                                    <select onChange={() => { this.CambiarColorTrabajo() }} ref={this.selectElegirColorTrabajo} className="form-select"
                                        style={{ backgroundColor: this.state.color, color: this.state.colorText }}>
                                        <option value="" >(Sin color)</option>
                                        <option value="rojo" style={{ backgroundColor: 'rgb(255,199,206)', color: 'white' }}>Rojo</option>
                                        <option value="naranja" style={{ backgroundColor: 'rgb(255,153,51)', color: 'white' }}>Naranja</option>
                                        <option value="amarillo" style={{ backgroundColor: 'rgb(255,235,156)', color: 'red' }}>Amarillo</option>
                                        <option value="azul" style={{ backgroundColor: 'rgb(155,194,230)', color: 'white' }}>Azul</option>
                                        <option value="verde" style={{ backgroundColor: 'rgb(198,239,206)', color: 'white' }}>Verde</option>
                                    </select>
                                    <small className="text-secondary"></small>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col  text-end">
                                    <button onClick={() => { this.ReasignarColorTrabajoSuministro() }} className=" btn btn-outline-primary " >
                                        <i className="fas fa-exchange-alt p-1"></i>
                                        <span>Asignar</span>
                                    </button>

                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.CerrarModalAsignarColorTrabajo() }} className="btn btn-outline-secondary mx-1">Cerrar</button>
                        </div>
                    </Modal.Footer>

                </Modal>


            </Fragment >
        );
    }
}

export default programacion;