// import react from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';


import { ThemeProvider } from '@material-ui/core';
import theme from './config/configtheme';
// Componentes
import Login from './components/login/login';
import Dashboard from './components/dashboard/dashboard';
import Programacion from './components/programacion/programacion'
import Monitoreo from './components/monitoreo/monitoreo';
import MonitoreoTrabajador from './components/monitoreo/monitoreoTrabajador';
import MonitoreoSuministro from './components/monitoreo/monitoreoSuministro';
import MonitoreoSuministroMapa from './components/monitoreo/monitoreSuministroMapa'
import MonitoreoTrabajadorMapa from './components/monitoreo/monitoreoTrabajadorMapa';

import Estadistica from './components/estadisticas/estadistica';
// Componentes configuracion
import ConfigDashboard from './components/configuracion/configDashboard';
import Personal from './components/configuracion/configPersonal';
import Actividad from './components/configuracion/configActividad';
import Material from './components/configuracion/configMaterial';
import TipoTrabajo from './components/configuracion/configTipoTrabajo';
import Receta from './components/configuracion/configRecetaMaterial';
import Permisos from './components/configuracion/configPermisos';
import NotFound from './components/notFound/notFound'
import Almacen from './components/almacen/almacen';
import AlmacenInventario from './components/almacen/almacenInventario';
import AlmacenMaterialProgramado from './components/almacen/almacenMaterialProgramado';
import Buscar from './components/buscar/buscar';
import ProgramacionMapa from './components/programacion/programacionMapa';
import Fotografia from './components/monitoreo/fotografia';
import BuscarUbicacion from './components/buscar/buscarUbicacion';
import Mapa from './components/mapa/mapa';
import MapaReordenamiento from './components/buscar/mapa_reordenamiento'
import CargarGps from './components/buscar/cargargps'


function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* aGREGAR rOUTER */}
      <BrowserRouter>
        <Switch>
          <Route exact path="/dashboard" component={Dashboard}/>
          <Route exact path="/login"  component={Login}/>
          <Route exact path="/almacen"  component={Almacen}/>
          <Route exact path="/almacenInventario"  component={AlmacenInventario}/>
          <Route exact path="/almacenMaterialProgramado"  component={AlmacenMaterialProgramado}/>
          <Route exact path="/programacion" component={Programacion}/>                  
          <Route exact path="/programacionMapa" component={ProgramacionMapa}/>
          <Route exact path="/monitoreo/mapa/:fecha" component={MonitoreoTrabajadorMapa}/>
          <Route exact path="/monitoreo/trabajador/:fecha/:idtrabajador" component={MonitoreoTrabajador}/>
          <Route exact path="/monitoreo/trabajador/mapa/:fecha/:idtrabajador" component={MonitoreoSuministroMapa}/>
          <Route exact path="/monitoreo/suministro/mapa/:idtrabajosuministro" component={MonitoreoSuministro}/>
          <Route exact path="/monitoreo/suministro/:idtrabajosuministro" component={MonitoreoSuministro}/>
          <Route exact path="/monitoreo" component={Monitoreo}/>          
          <Route exact path="/estadistica" component={Estadistica}/>  
          <Route exact path="/configuracion/personal" component={Personal}/>
          <Route exact path="/configuracion/actividad" component={Actividad}/>
          <Route exact path="/configuracion/material" component={Material}/>
          <Route exact path="/configuracion/receta" component={Receta}/>
          <Route exact path="/configuracion/tipotrabajo" component={TipoTrabajo}/>
          <Route exact path="/configuracion/permisos" component={Permisos}/>
          <Route exact path="/configuracion" component={ConfigDashboard}/>
          <Route exact path="/buscar" component={Buscar}/>
          <Route exact path="/fotografia/:fotografia" component={Fotografia}/>
          <Route exact path="/ubicaciones" component={BuscarUbicacion}/>
          <Route exact path="/mapa" component={Mapa}/>
          <Route exact path="/mapa_ordenamiento" component={MapaReordenamiento}/>          
          <Route exact path="/cargar_gps" component={CargarGps}/>  
          <Route exact exact path="/" component={Dashboard}/>
          <Route path='*'component={NotFound} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
