import React, { Component, Fragment, createRef } from 'react';

import config from './../../config/config';
import { BorrarStorage, GuardarEnStorage, ObtenerDetalleToken, ValidarUsuario, ValidarPermisos, listarPermisos,LIST_SCREENS } from './../utils/utils';
import fotoFondo from './fondo.jpg';
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';

class login extends Component {
    constructor(props) {
        super(props);
        this.usuario = createRef();
        this.password = createRef();
        this.state = {
            correcto: true,
            abrirToast: false,
            mensajeToast: '',
            tipotoast: 'success',
        }
    }

    login = () => {
        // console.log("Validadn");
        if (this.usuario.current.value !== "" && this.password.current.value !== "") {

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    usuario: this.usuario.current.value,
                    password: this.password.current.value
                })
            };
            this.setState({ correcto: true });
            
            fetch(`${config.endPoint}api/usuario/loguear/web`, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {                
                if (data.content === null) {                    
                    BorrarStorage(config.storageName);
                    this.setState({ correcto: false });
                }
                else {                    
                    let detalleToken = JSON.parse(ObtenerDetalleToken(data.token));
                    let trabajador = {
                        nombretrabajador: detalleToken.nombretrabajador,
                        tiposesion: detalleToken.tiposesion,
                        fotografia: detalleToken.fotografia,
                        token: data.token,
                        tuser: data.tipousuario
                    };
                    //almacenar Usuario
                    GuardarEnStorage(config.storageName, trabajador);

                    listarPermisos().then((permisos) => {
                        // console.log("Permisos");
                        // console.log(permisos);
                        if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_HOME, permisos)) {
                            localStorage.removeItem(config.storageDateName);
                            localStorage.removeItem(config.storageFilterTrabajo);
                            this.props.history.push("/dashboard");
                        }
                        else {
                            localStorage.removeItem(config.storageName);
                            this.setState({ mensajeToast: 'No tiene permisos para Iniciar Sesión', abrirToast: true, tipotoast: 'warning' })
                        }
                    });                 

                }
            }).catch((error) => {
                console.log(error);
                this.setState({ mensajeToast: 'Error Interno en el servidor, revisa el estado de la conexion.', abrirToast: true, tipotoast: 'warning' })
            });
        }
        else {
            this.setState({ correcto: false });
            // this.usuario.focus();
        }
    }

    componentDidMount() {
        if (ValidarUsuario()) {
            this.props.history.push("/dashboard");
        }
    }
    
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }


    render() {
        return (
            <Fragment>
                {/* <div className={`${ !this.state.correcto ? 'd-flex alert alert-danger ': 'd-flex alert alert-danger d-none d-flex'}`} role="alert">
            Usuario o contraseña no validos.
        </div> */}



                <form onSubmit={(ev) => { ev.preventDefault() }}>
                    <div className="container-fluid" style={{ backgroundImage: `url(${fotoFondo})` }}>
                        <div className="container container-login " >
                            <div className="m-auto col-sm-12 col-md-7 col-lg-6 col-xl-5  border " style={{ backgroundColor: 'white' }}>
                                <div className="row m-2 ">
                                    <div className="login-titulo pt-2">
                                        <label style={{ height: '50px', textAlign: 'center', color: 'white' }}><h2>Iniciar Sesión</h2></label>
                                    </div>
                                </div>

                                <div className="row m-2">
                                    <label className="">Usuario</label>
                                    <div className="input-group">
                                        <input ref={this.usuario} type='text' className={`form-control ${this.state.correcto ? '' : 'is-invalid'} `} placeholder="Usuario"></input>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">*</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row m-2">
                                    <label className="">Contraseña</label>
                                    <div className="input-group">
                                        <input ref={this.password} type='password' className={`form-control ${this.state.correcto ? '' : 'is-invalid'} `} placeholder="Contraseña"></input>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">*</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    <small id="emailHelp" className={`form-text text-danger ${this.state.correcto ? 'd-none' : ''}`}>Usuario o contraseña Incorrectos</small>
                                </div>
                                <div className="row mx-4">
                                    <button onClick={() => { this.login() }} type="submit" className="btn btn-primary ">Iniciar Sesión</button>
                                </div>
                                <div className="row mx-4 text-secondary ">
                                    <small>{`Version ${config.aplicationVersion}`}</small>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>


                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

            </Fragment >
        );
    }
}

export default login;




