import React, { Component, createRef, Fragment } from 'react';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SpinnerCarga from './../utils/spinner';
import LoadingModal from './../utils/loadModal';
// Bootstrap
import { Modal } from 'react-bootstrap';

import { ValidarUsuario, ObtenerUsuario,ValidarPermisos,listarPermisos,LIST_SCREENS } from './../utils/utils';
import config from './../../config/config'

class configMaterial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            MostrarModalAgregar: false,
            MostrarModalAgregarMaterialConsorcio: false,
            AccionMaterial: "",
            abrirToast: false,
            Materiales: [],
            Material: null,
            TipoMaterial: [],
            SubTipoMaterial: [],
            ListaMaterialesTipo: [],
            loadData: false,
            nombrebuscar: '',
            pagina: 1,
            filasPorPagina: 10,
            cantidadElementos: 0,
        };
        this.headCells = [
            { id: 'Acciones' },
            { id: 'GOTOP' },
            { id: 'Codigo' },
            { id: 'Descripción' },            
            { id: 'Tipo' },
            { id: 'Sub Tipo' },
            { id: 'Estado' }
        ];
        // Referencias
        this.inputBuscar = createRef();
        //Referencias Modal Agregar
        this.inputCodigoMaterial = createRef();
        this.inputCodigoGotop = createRef();
        this.inputDescripcion = createRef();
        this.inputTipoMaterial = createRef();
        this.inputSubTipoMaterial = createRef();
        this.selectEstadoMaterial = createRef();

        this.buttonModificar = createRef();
        this.buttonGuardar = createRef();
    }
    async componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async(permisos)=>{
                if(ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_MATERIALES,permisos)){
                    await this.BuscarMateriales();
                }else{
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    // Funciones Material
    BuscarMateriales = () => {
        if (ValidarUsuario()) {
            let url;
            if (this.state.nombrebuscar.trim() !== '') {
                url = `${config.endPoint}api/basematerial/listar/paginacion/${this.state.filasPorPagina}/${this.state.pagina}/${this.state.nombrebuscar}`;
            } else {
                url = `${config.endPoint}api/basematerial/listar/paginacion/${this.state.filasPorPagina}/${this.state.pagina}`;
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ Materiales: data.content, cantidadElementos: data.count, loadData: true });
                }
                else {
                    this.setState({ Materiales: [], cantidadElementos: data.count, loadData: true });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }

    }
    BuscarMaterial = (idbasematerial) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/basematerial/${idbasematerial}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ Material: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ Material: null });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(true)
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    BuscarTipoMaterial = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/basematerial/tipomaterial`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ TipoMaterial: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ TipoMaterial: null });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });

            } else {
                reject(true);
            }
        });
    }
    BuscarSubTipoMaterial = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/basematerial/subtipomaterial`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ SubTipoMaterial: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ SubTipoMaterial: null });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });

    }
    AgregarMaterial = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/basematerial/agregar`
            let nuevoMaterial = {
                codigoMaterial: this.inputCodigoMaterial.current.value,
                codigoGotop: this.inputCodigoGotop.current.value,
                descripcion: this.inputDescripcion.current.value.toUpperCase(),
                tipo: this.inputTipoMaterial.current.value.toUpperCase(),
                subtipo: this.inputSubTipoMaterial.current.value.toUpperCase(),
                estado: this.selectEstadoMaterial.current.value.toUpperCase()
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevoMaterial)
            };

            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Material Agregado Correctamente", tipotoast: 'success' });
                    this.CerrarModalAgregar();
                    this.BuscarMateriales();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    ActualizarMaterial = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/basematerial/modificar`
            let nuevoMaterial = {
                idBaseMaterial: this.state.Material.idBaseMaterial,
                codigoMaterial: this.inputCodigoMaterial.current.value,
                codigoGotop: this.inputCodigoGotop.current.value,
                descripcion: this.inputDescripcion.current.value.toUpperCase(),
                tipo: this.inputTipoMaterial.current.value.toUpperCase(),
                subtipo: this.inputSubTipoMaterial.current.value.toUpperCase(),
                estado: this.selectEstadoMaterial.current.value.toUpperCase()
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevoMaterial)
            };
            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Material Actualizado Correctamente", tipotoast: 'success' });
                    this.CerrarModalAgregar();
                    this.BuscarMateriales();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

        } else {
            this.props.history.push('/login');
        }
    }
    GuardarMaterial = (ev) => {
        ev.preventDefault();
        switch (this.state.AccionMaterial) {
            case 'Ver':
                this.ActualizarMaterial();
                break;
            case 'Agregar':
                this.AgregarMaterial();
                break;
            default:
        }
    }


    BuscarListaMaterialPorTipo = (tipo) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/basematerial/buscarlista/${tipo}`
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaMaterialesTipo: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaMaterialesTipo: [] });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });

    }

    BuscarMaterialnombre = async () => {
        await this.setState({ nombrebuscar: this.inputBuscar.current.value });
        await this.BuscarMateriales();
    }

    DeshabilitarControlesModal = (valor) => {
        this.inputCodigoMaterial.current.readOnly = valor
        this.inputCodigoGotop.current.readOnly = valor
        this.inputDescripcion.current.readOnly = valor
        this.inputTipoMaterial.current.readOnly = valor
        this.inputSubTipoMaterial.current.readOnly = valor
        this.selectEstadoMaterial.current.disabled = valor
    }
    ModificarActividad = () => {
        this.DeshabilitarControlesModal(false);
        this.buttonGuardar.current.disabled = false;
        this.buttonModificar.current.disabled = true;
    }

    //*********************************** */
    // Fuciones Modal Agregar
    //*********************************** */
    AbrirModalAgregar = async (idmaterial, accion) => {
        if (ValidarUsuario()) {
            await this.setState({ OcultarLoading: false });

            await this.BuscarTipoMaterial().then(async () => {
                await this.BuscarSubTipoMaterial().then(async () => {
                    await this.setState({ MostrarModalAgregar: true, AccionMaterial: accion });
                    if (this.state.AccionMaterial === "Ver") {

                        await this.BuscarMaterial(idmaterial).then();
                        this.inputCodigoMaterial.current.value = this.state.Material.codigoMaterial;
                        this.inputCodigoGotop.current.value = this.state.Material.codigoGotop;
                        this.inputDescripcion.current.value = this.state.Material.descripcion;
                        this.inputTipoMaterial.current.value = this.state.Material.tipo.toUpperCase();
                        this.inputSubTipoMaterial.current.value = this.state.Material.subtipo.toUpperCase();
                        this.selectEstadoMaterial.current.value = this.state.Material.estado.toUpperCase();

                        this.DeshabilitarControlesModal(true);
                        this.buttonGuardar.current.disabled = true;
                        this.setState({ OcultarLoading: true });
                    }
                    else {
                        this.buttonModificar.current.hidden = true;
                        this.setState({ OcultarLoading: true });
                    }
                }).catch((error) => {
                    if (error) {
                        this.props.history.push('/login');
                    }
                });
            }).catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    CerrarModalAgregar = () => {
        this.setState({ MostrarModalAgregar: false, AccionMaterial: "" });
    }
    //*********************************** */
    // Fuciones Vincular MAterial Consorcio contratista
    //*********************************** */

    AbrirModalVincularMaterial = async () => {
        //Buscar Lista Materiales Consorcio
        this.setState({ OcultarLoading: false });
        this.setState({ MostrarModalAgregarMaterialConsorcio: true, AccionMaterial: "" });

        await this.BuscarListaMaterialPorTipo("EMPRESA").then(() => {

        }).catch((error) => {
            if (error) {
                this.props.history.push('/login');
            }
        });

    }

    CerrarModalAgrgarMaterialConsorcio = () => {
        this.setState({ MostrarModalAgregarMaterialConsorcio: false, AccionMaterial: "" });
    }
    //*********************************** */
    // Fuciones de InputBuscar
    //*********************************** */
    CambiarInputBuscar = async () => {
        if (this.inputBuscar.current.value === '') {
            await this.setState({ nombrebuscar: this.inputBuscar.current.value, pagina: 1 });
            await this.BuscarMateriales();
        }
    }
    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.setState({ nombrebuscar: this.inputBuscar.current.value, pagina: 1 });
            await this.BuscarMateriales();
        }
    }
    //*************************************************
    //Funciones para Paginacion
    //*************************************************
    CambioFilasPorPagina = async (event) => {
        await this.setState({ loadData: false, filasPorPagina: parseInt(event.target.value), pagina: 1 });
        await this.BuscarMateriales();
    }
    CambiarPagina = async (event, nuevaPagina) => {
        await this.setState({ loadData: false, pagina: (parseInt(nuevaPagina) + 1) });
        await this.BuscarMateriales();
    }

    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    render() {
        return (
            <Fragment>
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>
                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar />
                <TituloPagina titulo="CONFIGURACIÓN - MATERIAL" regresar={() => { this.props.history.push('/configuracion') }} />
                {/* Contenedor de Datos */}
                <div className="container border mt-1">
                    <div className="row mt-1">
                        <div className=" col-8 col-md-4">
                            <input onChange={() => { this.CambiarInputBuscar() }} onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} ref={this.inputBuscar} className="form-control form-control-sm" placeholder="Buscar Material"></input>
                        </div>
                        <div className="col-4 col-md-4 ">
                            <button onClick={() => { this.BuscarMaterialnombre() }} className="btn btn-sm btn-outline-secondary"><i className="fas fa-search p-1"></i>Buscar</button>
                        </div>

                        <div className="col-12 col-md-4 text-md-end mt-md-0 mt-1">
                            <button onClick={() => { this.AbrirModalAgregar(null, "Agregar") }} className=" btn btn-sm btn-outline-secondary " style={{ width: '130px' }}><i className="fas fa-upload mx-1"></i> Agregar</button>
                            {/* <button onClick={() => { this.props.history.push("/configuracion") }} className=" btn btn-outline-secondary m-1 p-1" style={{ width: '130px' }}><i className="fas fa-arrow-left p-1"></i> Regresar</button> */}
                        </div>
                    </div>
                    <div className="row p-1">
                        {
                            this.state.loadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    {
                                                        this.headCells.map((head, index) => {
                                                            return (
                                                                <TableCell style={{ color: 'aliceblue' }} key={index}>{head.id}</TableCell>
                                                            );
                                                        })
                                                    }

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.Materiales.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <i onClick={() => { this.AbrirModalAgregar(row.idBaseMaterial, "Ver") }} title="Ver" className="fas fa-eye  mx-2 iconoAcciones" ></i>
                                                            </TableCell>
                                                            <TableCell>{row.codigoGotop}</TableCell>
                                                            <TableCell>{row.codigoMaterial}</TableCell>
                                                            <TableCell>{row.descripcion}</TableCell>                                                            
                                                            <TableCell>{row.tipo}</TableCell>
                                                            <TableCell>{row.subtipo}</TableCell>
                                                            <TableCell>{row.estado.toUpperCase() === 'ACTIVO' ?
                                                                <span className="estadoActivo">{row.estado.toUpperCase()}</span> :
                                                                <span className="estadoInactivo">{row.estado.toUpperCase()}</span>}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 50]}
                                        component="div"
                                        count={this.state.cantidadElementos}
                                        rowsPerPage={this.state.filasPorPagina}
                                        page={this.state.pagina - 1}
                                        onChangePage={this.CambiarPagina}
                                        onChangeRowsPerPage={this.CambioFilasPorPagina}
                                        labelRowsPerPage={"Filas Por Pagina"}
                                    />
                                </>
                                :
                                <SpinnerCarga />
                        }
                    </div>
                </div>    
                {/* Modal Agregar */}
                <Modal
                    show={this.state.MostrarModalAgregar}
                    onHide={() => { this.CerrarModalAgregar() }}
                    backdrop="static"
                    keyboard={false}
                >
                    <form onSubmit={(ev) => { this.GuardarMaterial(ev) }}>
                        <Modal.Header>
                            <Modal.Title>Material - {this.state.AccionMaterial}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                            <div className="container">
                                <div className="row my-1">

                                    <div className="col-md-6">
                                        <small className="text-primary">Codigo Material</small>
                                        <input ref={this.inputCodigoMaterial} required className="form-control form-control-sm " placeholder="Ingrese el Codigo de Material"></input>
                                    </div>
                                    <div className="col-md-6">
                                        <small className="text-primary">Codigo GOTOP</small>
                                        <input ref={this.inputCodigoGotop} required className="form-control form-control-sm" placeholder="Ingrese el Codigo GOTOP"></input>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col">
                                        <small className="text-primary">Descripción</small>
                                        <input ref={this.inputDescripcion} required type="" className="form-control form-control-sm" placeholder="Ingrese una Descripcion"></input>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col">
                                        <small className="text-primary">Tipo Material</small>
                                        <input ref={this.inputTipoMaterial} required type="" className="form-control form-control-sm" list="listaTipos" placeholder="Ingrese el tipo de Material (Ejm. Empresa, Seal...)"></input>
                                        <datalist id="listaTipos">
                                            {
                                                this.state.TipoMaterial.map((row, index) => {
                                                    return (<option key={index} value={row.tipo.toUpperCase()} />);
                                                })
                                            }
                                        </datalist>
                                    </div>
                                </div>
                                <div className="row my-1">
                                    <div className="col">
                                        <small className="text-primary">Sub Tipo Material</small>
                                        <input ref={this.inputSubTipoMaterial} required type="" className="form-control form-control-sm" list="listaSubTipos" placeholder="Ingrese el Sub tipo de Material (Ejm. Cable,Termico...)"></input>
                                        <datalist id="listaSubTipos">
                                            {
                                                this.state.SubTipoMaterial.map((row, index) => {
                                                    return (<option key={index} value={row.subtipo.toUpperCase()} />);
                                                })
                                            }
                                        </datalist>
                                    </div>
                                </div>

                                <div className="row my-1">
                                    <div className="col-md-6">
                                        <small className="text-primary">Estado</small>
                                        <select ref={this.selectEstadoMaterial} required className="form-select form-select-sm" >
                                            <option value="ACTIVO">Activo</option>
                                            <option value="INACTIVO">Inactivo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                        </Modal.Body>
                        <Modal.Footer>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-5 col-md-6">
                                        <button onClick={() => { this.ModificarActividad() }} ref={this.buttonModificar} className="btn btn-sm btn-outline-primary">Modificar</button>
                                    </div>
                                    <div className="col-7 col-md-6 text-end">
                                        <button onClick={() => { this.CerrarModalAgregar() }} className="btn btn-sm btn-outline-secondary mx-1">Cancelar</button>
                                        <button ref={this.buttonGuardar} type="submit" className="btn btn-sm btn-primary mx-1">Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>            

            </Fragment>
        );
    }
}

export default configMaterial;