import React, { Component, Fragment } from 'react';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';

import { ValidarUsuario, ValidarPermisos, listarPermisos, LIST_SCREENS } from './../utils/utils'
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { Link } from 'react-router-dom';

class Almacen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            abrirToast: false,
            permisos: []
        };
    }
    componentDidMount() {
        if (!ValidarUsuario()) {
            this.props.history.push("/login");
        }
        else {
            listarPermisos().then(async (permisos) => {
                // console.log(permisos);
                await this.setState({ permisos: permisos });
                if (!ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ALMACEN, permisos)) {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);

                }
            })
        }
    }
    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }
    render() {
        return (
            <Fragment>
                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar />
                <TituloPagina titulo="ALMACÉN" regresar={() => { this.props.history.push('/') }} />

                <div className="container">
                    <div className="row mt-3">
                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ALMACEN_INVENTARIO, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-dolly-flatbed fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                        </div>
                                        <Link to="/almacenInventario" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Inventario</Link>
                                    </div>
                                </div>
                                : <></>
                        }

                        {
                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ALMACEN_RESUMEN, this.state.permisos) ?
                                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 p-1 contenerdor-centro">
                                    <div className="d-flex flex-column justify-content-center align-items-center border " style={{ width: '200px' }}>
                                        <div className="border rounded-circle m-1 p-2 d-flex justify-content-center align-items-center" style={{ height: 60, width: 60 }}>
                                            <i className="fas fa-toolbox fa-2x p-2 " style={{ color: 'rgb(230,83,97)' }}></i>
                                        </div>
                                        <Link to="/almacenMaterialProgramado" className="btn btn-item-dashboard m-2" style={{ width: 150 }}>Material Prog.</Link>
                                    </div>
                                </div>
                                : <></>
                        }

                    </div>
                </div>

            </Fragment>
        );
    }
}

export default Almacen;