import React, { Component, createRef } from 'react';
import { Fragment } from 'react';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import LoadingModal from './../utils/loadModal'
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Modal } from 'react-bootstrap';
// React LeafLet
import { MapContainer, Marker, TileLayer, Tooltip, FeatureGroup, Popup } from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

//Turf JS
import * as turf from '@turf/turf';

import { Pin1, Pin2, Pin3, Pin4, Pin5, Pin6, Pin7, Pin8, Pin9, Pin10, Pin11, Pin12, Pin13, Pin14, Pin15, Pin16, Pin17, Pin18, Pin19, Pin20, IconoWorkFree, IconoWorkSelect } from './../../config/icono'

import { ValidarUsuario, ObtenerUsuario, ReadExcelfileToJson, ValidarPermisos, LIST_SCREENS, listarPermisos } from './../utils/utils'

//Exportar Excel
import { CreateJsonToExcel } from './../utils/utils';

class Mapa extends Component {

    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            loadMapa: false,
            MostrarPorcentajeCarga: false,
            nombreColumnaLatitud: '',
            nombreColumnaLongitud: '',
            nombreColumnaTitulo: '',
            centro: {
                lat: -16.398821,
                lng: -71.536862
            },
            zoomMapa: 13,

            ListaDatosCargados: [],
            ListaSuministros: [],
            ListaNombresCabecera: [],
            ListaTrabajadoresAsignados: [],
            ListaMarcadoresSeleccionados: [],
            cantidadMarcadoresSeleccionados: 0,
            cantidadMarcadoresSinAsignar:0,
            ListaSuministrosGps: [],

            ListaPines: [Pin1, Pin2, Pin3, Pin4, Pin5, Pin6, Pin7, Pin8, Pin9, Pin10, Pin11, Pin12, Pin13, Pin14, Pin15, Pin16, Pin17, Pin18, Pin19, Pin20],
            ListaImagenesPines: [
                'img/pin1.svg',
                'img/pin2.svg',
                'img/pin3.svg',
                'img/pin4.svg',
                'img/pin5.svg',
                'img/pin6.svg',
                'img/pin7.svg',
                'img/pin8.svg',
                'img/pin9.svg',
                'img/pin10.svg',
                'img/pin11.svg',
                'img/pin12.svg',
                'img/pin13.svg',
                'img/pin14.svg',
                'img/pin15.svg',
                'img/pin16.svg',
                'img/pin17.svg',
                'img/pin18.svg',
                'img/pin19.svg',
                'img/pin20.svg'],
            MostarModalElegirOrigenLatLng: false,
            MostarModalElegirNombreColumna: false,
            MostrarModalAsignarMarcadores: false,
            MostrarModalReasignarMarcadores: false,
            MostrarLiberarTrabajos: false,
            nombreColumnaElegida: '',
            NombreAsignadoCambiar: '',
            trabajadorSeleccionado: {}
        }

        //Referencias FeatureGroup React-Leafleat-draw
        this.featureGroupRef = createRef();

        //Referencias  Archivo Carga
        this.inputFileSubirArchivo = createRef();
        this.inputTextNombreAgrupacion = createRef();
        this.inputTextNombreReasignarAgrupacion = createRef();

    };

    async componentDidMount() {
        if (ValidarUsuario()) {

            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MAPA, permisos)) {

                    await this.setState({ OcultarLoading: false });
                    this.buscarEnStorage().then(() => {
                    });
                    this.BuscarSuministrosSinSeleccionar();
                    this.setState({ OcultarLoading: true });
                    
                } else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }
            });

        }
        else {
            this.props.history.push('/login');
        }


    }
    buscarEnStorage = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                // Buscar datos guardados en storage
                let DataMapas = localStorage.getItem('DataMapas');
                if (DataMapas) {
                    // Volcando Informacion de Storage
                    DataMapas = JSON.parse(DataMapas);
                    this.setState(
                        {
                            ListaSuministros: DataMapas.ListaSuministros,
                            ListaSuministrosGps: DataMapas.ListaSuministros,
                            ListaDatosCargados: DataMapas.ListaSuministros,
                            ListaTrabajadoresAsignados: DataMapas.ListaTrabajadoresAsignados,
                            nombreColumnaLatitud: DataMapas.nombreColumnaLatitud,
                            nombreColumnaLongitud: DataMapas.nombreColumnaLongitud,
                            nombreColumnaTitulo: DataMapas.nombrecolumnaTitulo,
                        });
                    this.ElegirMarcador();

                    // console.log(DataMapas);
                    resolve(true);
                }
            }
            else {
                reject(true);
            }
        });
    }
    BuscarSuministrosSinSeleccionar=()=>{
        let listaSuminitrosTemp = this.state.ListaSuministros;
        let cantidadNoSeleccionados = listaSuminitrosTemp.filter(x => x.nombreAsignado === null);
            this.setState({ cantidadMarcadoresSinAsignar: cantidadNoSeleccionados.length });
    }

    CargaArchivoXLS = async () => {
        // Reiniciar Datos        
        await this.setState({
            nombreColumnaLatitud: '',
            nombreColumnaLongitud: '',
            nombreColumnaTitulo: '',
            ListaDatosCargados: [],
            ListaNombresCabecera: [],
            ListaSuministrosGps: [],
            ListaSuministros: [],
            ListaTrabajadoresAsignados:[]
        });

        await this.setState({ OcultarLoading: false });

        let DataExcel = [];
        await ReadExcelfileToJson(this.inputFileSubirArchivo.current.files[0]).then(async (respuesta) => {
            DataExcel = respuesta;

            this.setState({ ListaDatosCargados: respuesta });
        }).catch((error) => {
            DataExcel = [];
            this.setState({ mensajeToast: "Error al cargar el archivo.", abrirToast: true, tipotoast: 'error' });
        });
        // console.log(DataExcel);

        let nombreColumnas = [];
        if (DataExcel.length > 0) {
            for (var clave in DataExcel[0]) {
                nombreColumnas.push(clave);
            }
        }
        // Abrir Modal Elegir GPS
        if (nombreColumnas.length > 0) {
            this.setState({ ListaNombresCabecera: nombreColumnas, MostarModalElegirNombreColumna: true });
        }

    }
    MapearSuministros = async () => {
        this.setState({ OcultarLoading: false });
        let nuevoArray = [];
        let datosCargados = this.state.ListaDatosCargados;
        // Recorrer Arreglo y Asignar identificador
        // console.log(datosCargados);
        for (let x = 0; x < datosCargados.length; x++) {
            datosCargados[x].id = x;
            datosCargados[x].nombreAsignado = null;
            datosCargados[x].seleccionado = false;
            // datosCargados[x].marker = ;            
            nuevoArray.push(datosCargados[x]);
        }
        // console.log(datosCargados);
        await this.setState({ ListaSuministros: datosCargados, ListaSuministrosGps: datosCargados });
        // Guardar Datos en localStorage    

        let data = {
            ListaSuministros: datosCargados,
            nombreColumnaLatitud: this.state.nombreColumnaLatitud,
            nombreColumnaLongitud: this.state.nombreColumnaLongitud,
            nombrecolumnaTitulo: this.state.nombreColumnaTitulo,
            ListaTrabajadoresAsignados: []
        }
        localStorage.setItem('DataMapas', JSON.stringify(data));
        this.BuscarSuministrosSinSeleccionar();
        this.CerrarModalElegirNombreColumna(false);
        this.setState({ OcultarLoading: true });
    }


    AbrirModalElegirOrigenLatLng = (e) => {
        // console.log(e);
        this.setState({ MostarModalElegirOrigenLatLng: true, nombreColumnaElegida: e });
    }
    CerrarModalElegirOrigenLatLng = () => {
        this.setState({ MostarModalElegirOrigenLatLng: false });
    }

    AbrirModalElegirNombreColumna = () => {
        this.setState({ MostarModalElegirNombreColumna: true });
    }
    CerrarModalElegirNombreColumna = (e) => {
        if(e){
            this.EliminarDatosCargados();
        }

        this.setState({ MostarModalElegirNombreColumna: false,OcultarLoading:true });
    }

    elegirLatLngColumna = (e, nombreColumna) => {

        switch (e.target.value) {
            case 'latitud':
                this.setState({ nombreColumnaLatitud: nombreColumna });
                this.CerrarModalElegirOrigenLatLng();
                break;
            case 'longitud':
                this.setState({ nombreColumnaLongitud: nombreColumna });
                this.CerrarModalElegirOrigenLatLng();
                break;
            case 'titulo':
                this.setState({ nombreColumnaTitulo: nombreColumna });
                this.CerrarModalElegirOrigenLatLng();
                break;
        }
    }

    PosicionMarcadorSeleccionado = (id) => {
        let posicion = -1;
        for (let x = 0; x < this.state.ListaSuministros.length; x++) {
            if (this.state.ListaSuministros[x].id === id) {
                posicion = x;
                break;
            }
        }
        return posicion;
    }
    SeleccionarMarcador = (indice, row) => {
        if (row.nombreAsignado === null) {
            let posicion = this.PosicionMarcadorSeleccionado(row.id);
            let listaSuminitrosTemp = this.state.ListaSuministros;

            if (row.seleccionado) {
                listaSuminitrosTemp[posicion].seleccionado = false;
            }
            else {
                listaSuminitrosTemp[posicion].seleccionado = true;
            }
            // Contar Marcadores Seleccionados
            let cantidadSeleccionados = listaSuminitrosTemp.filter(x => x.seleccionado === true);
            this.setState({ ListaSuministros: listaSuminitrosTemp, ListaMarcadoresSeleccionados: cantidadSeleccionados });

        }
        else {
            this.setState({ mensajeToast: `Trabajo ya esta asigando a ${row.nombreAsignado}`, abrirToast: true, tipotoast: 'warning' });
        }
    }

    AsignarMarcadores = async () => {
        if (this.state.ListaMarcadoresSeleccionados.length > 0) {
            // await this.setState({ OcultarLoading: false });
            // Mostrar modal Ingresar nombre a Asignar
            this.AbrirModalAsignarMarcadores();

        } else {
            this.setState({ abrirToast: true, mensajeToast: 'Elija los marcadores a asignar', tipotoast: 'warning' });
        }
    }
    validarModalAsignarMarcadores = () => {
        // console.log(this.inputTextNombreAgrupacion.current.value);
        if (this.inputTextNombreAgrupacion.current.value === '') {
            // this.setState({ mensajeToast: `Suministro ya esta asigando a `, abrirToast: true, tipotoast: 'warning' });
            this.inputTextNombreAgrupacion.current.classList.add('is-invalid');
        }
        else {
            this.inputTextNombreAgrupacion.current.classList.remove('is-invalid');
            this.AsignarNombreAgrupacionMarcadores(this.inputTextNombreAgrupacion.current.value);
        }
    }

    AsignarNombreAgrupacionMarcadores = async (nombreAsignar) => {
        // Recorrer y buscar todos los valores seleccionados
        let gpsSuministros = this.state.ListaSuministros;
        let elementoAsignado = {
            nombre: nombreAsignar,
            cantidad: 0,
            marcador: null
        };
        let cantidadSeleccionados = 0;
        let arrayNombresAsignados = this.state.ListaTrabajadoresAsignados;
        let posicionSiExiste = -1;
        // Buscar Si existe Nombre
        for (let x = 0; x < arrayNombresAsignados.length; x++) {
            if (arrayNombresAsignados[x].nombre === nombreAsignar) {
                posicionSiExiste = x;
                break;
            }
        }

        for (let x = 0; x < gpsSuministros.length; x++) {
            if (gpsSuministros[x].seleccionado) {
                gpsSuministros[x].nombreAsignado = nombreAsignar;
                cantidadSeleccionados++;
            }
        }

        if (posicionSiExiste > -1) {
            // Existe Elemento
            let elementosexiste = gpsSuministros.filter(x => x.nombreAsignado === nombreAsignar);
            arrayNombresAsignados[posicionSiExiste].cantidad = elementosexiste.length;
        }
        else {
            // No existe Elemento
            elementoAsignado.cantidad = cantidadSeleccionados;
            arrayNombresAsignados.push(elementoAsignado);
        }
        await this.setState({ ListaTrabajadoresAsignados: arrayNombresAsignados, ListaMarcadoresSeleccionados: [], ListaSuministros: gpsSuministros, ListaSuministrosGps: gpsSuministros });

        // Guardar DAtos
        let data = {
            ListaSuministros: this.state.ListaSuministros,
            ListaTrabajadoresAsignados: this.state.ListaTrabajadoresAsignados,
            nombreColumnaLatitud: this.state.nombreColumnaLatitud,
            nombreColumnaLongitud: this.state.nombreColumnaLongitud,
            nombrecolumnaTitulo: this.state.nombreColumnaTitulo
        }
        localStorage.setItem('DataMapas', JSON.stringify(data));

        // Agregar nombre      
        this.ElegirMarcador();
        this.BuscarSuministrosSinSeleccionar();
        this._eliminarPoligonos();
        this.CerrarModalAsignarMarcadores()
    }

    AbrirModalAsignarMarcadores = () => {
        this.setState({ MostrarModalAsignarMarcadores: true });
    }

    CerrarModalAsignarMarcadores = () => {
        this.setState({ MostrarModalAsignarMarcadores: false });
    }

    AbrirModalReasignarMarcadores = (row) => {
        console.log(row);
        this.setState({ MostrarModalReasignarMarcadores: true, NombreAsignadoCambiar: row.nombre });
    }

    CerrarModalReasignarMarcadores = () => {
        this.setState({ MostrarModalReasignarMarcadores: false });
    }

    validarModalReasignarMarcadores = async () => {
        if (this.inputTextNombreReasignarAgrupacion.current.value === '') {
            this.inputTextNombreReasignarAgrupacion.current.classList.add('is-invalid');
        } else {
            this.inputTextNombreReasignarAgrupacion.current.classList.remove('is-invalid');
            // Reasignar todos los nombres Lista Suministros
            let listaSuminitrosTemp = this.state.ListaSuministros;
            let listaTrabajadoresasignadosTemp = this.state.ListaTrabajadoresAsignados;
            for (let x = 0; x < listaSuminitrosTemp.length; x++) {
                if (listaSuminitrosTemp[x].nombreAsignado === this.state.NombreAsignadoCambiar) {
                    listaSuminitrosTemp[x].nombreAsignado = this.inputTextNombreReasignarAgrupacion.current.value;
                }
            }
            //ReasignarNombres Lista Trabajadores
            for (let x = 0; x < listaTrabajadoresasignadosTemp.length; x++) {
                if (listaTrabajadoresasignadosTemp[x].nombre === this.state.NombreAsignadoCambiar) {
                    listaTrabajadoresasignadosTemp[x].nombre = this.inputTextNombreReasignarAgrupacion.current.value;
                }
            }
            await this.setState({ ListaSuministros: listaSuminitrosTemp, ListaSuministrosGps: listaSuminitrosTemp, ListaTrabajadoresAsignados: listaTrabajadoresasignadosTemp });
            // Almacenar En storage
            let data = {
                ListaSuministros: this.state.ListaSuministros,
                ListaTrabajadoresAsignados: this.state.ListaTrabajadoresAsignados,
                nombreColumnaLatitud: this.state.nombreColumnaLatitud,
                nombreColumnaLongitud: this.state.nombreColumnaLongitud,
                nombrecolumnaTitulo: this.state.nombreColumnaTitulo
            }
            localStorage.setItem('DataMapas', JSON.stringify(data));
        }
        this.CerrarModalReasignarMarcadores();
    }

    MapearSuministrosTrabajador = (row) => {
        let filtro = this.state.ListaSuministros.filter(x => x.nombreAsignado === row.nombre);
        // console.log(filtro);
        this.setState({ ListaSuministrosGps: filtro });
    }
    MapearTodosSuministros = (row) => {
        // console.log(filtro);
        this.setState({ ListaSuministrosGps: this.state.ListaSuministros });
    }

    ElegirMarcador = async () => {
        // console.log(this.state.ListaGPSSuministros);
        let _listasuministrosTemp = this.state.ListaSuministros;
        //Buscar Lista de TECNICOS
        let arrayTrabajadores = this.state.ListaTrabajadoresAsignados;
        // let _existe = false;
        let _cantMarcadores = 20;

        //Asignar Valores a Marcador
        for (let x = 0; x < _listasuministrosTemp.length; x++) {
            for (let y = 0; y < arrayTrabajadores.length; y++) {
                if (_listasuministrosTemp[x].nombreAsignado === arrayTrabajadores[y].nombre) {
                    _listasuministrosTemp[x].seleccionado = false;
                    _listasuministrosTemp[x].marker = y - (Math.trunc(y / _cantMarcadores) * _cantMarcadores);
                    arrayTrabajadores[y].marcador = y - (Math.trunc(y / _cantMarcadores) * _cantMarcadores);
                    break;
                }
            }
        }
        await this.setState({ ListaSuministros: _listasuministrosTemp, ListaTrabajadoresAsignados: arrayTrabajadores, loadMapa: true });
    }

    AbrirModalLiberarSuministros = (row) => {
        // console.log(row);
        this.setState({ MostrarLiberarTrabajos: true, trabajadorSeleccionado: row });
    }

    CerrarModalLiberarSuministros = () => {
        this.setState({ MostrarLiberarTrabajos: false });
    }

    LiberarTrabajo = async () => {
        try {
            // Recorrer lista de suministros y Limpiar Valores
            // Reasignar todos los nombres Lista Suministros
            let listaSuminitrosTemp = this.state.ListaSuministros;
            let listaTrabajadoresasignadosTemp = this.state.ListaTrabajadoresAsignados;
            for (let x = 0; x < listaSuminitrosTemp.length; x++) {
                if (listaSuminitrosTemp[x].nombreAsignado === this.state.trabajadorSeleccionado.nombre) {
                    listaSuminitrosTemp[x].nombreAsignado = null;
                }
            }
            //ReasignarNombres Lista Trabajadores
            for (let x = 0; x < listaTrabajadoresasignadosTemp.length; x++) {
                console.log(x);
                if (listaTrabajadoresasignadosTemp[x].nombre === this.state.trabajadorSeleccionado.nombre) {
                    // Eliminar  elemento                 
                    listaTrabajadoresasignadosTemp.splice(x, 1);
                    x = 0;
                }
            }
            // console.log(listaTrabajadoresasignadosTemp);

            await this.setState({ ListaSuministros: listaSuminitrosTemp, ListaSuministrosGps: listaSuminitrosTemp, ListaTrabajadoresAsignados: listaTrabajadoresasignadosTemp });
            // Almacenar En storage
            let data = {
                ListaSuministros: this.state.ListaSuministros,
                ListaTrabajadoresAsignados: this.state.ListaTrabajadoresAsignados,
                nombreColumnaLatitud: this.state.nombreColumnaLatitud,
                nombreColumnaLongitud: this.state.nombreColumnaLongitud,
                nombrecolumnaTitulo: this.state.nombreColumnaTitulo
            }
            localStorage.setItem('DataMapas', JSON.stringify(data));
            this.BuscarSuministrosSinSeleccionar();
            this.CerrarModalLiberarSuministros();

        } catch (error) {
            this.setState({ mensajeToast: error.message, abrirToast: true, tipotoast: 'error' });
        }

    }

    ExisteMarcador = (id) => {
        let existe = false;
        for (let x = 0; x < this.state.ListaMarcadoresSeleccionados.length; x++) {
            if (this.state.ListaMarcadoresSeleccionados[x].id === id) {
                existe = true;
                break;
            }
        }
        return existe;
    }

    EliminarDatosCargados = () => {
        // Limpiar Storage
        localStorage.removeItem("DataMapas");
        this.setState(
            {
                ListaSuministros: [],
                ListaDatosCargados: [],
                ListaSuministrosGps: [],
                ListaTrabajadoresAsignados:[],
                nombreColumnaLatitud: '',
                nombreColumnaLongitud: '',
                nombreColumnaTitulo: '',                
            });
    }

    RevisarPuntosEnPoligono = async (coord_polig) => {
        //Limpiar Marcadores
        // console.log(coord_polig);
        let marcadoresTemp = [];
        //
        let gpsSuministros = this.state.ListaSuministros;
        //Obteniendo Datos de Poligono
        let _poligono = turf.polygon(coord_polig);

        for (let x = 0; x < gpsSuministros.length; x++) {
            if (gpsSuministros[x].nombreAsignado === null) {
                // console.log(gpsSuministros[x]);
                let _punto = turf.point([parseFloat(gpsSuministros[x][this.state.nombreColumnaLongitud]), parseFloat(gpsSuministros[x][this.state.nombreColumnaLatitud])]);

                let _busqueda = turf.pointsWithinPolygon(_punto, _poligono);

                if (_busqueda.features.length > 0) {
                    //Punto Seleccionado
                    gpsSuministros[x].seleccionado = true;
                    marcadoresTemp.push(gpsSuministros[x]);
                }
                else {
                    gpsSuministros[x].seleccionado = false;
                }
            }
        }
        await this.setState({ ListaGPSSuministros: gpsSuministros, ListaMarcadoresSeleccionados: marcadoresTemp });

    }


    _onCreate = (ev) => {
        // console.log(ev);        
        // let polygon=ev.layer.getLatLngs();
        let coords = ev.layer.toGeoJSON();
        let coordenadas = coords.geometry.coordinates
        // let tipo = coords.geometry.type;
        // console.log(coordenadas);

        this.RevisarPuntosEnPoligono(coordenadas);
    }

    _onEdited = (ev) => {
        let { layers: { _layers } } = ev;
        let primeraPosicion;
        let coordenadas = [];
        // console.log(_layers);
        Object.values(_layers).map((_leafleat_id) => {
            let _coords = _leafleat_id._latlngs[0].map((e, index) => {
                let LatLng = e;
                if (index === 0) {
                    primeraPosicion = [LatLng.lng, LatLng.lat];
                }
                return [LatLng.lng, LatLng.lat];
            });
            _coords.push(primeraPosicion);
            coordenadas.push(_coords);
            this.RevisarPuntosEnPoligono(coordenadas);
        });
    }

    _onDeleted = (ev) => {
        // console.log(ev);
        //Reiniciando Elementos Seleccionados        
        let gpsSuministros = this.state.ListaSuministros;
        for (let x = 0; x < gpsSuministros.length; x++) {
            gpsSuministros[x].seleccionado = false;
        }
        this.setState({ ListaSuministros: gpsSuministros, ListaMarcadoresSeleccionados: [] });
    }

    _eliminarPoligonos = () => {
        let _layers = this.featureGroupRef.current._layers;
        Object.keys(_layers).forEach((layerid, index) => {
            _layers[layerid].remove();
        });
    }
    DescargaraExcel = async () => {
        //Descargar Informacion
        if (this.state.ListaSuministros.length > 0) {
            await CreateJsonToExcel(this.state.ListaSuministros);
        } else {
            this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para Exportar", tipotoast: 'warning' });
        }
    }

    render() {
        return (
            <Fragment>
                <Snackbar
                    open={this.state.MostrarPorcentajeCarga}
                    message={this.state.mensaCargaProgramacion}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo="MAPA" regresar={() => { this.props.history.push('/dashboard') }}></TituloPagina>


                <div className="container-fluid">
                    <div className="row m-1" >
                        <div className="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-3  overflow-auto" style={{ maxHeight: '88vh' }}>
                            <div className="border p-1">
                                <div className="text-center">
                                    {/* <i onClick={() => { }} title="Editar Mapeo" className="fas fa-edit mx-2 iconoAcciones" ></i> */}
                                    <i onClick={() => { this.EliminarDatosCargados() }} title="Eliminar Datos Cargados" className="far fa-trash-alt mx-2 iconoAcciones"></i>
                                    {/* <i onClick={() => { }} title="Quitar Mapeo" className="fas fa-unlink mx-2 iconoAcciones" ></i> */}
                                    <i onClick={() => { this.MapearTodosSuministros() }} title="Mapear Todos" className="fas fa-draw-polygon mx-2 iconoAcciones"></i>
                                    <i onClick={() => { this._eliminarPoligonos() }} title="Borrar Poligono" className="fas fa-eraser mx-2 iconoAcciones"></i>
                                </div>
                                {/* Cargar */}
                                {
                                    this.state.ListaSuministrosGps.length > 0 ?
                                        <></> :
                                        <div className="my-1">
                                            <div className="">
                                                <input ref={this.inputFileSubirArchivo} className="form-control form-control-sm" type="file" id="formFile" />
                                            </div>
                                            <div className="d-grid mt-1">
                                                <button onClick={() => {
                                                    this.CargaArchivoXLS();
                                                }} className=" btn btn-sm btn-outline-primary  ">
                                                    <i className="fas fa-file-upload p-1"></i>
                                                    <span>Cargar Archivo</span>
                                                </button>

                                            </div>
                                        </div>
                                }

                                {
                                    this.state.ListaDatosCargados.length > 0 ?
                                        <div>
                                            <small className="text-secondary">{this.state.ListaDatosCargados.length} registros cargados.</small>
                                        </div>
                                        : <></>


                                }
                                <div className="d-grid mt-2">
                                    <button onClick={() => { this.AsignarMarcadores() }} className="btn btn-sm btn-primary">
                                        <i className="fas fa-link mx-1"></i>
                                        {
                                            this.state.ListaMarcadoresSeleccionados.length > 0 ?
                                                `Asignar ${this.state.ListaMarcadoresSeleccionados.length} suministros selecionados`
                                                : `Elija los Trabajos a Asignar`
                                        }
                                    </button>
                                </div>

                                <div className="text-center mt-2">
                                    <i onClick={() => { this.DescargaraExcel() }} title="Exportar a Excel" className="fas fa-file-excel mx-2 iconoAcciones" ></i>
                                </div>

                            </div>

                            {/* Asignaciones*/}
                            {
                                this.state.ListaTrabajadoresAsignados.length > 0 ?
                                    <div className="border border-info my-1 p-1">
                                        <div className="text-center">
                                            <strong>Asignaciones</strong>
                                        </div>
                                        {/* {
                                            this.state.ListaSuministrosSinAsignar.length > 0 ?
                                                <div>
                                                    <small className="text-secondary">{`${this.state.ListaSuministrosSinAsignar.length} suministros sin asignar`}</small>
                                                </div>
                                                : <></>
                                        } */}
                                        <div>
                                            <small>{this.state.cantidadMarcadoresSinAsignar} Trabajos sin Asignar</small>
                                        </div>
                                        <TableContainer style={{ maxHeight: '500px' }}>
                                            <Table size='small' stickyHeader  >
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell>[Asignado] Nombre</TableCell>

                                                        <TableCell>Acciones</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody >
                                                    {this.state.ListaTrabajadoresAsignados.map((row, index) => {
                                                        // if (row.idpersonalasignado != null)
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell style={{ fontSize: '0.75em' }}><img src={this.state.ListaImagenesPines[row.marcador]} style={{ height: 24 }}></img> {`[${row.cantidad}] ${row.nombre}`}</TableCell>
                                                                <TableCell>
                                                                    <i onClick={() => { this.AbrirModalReasignarMarcadores(row) }} title="Reasignar" className="fas fa-exchange-alt mx-2 iconoAcciones"></i>
                                                                    <i onClick={() => { this.MapearSuministrosTrabajador(row) }} title="Mapear" className="fas fa-route mx-2 iconoAcciones"></i>
                                                                    <i onClick={() => { this.AbrirModalLiberarSuministros(row) }} title="Liberar" className="fas fa-unlock-alt mx-2 iconoAcciones"></i>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    : <></>

                            }

                        </div>

                        <div className="col-12 col-sm-7 col-md-7 col-lg-8 col-xl-9">
                            <MapContainer center={this.state.centro} zoom={this.state.zoomMapa} style={{ height: `88vh` }}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <FeatureGroup ref={this.featureGroupRef}>
                                    <EditControl
                                        position="topright"
                                        onCreated={this._onCreate}
                                        onEdited={this._onEdited}
                                        onDeleted={this._onDeleted}
                                        draw={{
                                            rectangle: false,
                                            polyline: false,
                                            circlemarker: false,
                                            marker: false,
                                            circle: false
                                        }} />
                                </FeatureGroup>

                                {

                                    this.state.ListaSuministrosGps.map((row, index) => {
                                        // console.log(row);    
                                        
                                        if(row[this.state.nombreColumnaLatitud] && row[this.state.nombreColumnaLongitud]){
                                            return (
                                                row.nombreAsignado == null ?
                                                    <Marker eventHandlers={{ click: () => { this.SeleccionarMarcador(index, row) } }} key={index} title="" position={[row[this.state.nombreColumnaLatitud], row[this.state.nombreColumnaLongitud]]} icon={row.seleccionado ? IconoWorkSelect : IconoWorkFree}>
                                                        {/* <Tooltip opacity={0.8}>
                                                            <div>
                                                                <small className="text-primary">Suministro :</small><span>{row[this.state.nombreColumnaTitulo]}</span>
                                                            </div> */}
                                                        {/* <div>
                                                                <small className="text-primary">Tipo :</small><span>{row.tipotrabajo}</span>
                                                            </div>
                                                            <div>
                                                                <small className="text-primary">Asignado :</small><span>{row.nombretrabajador}</span>
                                                            </div> */}
                                                        {/* </Tooltip> */}
                                                        <Tooltip opacity={0.8}>
                                                            <div>
                                                                {/* <small className="text-primary">{row[this.state.nombreColumnaTitulo]}</small> */}
                                                                {/* <small className="text-primary">{this.state.nombrecolumnaTitulo} :</small><span>{row[this.state.nombreColumnaTitulo]}</span> */}
                                                                <small>{row[this.state.nombreColumnaTitulo]}</small>
                                                            </div>
    
                                                        </Tooltip>
                                                    </Marker>
    
                                                    :
                                                    <Marker eventHandlers={{ click: () => { this.SeleccionarMarcador(index, row) } }} key={index} title="" position={[row[this.state.nombreColumnaLatitud], row[this.state.nombreColumnaLongitud]]} icon={row.marker ? this.state.ListaPines[row.marker] : this.state.ListaPines[0]}>
                                                        <Tooltip opacity={0.8} >
                                                            <div>
                                                                {/* <small className="text-primary">{this.state.nombrecolumnaTitulo} :</small><span>{row[this.state.nombreColumnaTitulo]}</span> */}
                                                                <small>{row[this.state.nombreColumnaTitulo]}</small>
                                                            </div>
                                                            {/* <div>
                                                                <small className="text-primary">Tipo :</small><span>{row.tipotrabajo}</span>
                                                            </div>
                                                            <div>
                                                                <small className="text-primary">Asignado :</small><span>{row.nombretrabajador}</span>
                                                            </div> */}
                                                        </Tooltip>
                                                    </Marker>  
    
    
                                            );
                                        }
                                    })
                                }

                            </MapContainer>
                        </div>
                    </div>
                </div>

                {/* Modal Elegir Nombre Columnas Lat Lng */}
                <Modal
                    show={this.state.MostarModalElegirNombreColumna}
                    onHide={() => { this.CerrarModalElegirNombreColumna(false) }}
                    keyboard={false}
                    centered
                    size='md'
                // backdrop="static"
                >
                    <Modal.Body>
                        <div className='mb-2 fw-bold'>
                            <label>Selecciona columnas para colocar las marcas de posicion</label>
                        </div>
                        <div className='fw-light mb-1'>
                            <small>Selecciona las columnas de tu archivo que nos indican dónde se deben colocar las marcas de posición en el mapa como, por ejemplo, direcciones o pares de latitud y longitud. Se importarán todas las columnas.</small>
                        </div>
                        <div className='border rounded overflow-auto p-1' style={{ maxHeight: 200 }}>
                            {
                                this.state.ListaNombresCabecera.map((row, index) => {
                                    // Revisar nombre de columna
                                    return (
                                        <div key={index} className="form-check" >
                                            <input id={index} onChange={(e) => { this.AbrirModalElegirOrigenLatLng(row) }} className="form-check-input" type="radio" name="origen" value={row} />
                                            <label className="form-check-label" htmlFor={index}>
                                                {row}
                                            </label>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className='fw-light mb-1'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col'>
                                        <strong>
                                            Latitud :
                                            <small className='text-primary'> {this.state.nombreColumnaLatitud}</small>
                                        </strong>

                                    </div>
                                    <div className='col'>
                                        <strong>
                                            Longitud :
                                            <small className='text-primary'> {this.state.nombreColumnaLongitud}</small>
                                        </strong>
                                    </div>
                                    <div className='col'>
                                        <strong>
                                            Titulo :
                                            <small className='text-primary'> {this.state.nombreColumnaTitulo}</small>
                                        </strong>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="row">
                                <div className="d-flex justify-content-between">
                                    <div >

                                        <button onClick={() => { this.CerrarModalElegirNombreColumna(true) }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                    </div>

                                    <div >
                                        {
                                            this.state.nombreColumnaLatitud != '' && this.state.nombreColumnaLongitud != '' && this.state.nombreColumnaTitulo != '' ?
                                                <button onClick={() => { this.MapearSuministros() }} className="btn btn-sm btn-primary mx-1 " >Continuar</button>
                                                :
                                                <button onClick={() => { }} className="btn btn-sm btn-primary mx-1 " disabled>Continuar</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>


                {/* Modal Elegir LatLng */}
                <Modal
                    show={this.state.MostarModalElegirOrigenLatLng}
                    onHide={() => { this.CerrarModalElegirOrigenLatLng() }}
                    keyboard={false}
                    centered
                    size='sm'
                    backdrop="static"
                >
                    {/* <Modal.Header >
                        <Modal.Title>{`Nombre Columna: ${this.state.nombreColumnaElegida}`}</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body>
                        <div>
                            {/* <small className='text-primary'>{`Nombre de columna : ${this.state.nombreColumnaElegida}`}</small> */}
                            <div className="form-check">
                                <input onChange={(e) => { this.elegirLatLngColumna(e, this.state.nombreColumnaElegida) }} className="form-check-input" type="radio" name="opciones" value="latitud" id="radioelegirmapear" />
                                <label className="form-check-label" htmlFor="radioelegirmapear">
                                    Latitud
                                </label>
                            </div>
                            <div className="form-check">
                                <input onChange={(e) => { this.elegirLatLngColumna(e, this.state.nombreColumnaElegida) }} className="form-check-input" type="radio" name="opciones" value="longitud" id="radioelegirorigen" />
                                <label className="form-check-label" htmlFor="radioelegirorigen">
                                    Longitud
                                </label>
                            </div>
                            <div className="form-check">
                                <input onChange={(e) => { this.elegirLatLngColumna(e, this.state.nombreColumnaElegida) }} className="form-check-input" type="radio" name="opciones" value="titulo" id="radioelegirtitulo" />
                                <label className="form-check-label" htmlFor="radioelegirtitulo">
                                    Titulo de Marcador
                                </label>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="row">
                                <div >
                                    <div >
                                        <button onClick={() => { this.CerrarModalElegirOrigenLatLng() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                    </div>

                                    {/* <div >
                                        <button onClick={() => { this.CerrarModalElegirOrigenLatLng() }} className="btn btn-sm btn-primary mx-1">Elegir</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>


                {/* Modal Agregar Nombre */}
                <Modal
                    show={this.state.MostrarModalAsignarMarcadores}
                    onHide={() => { this.CerrarModalAsignarMarcadores() }}
                    keyboard={false}
                    centered
                    size='md'
                    backdrop="static"
                >
                    {/* <Modal.Header >
                        <Modal.Title>{`Nombre Columna: ${this.state.nombreColumnaElegida}`}</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body>
                        <div>
                            <label className='text-primary'>Nombre del Agrupamiento</label>
                        </div>
                        <div>
                            <small className='my-1 fw-light'>
                                Ingrese el nombre o identificador del grupo de marcadores seleccionados, este nombre identificara a los marcadores y permitira mostrarlos y diferenciarlos de los marcadores no seleccionados.
                            </small>
                        </div>
                        <div className='mt-3'>
                            <small className="text-primary ">Nombre del Segmento Agrupado</small>
                            <input ref={this.inputTextNombreAgrupacion} type="text" className="form-control" id="floatingInput" placeholder="Nombre Agrupación" />
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="row">
                                <div className='d-flex justify-content-between'>
                                    <div >
                                        <button onClick={() => { this.CerrarModalAsignarMarcadores() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                    </div>

                                    <div >
                                        <button onClick={() => { this.validarModalAsignarMarcadores() }} className="btn btn-sm btn-primary mx-1">Asignar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Modal Reasignar Nombre */}
                <Modal
                    show={this.state.MostrarModalReasignarMarcadores}
                    onHide={() => { this.CerrarModalReasignarMarcadores() }}
                    keyboard={false}
                    centered
                    size='md'
                    backdrop="static"
                >
                    {/* <Modal.Header >
                        <Modal.Title>{`Nombre Columna: ${this.state.nombreColumnaElegida}`}</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body>
                        <div>
                            <label className='text-primary'>Reasignar Nombre del Agrupamiento</label>
                        </div>
                        <div>
                            <small className='my-1 fw-light'>
                                Ingrese el nombre o identificador del grupo de marcadores seleccionados, este nombre identificara a los marcadores y permitira mostrarlos y diferenciarlos de los marcadores no seleccionados.
                            </small>
                        </div>
                        <div className='mt-3'>
                            <small className="text-primary ">Nombre Actual del Agrupamiento</small>
                            <input type="text" className="form-control" placeholder="Nombre Agrupación" value={this.state.NombreAsignadoCambiar} disabled />
                        </div>

                        <div className='mt-1'>
                            <small className="text-primary ">Nombre Reasignado</small>
                            <input ref={this.inputTextNombreReasignarAgrupacion} type="text" className="form-control" id="floatingInput" placeholder="Nombre Agrupación" />
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="row">
                                <div className='d-flex justify-content-between'>
                                    <div >
                                        <button onClick={() => { this.CerrarModalReasignarMarcadores() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                    </div>

                                    <div >
                                        <button onClick={() => { this.validarModalReasignarMarcadores() }} className="btn btn-sm btn-primary mx-1">Asignar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Liberar Suministros  */}
                <Modal
                    show={this.state.MostrarLiberarTrabajos}
                    onHide={() => { this.CerrarModalLiberarSuministros() }}
                    keyboard={false}
                    centered
                >
                    <Modal.Header >
                        <Modal.Title>{`¿Liberar Asignaciones?`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <label>
                                    ¿Estas seguro de liberar <strong>{this.state.trabajadorSeleccionado.cantidad}</strong> elementos asignados a <strong>{this.state.trabajadorSeleccionado.nombre}</strong>?
                                </label>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="text-end">
                                <button onClick={() => { this.LiberarTrabajo() }} className="btn btn-sm btn-primary">Liberar</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Fragment>

        );
    }
}

export default Mapa;