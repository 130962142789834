import React, { Component, Fragment } from 'react';

class headerTitle extends Component {
    render() {    
        let {regresar}=this.props;
        return (
            <Fragment>
                <div className="container-fluid " style={{ backgroundColor: 'rgb(25,118,210)'}}>
                    <div className="container  ">
                            <div className="row py-1">
                                <div className="col-6 col-sm-6 col-md-8 ">
                                    <i title="Regresar" className="fas fa-tag  iconotitulo " style={{color:'aliceblue'}}></i>
                                    <span className="" style={{color:'aliceblue'}}> {this.props.titulo}</span>
                                </div>
                                <div className="col-6 col-sm-6 col-md-4 text-right text-end">
                                    <div onClick={regresar} className="iconoSalir ">
                                        {/* <i className="fas fa-sign-out-alt"></i> */}
                                        {/* <i class="far fa-arrow-alt-circle-left"></i> */}
                                        <i className="fas fa-arrow-left mx-1" style={{color:'aliceblue'}}></i>
                                        <span>Regresar</span>
                                    </div>
                                    
                                </div>
                            </div>
                        

                    </div>
                </div>
            </Fragment>
        );
    }
}

export default headerTitle;