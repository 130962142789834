import React, { Component } from 'react';


class notFound extends Component {    

    irHome=()=>{        
        this.props.history.push("/");            
    }

    render() {
        return (
            <div>
                <h2>Pagina No Encontrada</h2>
                {/* <Link className="btn btn-outline-secondary" to="/dashboard">Ir al Inicio</Link> */}
                <button className="btn btn-outline-secondary" onClick={()=>{this.irHome()}}>Ir al Inicio</button>
            </div>
        );
    }
}

export default notFound;
