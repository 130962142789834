import React, { Component, createRef, Fragment } from 'react';

import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import LoadingModal from './../utils/loadModal';
import SpinnerCarga from './../utils/spinner';

import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Snackbar } from '@material-ui/core';

import { Alert } from '@material-ui/lab'
import { Modal, ModalFooter } from 'react-bootstrap';


import config from './../../config/config'

import { ValidarUsuario, ObtenerUsuario, ReadExcelfileToJson, ValidarPermisos, LIST_SCREENS, listarPermisos } from './../utils/utils'

class CargarGps extends Component {

    constructor(props) {
        super(props)
        this.state = {
            OcultarLoading: true,
            OcultarSpinnerCarga: true,
            MostrarPorcentajeCarga: false,
            MostrarModalCargarGps: false,
            MostrarModalEliminarZonaSector: false,
            MostrarModalEliminarTodoZonaSector: false,
            MostrarModalVerZonaSector: false,
            ListaZonasSector: [],
            ListaLibros: [],
            ZonaSectorSeleccionado: {}
        };

        this.headCells = [
            { id: 'Zona-Sector' },
            { id: 'Total Suministros' },
            { id: 'Ver' },
            { id: 'Eliminar' }
        ];

        this.inputFileSubirArchivo = createRef();
    }

    async componentDidMount() {
        if (ValidarUsuario()) {

            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CARGAR_GPS, permisos)) {

                    this.BuscarZonaSector().then(() => {
                        this.setState({ loadData: true });
                    });


                } else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }

            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    CargarArchivoGps = async () => {
        if (this.inputFileSubirArchivo.current.files.length > 0) {
            this.inputFileSubirArchivo.current.classList.remove('is-invalid');
            this.setState({ OcultarSpinnerCarga: false });
            // Leer Archivo Excel
            let DataExcel = null;
            await ReadExcelfileToJson(this.inputFileSubirArchivo.current.files[0]).then(async (respuesta) => {
                DataExcel = respuesta;
                // console.log(respuesta);
            }).catch((error) => {
                // console.log(error);
                DataExcel = [];
                this.setState({ mensajeToast: "Error al cargar el archivo.", abrirToast: true, tipotoast: 'error' });
            });

            // Buscando Elementos Necesarios
            if (DataExcel.length > 0) {
                let arrayDatos = [];

                for (let x = 0; x < DataExcel.length; x++) {
                    let nuevaFila = {};
                    for (var clave in DataExcel[x]) {
                        //Cargar Tipo Trabajo elegido                                    

                        //Verificando Clave Existe
                        if (DataExcel[x].hasOwnProperty(clave)) {
                            //Verificando si la Clave es Cabecera o Material 
                            switch (clave) {
                                case 'zonasector':
                                    nuevaFila.zonasector = DataExcel[x][clave];
                                    break;
                                case 'libro':
                                    nuevaFila.libro = DataExcel[x][clave];
                                    break;
                                case 'muestra':
                                    nuevaFila.muestra = DataExcel[x][clave];
                                    break;
                                case 'contrato':
                                    nuevaFila.contrato = DataExcel[x][clave];
                                    break;
                                case 'codigo':
                                    nuevaFila.codigo = DataExcel[x][clave];
                                    break;
                                case 'tarifa':
                                    nuevaFila.tarifa = DataExcel[x][clave];
                                    break;
                                case 'nombres':
                                    nuevaFila.nombres = DataExcel[x][clave];
                                    break;
                                case 'direccion':
                                    nuevaFila.direccion = DataExcel[x][clave];
                                    break;
                                case 'direccionentrega':
                                    nuevaFila.direccionentrega = DataExcel[x][clave];
                                    break;
                                case 'provincia':
                                    nuevaFila.provincia = DataExcel[x][clave];
                                    break;
                                case 'distrito':
                                    nuevaFila.distrito = DataExcel[x][clave];
                                    break;
                                case 'sed':
                                    nuevaFila.sed = DataExcel[x][clave];
                                    break;
                                case 'recibo':
                                    nuevaFila.recibo = DataExcel[x][clave];
                                    break;
                                case 'lat':
                                    let _valorLat = parseFloat(DataExcel[x][clave]);
                                    if (isNaN(_valorLat) || _valorLat == 0) {
                                        nuevaFila.lat = 0;
                                    }
                                    else {
                                        nuevaFila.lat = _valorLat;
                                    }

                                    break;
                                case 'lng':
                                    let _valorLng = parseFloat(DataExcel[x][clave]);
                                    if (isNaN(_valorLng) || _valorLng == 0) {
                                        nuevaFila.lng = 0;
                                    }
                                    else {
                                        nuevaFila.lng = _valorLng;
                                    }
                                    break;
                            }
                        }
                    }
                    arrayDatos.push(nuevaFila);
                }

                if (arrayDatos.length > 0) {
                    // buscar elementos dusplicados
                    // console.log("Buscando Duplicados");
                    let arrayDuplicados = [];
                    for (let y = 0; y < arrayDatos.length; y++) {
                        let _busqueda = arrayDuplicados.filter(x => x == arrayDatos[y].zonasector);
                        if (_busqueda.length == 0) {
                            arrayDuplicados.push(arrayDatos[y].zonasector);
                        }
                    }
                    // Eliminar Elmentos Duplicados
                    // console.log("Eliminar Elementos Existentes");

                    for (let elm = 0; elm < arrayDuplicados.length; elm++) {
                        // console.log(`Eliminado elemento ${elm}`);
                        await this.Primise_EliminarZonaSector(arrayDuplicados[elm]).then();
                    }

                    // Dividir Elementos en segmentos
                    // console.log("Dividir Elementos en Segmentos");
                    let tamanioDivision = 5000;
                    let result = new Array(Math.ceil(arrayDatos.length / tamanioDivision)).fill().map(_ => arrayDatos.splice(0, tamanioDivision));
                    this.CargarBaseZonaSector(result);
                }
            }
            else {
                this.setState({ mensajeToast: "No se encontraron registros en el archivo", abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
            }

        }
        else {
            this.inputFileSubirArchivo.current.classList.add('is-invalid');
            this.setState({ abrirToast: true, mensajeToast: "No se cargo Archivos.", tipotoast: 'warning' });
        }
    }

    CargarBaseZonaSector = (baseCargar) => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/basesuministrosreparto/cargarbase`
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({ base: baseCargar })
            };

            fetch(url, headers).then((resp) => {
                return resp.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: `Se cargaron ${data.content} elementos.`, tipotoast: 'success' });
                    this.CerrarModarCargarGPs();
                    this.BuscarZonaSector().then();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }

    }


    BuscarZonaSector = () => {

        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/basesuministrosreparto/listaSectoresDetalle`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        this.setState({ ListaZonasSector: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaZonasSector: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }

    AbrirModarCargarGPs = () => {
        this.setState({ MostrarModalCargarGps: true, OcultarSpinnerCarga: true });
    }

    CerrarModarCargarGPs = () => {
        this.setState({ MostrarModalCargarGps: false });
    }

    AbrirModalEliminarZonaSector = (row) => {

        this.setState({ MostrarModalEliminarZonaSector: true, ZonaSectorSeleccionado: row });
    }

    CerrarModalEliminarZonaSector = () => {
        this.setState({ MostrarModalEliminarZonaSector: false });
    }

    EliminarZonaSector = (zonasector) => {

        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/basesuministrosreparto/eliminarZonaSector`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify({
                    zonasector: zonasector
                })
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Eliminado Correctamente", tipotoast: 'success' });
                    this.CerrarModalEliminarZonaSector();
                    this.BuscarZonaSector();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning' });
            });
        }
        else {

            this.props.history.push('/login');
        }
    }

    Primise_EliminarZonaSector = (zonasector) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/basesuministrosreparto/eliminarZonaSector`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({
                        zonasector: zonasector
                    })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }

                }).catch(() => {
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });

    }



    AbrirModalEliminarTodoZonaSector = (row) => {

        this.setState({ MostrarModalEliminarTodoZonaSector: true });
    }
    CerrarModalEliminarTodoZonaSector = () => {

        this.setState({ MostrarModalEliminarTodoZonaSector: false });
    }
    EliminarTodo = (zonasector) => {

        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/basesuministrosreparto/eliminarTodoZonaSector`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                if (data.content) {
                    this.setState({ abrirToast: true, mensajeToast: "Eliminado Correctamente", tipotoast: 'success' });
                    this.CerrarModalEliminarTodoZonaSector();
                    this.BuscarZonaSector();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }

            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'warning' });
            });
        }
        else {

            this.props.history.push('/login');
        }
    }
    AbrirModalMostrarZonaSector = async (row) => {
        await this.setState({ OcultarLoading: false });
        this.VerZonaSector(row.zonasector).then(() => {
            this.setState({ MostrarModalVerZonaSector: true, ZonaSectorSeleccionado: row });
            this.setState({ OcultarLoading: true });
        }).catch(() => {
            this.setState({ OcultarLoading: true });
        });
    }
    CerrarModalMostrarZonaSector = () => {
        this.setState({ MostrarModalVerZonaSector: false });
    }

    VerZonaSector = async (zonasector) => {
        return new Promise((resolve, reject) => {
            this.setState({ ListaLibros: [] });

            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/basesuministrosreparto/listaLibrosDetalle`;

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({ zonasector: zonasector })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaLibros: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaLibros: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });

    }

    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    render() {
        return (
            <Fragment>
                <Snackbar
                    open={this.state.MostrarPorcentajeCarga}
                    message={this.state.mensaCargaProgramacion}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo="CARGA GPS REPARTO" regresar={() => { this.props.history.push('/') }}></TituloPagina>



                {/* Contenedor de Datos */}
                <div className="container border mt-1">
                    <div className="row mt-1">
                        <div className="col-12 ">
                            <button onClick={() => { this.AbrirModarCargarGPs() }} className=" btn btn-sm btn-outline-secondary " style={{ width: '130px' }}><i className="fas fa-upload mx-1"></i> Cargar GPS</button>
                            <button onClick={() => { this.AbrirModalEliminarTodoZonaSector() }} className=" btn btn-sm btn-outline-danger ms-1" style={{ width: '130px' }}><i className="fa fa-eraser mx-1"></i> Eliminar Todo</button>
                        </div>

                    </div>
                    <div className="row p-1">
                        {
                            this.state.loadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer style={{ maxHeight: 440 }}>
                                        <Table size='small' stickyHeader>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    {
                                                        this.headCells.map((head, index) => {
                                                            return (
                                                                <TableCell key={index}>{head.id}</TableCell>
                                                            );
                                                        })
                                                    }

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.ListaZonasSector.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>{row.zonasector}</TableCell>
                                                            <TableCell>{row.Total}</TableCell>
                                                            <TableCell onClick={() => { this.AbrirModalMostrarZonaSector(row) }}>
                                                                <i title="Ver" className="fas fa-eye  mx-2 iconoAcciones" ></i>
                                                                <small style={{ cursor: 'pointer' }}>Ver</small>
                                                            </TableCell>
                                                            <TableCell onClick={() => { this.AbrirModalEliminarZonaSector(row) }}>
                                                                <i title="Ver" className="fa fa-trash  mx-2 iconoAcciones" ></i>
                                                                <small style={{ cursor: 'pointer' }}>Eliminar</small>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div className="mt-3">
                                        <small >{`${this.state.ListaZonasSector.length} Elemento(s) Encontrado(s)`}</small>
                                    </div>

                                </>
                                : <SpinnerCarga />

                        }

                    </div>
                </div>


                {/* Modales */}

                <Modal
                    show={this.state.MostrarModalCargarGps}
                    onHide={() => { this.CerrarModarCargarGPs() }}
                    keyboard={false}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Cargar GPS</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="container">

                            <div className="row my-1">
                                <small className='text-secondary'>Agregue el archivo con la lista de GPS a cargar, el archivo debe contener las columnas (zonasector, libro, muestra, contrato, codigo, tarifa, nombres, direccion, direccionentrega, provincia, distrito, sed, recibo, lat, lng)</small>
                            </div>
                            <div className="row my-1">
                                <div className="col">
                                    <input ref={this.inputFileSubirArchivo} className="form-control form-control-sm" type="file" id="formFile" />
                                </div>

                            </div>

                            {/* <div className="row my-1">
                                <small className='text-primary'>Se encontraron 0 elementos</small>
                            </div> */}

                            <div className='row'>
                                <div className="text-center">

                                    {
                                        this.state.OcultarSpinnerCarga ?

                                            <button onClick={() => { this.CargarArchivoGps() }} className=" btn btn-sm btn-outline-secondary  ">
                                                <i className="fas fa-file-upload p-1"></i>
                                                <span>Cargar Archivo</span>
                                            </button>
                                            :
                                            <div className='mt-2'>
                                                <span className="spinner-border text-secondary ms-1" role="status" aria-hidden="true"></span>
                                                <span className='text-secondary ms-1'>Cargando ...</span>
                                            </div>
                                    }
                                </div>
                            </div>
                            {/* {
                                this.state.OcultarSpinnerCarga ?
                                    <></>
                                    :
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>

                            } */}
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container-fluid">
                            <div className="row">
                                <div className=" text-end">
                                    <button onClick={() => { this.CerrarModarCargarGPs() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </Modal>

                {/* Modal Confirmacion Elimiar Zona Sector */}
                <Modal
                    show={this.state.MostrarModalEliminarZonaSector}
                    onHide={() => { this.CerrarModalEliminarZonaSector() }}
                    keyboard={false}
                    centered>
                    <Modal.Body>
                        <div>
                            <span>
                                {
                                    `
                                    Estas tratando de eliminar la Zona-Sector ${this.state.ZonaSectorSeleccionado.zonasector}, si eliminas esta Zona-Sector los datos se perderan y tendras que volver a subir nuevamente los datos cargados. 
                                    ¿Estas seguro de eliminar la Zona-Sector ${this.state.ZonaSectorSeleccionado.zonasector} ?                                
                                    `
                                }
                            </span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col'>
                                    <button className='btn btn-sm btn-outline-secondary' onClick={() => { this.CerrarModalEliminarZonaSector() }}>Cerrar</button>
                                </div>
                                <div className='col text-end'>
                                    <button className='btn btn-sm btn-outline-primary' onClick={() => { this.EliminarZonaSector(this.state.ZonaSectorSeleccionado.zonasector) }}>Eliminar</button>
                                </div>
                            </div>
                        </div>

                    </Modal.Footer>
                </Modal>

                {/* Modal Confirmacion Elimiar Todo*/}
                <Modal
                    show={this.state.MostrarModalEliminarTodoZonaSector}
                    onHide={() => { this.CerrarModalEliminarTodoZonaSector() }}
                    keyboard={false}
                    centered>
                    <Modal.Body>
                        <div>
                            <span>
                                {
                                    `
                                    Estas tratando de eliminar todos los datos, si eliminas todo tendras que volver a cargar los datos.\n
                                    ¿Estas seguro de eliminar ?                                
                                    `
                                }
                            </span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col'>
                                    <button className='btn btn-sm btn-outline-secondary' onClick={() => { this.CerrarModalEliminarTodoZonaSector() }}>Cerrar</button>
                                </div>
                                <div className='col text-end'>
                                    <button className='btn btn-sm btn-outline-primary' onClick={() => { this.EliminarTodo() }}>Eliminar</button>
                                </div>
                            </div>
                        </div>

                    </Modal.Footer>
                </Modal>

                {/* Modal Mostrar Zona Sector*/}
                <Modal
                    show={this.state.MostrarModalVerZonaSector}
                    onHide={() => { this.CerrarModalMostrarZonaSector() }}
                    keyboard={false}
                    centered>
                    <Modal.Header>
                        <Modal.Title>Detalle {this.state.ZonaSectorSeleccionado.zonasector}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TableContainer style={{ maxHeight: 400 }}>
                            <Table size='small' stickyHeader>
                                <TableHead>
                                    <TableRow className="fondoTituloTabla">
                                        <TableCell >Libro</TableCell>
                                        <TableCell >Cantidad</TableCell>
                                        <TableCell >*</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.ListaLibros.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{row.libro}</TableCell>
                                                <TableCell>{row.Total}</TableCell>
                                                <TableCell onClick={() => { }}>
                                                    <i title="Ver" className="fas fa-trash  mx-2 iconoAcciones" ></i>
                                                    <small style={{ cursor: 'pointer' }}>Eliminar</small>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="mt-3">
                            <small >{`${this.state.ListaLibros.length} Elemento(s) Encontrado(s)`}</small>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col text-end'>
                                    <button className='btn btn-sm btn-outline-secondary' onClick={() => { this.CerrarModalMostrarZonaSector() }}>Cerrar</button>
                                </div>
                            </div>
                        </div>

                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}

export default CargarGps;