import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { ObtenerUsuario, listarPermisos, LIST_SCREENS, ValidarUsuario,ValidarPermisos } from './../utils/utils';
import config from './../../config/config'
import { BorrarStorage } from './../utils/utils'


class navBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            usuario: {},
            permisos: []
        }

    }
    componentDidMount() {
        if(ValidarUsuario()){
            this.LlenarUsuario();
            listarPermisos().then((permisos) => {
                this.setState({ permisos: permisos });
            });
        }
    }

    LlenarUsuario = () => {
        let usuario = ObtenerUsuario();
        //    console.log("pidiendo usuario");       
        if (usuario) {
            usuario = JSON.parse(usuario);
            this.setState({
                usuario: {
                    usuario: usuario.nombretrabajador,
                    tipousuario: usuario.tiposesion
                }
            })
        }
        else {
            this.setState({
                usuario: {
                    usuario: "No User",
                    tipousuario: "No Session"
                }
            })
        }
    }
    CerrarSesion = () => {
        // console.log(window.location);
        BorrarStorage(config.storageName);
        window.location.replace(`${window.location.origin}`);
    }

    render() {
        return (
            <Fragment>
                <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: 'rgb(0,75,160)' }}>
                    <div className="container-fluid">
                        <button className="navbar-toggler text-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon " ></span>
                        </button>
                        <div className="collapse navbar-collapse " id="navbarNav">
                            <Link className="navbar-brand " to="/">
                                <img src="https://cdn2.iconfinder.com/data/icons/hard-work-colored/512/electrician-256.png" alt="" width="25" height="25" />
                            </Link>
                            <ul className="navbar-nav ">
                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_HOME, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link linknavbar" aria-current="page" to="/" >Inicio</Link>
                                        </li>
                                        : <></>
                                }

                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_PROGRAM, this.state.permisos) ?
                                        <li className="nav-item linknavbar">
                                            <Link className="nav-link" to="/programacion" >Programación</Link>
                                        </li>
                                        : <></>
                                }

                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BUSCAR, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/buscar" >Buscar</Link>
                                        </li>
                                        : <></>
                                }

                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/monitoreo" >Monitoreo</Link>
                                        </li>
                                        : <></>
                                }

                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ALMACEN, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/almacen" >Almacén</Link>
                                        </li>
                                        : <></>
                                }

                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/configuracion" >Configuración</Link>
                                        </li>
                                        : <></>
                                }

                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_ESTADISTICA, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/estadistica" >Estadisticas</Link>
                                        </li>
                                        : <></>
                                }
                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BUSCAR_UBICACION, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/ubicaciones" >Ubicaciones</Link>
                                        </li>
                                        : <></>
                                }

                                {
                                    ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MAPA, this.state.permisos) ?
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/mapa" >Mapa</Link>
                                        </li>
                                        : <></>
                                }

                            </ul>
                        </div>
                        <div>
                            <div className="btn-group ">
                                <button type="button" className="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: 'white' }}>
                                    <span style={{ color: 'white' }}>
                                        {this.state.usuario.usuario}
                                        {/* <small style={{color:'gray'}}> ({this.state.usuario.tipousuario})</small> */}
                                    </span>
                                    <i className="mx-1 far fa-user " style={{ color: 'white' }}></i>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    {/* <li><button className="dropdown-item " type="button">Cambiar Contraseña</button></li>
                                    <li><button className="dropdown-item" type="button">Ver Perfil</button></li> */}
                                    {/* <li><hr className="dropdown-divider" /></li> */}
                                    <li><button className="dropdown-item" type="button" onClick={() => { this.CerrarSesion() }}>Cerrar Sesión</button></li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </nav>



            </Fragment>

        );
    }
}

export default navBar;