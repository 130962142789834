import React, { Component, createRef } from 'react';
import { Fragment } from 'react';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import LoadingModal from './../utils/loadModal';
import SpinnerCarga from './../utils/spinner';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { Modal } from 'react-bootstrap';

import { ValidarUsuario, ObtenerUsuario, ValidarPermisos,listarPermisos,LIST_SCREENS } from './../utils/utils';
import config from './../../config/config';


class configPermisos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            TiposUsuario: [],
            ListaPantallas: [],
            TipoUsuarioActual:null,
            PermisosTipoUsuario:[],
            MostrarModalPermisos: false,
        };
        this.headCells = [
            { id: 'Acciones' },
            { id: 'Tipo Usuario' },
            { id: 'Permisos' },
            { id: 'Estado' }
        ];

        //Referencias
        this.inputBuscar = createRef();

        //Referencias Modal Permisos
        this.inputTipoUsuario = createRef();
        this.selectPantallas = createRef();
    }
    async componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async(permisos)=>{
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_PERMISOS,permisos)) {
                    // Cargar Tipos de Usuario                
                    await this.listarTiposUsuario();
                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/configuracion');
                    }, 1000);
                }
            });
        }
        else {
            this.props.history.push('/login');
        }

    }
    listarTiposUsuario = () => {
        if (ValidarUsuario()) {
            let url;
            if (this.inputBuscar.current.value.trim() === '') {
                url = `${config.endPoint}api/tipousuario/listar`;
            }
            else {
                url = `${config.endPoint}api/tipousuario/listar/${this.inputBuscar.current.value.trim()}`;
            }

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ TiposUsuario: data.content, loadData: true });
                }
                else {
                    this.setState({ TiposUsuario: [], loadData: true });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    listarPantallas = () => {        
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/pantalla/listar`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ ListaPantallas: data.content, loadData: true });
                }
                else {
                    this.setState({ ListaPantallas: [], loadData: true });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    listarPermisos = () => {
        
        return new Promise((resolve,reject)=>{
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/permiso/listar/${this.state.TipoUsuarioActual.idtipousuario}`;    
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        
                        this.setState({ PermisosTipoUsuario: data.content, loadData: true });
                        resolve(true);
                    }
                    else {
                        this.setState({ PermisosTipoUsuario: [], loadData: true });
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                this.props.history.push('/login');
                reject(true)
            }
        });        
       
    }
    //*************************************** */
    //MODAL PERMISOS
    //*************************************** */
    AbrirModalPermisos = async(tipousuario) => {
        //Cargar Lista Pantallas
        await this.setState({OcultarLoading:false,TipoUsuarioActual:tipousuario});        
        await this.listarPantallas(); 
        //Buscar Permisos
        await this.listarPermisos().then(async()=>{
            await this.setState({MostrarModalPermisos:true});        
            this.inputTipoUsuario.current.value=tipousuario.tipoUsuario;
        });

        await this.setState({OcultarLoading:true});
    }
    CerrarModalPermisos = () => {
        this.setState({MostrarModalPermisos:false});
        this.listarTiposUsuario();
    }
    AgregarPantallaTipoUsuario=()=>{
        if (ValidarUsuario()) {
            if (this.selectPantallas.current.value.trim() !== 'TODOS') {
                this.selectPantallas.current.classList.remove('is-invalid');

                let url = `${config.endPoint}api/permiso/agregar`; 
                    let body_permisos = {
                        idpantalla: this.selectPantallas.current.value,
                        idtipousuario: this.state.TipoUsuarioActual.idtipousuario,
                        estado: 'ACTIVO'
                    }
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(body_permisos)
                    };
                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then((data) => {
                        if (data.content) {                            
                            this.setState({ abrirToast: true, mensajeToast: "Permiso Agregado", tipotoast: 'success' }); 
                            this.listarPermisos();                           
                        }
                        else {
                            this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        }

                    }).catch(() => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });
            }
            else {
                this.selectPantallas.current.classList.add('is-invalid');
                this.setState({ abrirToast: true, mensajeToast: "Seleccione una Pantalla de la lista", tipotoast: 'warning' });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    CambiarEstadoPermisoPantalla=(idpermiso, estado)=>{
        if (ValidarUsuario()) {
            
                let url = `${config.endPoint}api/permiso/modificar`; 
                let body_permisos = {
                    idpermiso: idpermiso, 
                    estado:estado                   
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(body_permisos)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {                            
                        this.setState({ abrirToast: true, mensajeToast: "Permiso Actualizado", tipotoast: 'success' }); 
                        this.listarPermisos();                           
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
            
        }
        else {
            this.props.history.push('/login');
        }
    }
    EliminarPermiso=(permiso)=>{
        if (ValidarUsuario()) {
            if(window.confirm(`¿Desea Eliminar el permiso asociado a la Pantalla [${permiso.t_pantalla.pantalla}]?`)){
                let url = `${config.endPoint}api/permiso/eliminar`; 
                let body_permisos = {
                    idpermiso: permiso.idpermiso,                        
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(body_permisos)
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {                            
                        this.setState({ abrirToast: true, mensajeToast: "Permiso Eliminado", tipotoast: 'success' }); 
                        this.listarPermisos();                           
                    }
                    else {
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    //*************************************** */
    //MODAL AGREGAR TIPO USUARIO
    //*************************************** */
    AbrirModalAgregarTipoUsuario = () => {

    }
    CerrarModalAgregarTipoUsuario = () => {
    }    
    //*************************************************
    //Funciones InputBuscar
    //*************************************************
    CambiarInputBuscar = async () => {
        if (this.inputBuscar.current.value.trim() === '') {
            await this.listarTiposUsuario();
        }
    }
    KeyEnterInputBuscar = async (ev) => {
        if (ev.key === "Enter") {
            await this.listarTiposUsuario();
        }
    }

    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    render() {
        return (
            <Fragment>
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>
                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar />
                <TituloPagina titulo="CONFIGURACIÓN - PERMISOS" regresar={() => { this.props.history.push('/configuracion') }} />

                {/* Contenedor de Datos */}
                <div className="container border mt-1">
                    <div className="row mt-1">
                        <div className=" col-8 col-md-4">
                            <input onChange={() => { this.CambiarInputBuscar() }} onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} ref={this.inputBuscar} className="form-control form-control-sm" placeholder="Buscar Tipo Usuario"></input>
                        </div>
                        <div className="col-4 col-md-4 ">
                            <button onClick={() => { this.listarTiposUsuario() }} className="btn btn-sm btn-outline-secondary"><i className="fas fa-search p-1"></i>Buscar</button>
                        </div>

                        <div className="col-12 col-md-4 text-md-end mt-md-0 mt-1">
                            <button onClick={() => { this.AbrirModalAgregarTipoUsuario(null, "Agregar") }} className=" btn btn-sm btn-outline-secondary " style={{ width: '130px' }}><i className="fas fa-upload mx-1"></i> Agregar</button>
                        </div>

                    </div>
                    <div className="row p-1">
                        {
                            this.state.loadData ?
                                <>
                                    {/* Tabla Aqui */}
                                    <TableContainer>
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow className="fondoTituloTabla">
                                                    {
                                                        this.headCells.map((head, index) => {
                                                            return (
                                                                <TableCell style={{ color: 'aliceblue' }} key={index}>{head.id}</TableCell>
                                                            );
                                                        })
                                                    }

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.TiposUsuario.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <i onClick={() => { this.AbrirModalAgregarTipoUsuario(row.idtipousuario, "Ver") }} title="Ver" className="fas fa-eye  mx-2 iconoAcciones" ></i>
                                                            </TableCell>
                                                            <TableCell>{row.tipoUsuario}</TableCell>
                                                            <TableCell onClick={() => { this.AbrirModalPermisos(row) }} className="iconoAcciones">
                                                                {<i title="Materiales Incluidos" className="fas fa-screwdriver iconoAcciones"></i>}
                                                                <span >{`(${row.t_permisos.length}) Permiso(s) Incluido(s)`}</span>
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.estado.toUpperCase() === 'ACTIVO' ?
                                                                    <span className="estadoActivo">{row.estado.toUpperCase()}</span> :
                                                                    <span className="estadoInactivo">{row.estado.toUpperCase()}</span>}

                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div className="mt-3">
                                        <small >{`${this.state.TiposUsuario.length} Elemento(s) Encontrado(s)`}</small>
                                    </div>

                                </>
                                : <SpinnerCarga />
                        }

                    </div>
                </div>

                {/* Modal Permisos */}

                <Modal
                    show={this.state.MostrarModalPermisos}
                    onHide={() => { this.CerrarModalPermisos() }}
                    keyboard={false}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Permisos</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="container">
                            <div className="row my-1">
                                <div className="col-md-8">
                                    <small className="text-primary">Tipo de Usuario</small>
                                    <input ref={this.inputTipoUsuario} className="form-control form-control-sm" disabled></input>
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-8">
                                    <small className="text-primary">Screen</small>
                                    <select ref={this.selectPantallas} className="form-select form-select-sm" >
                                        <option value="TODOS">Seleccione Pantalla</option>
                                        {this.state.ListaPantallas.map((row, index) => {
                                            return (<option key={index} value={row.idpantalla}>{row.pantalla}</option>)
                                        })
                                        }
                                    </select>
                                </div>
                                <div className="col-md-4 d-flex flex-row-reverse align-items-end mt-md-0 mt-1">
                                    <button onClick={() => { this.AgregarPantallaTipoUsuario() }} className="btn btn-sm btn-outline-secondary"><i className="fas fa-plus mx-1"></i>Agregar</button>
                                </div>
                            </div>
                        </div>
                        {/* Tabla de Actividades aqui */}
                        <TableContainer style={{ maxHeight: '300px' }}>
                            <Table size='small' stickyHeader>
                                <TableHead>
                                    <TableRow className='fondoTituloTabla'>
                                        <TableCell>Acciones</TableCell>
                                        <TableCell>Pantalla</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.PermisosTipoUsuario.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell className="">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-6 ">
                                                                <i onClick={() => { this.EliminarPermiso(row) }} title="Eliminar" className="fas fa-trash-alt iconoAcciones "></i>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className=" form-check form-switch " style={{ width: '15px' }}>
                                                                    {
                                                                        (row.estado === 'ACTIVO') ?
                                                                            <input onClick={() => { this.CambiarEstadoPermisoPantalla(row.idpermiso, 'INACTIVO') }} className="form-check-input" type="checkbox" defaultChecked /> :
                                                                            <input onClick={() => { this.CambiarEstadoPermisoPantalla(row.idpermiso, 'ACTIVO') }} className="form-check-input" type="checkbox" />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>{row.t_pantalla.pantalla}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>


                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-6 col-md-6">
                                    <small>{`${this.state.PermisosTipoUsuario.length} Elementos`}</small>
                                </div>
                                <div className="col-6 col-md-6 text-end">
                                    <button onClick={() => { this.CerrarModalPermisos() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </Modal>

            </Fragment >
        );
    }
}

export default configPermisos;