import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { hslToRgb } from '@material-ui/core';

class fotografia extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                Fotografias
            </div>

        );
    }
}

fotografia.propTypes = {};

export default fotografia;
