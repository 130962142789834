import React, { Component, createRef } from 'react';
import { Fragment } from 'react';

import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import LoadingModal from './../utils/loadModal'
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Modal } from 'react-bootstrap';
// React LeafLet
import { MapContainer, Marker, TileLayer, Tooltip, FeatureGroup, Popup } from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

//Turf JS
import * as turf from '@turf/turf';

import { Pin1, Pin2, Pin3, Pin4, Pin5, Pin6, Pin7, Pin8, Pin9, Pin10, Pin11, Pin12, Pin13, Pin14, Pin15, Pin16, Pin17, Pin18, Pin19, Pin20, IconoWorkFree, IconoWorkSelect } from './../../config/icono'

import { ValidarUsuario, ObtenerUsuario, ReadExcelfileToJson, ValidarPermisos, LIST_SCREENS, listarPermisos } from './../utils/utils'

//Exportar Excel
import { CreateJsonToExcel } from './../utils/utils';


class MapaReordenamiento extends Component {

    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            loadMapa: false,
            MostrarPorcentajeCarga: false,
            nombreColumnaLatitud: '',
            nombreColumnaLongitud: '',
            nombreColumnaTitulo: '',
            centro: {
                lat: -16.398821,
                lng: -71.536862
            },
            zoomMapa: 13,

            ListaDatosCargados: [],
            ListaSuministros: [],
            ListaNombresCabecera: [],
            ListaTrabajadoresAsignados: [],
            ListaMarcadoresSeleccionados: [],
            ListaElementosAgrupados: [],
            cantidadMarcadoresSeleccionados: 0,
            cantidadMarcadoresSinAsignar: 0,
            ListaSuministrosGps: [],

            ListaPines: [Pin1, Pin2, Pin3, Pin4, Pin5, Pin6, Pin7, Pin8, Pin9, Pin10, Pin11, Pin12, Pin13, Pin14, Pin15, Pin16, Pin17, Pin18, Pin19, Pin20],
            ListaImagenesPines: [
                'img/pin1.svg',
                'img/pin2.svg',
                'img/pin3.svg',
                'img/pin4.svg',
                'img/pin5.svg',
                'img/pin6.svg',
                'img/pin7.svg',
                'img/pin8.svg',
                'img/pin9.svg',
                'img/pin10.svg',
                'img/pin11.svg',
                'img/pin12.svg',
                'img/pin13.svg',
                'img/pin14.svg',
                'img/pin15.svg',
                'img/pin16.svg',
                'img/pin17.svg',
                'img/pin18.svg',
                'img/pin19.svg',
                'img/pin20.svg'],
            MostarModalElegirOrigenLatLng: false,
            MostarModalElegirNombreColumna: false,
            MostrarModalAsignarMarcadores: false,
            MostrarModalReasignarMarcadores: false,
            MostrarModalElegirColumnaAgrupar: false,
            MostrarModalReasignarValorAgrupacion: false,
            MostrarModalModificarElemento: false,
            MostrarLiberarTrabajos: false,
            ElementoActualModificar: {},
            ElementoMostrar: {},
            claves: [],
            claves_mostrar: [],
            cantidadClaves: 0,
            nombreColumnaElegida: '',
            NombreAsignadoCambiar: '',
            NombreColumnaAgrupar: '',
            trabajadorSeleccionado: {}
        }

        //Referencias FeatureGroup React-Leafleat-draw
        this.featureGroupRef = createRef();

        //Referencias  Archivo Carga
        this.inputFileSubirArchivo = createRef();
        this.inputTextNombreAgrupacion = createRef();
        this.inputTextNombreReasignarAgrupacion = createRef();

        //Referencia Modificar Valor Agrupacion
        this.inputTextValorAgrupacionModificar = createRef();
        this.inputTextObsAgrupacionModificar = createRef();

    };

    async componentDidMount() {
        if (ValidarUsuario()) {

            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MAPA, permisos)) {

                    await this.setState({ OcultarLoading: false });
                    this.buscarEnStorage().then(() => {
                    });
                    this.BuscarSuministrosSinSeleccionar();
                    this.setState({ OcultarLoading: true });

                } else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }
            });

        }
        else {
            this.props.history.push('/login');
        }


    }
    buscarEnStorage = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                // Buscar datos guardados en storage
                let DataMapas = localStorage.getItem('OrdenMapa');
                if (DataMapas) {
                    // Volcando Informacion de Storage
                    DataMapas = JSON.parse(DataMapas);
                    this.setState(
                        {
                            ListaSuministros: DataMapas.ListaSuministros,
                            ListaSuministrosGps: [],
                            ListaDatosCargados: DataMapas.ListaSuministros,
                            ListaTrabajadoresAsignados: DataMapas.ListaTrabajadoresAsignados,
                            ListaNombresCabecera: DataMapas.ListaNombresCabecera,
                            nombreColumnaLatitud: DataMapas.nombreColumnaLatitud,
                            nombreColumnaLongitud: DataMapas.nombreColumnaLongitud,
                            nombreColumnaTitulo: DataMapas.nombrecolumnaTitulo,
                        });
                    this.ElegirMarcador();

                    // console.log(DataMapas);
                    resolve(true);
                }
            }
            else {
                reject(true);
            }
        });
    }
    BuscarSuministrosSinSeleccionar = () => {
        let listaSuminitrosTemp = this.state.ListaSuministros;
        let cantidadNoSeleccionados = listaSuminitrosTemp.filter(x => x.nombreAsignado === null);
        this.setState({ cantidadMarcadoresSinAsignar: cantidadNoSeleccionados.length });
    }

    CargaArchivoXLS = async () => {
        // Reiniciar Datos        
        await this.setState({
            nombreColumnaLatitud: '',
            nombreColumnaLongitud: '',
            nombreColumnaTitulo: '',
            ListaDatosCargados: [],
            ListaNombresCabecera: [],
            ListaSuministrosGps: [],
            ListaSuministros: [],
            ListaTrabajadoresAsignados: []
        });

        await this.setState({ OcultarLoading: false });

        let DataExcel = [];
        await ReadExcelfileToJson(this.inputFileSubirArchivo.current.files[0]).then(async (respuesta) => {
            DataExcel = respuesta;
            this.setState({ ListaDatosCargados: respuesta });
        }).catch((error) => {
            DataExcel = [];
            this.setState({ mensajeToast: "Error al cargar el archivo.", abrirToast: true, tipotoast: 'error' });
        });
        // console.log(DataExcel);

        let nombreColumnas = [];
        if (DataExcel.length > 0) {
            for (var clave in DataExcel[0]) {
                nombreColumnas.push(clave);
            }
        }

        // Abrir Modal Elegir GPS
        if (nombreColumnas.length > 0) {
            this.setState({ ListaNombresCabecera: nombreColumnas, MostarModalElegirNombreColumna: true });
        }
    }


    MapearSuministros = async () => {
        this.setState({ OcultarLoading: false });
        let nuevoArray = [];
        let datosCargados = this.state.ListaDatosCargados;
        // Recorrer Arreglo y Asignar identificador
        // console.log(datosCargados);
        for (let x = 0; x < datosCargados.length; x++) {
            datosCargados[x].id = x;
            datosCargados[x].nombreAsignado = null;
            datosCargados[x].seleccionado = false;
            datosCargados[x].observacion = "";
            datosCargados[x].AgrupacionOriginal = "";
            // datosCargados[x].marker = ;            
            nuevoArray.push(datosCargados[x]);
        }

        // console.log(datosCargados);
        await this.setState({ ListaSuministros: datosCargados });
        // Guardar Datos en localStorage  

        let data = {
            ListaSuministros: datosCargados,
            ListaNombresCabecera: this.state.ListaNombresCabecera,
            nombreColumnaLatitud: this.state.nombreColumnaLatitud,
            nombreColumnaLongitud: this.state.nombreColumnaLongitud,
            nombrecolumnaTitulo: this.state.nombreColumnaTitulo,
            ListaTrabajadoresAsignados: []
        }
        localStorage.setItem('OrdenMapa', JSON.stringify(data));
        // this.BuscarSuministrosSinSeleccionar();
        this.CerrarModalElegirNombreColumna(false);
        this.setState({ OcultarLoading: true });
    }

    AbrirModalElegirColumnaAgrupar = () => {
        this.setState({ MostrarModalElegirColumnaAgrupar: true, NombreColumnaAgrupar: null });
    }

    CerrarModalElegirColumnaAgrupar = () => {
        this.setState({ MostrarModalElegirColumnaAgrupar: false });
    }

    CambiarColumnaAgrupacion = (row) => {
        // console.log(row);
        this.setState({ NombreColumnaAgrupar: row });
    }

    BuscarListaElementosAgrupados = async () => {
        // console.log(this.state.NombreColumnaAgrupar);
        if (this.state.NombreColumnaAgrupar) {
            // Convertir columna Agrupar en Texto
            let arrayDatosCargados = this.state.ListaSuministros;
            for (let x = 0; x < arrayDatosCargados.length; x++) {
                arrayDatosCargados[x][this.state.NombreColumnaAgrupar] = String(arrayDatosCargados[x][this.state.NombreColumnaAgrupar]);
                // arrayDatosCargados[x].AgrupacionOriginal='';
            }

            await this.setState({ ListaSuministros: arrayDatosCargados });

            let arrayElementosAgrupados = [];
            for (let x = 0; x < this.state.ListaSuministros.length; x++) {

                let _elemento = this.state.ListaSuministros[x][this.state.NombreColumnaAgrupar];
                let existe = false;
                for (let y = 0; y < arrayElementosAgrupados.length; y++) {
                    if (_elemento === arrayElementosAgrupados[y]) {
                        existe = true;
                        break;
                    }
                }
                if (!existe) {
                    arrayElementosAgrupados.push(_elemento);
                }
            }
            // contar Elementos
            let arrayOrdenado = arrayElementosAgrupados.sort();
            let arrayElmentosAgruparCantidad = [];

            let _cantMarcadores = 20;
            for (let x = 0; x < arrayOrdenado.length; x++) {
                let _cantidaElementos = this.state.ListaSuministros.filter(y => y[this.state.NombreColumnaAgrupar] === arrayOrdenado[x]);

                // arrayTrabajadores[y].marcador = y - (Math.trunc(y / _cantMarcadores) * _cantMarcadores);
                arrayElmentosAgruparCantidad.push({
                    elemento: arrayOrdenado[x],
                    cantidad: _cantidaElementos.length,
                    marcador: x - (Math.trunc(x / _cantMarcadores) * _cantMarcadores),
                    mapeado: false
                });
            }

            // console.log(arrayElmentosAgruparCantidad);
            this.setState({ ListaElementosAgrupados: arrayElmentosAgruparCantidad, MostrarModalElegirColumnaAgrupar: false });
        }
        else {
            this.setState({ mensajeToast: `Debe elegir una columna para realizar la agrupación`, abrirToast: true, tipotoast: 'error' });
        }
    }

    ElegirMarcador = async () => {
        // console.log(this.state.ListaGPSSuministros);
        let _listasuministrosTemp = this.state.ListaSuministros;
        //Buscar Lista de TECNICOS
        let arrayTrabajadores = this.state.ListaTrabajadoresAsignados;
        // let _existe = false;
        let _cantMarcadores = 20;

        //Asignar Valores a Marcador
        for (let x = 0; x < _listasuministrosTemp.length; x++) {
            for (let y = 0; y < arrayTrabajadores.length; y++) {
                if (_listasuministrosTemp[x].nombreAsignado === arrayTrabajadores[y].nombre) {
                    _listasuministrosTemp[x].seleccionado = false;
                    _listasuministrosTemp[x].marker = y - (Math.trunc(y / _cantMarcadores) * _cantMarcadores);
                    arrayTrabajadores[y].marcador = y - (Math.trunc(y / _cantMarcadores) * _cantMarcadores);
                    break;
                }
            }
        }
        await this.setState({ ListaSuministros: _listasuministrosTemp, ListaTrabajadoresAsignados: arrayTrabajadores, loadMapa: true });
    }

    AbrirModalElegirOrigenLatLng = (e) => {
        // console.log(e);
        this.setState({ MostarModalElegirOrigenLatLng: true, nombreColumnaElegida: e });
    }
    CerrarModalElegirOrigenLatLng = () => {
        this.setState({ MostarModalElegirOrigenLatLng: false });
    }

    AbrirModalElegirNombreColumna = () => {
        this.setState({ MostarModalElegirNombreColumna: true });
    }
    CerrarModalElegirNombreColumna = (e) => {
        if (e) {
            this.EliminarDatosCargados();
        }

        this.setState({ MostarModalElegirNombreColumna: false, OcultarLoading: true });
    }

    elegirLatLngColumna = (e, nombreColumna) => {

        switch (e.target.value) {
            case 'latitud':
                this.setState({ nombreColumnaLatitud: nombreColumna });
                this.CerrarModalElegirOrigenLatLng();
                break;
            case 'longitud':
                this.setState({ nombreColumnaLongitud: nombreColumna });
                this.CerrarModalElegirOrigenLatLng();
                break;
            case 'titulo':
                this.setState({ nombreColumnaTitulo: nombreColumna });
                this.CerrarModalElegirOrigenLatLng();
                break;
        }
    }

    PosicionMarcadorSeleccionado = (id) => {
        let posicion = -1;
        for (let x = 0; x < this.state.ListaSuministros.length; x++) {
            if (this.state.ListaSuministros[x].id === id) {
                posicion = x;
                break;
            }
        }
        return posicion;
    }

    SeleccionarMarcador = (indice, row) => {
        if (row.nombreAsignado === null) {
            let posicion = this.PosicionMarcadorSeleccionado(row.id);
            let listaSuminitrosTemp = this.state.ListaSuministros;

            if (row.seleccionado) {
                listaSuminitrosTemp[posicion].seleccionado = false;
            }
            else {
                listaSuminitrosTemp[posicion].seleccionado = true;
            }
            // Contar Marcadores Seleccionados
            let cantidadSeleccionados = listaSuminitrosTemp.filter(x => x.seleccionado === true);
            this.setState({ ListaSuministros: listaSuminitrosTemp, ListaMarcadoresSeleccionados: cantidadSeleccionados });

        }
        else {
            this.setState({ mensajeToast: `Trabajo ya esta asigando a ${row.nombreAsignado}`, abrirToast: true, tipotoast: 'warning' });
        }
    }

    AsignarMarcadores = async () => {
        if (this.state.ListaMarcadoresSeleccionados.length > 0) {
            // await this.setState({ OcultarLoading: false });
            // Mostrar modal Ingresar nombre a Asignar
            // this.AbrirModalAsignarMarcadores();
            this.setState({ MostrarModalReasignarValorAgrupacion: true });
        } else {
            this.setState({ abrirToast: true, mensajeToast: 'Elija los marcadores a asignar', tipotoast: 'warning' });
        }
    }

    ModificarAgrupacion = async () => {

        if (this.inputTextValorAgrupacionModificar.current.value != '') {
            this.inputTextValorAgrupacionModificar.current.classList.remove('is-invalid');

            let arraySuministrosGPS = this.state.ListaSuministrosGps;
            let arrayListaSuministros = this.state.ListaSuministros;

            // Elementos Seleccionados
            for (let x = 0; x < arraySuministrosGPS.length; x++) {
                if (arraySuministrosGPS[x].seleccionado) {

                    let valorOriginal = arrayListaSuministros[arraySuministrosGPS[x].id][this.state.NombreColumnaAgrupar];
                    let valorNuevo = this.inputTextValorAgrupacionModificar.current.value;

                    if (valorOriginal != valorNuevo) {
                        // console.log(`Modificado ${valorOriginal} => ${valorNuevo}`);

                        arrayListaSuministros[arraySuministrosGPS[x].id].observacion = `Modificado ${valorOriginal} => ${valorNuevo}`
                        arrayListaSuministros[arraySuministrosGPS[x].id].AgrupacionOriginal = valorOriginal;
                        arrayListaSuministros[arraySuministrosGPS[x].id][this.state.NombreColumnaAgrupar] = valorNuevo;

                        // console.log(`Valor Original: ${valorOriginal}`);
                        // console.log(`Valor Nuevo: ${valorNuevo}`);
                    }
                }
            }

            // #########################################
            let _listaElementosAgrupados = this.state.ListaElementosAgrupados;
            let _listaAgrupacionesSeleccionados = [];
            for (let x = 0; x < _listaElementosAgrupados.length; x++) {
                if (_listaElementosAgrupados[x].mapeado) {
                    _listaAgrupacionesSeleccionados.push(_listaElementosAgrupados[x].elemento);
                }
            }

            // console.log(_listaAgrupacionesSeleccionados);

            await this.setState({ ListaSuministros: arrayListaSuministros, ListaSuministrosGps: [] });

            await this.BuscarListaElementosAgrupados()
            // this.AsignarNombreAgrupacionMarcadores();
            // #########################################
            // MapearSuministrosTrabajador                    

            // Volver a Mapear Suministros
            for (let x = 0; x < _listaElementosAgrupados.length; x++) {
                if (_listaElementosAgrupados[x].mapeado) {
                    // Volver A Mapear
                    this.MapearSuministrosTrabajador(this.state.ListaElementosAgrupados[x], x);
                    // Guardar la Posicion de los elementos Agrupados                    
                }
            }

            // Actualizar Elementos Agrupados
            // this.setState({ListaElementosAgrupados:_listaElementosAgrupados});

            this._eliminarPoligonos();
            this.CerrarModalReasignarValorAgrupacion();
            // console.log(this.state.ListaElementosAgrupados);            
        } else {
            this.inputTextValorAgrupacionModificar.current.classList.add('is-invalid');
        }
    }

    CerrarModalReasignarValorAgrupacion = () => {
        this.setState({ MostrarModalReasignarValorAgrupacion: false });
    }

    validarModalAsignarMarcadores = () => {
        // console.log(this.inputTextNombreAgrupacion.current.value);
        if (this.inputTextNombreAgrupacion.current.value === '') {
            // this.setState({ mensajeToast: `Suministro ya esta asigando a `, abrirToast: true, tipotoast: 'warning' });
            this.inputTextNombreAgrupacion.current.classList.add('is-invalid');
        }
        else {
            this.inputTextNombreAgrupacion.current.classList.remove('is-invalid');
            this.AsignarNombreAgrupacionMarcadores(this.inputTextNombreAgrupacion.current.value);
        }
    }

    AsignarNombreAgrupacionMarcadores = async (nombreAsignar) => {
        // Recorrer y buscar todos los valores seleccionados
        let gpsSuministros = this.state.ListaSuministros;
        let elementoAsignado = {
            nombre: nombreAsignar,
            cantidad: 0,
            marcador: null
        };
        let cantidadSeleccionados = 0;
        let arrayNombresAsignados = this.state.ListaTrabajadoresAsignados;
        let posicionSiExiste = -1;
        // Buscar Si existe Nombre
        for (let x = 0; x < arrayNombresAsignados.length; x++) {
            if (arrayNombresAsignados[x].nombre === nombreAsignar) {
                posicionSiExiste = x;
                break;
            }
        }

        for (let x = 0; x < gpsSuministros.length; x++) {
            if (gpsSuministros[x].seleccionado) {
                gpsSuministros[x].nombreAsignado = nombreAsignar;
                cantidadSeleccionados++;
            }
        }

        if (posicionSiExiste > -1) {
            // Existe Elemento
            let elementosexiste = gpsSuministros.filter(x => x.nombreAsignado === nombreAsignar);
            arrayNombresAsignados[posicionSiExiste].cantidad = elementosexiste.length;
        }
        else {
            // No existe Elemento
            elementoAsignado.cantidad = cantidadSeleccionados;
            arrayNombresAsignados.push(elementoAsignado);
        }
        await this.setState({ ListaTrabajadoresAsignados: arrayNombresAsignados, ListaMarcadoresSeleccionados: [], ListaSuministros: gpsSuministros, ListaSuministrosGps: gpsSuministros });

        // Guardar DAtos
        let data = {
            ListaSuministros: this.state.ListaSuministros,
            ListaTrabajadoresAsignados: this.state.ListaTrabajadoresAsignados,
            ListaNombresCabecera: this.state.ListaNombresCabecera,
            nombreColumnaLatitud: this.state.nombreColumnaLatitud,
            nombreColumnaLongitud: this.state.nombreColumnaLongitud,
            nombrecolumnaTitulo: this.state.nombreColumnaTitulo
        }
        localStorage.setItem('OrdenMapa', JSON.stringify(data));

        // Agregar nombre      
        this.ElegirMarcador();
        this.BuscarSuministrosSinSeleccionar();
        this._eliminarPoligonos();
        this.CerrarModalAsignarMarcadores()
    }

    AbrirModalAsignarMarcadores = () => {
        this.setState({ MostrarModalAsignarMarcadores: true });
    }

    CerrarModalAsignarMarcadores = () => {
        this.setState({ MostrarModalAsignarMarcadores: false });
    }

    AbrirModalReasignarMarcadores = (row) => {

        this.setState({ MostrarModalReasignarMarcadores: true, NombreAsignadoCambiar: row.nombre });
    }

    CerrarModalReasignarMarcadores = () => {
        this.setState({ MostrarModalReasignarMarcadores: false });
    }

    AbrirModalModificarElemento = (row) => {
        let claves = Object.keys(row);
        let elementos = claves.length - 6;
        let nuevo_elemento = {};
        let claves_mostrar = []

        for (let y = 0; y < elementos; y++) {
            nuevo_elemento[claves[y]] = row[claves[y]];
            claves_mostrar.push(claves[y]);
        }
        // console.log(nuevo_elemento);
        this.setState({ MostrarModalModificarElemento: true, ElementoActualModificar: row, ElementoMostrar: nuevo_elemento, claves: claves, claves_mostrar: claves_mostrar, cantidadClaves: elementos });
    }

    CerrarModalModificarElemento = (row) => {
        this.setState({ MostrarModalModificarElemento: false, ElementoActualModificar: {}, ElementoMostrar: {}, claves: [], claves_mostrar: [], cantidadClaves: 0 })
    }

    CambiarDatosElementoMostrar = (ev, clave) => {
        let modificacionElemento = this.state.ElementoMostrar;
        modificacionElemento[clave] = ev.target.value;
        // console.log("Elemento Modificado:");
        // console.log(modificacionElemento);
        // Actualizar Elemento
        this.setState({ ElementoMostrar: modificacionElemento });
        // console.log("Valores elemento:");
        // console.log(this.state.ElementoMostrar);
    }

    GuardarModificacion = async() => {
        
        let arrayListaSuministros = this.state.ListaSuministros;

        let _ElementoActualmodificar = this.state.ElementoActualModificar;
        let _ElementoMostrar = this.state.ElementoMostrar;

        // let clavesElementoActualModificar=Object.keys(_ElementoActualmodificar);
        let clavesElementoMostrar = Object.keys(_ElementoMostrar);
        // console.log(clavesElementoMostrar);

        for (let x = 0; x < clavesElementoMostrar.length; x++) {
            if (_ElementoActualmodificar[clavesElementoMostrar[x]] != _ElementoMostrar[clavesElementoMostrar[x]]) {
                // Actualizar Elemento
                let _valorColumna = clavesElementoMostrar[x];
                let _valorAmnterior = _ElementoActualmodificar[clavesElementoMostrar[x]];
                let _valorActualizado = _ElementoMostrar[clavesElementoMostrar[x]];

                // _ElementoActualmodificar.ObsModificaciones = `${_valorColumna}:${_valorAmnterior}=> ${_valorActualizado}`
                // _ElementoActualmodificar[clavesElementoMostrar[x]] = _ElementoMostrar[clavesElementoMostrar[x]]

                arrayListaSuministros[_ElementoActualmodificar.id].ObsModificaciones = `${_valorColumna}:${_valorAmnterior} => ${_valorActualizado}`;
                arrayListaSuministros[_ElementoActualmodificar.id][clavesElementoMostrar[x]] = _ElementoMostrar[clavesElementoMostrar[x]]
            }
        }

        // console.log(this.state.ElementoActualModificar);
        // console.log(arrayListaSuministros[_ElementoActualmodificar.id]);


        // #########################################
        let _listaElementosAgrupados = this.state.ListaElementosAgrupados;
        let _listaAgrupacionesSeleccionados = [];
        for (let x = 0; x < _listaElementosAgrupados.length; x++) {
            if (_listaElementosAgrupados[x].mapeado) {
                _listaAgrupacionesSeleccionados.push(_listaElementosAgrupados[x].elemento);
            }
        }

        // console.log(_listaAgrupacionesSeleccionados);

        await this.setState({ ListaSuministros: arrayListaSuministros, ListaSuministrosGps: [] });

        await this.BuscarListaElementosAgrupados()
        // this.AsignarNombreAgrupacionMarcadores();
        // #########################################
        // MapearSuministrosTrabajador                    

        // Volver a Mapear Suministros
        for (let x = 0; x < _listaElementosAgrupados.length; x++) {
            if (_listaElementosAgrupados[x].mapeado) {
                // Volver A Mapear
                this.MapearSuministrosTrabajador(this.state.ListaElementosAgrupados[x], x);
                // Guardar la Posicion de los elementos Agrupados                    
            }
        }
        

        this._eliminarPoligonos();
        this.CerrarModalModificarElemento();    

    }

    validarModalReasignarMarcadores = async () => {
        if (this.inputTextNombreReasignarAgrupacion.current.value === '') {
            this.inputTextNombreReasignarAgrupacion.current.classList.add('is-invalid');
        } else {
            this.inputTextNombreReasignarAgrupacion.current.classList.remove('is-invalid');
            // Reasignar todos los nombres Lista Suministros
            let listaSuminitrosTemp = this.state.ListaSuministros;
            let listaTrabajadoresasignadosTemp = this.state.ListaTrabajadoresAsignados;
            for (let x = 0; x < listaSuminitrosTemp.length; x++) {
                if (listaSuminitrosTemp[x].nombreAsignado === this.state.NombreAsignadoCambiar) {
                    listaSuminitrosTemp[x].nombreAsignado = this.inputTextNombreReasignarAgrupacion.current.value;
                }
            }
            //ReasignarNombres Lista Trabajadores
            for (let x = 0; x < listaTrabajadoresasignadosTemp.length; x++) {
                if (listaTrabajadoresasignadosTemp[x].nombre === this.state.NombreAsignadoCambiar) {
                    listaTrabajadoresasignadosTemp[x].nombre = this.inputTextNombreReasignarAgrupacion.current.value;
                }
            }
            await this.setState({ ListaSuministros: listaSuminitrosTemp, ListaSuministrosGps: listaSuminitrosTemp, ListaTrabajadoresAsignados: listaTrabajadoresasignadosTemp });
            // Almacenar En storage
            let data = {
                ListaSuministros: this.state.ListaSuministros,
                ListaTrabajadoresAsignados: this.state.ListaTrabajadoresAsignados,
                ListaNombresCabecera: this.state.ListaNombresCabecera,
                nombreColumnaLatitud: this.state.nombreColumnaLatitud,
                nombreColumnaLongitud: this.state.nombreColumnaLongitud,
                nombrecolumnaTitulo: this.state.nombreColumnaTitulo
            }
            localStorage.setItem('OrdenMapa', JSON.stringify(data));
        }
        this.CerrarModalReasignarMarcadores();
    }

    MapearSuministrosTrabajador = (row, index) => {
        // let filtro = this.state.ListaSuministros.filter(x => x.nombreAsignado === row.nombre);
        // // console.log(filtro);
        // this.setState({ ListaSuministrosGps: filtro });

        let filtro = this.state.ListaSuministros.filter(y => y[this.state.NombreColumnaAgrupar] === row.elemento);
        // Agregar Elementos  a Array de Agregados
        let _arrayElementos = this.state.ListaSuministrosGps;
        let cantidad_total = filtro.length + _arrayElementos.length;

        let _ListaElementosAgrupados = this.state.ListaElementosAgrupados;
        let _listasuministros = this.state.ListaSuministros;

        if (cantidad_total > 6000) {
            this.setState({ mensajeToast: `Esta tratando de mapear demasiados puntos GPS, solo se permite como maximo 6mil puntos .`, abrirToast: true, tipotoast: 'warning' });
        } else {
            filtro = [];
            for (let x = 0; x < _listasuministros.length; x++) {
                if (_listasuministros[x][this.state.NombreColumnaAgrupar] === row.elemento) {
                    _listasuministros[x].marcador = row.marcador
                    _listasuministros[x].seleccionado = false
                    filtro.push(_listasuministros[x]);
                }
            }
            // Actualizar elemento Seleccionado
            _ListaElementosAgrupados[index].mapeado = true;
            // console.log(_ListaElementosAgrupados);

            if (_arrayElementos.length > 0) {
                let _narrarConcat = _arrayElementos.concat(filtro);
                this.setState({ ListaSuministrosGps: _narrarConcat, ListaElementosAgrupados: _ListaElementosAgrupados });
            }
            else {
                this.setState({ ListaSuministrosGps: filtro, ListaElementosAgrupados: _ListaElementosAgrupados });
            }
        }
    }

    // MapearTodosSuministros = (row) => {
    //     // console.log(filtro);
    //     this.setState({ ListaSuministrosGps: this.state.ListaSuministros });
    // }

    LiberarSuministros = (row, index) => {
        // console.log(row);
        // this.setState({ MostrarLiberarTrabajos: true, trabajadorSeleccionado: row });

        let _ListaElementosAgrupados = this.state.ListaElementosAgrupados;
        let filtro = this.state.ListaSuministrosGps.filter(y => y[this.state.NombreColumnaAgrupar] != row.elemento);

        _ListaElementosAgrupados[index].mapeado = false;
        this.setState({ ListaSuministrosGps: filtro, ListaElementosAgrupados: _ListaElementosAgrupados });

    }

    CerrarModalLiberarSuministros = () => {
        this.setState({ MostrarLiberarTrabajos: false });
    }

    LiberarTrabajo = async () => {
        try {
            // Recorrer lista de suministros y Limpiar Valores
            // Reasignar todos los nombres Lista Suministros
            let listaSuminitrosTemp = this.state.ListaSuministros;
            let listaTrabajadoresasignadosTemp = this.state.ListaTrabajadoresAsignados;
            for (let x = 0; x < listaSuminitrosTemp.length; x++) {
                if (listaSuminitrosTemp[x].nombreAsignado === this.state.trabajadorSeleccionado.nombre) {
                    listaSuminitrosTemp[x].nombreAsignado = null;
                }
            }
            //ReasignarNombres Lista Trabajadores
            for (let x = 0; x < listaTrabajadoresasignadosTemp.length; x++) {
                // console.log(x);
                if (listaTrabajadoresasignadosTemp[x].nombre === this.state.trabajadorSeleccionado.nombre) {
                    // Eliminar  elemento                 
                    listaTrabajadoresasignadosTemp.splice(x, 1);
                    x = 0;
                }
            }
            // console.log(listaTrabajadoresasignadosTemp);

            await this.setState({ ListaSuministros: listaSuminitrosTemp, ListaSuministrosGps: listaSuminitrosTemp, ListaTrabajadoresAsignados: listaTrabajadoresasignadosTemp });
            // Almacenar En storage
            let data = {
                ListaSuministros: this.state.ListaSuministros,
                ListaTrabajadoresAsignados: this.state.ListaTrabajadoresAsignados,
                ListaNombresCabecera: this.state.ListaNombresCabecera,
                nombreColumnaLatitud: this.state.nombreColumnaLatitud,
                nombreColumnaLongitud: this.state.nombreColumnaLongitud,
                nombrecolumnaTitulo: this.state.nombreColumnaTitulo
            }
            localStorage.setItem('OrdenMapa', JSON.stringify(data));
            this.BuscarSuministrosSinSeleccionar();
            this.CerrarModalLiberarSuministros();

        } catch (error) {
            this.setState({ mensajeToast: error.message, abrirToast: true, tipotoast: 'error' });
        }

    }

    ExisteMarcador = (id) => {
        let existe = false;
        for (let x = 0; x < this.state.ListaMarcadoresSeleccionados.length; x++) {
            if (this.state.ListaMarcadoresSeleccionados[x].id === id) {
                existe = true;
                break;
            }
        }
        return existe;
    }

    EliminarDatosCargados = () => {
        // Limpiar Storage
        localStorage.removeItem("OrdenMapa");
        this.setState(
            {
                ListaSuministros: [],
                ListaDatosCargados: [],
                ListaSuministrosGps: [],
                ListaTrabajadoresAsignados: [],
                ListaElementosAgrupados: [],
                nombreColumnaLatitud: '',
                nombreColumnaLongitud: '',
                nombreColumnaTitulo: '',
            });
    }

    EliminarGPSCargados = () => {
        // Limpiar ListaElementosAgrupados
        let _ListaElementosAgrupados = this.state.ListaElementosAgrupados;
        for (let x = 0; x < _ListaElementosAgrupados.length; x++) {
            _ListaElementosAgrupados[x].mapeado = false;
        }
        this.setState({ ListaSuministrosGps: [], ListaElementosAgrupados: _ListaElementosAgrupados });
    }

    RevisarPuntosEnPoligono = async (coord_polig) => {
        //Limpiar Marcadores
        // console.log(coord_polig);
        let marcadoresTemp = [];
        //
        let gpsSuministros = this.state.ListaSuministrosGps;
        //Obteniendo Datos de Poligono
        let _poligono = turf.polygon(coord_polig);

        for (let x = 0; x < gpsSuministros.length; x++) {
            // if (gpsSuministros[x].nombreAsignado === null) {
            // console.log(gpsSuministros[x]);
            let _punto = turf.point([parseFloat(gpsSuministros[x][this.state.nombreColumnaLongitud]), parseFloat(gpsSuministros[x][this.state.nombreColumnaLatitud])]);

            let _busqueda = turf.pointsWithinPolygon(_punto, _poligono);

            if (_busqueda.features.length > 0) {
                //Punto Seleccionado
                gpsSuministros[x].seleccionado = true;
                marcadoresTemp.push(gpsSuministros[x]);
            }
            else {
                gpsSuministros[x].seleccionado = false;
            }
            // }
        }
        await this.setState({ ListaGPSSuministros: gpsSuministros, ListaMarcadoresSeleccionados: marcadoresTemp });

    }


    _onCreate = (ev) => {
        // console.log(ev);        
        // let polygon=ev.layer.getLatLngs();
        let coords = ev.layer.toGeoJSON();
        let coordenadas = coords.geometry.coordinates
        // let tipo = coords.geometry.type;
        // console.log(coordenadas);

        this.RevisarPuntosEnPoligono(coordenadas);
    }

    _onEdited = (ev) => {
        let { layers: { _layers } } = ev;
        let primeraPosicion;
        let coordenadas = [];
        // console.log(_layers);
        Object.values(_layers).map((_leafleat_id) => {
            let _coords = _leafleat_id._latlngs[0].map((e, index) => {
                let LatLng = e;
                if (index === 0) {
                    primeraPosicion = [LatLng.lng, LatLng.lat];
                }
                return [LatLng.lng, LatLng.lat];
            });
            _coords.push(primeraPosicion);
            coordenadas.push(_coords);
            this.RevisarPuntosEnPoligono(coordenadas);
        });
    }

    _onDeleted = (ev) => {
        // console.log(ev);
        //Reiniciando Elementos Seleccionados        
        let gpsSuministros = this.state.ListaSuministrosGps;
        for (let x = 0; x < gpsSuministros.length; x++) {
            gpsSuministros[x].seleccionado = false;
        }
        this.setState({ ListaSuministrosGps: gpsSuministros, ListaMarcadoresSeleccionados: [] });
    }

    _eliminarPoligonos = () => {
        let _layers = this.featureGroupRef.current._layers;
        Object.keys(_layers).forEach((layerid, index) => {
            _layers[layerid].remove();
        });
        // Eliminar elementos Seleccionados si los hubiera
        let _arrayGps = this.state.ListaSuministrosGps;
        for (let x = 0; x < _arrayGps.length; x++) {
            if (_arrayGps[x].seleccionado) {
                _arrayGps[x].seleccionado = false;
            }
        }
        this.setState({ ListaSuministrosGps: _arrayGps, ListaMarcadoresSeleccionados: [] });
    }

    DescargaraExcel = async () => {

        //Descargar Informacion
        if (this.state.ListaSuministros.length > 0) {
            await CreateJsonToExcel(this.state.ListaSuministros);
        } else {
            this.setState({ abrirToast: true, mensajeToast: "No se encontraron datos para Exportar", tipotoast: 'warning' });
        }
    }

    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    render() {
        return (
            <Fragment>
                <Snackbar
                    open={this.state.MostrarPorcentajeCarga}
                    message={this.state.mensaCargaProgramacion}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo="MAPA" regresar={() => { this.props.history.push('/dashboard') }}></TituloPagina>


                <div className="container-fluid">
                    <div className="row m-1" >
                        <div className="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-3  overflow-auto" style={{ maxHeight: '88vh' }}>
                            <div className="border p-1">
                                <div className="text-center">
                                    <i onClick={() => { this.EliminarDatosCargados() }} title="Eliminar Datos Cargados" className="far fa-trash-alt mx-2 iconoAcciones"></i>
                                    <i onClick={() => { this.MapearTodosSuministros() }} title="Mapear Todos" className="fas fa-draw-polygon mx-2 iconoAcciones"></i>
                                    <i onClick={() => { this._eliminarPoligonos() }} title="Borrar Poligono" className="fas fa-eraser mx-2 iconoAcciones"></i>
                                </div>
                                {/* Cargar */}
                                {
                                    this.state.ListaSuministros.length > 0 ?
                                        <></> :
                                        <div className="my-1">
                                            <div className="">
                                                <input ref={this.inputFileSubirArchivo} className="form-control form-control-sm" type="file" id="formFile" />
                                            </div>
                                            <div className="d-grid mt-1">
                                                <button onClick={() => {
                                                    this.CargaArchivoXLS();
                                                }} className=" btn btn-sm btn-outline-primary  ">
                                                    <i className="fas fa-file-upload p-1"></i>
                                                    <span>Cargar Archivo</span>
                                                </button>
                                            </div>
                                        </div>
                                }

                                {
                                    this.state.ListaDatosCargados.length > 0 ?
                                        <div className='d-flex justify-content-between '>
                                            {/* <div class="alert alert-success" role="alert"> */}
                                            <small className="text-secondary"> <strong>{this.state.ListaDatosCargados.length}</strong> registros cargados.</small>

                                            <div className="dropdown">
                                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fas fa-ellipsis-v text-secondary"></i>
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                                    <li className="pointercursos" onClick={() => { this.AbrirModalElegirColumnaAgrupar() }}><small className="dropdown-item" ><i className="fas fa-object-group me-2"></i>Agrupar Por</small></li>
                                                    {/* <li className="pointercursos" onClick={() => { }}><small className="dropdown-item" ><i className="fas fa-calendar-check me-2"></i>Agregar</small></li>     */}
                                                    <li><hr className="dropdown-divider" /></li>
                                                    <li className="pointercursos" onClick={() => { this.DescargaraExcel() }}><small className="dropdown-item" ><i className="fas fa-file-excel me-2"></i>Descargar Excel</small></li>

                                                </ul>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                        : <></>

                                }

                                <div className="d-grid mt-2">
                                    <button onClick={() => { this.AsignarMarcadores() }} className="btn btn-sm btn-primary">
                                        <i className="fas fa-link mx-1"></i>
                                        {
                                            this.state.ListaMarcadoresSeleccionados.length > 0 ?
                                                `Modificar ${this.state.ListaMarcadoresSeleccionados.length} puntos GPS selecionados`
                                                : `Elija los puntos GPS`
                                        }
                                    </button>

                                </div>

                            </div>

                            {/* Asignaciones*/}
                            {
                                this.state.ListaElementosAgrupados.length > 0 ?
                                    <div className="border border-info my-1 p-1">
                                        <div className="text-center">
                                            <strong>Asignaciones</strong>
                                        </div>
                                        {/* {
                                            this.state.ListaSuministrosSinAsignar.length > 0 ?
                                                <div>
                                                    <small className="text-secondary">{`${this.state.ListaSuministrosSinAsignar.length} suministros sin asignar`}</small>
                                                </div>
                                                : <></>
                                        } */}
                                        <div>
                                            <i onClick={() => { this.EliminarGPSCargados() }} title="Eliminar GPS Mapeados" className="fas fa-eraser mx-2 iconoAcciones"></i>
                                        </div>
                                        <div>
                                            <small>{this.state.ListaSuministrosGps.length} puntos GPS mapeados.</small>
                                        </div>
                                        <TableContainer style={{ maxHeight: '500px' }}>
                                            <Table size='small' stickyHeader  >
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell>[Asignado] Nombre</TableCell>

                                                        <TableCell>Acciones</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody >
                                                    {this.state.ListaElementosAgrupados.map((row, index) => {
                                                        // if (row.idpersonalasignado != null)

                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell style={{ fontSize: '0.75em' }}><img src={this.state.ListaImagenesPines[row.marcador]} style={{ height: 24 }}></img> {`[${this.state.NombreColumnaAgrupar} ${row.elemento}] ${row.cantidad}`}</TableCell>
                                                                <TableCell>
                                                                    {/* <i onClick={() => { this.AbrirModalReasignarMarcadores(row) }} title="Reasignar" className="fas fa-exchange-alt mx-2 iconoAcciones"></i> */}
                                                                    <i onClick={() => { this.MapearSuministrosTrabajador(row, index) }} title="Mapear" className="fas fa-route mx-2 iconoAcciones"></i>
                                                                    {
                                                                        row.mapeado ?
                                                                            <i onClick={() => { this.LiberarSuministros(row, index) }} title="Liberar" className="fas fa-unlock-alt mx-2 iconoAcciones"></i>
                                                                            :
                                                                            <></>

                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    : <></>

                            }

                        </div>

                        <div className="col-12 col-sm-7 col-md-7 col-lg-8 col-xl-9">
                            <MapContainer center={this.state.centro} zoom={this.state.zoomMapa} style={{ height: `88vh` }}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <FeatureGroup ref={this.featureGroupRef}>
                                    <EditControl
                                        position="topright"
                                        onCreated={this._onCreate}
                                        onEdited={this._onEdited}
                                        onDeleted={this._onDeleted}
                                        draw={{
                                            rectangle: false,
                                            polyline: false,
                                            circlemarker: false,
                                            marker: false,
                                            circle: false
                                        }} />
                                </FeatureGroup>

                                {

                                    this.state.ListaSuministrosGps.map((row, index) => {
                                        // console.log(row);               
                                        if (row[this.state.nombreColumnaLatitud] != null && row[this.state.nombreColumnaLongitud] != null) {
                                            let claves = Object.keys(row);
                                            let elementos = claves.length - 6;
                                            return (
                                                row.seleccionado ?
                                                    <Marker
                                                        // eventHandlers={{ click: () => { this.SeleccionarMarcador(index, row) } }} 
                                                        key={index}
                                                        title=""
                                                        position={[row[this.state.nombreColumnaLatitud], row[this.state.nombreColumnaLongitud]]}
                                                        icon={IconoWorkSelect}>

                                                        <Tooltip opacity={0.8}>
                                                            <div>
                                                                <strong>{`${this.state.NombreColumnaAgrupar}: `}</strong>
                                                                <small>{`${row[this.state.NombreColumnaAgrupar]}`}</small>
                                                            </div>

                                                            <div>
                                                                <strong>{`${this.state.nombreColumnaTitulo}: `}</strong>
                                                                <small>{`${row[this.state.nombreColumnaTitulo]}`}</small>
                                                            </div>
                                                        </Tooltip>
                                                    </Marker>


                                                    :
                                                    <Marker
                                                        // eventHandlers={{ click: () => { this.SeleccionarMarcador(index, row) } }}
                                                        key={index}
                                                        title=""
                                                        position={[row[this.state.nombreColumnaLatitud], row[this.state.nombreColumnaLongitud]]}
                                                        icon={this.state.ListaPines[row.marcador]}>

                                                        <Popup>
                                                            {
                                                                0 < elementos ?
                                                                    claves[0] ?
                                                                        <div>
                                                                            <label className="text-primary">{claves[0]} :</label><span>{row[claves[0]]}</span>
                                                                        </div>
                                                                        : <></>
                                                                    : <></>
                                                            }
                                                            {
                                                                1 < elementos ?
                                                                    claves[12] ?
                                                                        <div>
                                                                            <label className="text-primary">{claves[1]} :</label><span>{row[claves[1]]}</span>
                                                                        </div>
                                                                        : <></>
                                                                    : <></>
                                                            }
                                                            {
                                                                2 < elementos ?
                                                                    claves[2] ?
                                                                        <div>
                                                                            <label className="text-primary">{claves[2]} :</label><span>{row[claves[2]]}</span>
                                                                        </div>
                                                                        : <></>
                                                                    : <></>
                                                            }
                                                            {
                                                                3 < elementos ?
                                                                    claves[3] ?
                                                                        <div>
                                                                            <label className="text-primary">{claves[3]} :</label><span>{row[claves[3]]}</span>
                                                                        </div>
                                                                        : <></>
                                                                    : <></>
                                                            }
                                                            {
                                                                4 < elementos ?
                                                                    claves[4] ?
                                                                        <div>
                                                                            <label className="text-primary">{claves[4]} :</label><span>{row[claves[4]]}</span>
                                                                        </div>
                                                                        : <></>
                                                                    : <></>
                                                            }
                                                            {
                                                                5 < elementos ?
                                                                    claves[5] ?
                                                                        <div>
                                                                            <label className="text-primary">{claves[5]} :</label><span>{row[claves[5]]}</span>
                                                                        </div>
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                            <div onClick={() => { this.AbrirModalModificarElemento(row) }} className='text-center mt-3'>
                                                                <button type="button" className="btn btn-sm btn-outline-primary">Editar</button>
                                                            </div>

                                                            {/* <div>
                                                                <label className="text-primary">Suministro :</label><span>{row.contrato}</span>
                                                                <label className="text-primary mx-1">Cliente :</label><span>{row.nombres}</span>
                                                                <label className="text-primary mx-1">Dirección Entrega :</label><span>{row.direccionentrega}</span>
                                                            </div>
                                                            <div>
                                                                <label className="text-primary">Tipo :</label><span>{row.muestra}</span>
                                                                <label className="text-primary">Recibo :</label><span>{row.recibo}</span>
                                                            </div> */}
                                                        </Popup>


                                                        <Tooltip opacity={0.8} >
                                                            <div>
                                                                <strong>{`${this.state.NombreColumnaAgrupar}: `}</strong>
                                                                <small>{`${row[this.state.NombreColumnaAgrupar]}`}</small>
                                                            </div>

                                                            <div>
                                                                <strong>{`${this.state.nombreColumnaTitulo}: `}</strong>
                                                                <small>{`${row[this.state.nombreColumnaTitulo]}`}</small>
                                                            </div>
                                                        </Tooltip>



                                                    </Marker>
                                            );
                                        }
                                    })
                                }

                            </MapContainer>
                        </div>
                    </div>
                </div>

                {/* Modal Elegir Nombre Columnas Lat Lng */}
                <Modal
                    show={this.state.MostarModalElegirNombreColumna}
                    onHide={() => { this.CerrarModalElegirNombreColumna(false) }}
                    keyboard={false}
                    centered
                    size='md'
                // backdrop="static"
                >
                    <Modal.Body>
                        <div className='mb-2 fw-bold'>
                            <label>Selecciona columnas para colocar las marcas de posicion</label>
                        </div>
                        <div className='fw-light mb-1'>
                            <small>Selecciona las columnas de tu archivo que nos indican dónde se deben colocar las marcas de posición en el mapa como, por ejemplo, direcciones o pares de latitud y longitud. Se importarán todas las columnas.</small>
                        </div>
                        <div className='border rounded overflow-auto p-1' style={{ maxHeight: 200 }}>
                            {
                                this.state.ListaNombresCabecera.map((row, index) => {
                                    // Revisar nombre de columna
                                    return (
                                        <div key={index} className="form-check" >
                                            <input id={index} onChange={(e) => { this.AbrirModalElegirOrigenLatLng(row) }} className="form-check-input" type="radio" name="origen" value={row} />
                                            <label className="form-check-label" htmlFor={index}>
                                                {row}
                                            </label>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className='fw-light mb-1'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col'>
                                        <strong>
                                            Latitud :
                                            <small className='text-primary'> {this.state.nombreColumnaLatitud}</small>
                                        </strong>

                                    </div>
                                    <div className='col'>
                                        <strong>
                                            Longitud :
                                            <small className='text-primary'> {this.state.nombreColumnaLongitud}</small>
                                        </strong>
                                    </div>
                                    <div className='col'>
                                        <strong>
                                            Titulo :
                                            <small className='text-primary'> {this.state.nombreColumnaTitulo}</small>
                                        </strong>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="row">
                                <div className="d-flex justify-content-between">
                                    <div >

                                        <button onClick={() => { this.CerrarModalElegirNombreColumna(true) }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                    </div>

                                    <div >
                                        {
                                            this.state.nombreColumnaLatitud != '' && this.state.nombreColumnaLongitud != '' && this.state.nombreColumnaTitulo != '' ?
                                                <button onClick={() => { this.MapearSuministros() }} className="btn btn-sm btn-primary mx-1 " >Continuar</button>
                                                :
                                                <button onClick={() => { }} className="btn btn-sm btn-primary mx-1 " disabled>Continuar</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>


                {/* Modal Elegir LatLng */}
                <Modal
                    show={this.state.MostarModalElegirOrigenLatLng}
                    onHide={() => { this.CerrarModalElegirOrigenLatLng() }}
                    keyboard={false}
                    centered
                    size='sm'
                    backdrop="static"
                >
                    {/* <Modal.Header >
                        <Modal.Title>{`Nombre Columna: ${this.state.nombreColumnaElegida}`}</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body>
                        <div>
                            {/* <small className='text-primary'>{`Nombre de columna : ${this.state.nombreColumnaElegida}`}</small> */}
                            <div className="form-check">
                                <input onChange={(e) => { this.elegirLatLngColumna(e, this.state.nombreColumnaElegida) }} className="form-check-input" type="radio" name="opciones" value="latitud" id="radioelegirmapear" />
                                <label className="form-check-label" htmlFor="radioelegirmapear">
                                    Latitud
                                </label>
                            </div>
                            <div className="form-check">
                                <input onChange={(e) => { this.elegirLatLngColumna(e, this.state.nombreColumnaElegida) }} className="form-check-input" type="radio" name="opciones" value="longitud" id="radioelegirorigen" />
                                <label className="form-check-label" htmlFor="radioelegirorigen">
                                    Longitud
                                </label>
                            </div>
                            <div className="form-check">
                                <input onChange={(e) => { this.elegirLatLngColumna(e, this.state.nombreColumnaElegida) }} className="form-check-input" type="radio" name="opciones" value="titulo" id="radioelegirtitulo" />
                                <label className="form-check-label" htmlFor="radioelegirtitulo">
                                    Titulo de Marcador
                                </label>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="row">
                                <div >
                                    <div >
                                        <button onClick={() => { this.CerrarModalElegirOrigenLatLng() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                    </div>

                                    {/* <div >
                                        <button onClick={() => { this.CerrarModalElegirOrigenLatLng() }} className="btn btn-sm btn-primary mx-1">Elegir</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>


                {/* Elegir Columna Para Agrupar */}
                <Modal
                    show={this.state.MostrarModalElegirColumnaAgrupar}
                    onHide={() => { this.CerrarModalElegirColumnaAgrupar(false) }}
                    keyboard={false}
                    centered
                    size='md'
                // backdrop="static"
                >
                    <Modal.Body>
                        <div className='mb-2 fw-bold'>
                            <label>Selecciona columnas para colocar las marcas de agrupación</label>
                        </div>
                        <div className='fw-light mb-1'>
                            <small>Selecciona las columnas de tu archivo que nos indican dónde se deben colocar las marcas de agrupación en el mapa como, Se agruparan por el nombre de la columna elegida.</small>
                        </div>
                        <div className='border rounded overflow-auto p-1' style={{ maxHeight: 200 }}>
                            {
                                this.state.ListaNombresCabecera.map((row, index) => {
                                    // Revisar nombre de columna
                                    return (
                                        <div key={index} className="form-check" >
                                            <input id={index} onChange={(e) => { this.CambiarColumnaAgrupacion(row) }} className="form-check-input" type="radio" name="origen" value={row} />
                                            <label className="form-check-label" htmlFor={index}>
                                                {row}
                                            </label>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="row">
                                <div className="d-flex justify-content-between">
                                    <div >
                                        <button onClick={() => { this.CerrarModalElegirColumnaAgrupar(true) }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                    </div>

                                    <div >
                                        <button onClick={() => { this.BuscarListaElementosAgrupados() }} className="btn btn-sm btn-primary mx-1 " >Aceptar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Modal Reasignar Nombre Agrupacion*/}
                <Modal
                    show={this.state.MostrarModalReasignarValorAgrupacion}
                    onHide={() => { this.CerrarModalReasignarValorAgrupacion() }}
                    keyboard={false}
                    centered
                    size='sm'
                    backdrop="static"
                >
                    <Modal.Body>
                        <div>
                            <label className='text-primary'>Reasignar Valor de Agrupación</label>
                        </div>
                        <div>
                            <small className='my-1 fw-light'>
                                Ingrese el valor o identificador del grupo de marcadores a modificar, este nommbre se utilizara para agrupar los elementos seleccionados actuales.
                            </small>
                        </div>
                        {/* <div className='mt-3'>
                            <small className="text-primary ">Nombre Valor del Agrupamiento</small>
                            <input type="text" className="form-control" placeholder="Nombre Agrupación" value={this.state.NombreAsignadoCambiar} disabled />
                        </div> */}

                        <div className='mt-1'>
                            <small className="text-primary ">Cambiar el valor de agrupacion {this.state.NombreColumnaAgrupar}</small>
                            <input ref={this.inputTextValorAgrupacionModificar} type="text" className="form-control" id="" placeholder="Nuevo Valor de Agrupación" />
                        </div>

                        <div className='mt-1'>
                            <small className="text-primary ">Obs. Modificación</small>
                            <input ref={this.inputTextObsAgrupacionModificar} type="text" className="form-control" id="" placeholder="Obs. Modificación" value={`Modificado [Valor_Actual]=>[Valor_nuevo]`} disabled />
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="row">
                                <div className='d-flex justify-content-between'>
                                    <div >
                                        <button onClick={() => { this.CerrarModalReasignarValorAgrupacion() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                    </div>

                                    <div >
                                        <button onClick={() => { this.ModificarAgrupacion() }} className="btn btn-sm btn-primary mx-1">Asignar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>


                {/* Modal Agregar Nombre */}
                {/* <Modal
                    show={this.state.MostrarModalAsignarMarcadores}
                    onHide={() => { this.CerrarModalAsignarMarcadores() }}
                    keyboard={false}
                    centered
                    size='md'
                    backdrop="static"
                >
                    
                    <Modal.Body>
                        <div>
                            <label className='text-primary'>Nombre del Agrupamiento</label>
                        </div>
                        <div>
                            <small className='my-1 fw-light'>
                                Ingrese el nombre o identificador del grupo de marcadores seleccionados, este nombre identificara a los marcadores y permitira mostrarlos y diferenciarlos de los marcadores no seleccionados.
                            </small>
                        </div>
                        <div className='mt-3'>
                            <small className="text-primary ">Nombre del Segmento Agrupado</small>
                            <input ref={this.inputTextNombreAgrupacion} type="text" className="form-control" id="floatingInput" placeholder="Nombre Agrupación" />
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="row">
                                <div className='d-flex justify-content-between'>
                                    <div >
                                        <button onClick={() => { this.CerrarModalAsignarMarcadores() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                    </div>

                                    <div >
                                        <button onClick={() => { this.validarModalAsignarMarcadores() }} className="btn btn-sm btn-primary mx-1">Asignar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal> */}

                {/* Modal Reasignar Nombre */}
                {/* <Modal
                    show={this.state.MostrarModalReasignarMarcadores}
                    onHide={() => { this.CerrarModalReasignarMarcadores() }}
                    keyboard={false}
                    centered
                    size='md'
                    backdrop="static"
                >                    
                    <Modal.Body>
                        <div>
                            <label className='text-primary'>Reasignar Nombre del Agrupamiento</label>
                        </div>
                        <div>
                            <small className='my-1 fw-light'>
                                Ingrese el nombre o identificador del grupo de marcadores seleccionados, este nombre identificara a los marcadores y permitira mostrarlos y diferenciarlos de los marcadores no seleccionados.
                            </small>
                        </div>
                        <div className='mt-3'>
                            <small className="text-primary ">Nombre Actual del Agrupamiento</small>
                            <input type="text" className="form-control" placeholder="Nombre Agrupación" value={this.state.NombreAsignadoCambiar} disabled />
                        </div>

                        <div className='mt-1'>
                            <small className="text-primary ">Nombre Reasignado</small>
                            <input ref={this.inputTextNombreReasignarAgrupacion} type="text" className="form-control" id="floatingInput" placeholder="Nombre Agrupación" />
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="row">
                                <div className='d-flex justify-content-between'>
                                    <div >
                                        <button onClick={() => { this.CerrarModalReasignarMarcadores() }} className="btn btn-sm btn-outline-secondary mx-1">Cerrar</button>
                                    </div>

                                    <div >
                                        <button onClick={() => { this.validarModalReasignarMarcadores() }} className="btn btn-sm btn-primary mx-1">Asignar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal> */}

                {/* Liberar Suministros  */}
                <Modal
                    show={this.state.MostrarModalModificarElemento}
                    onHide={() => { this.CerrarModalModificarElemento() }}
                    keyboard={false}
                    size='sm'
                    centered
                >
                    <Modal.Header >
                        <Modal.Title>{`Modificar Elemento`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">
                            {
                                this.state.claves_mostrar.map((row, index) => {
                                    // console.log(row);
                                    return (
                                        <div key={index} className="row mt-1">
                                            <div className='col'>
                                                <small className="text-primary ">{row}</small>
                                            </div>
                                            <div className='col'>
                                                <input onChange={(ev) => { this.CambiarDatosElementoMostrar(ev, row) }} type="text" className="form-control form-control-sm" placeholder={this.state.ElementoMostrar[row]} />
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="text-end">
                                <button onClick={() => { this.GuardarModificacion() }} className="btn btn-sm btn-primary">Modificar</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>

            </Fragment>
        );
    }
}

export default MapaReordenamiento;