import React, { Component, createRef } from 'react';
import { Fragment } from 'react';
import NavBar from './../dashboard/navBar';
import TituloPagina from './../dashboard/headerTitle';
import LoadingModal from './../utils/loadModal'
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Modal } from 'react-bootstrap';
// React LeafLet
import { MapContainer, Marker, TileLayer, Tooltip, FeatureGroup } from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

//Turf JS
import * as turf from '@turf/turf';

import { Pin1, Pin2, Pin3, Pin4, Pin5, Pin6, Pin7, Pin8, Pin9, Pin10, Pin11, Pin12, Pin13, Pin14, Pin15, Pin16, Pin17, Pin18, Pin19, Pin20, IconoWorkFree, IconoWorkSelect } from './../../config/icono'
import moment from 'moment';
import config from './../../config/config'
import { ValidarUsuario, ObtenerUsuario, ValidarPermisos, LIST_SCREENS, listarPermisos } from './../utils/utils'
import { ThreeSixty } from '@material-ui/icons';


class programacionMapa extends Component {


    constructor(props) {
        super(props);
        this.state = {
            OcultarLoading: true,
            loadMapa: false,
            fechaProgramacion: '',
            MostrarPorcentajeCarga: false,
            MostrarElegirTrabajador: false,
            MostrarCambiarGPS: false,
            MostrarLiberarSuministros: false,
            MostrarSuministrosSinAsignar: false,
            centro: {
                lat: -16.398821,
                lng: -71.536862
            },
            zoomMapa: 13,
            ListaTrabajadoresAsignados: [],
            ListaTrabajadoresActivos: [],
            listaProgramacion: [],
            ListaSuministrosSinGPS: [],
            ListaSuministrosSinAsignar: [],
            ListaGPSSuministros: [],
            ListaMarcadoresSeleccionados: [],
            TipoTrabajos: [],
            suministro: {},
            asignacion: {},
            ListaPines: [Pin1, Pin2, Pin3, Pin4, Pin5, Pin6, Pin7, Pin8, Pin9, Pin10, Pin11, Pin12, Pin13, Pin14, Pin15, Pin16, Pin17, Pin18, Pin19, Pin20]
        }
        // Referencias
        this.inputcheckMostrarSuministros = createRef();
        this.inputFechaProgramacion = createRef();
        this.selectTipoTrabajo = createRef();

        //Referencias CambiarGPs
        this.inputLat = createRef();
        this.inputLng = createRef();

        //Asignar Trabajador
        this.inputReasignarTrabajador = createRef();

        //Referencias FeatureGroup React-Leafleat-draw
        this.featureGroupRef = createRef();

    };

    async componentDidMount() {
        if (ValidarUsuario()) {

            listarPermisos().then(async (permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_PROGRAM_MAPA, permisos)) {
                    await this.setState({ fechaProgramacion: moment().format('YYYY-MM-DD') });
                    // await this.BuscarProgramacionPorFecha();
                    await this.BuscarListaTiposTrabajo();

                } else {
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });
                    setTimeout(() => {
                        this.props.history.push('/');
                    }, 1000);
                }

            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    // *****************************************
    // OPERACIONES
    // *****************************************
    BuscarProgramacionFecha = async () => {
        this.setState({ loadMapa: false });

        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = '';
                url = `${config.endPoint}api/trabajosuministro/listarProgramacionDia/${this.selectTipoTrabajo.current.value}/${this.inputFechaProgramacion.current.value}`;
                // console.log(url);

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {

                        this.setState({ listaProgramacion: data.content });
                        // console.log(data.content);
                        //Buscar GPS

                        if (this.state.MostrarSuministrosSinAsignar) {
                            let SuministrosSinAsignar = data.content.filter(x => x.idpersonalasignado === null);

                            this.setState({ ListaGPSSuministros: SuministrosSinAsignar })
                        } else {
                            this.setState({ ListaGPSSuministros: data.content })
                        }


                        this.ElegirMarcador();
                        resolve(true);
                    }
                    else {
                        this.setState({ listaProgramacion: [], })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }

        });

    }

    ElegirMarcador = async () => {
        // console.log(this.state.ListaGPSSuministros);
        let _listasuministrosTemp = this.state.ListaGPSSuministros;
        //Buscar Lista de TECNICOS
        let arrayTrabajadores = [];
        let _existe = false;
        let _cantMarcadores = 20;
        for (let x = 0; x < _listasuministrosTemp.length; x++) {
            _existe = false;
            let _temp = _listasuministrosTemp[x].idpersonalasignado
            for (let y = 0; y < arrayTrabajadores.length; y++) {
                // console.log(_temp);
                if (arrayTrabajadores[y] === _temp) {
                    _existe = true;
                    break;
                }
            }
            if (!_existe && _temp !== null) {
                arrayTrabajadores.push(_temp);
            }
        }

        //Asignar Valores a Marcador
        for (let x = 0; x < _listasuministrosTemp.length; x++) {
            for (let y = 0; y < arrayTrabajadores.length; y++) {
                if (_listasuministrosTemp[x].idpersonalasignado === arrayTrabajadores[y]) {
                    _listasuministrosTemp[x].marker = y - (Math.trunc(y / _cantMarcadores) * _cantMarcadores);
                    break;
                }
            }
        }
        await this.setState({ ListaGPSSuministros: _listasuministrosTemp, loadMapa: true });

        // console.log(arrayTrabajadores);
        // console.log(this.state.ListaGPSSuministros);
    }

    BuscarProgramacionAsignada = async () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = '';
                url = `${config.endPoint}api/trabajosuministro/listarProgramacionDiaPorTecnico/${this.selectTipoTrabajo.current.value}/${this.inputFechaProgramacion.current.value}`;
                // console.log(url);

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        this.setState({ ListaTrabajadoresAsignados: data.content });
                        // console.log(data.content);
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaTrabajadoresAsignados: [], })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }

    BuscarListaTrabajadoresActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajador/listar/ACTIVO`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaTrabajadoresActivos: data.content });
                        resolve(true);
                    }
                    else {
                        this.setState({ ListaTrabajadoresActivos: [] })
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                        resolve(false);
                    }

                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });

    }
    BuscarListaTiposTrabajo = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/tipotrabajo/listar`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                if (data.content) {
                    this.setState({ TipoTrabajos: data.content });
                }
                else {
                    this.setState({ TipoTrabajos: [] });
                    this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'warning' });
                }
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }

    }

    ExisteMarcador = (id) => {
        let existe = false;
        for (let x = 0; x < this.state.ListaMarcadoresSeleccionados.length; x++) {
            if (this.state.ListaMarcadoresSeleccionados[x].idTrabajosuministro === id) {
                existe = true;
                break;
            }
        }
        return existe;
    }
    PosicionMarcadorSeleccionado = (id) => {
        let posicion = -1;
        for (let x = 0; x < this.state.ListaMarcadoresSeleccionados.length; x++) {
            if (this.state.ListaMarcadoresSeleccionados[x].idTrabajosuministro === id) {
                posicion = x;
                break;
            }
        }
        return posicion;
    }

    AgregarMarcador = (indice, row) => {
        // console.log(indice);
        // console.log(row);
        if (row.idpersonalasignado === null) {
            //Buscar si el marcador existe
            if (!this.ExisteMarcador(row.idTrabajosuministro)) {
                //Cambiar el estado del Marcador
                let _GPsSuministrosTemp = this.state.ListaGPSSuministros;
                _GPsSuministrosTemp[indice].seleccionado = true;

                let marcadoresTemp = this.state.ListaMarcadoresSeleccionados;
                marcadoresTemp.push(row);
                //Actualizar Lista de Suministros
                this.setState({ ListaGPSSuministros: _GPsSuministrosTemp, ListaMarcadoresSeleccionados: marcadoresTemp });
                // console.log(this.state.ListaMarcadoresSeleccionados);         
            } else {
                //Quitando el estado del Marcador
                let _GPsSuministrosTemp = this.state.ListaGPSSuministros;
                _GPsSuministrosTemp[indice].seleccionado = false;
                //Eliminando Marcador de Array MarcadoresSeleccionados

                let posicion = this.PosicionMarcadorSeleccionado(row.idTrabajosuministro);
                // console.log("posicion seleccionad");
                // console.log(posicion);
                let marcadoresTemp = this.state.ListaMarcadoresSeleccionados;
                marcadoresTemp.splice(posicion, 1);
                //Actualizar Marcadores y Seleccionados
                this.setState({ ListaGPSSuministros: _GPsSuministrosTemp, ListaMarcadoresSeleccionados: marcadoresTemp });
                // console.log(this.state.ListaMarcadoresSeleccionados);         
            }
        }
        else {
            this.setState({ mensajeToast: `Suministro ya esta asigando a ${row.nombretrabajador}`, abrirToast: true, tipotoast: 'warning' });
        }
    }

    ValidarBusqueda = async () => {

        let errores = 0;
        if (this.selectTipoTrabajo.current.value == "TODOS") {
            this.selectTipoTrabajo.current.classList.add('is-invalid');
            errores++;
        }

        if (this.inputFechaProgramacion.current.value == "") {
            this.inputFechaProgramacion.current.classList.add('is-invalid');
            errores++;
        }

        if (errores == 0) {
            await this.setState({ OcultarLoading: false });
            this.selectTipoTrabajo.current.classList.remove('is-invalid');
            this.inputFechaProgramacion.current.classList.remove('is-invalid');

            //Promesa:Buscar Lista de Trabajos
            await this.BuscarProgramacionFecha().then().catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });

            //Verificar Cantidad Suministros Sin GPS
            let sinGps = await this.state.listaProgramacion.filter(x => x.lat_inicio == 0 || x.lng_inicio == 0);

            //Cantidad de Suministros sin Asignar
            let sinAsignar = await this.state.listaProgramacion.filter(x => x.idpersonalasignado === null);

            //Verificar Lista Trabajadores
            await this.BuscarProgramacionAsignada().then().catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            });


            await this.setState({ ListaSuministrosSinGPS: sinGps, ListaSuministrosSinAsignar: sinAsignar, OcultarLoading: true, ListaMarcadoresSeleccionados: [] });
            this._eliminarPoligonos();

        }
    }

    GuardarGPSSuministro = async () => {
        let error = 0;
        let _lat = parseFloat(this.inputLat.current.value);
        let _lng = parseFloat(this.inputLng.current.value);

        if (isNaN(_lat) || _lat == 0) {
            this.inputLat.current.classList.add('is-invalid');
            error++;
        }
        if (isNaN(_lng) || _lng == 0) {
            this.inputLng.current.classList.add('is-invalid');
            error++;
        }

        if (error === 0) {
            this.inputLat.current.classList.remove('is-invalid');
            this.inputLng.current.classList.remove('is-invalid');

            //Actualizar GPS
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let url = `${config.endPoint}api/trabajosuministro/modificar`;
            let _trabajosuministro = {
                lat_inicio: _lat,
                lng_inicio: _lng,
                idTrabajosuministro: this.state.suministro.idTrabajosuministro
            }
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(_trabajosuministro)
            };

            await fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    //Buscar Valores
                    await this.ValidarBusqueda();
                    await this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'success' });
                    this.CerrarModalCambiarGPS();
                }
                else {
                    data.error ? this.setState({ abrirToast: true, mensajeToast: data.error, tipotoast: 'error' }) :
                        this.setState({ abrirToast: true, mensajeToast: data.message, tipotoast: 'error' });
                }
            }).catch(async (error) => {
                //Error de conexion                                                
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
            //Reiniciar Valores            
        }
    }
    CambiarMostrarSuministros = (ev) => {
        this.setState({ MostrarSuministrosSinAsignar: ev.target.checked });
        this.ValidarBusqueda();
        // console.log(this.inputcheckMostrarSuministros);
    }

    BuscarIdTrabajadorReasignado = async (trabajadorReasignado) => {
        for await (const trabajador of this.state.ListaTrabajadoresActivos) {
            if (trabajadorReasignado.indexOf(trabajador.nombretrabajador) > -1) {
                return trabajador.idtrabajador;
            }
        }
        return null;
    }
    ValidarModalReasignarTrabajador = () => {
        if (this.inputReasignarTrabajador.current.value.trim() === "") {
            this.setState({ mensajeToast: "Debe elegir un Trabajador de la lista.", abrirToast: true, tipotoast: 'warning' });
            this.inputReasignarTrabajador.current.classList.add('is-invalid');
            return false;
        }
        else {
            this.inputReasignarTrabajador.current.classList.remove('is-invalid');
        }
        return true;
    }

    AsignarTrabajador = async () => {
        if (ValidarUsuario()) {
            if (this.ValidarModalReasignarTrabajador()) {
                let IdTrabajadorReasignado = await this.BuscarIdTrabajadorReasignado(this.inputReasignarTrabajador.current.value);
                if (IdTrabajadorReasignado) {

                    await this.setState({ OcultarLoading: false });

                    this.inputReasignarTrabajador.current.classList.remove('is-invalid');
                    // Reasignar a los suministros Seleccionados
                    let url = `${config.endPoint}api/trabajosuministro/programacionreasignar/idTrabajador`;
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);
                    let nTrabajoSuministro = {
                        idpersonalasignado: IdTrabajadorReasignado,
                        ListaSuministros: this.state.ListaMarcadoresSeleccionados
                    }
                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(nTrabajoSuministro)
                    };
                    // console.log(nTrabajoSuministro);
                    await fetch(url, headers).then((respuesta) => {
                        return respuesta.json();
                    }).then((data) => {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'success' });
                        this.ValidarBusqueda();
                        this.CerrarModalElegirTrabajador();
                    }).catch(() => {
                        this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                    });

                    await this.setState({ OcultarLoading: true });

                }
                else {
                    this.setState({ abrirToast: true, mensajeToast: "Elija un nombre de la lista de trabajadores", tipotoast: 'warning' });
                    this.inputReasignarTrabajador.current.value = '';
                    this.inputReasignarTrabajador.current.classList.add('is-invalid');
                }
            }
        }
        else {
            this.props.history.push('/login');
        }
    }

    LiberarTrabajo = async () => {
        if (ValidarUsuario()) {

            await this.setState({ OcultarLoading: false });

            // Reasignar a los suministros Seleccionados
            let url = `${config.endPoint}api/trabajosuministro/liberarProgramacion/idtrabajador`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let nTrabajoSuministro = {
                idpersonalasignado: this.state.asignacion.idpersonalasignado,
                fechaProgramacion: this.inputFechaProgramacion.current.value,
                idtipotrabajo: this.selectTipoTrabajo.current.value
            }
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nTrabajoSuministro)
            };
            // console.log(nTrabajoSuministro);
            await fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {
                this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'success' });
                this.ValidarBusqueda();
                this.CerrarModalLiberarSuministros();
            }).catch(() => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });

            await this.setState({ OcultarLoading: true });
        }
        else {
            this.props.history.push('/login');
        }

    }
    QuitarMarcadorSeleccionado = () => {
        let _sumGPS = this.state.ListaGPSSuministros;
        for (let x = 0; x < _sumGPS.length; x++) {
            _sumGPS[x].seleccionado = false;
        }
        this.setState({ ListaMarcadoresSeleccionados: [], ListaGPSSuministros: _sumGPS });
    }
    MapearTodosSuministros = () => {
        this.setState({ ListaGPSSuministros: this.state.listaProgramacion });
    }

    MapearSuministrosTrabajador = (row) => {
        // console.log(row);
        let _gpsSuministro = this.state.listaProgramacion.filter(x => x.idpersonalasignado === row.idpersonalasignado);
        this.setState({ ListaGPSSuministros: _gpsSuministro });
    }

    QuitarMapeoSuministros = () => {
        this.setState({ ListaGPSSuministros: [] });
    }

    // *****************************************
    // MODALES
    // *****************************************
    AbrirModalElegirTrabajador = async () => {
        // Buscar Lista de Trabajadores
        if (this.state.ListaMarcadoresSeleccionados.length > 0) {
            await this.setState({ OcultarLoading: false });
            await this.BuscarListaTrabajadoresActivos().then().catch((error) => {
                if (error) {
                    this.props.history.push('/login');
                }
            })
            this.setState({ MostrarElegirTrabajador: true, OcultarLoading: true });
        }
    }

    CerrarModalElegirTrabajador = () => {
        this.setState({ MostrarElegirTrabajador: false });
    }

    AbrirModalCambiarGps = async (row) => {
        // console.log(row);
        await this.setState({ suministro: row, MostrarCambiarGPS: true });
        this.inputLat.current.value = row.lat_inicio;
        this.inputLng.current.value = row.lng_inicio;
    }

    CerrarModalCambiarGPS = () => {
        this.setState({ MostrarCambiarGPS: false });
    }

    AbrirModalLiberarSuministros = (row) => {
        // console.log(row);
        this.setState({ MostrarLiberarSuministros: true, asignacion: row });
    }

    CerrarModalLiberarSuministros = () => {
        this.setState({ MostrarLiberarSuministros: false });
    }

    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    // Procedimientos DrawLeafLet   

    RevisarPuntosEnPoligono = async (coord_polig) => {
        //Limpiar Marcadores
        // console.log(coord_polig);
        let marcadoresTemp = [];
        //
        let gpsSuministros = this.state.ListaGPSSuministros;
        //Obteniendo Datos de Poligono
        let _poligono = turf.polygon(coord_polig);

        for (let x = 0; x < gpsSuministros.length; x++) {
            if (gpsSuministros[x].idpersonalasignado === null) {
                // console.log(gpsSuministros[x]);
                let _punto = turf.point([parseFloat(gpsSuministros[x].lng_inicio), parseFloat(gpsSuministros[x].lat_inicio)]);
                let _busqueda = turf.pointsWithinPolygon(_punto, _poligono);

                if (_busqueda.features.length > 0) {
                    //Punto Seleccionado
                    gpsSuministros[x].seleccionado = true;
                    marcadoresTemp.push(gpsSuministros[x]);
                }
                else {
                    gpsSuministros[x].seleccionado = false;
                }
            }
        }
        await this.setState({ ListaGPSSuministros: gpsSuministros, ListaMarcadoresSeleccionados: marcadoresTemp });
        // console.log(this.state.ListaMarcadoresSeleccionados);
    }

    _onCreate = (ev) => {
        // console.log(ev);        
        // let polygon=ev.layer.getLatLngs();
        let coords = ev.layer.toGeoJSON();
        let coordenadas = coords.geometry.coordinates
        // let tipo = coords.geometry.type;

        this.RevisarPuntosEnPoligono(coordenadas);
    }

    _onEdited = (ev) => {
        let { layers: { _layers } } = ev;
        let primeraPosicion;
        let coordenadas = [];
        // console.log(_layers);
        Object.values(_layers).map((_leafleat_id) => {
            let _coords = _leafleat_id._latlngs[0].map((e, index) => {
                let LatLng = e;
                if (index === 0) {
                    primeraPosicion = [LatLng.lng, LatLng.lat];
                }
                return [LatLng.lng, LatLng.lat];
            });
            _coords.push(primeraPosicion);
            coordenadas.push(_coords);
            this.RevisarPuntosEnPoligono(coordenadas);
        });
    }

    _onDeleted = (ev) => {
        // console.log(ev);
        //Reiniciando Elementos Seleccionados        
        let gpsSuministros = this.state.ListaGPSSuministros;
        for (let x = 0; x < gpsSuministros.length; x++) {
            gpsSuministros[x].seleccionado = false;
        }
        this.setState({ ListaGPSSuministros: gpsSuministros, ListaMarcadoresSeleccionados: [] });
    }

    _eliminarPoligonos = () => {
        let _layers = this.featureGroupRef.current._layers;
        Object.keys(_layers).forEach((layerid, index) => {
            _layers[layerid].remove();
        });
    }

    CargarGPS = async () => {

        await this.setState({ OcultarLoading: false });
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let url = `${config.endPoint}api/trabajosuministro/cargarGps`;
        let programacionTrabajos = {
            fecha: this.inputFechaProgramacion.current.value,
            idtipotrabajo: this.selectTipoTrabajo.current.value
        }
        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body: JSON.stringify(programacionTrabajos)
        };
        // console.log("send " +elem.suministro );
        fetch(url, headers).then((respuesta) => {
            return respuesta.json();
        }).then((data) => {
            this.setState({ mensajeToast: "Los GPS estan siendo cargados en el servidor esto puede demorar, espere un momento y actualice esta pagina.", abrirToast: true, tipotoast: 'info', OcultarLoading: true });
        }).catch((error) => {
            this.setState({ mensajeToast: error.toString, abrirToast: true, tipotoast: 'warning', OcultarLoading: true });
        });

        // this.setState({ OcultarLoading: true });
    }


    render() {
        return (
            <Fragment>
                <Snackbar
                    open={this.state.MostrarPorcentajeCarga}
                    message={this.state.mensaCargaProgramacion}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
                {/* Modal de Carga */}
                <div hidden={this.state.OcultarLoading}>
                    <LoadingModal />
                </div>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>

                <NavBar CerrarSesion={this.CerrarSesion} />
                <TituloPagina titulo="PROGRAMACIÓN-MAPA" regresar={() => { this.props.history.push('/programacion') }}></TituloPagina>


                <div className="container-fluid">
                    <div className="row m-1" >
                        <div className="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-3  overflow-auto" style={{ maxHeight: '89vh' }}>
                            <div className="border p-1">
                                <div className="text-center">
                                    <i onClick={() => { this.QuitarMapeoSuministros() }} title="Quitar Mapeo" className="fas fa-unlink mx-2 iconoAcciones" ></i>
                                    <i onClick={() => { this.MapearTodosSuministros() }} title="Mapear Todos" className="fas fa-draw-polygon mx-2 iconoAcciones"></i>
                                    <i onClick={() => { this.QuitarMarcadorSeleccionado() }} title="Quitar Selección" className="fas fa-eraser mx-2 iconoAcciones"></i>
                                    <i onClick={() => { this._eliminarPoligonos() }} title="Borrar Poligono" className="far fa-trash-alt mx-2 iconoAcciones"></i>
                                </div>
                                <div className="form-check">
                                    <input onChange={(ev) => { this.CambiarMostrarSuministros(ev) }} ref={this.inputcheckMostrarSuministro} className="form-check-input" type="checkbox" />
                                    <label className="form-check-label" >
                                        <small>Mostrar solo suministros sin asignar</small>
                                    </label>
                                </div>

                                {/* fECHA */}
                                <div className="my-1">
                                    <div className="d-flex justify-content-between">
                                        <small className="text-primary">Fecha</small>
                                        <i onClick={() => { this.ValidarBusqueda()}} title="Actualizar" className="fas fa-sync-alt mx-2 iconoUpdate text-end" ></i>                                        
                                    </div>
                                    <input onChange={() => { this.ValidarBusqueda() }} className="form-control form-control-sm p-1" type="date" ref={this.inputFechaProgramacion}></input>
                                </div>

                                {/* TIPOS DE TRABAJO */}
                                <div className="my-1">
                                    <small className="text-primary">Tipo Trabajo</small>
                                    <select onChange={() => { this.ValidarBusqueda() }} className="form-select form-select-sm" ref={this.selectTipoTrabajo}>
                                        <option value="TODOS">Todos</option>
                                        {this.state.TipoTrabajos.map((row, index) => {
                                            return (<option key={index} value={row.idTipotrabajo}>{row.tipotrabajo}</option>)
                                        })
                                        }
                                    </select>
                                </div>

                                <div className="d-grid mt-2">
                                    <button onClick={() => { this.AbrirModalElegirTrabajador() }} className="btn btn-sm btn-primary">
                                        <i className="fas fa-link mx-1"></i>
                                        {
                                            this.state.ListaMarcadoresSeleccionados.length > 0 ?
                                                `Asignar ${this.state.ListaMarcadoresSeleccionados.length} suministros selecionados`
                                                : `Elija los suministros a Asignar`
                                        }
                                    </button>
                                </div>

                            </div>
                            {/* Suministros sin GPS */}
                            {
                                this.state.ListaSuministrosSinGPS.length > 0 ?
                                    <div className="border border-danger my-1 p-1">
                                        <div className="alert alert-danger">
                                            <i className="fas fa-exclamation-triangle me-2"></i>
                                            <strong onClick={() => { this.CargarGPS() }} className="pointer-link link-danger text-decoration-underline mx-1" >Corregir</strong>
                                            <label className="text-danger">{`${this.state.ListaSuministrosSinGPS.length} Suministros sin GPS, `}</label>
                                        </div>

                                        <TableContainer style={{ maxHeight: '400px' }}>
                                            <Table size='small' stickyHeader>
                                                <TableHead>
                                                    <TableRow className="fondoTituloTabla border" >
                                                        <TableCell >Suministro</TableCell>
                                                        <TableCell>*</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.ListaSuministrosSinGPS.map((row, index) => {
                                                        return (
                                                            <TableRow key={index} >
                                                                <TableCell style={{ fontSize: '0.75em' }}>{row.suministro}</TableCell>
                                                                <TableCell onClick={() => { this.AbrirModalCambiarGps(row) }} className="iconoAcciones" style={{ fontSize: '0.75em' }}>
                                                                    <i title="Asignar GPS" className="fas fa-satellite-dish iconoAcciones "></i>
                                                                    <span>Corregir</span>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </div>

                                    :
                                    <></>
                            }

                            {/* Asignaciones*/}
                            {
                                this.state.ListaTrabajadoresAsignados.length > 0 ?
                                    <div className="border border-info my-1 p-1">
                                        <div className="text-center">
                                            <strong>Asignaciones</strong>
                                        </div>
                                        {
                                            this.state.ListaSuministrosSinAsignar.length > 0 ?
                                                <div>
                                                    <small className="text-secondary">{`${this.state.ListaSuministrosSinAsignar.length} suministros sin asignar`}</small>
                                                </div>
                                                : <></>
                                        }
                                        <TableContainer style={{ maxHeight: '400px' }}>
                                            <Table size='small' stickyHeader  >
                                                <TableHead >
                                                    <TableRow >
                                                        <TableCell>[Asignado] Trabajador</TableCell>
                                                        {/* <TableCell>Distrito</TableCell> */}
                                                        <TableCell>Acciones</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody >
                                                    {this.state.ListaTrabajadoresAsignados.map((row, index) => {
                                                        if (row.idpersonalasignado != null)
                                                            return (
                                                                <TableRow key={index}>
                                                                    <TableCell style={{ fontSize: '0.75em' }}>{`[${row.asignado}] ${row.nombretrabajador}`}</TableCell>
                                                                    <TableCell>
                                                                        <i onClick={() => { this.AbrirModalElegirTrabajador(row) }} title="Reasignar" className="fas fa-exchange-alt mx-2 iconoAcciones"></i>
                                                                        <i onClick={() => { this.MapearSuministrosTrabajador(row) }} title="Mapear" className="fas fa-route mx-2 iconoAcciones"></i>
                                                                        <i onClick={() => { this.AbrirModalLiberarSuministros(row) }} title="Liberar" className="fas fa-unlock-alt mx-2 iconoAcciones"></i>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </div>
                                    : <></>

                            }


                        </div>
                        <div className="col-12 col-sm-7 col-md-7 col-lg-8 col-xl-9">
                            <MapContainer center={this.state.centro} zoom={this.state.zoomMapa} style={{ height: `89vh` }}>
                                {/* <TileLayer
                                    url='https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png'
                                    maxZoom={20}
                                    attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                                /> */}
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <FeatureGroup ref={this.featureGroupRef}>
                                    <EditControl
                                        position="topright"
                                        onCreated={this._onCreate}
                                        onEdited={this._onEdited}
                                        onDeleted={this._onDeleted}
                                        draw={{
                                            rectangle: false,
                                            polyline: false,
                                            circlemarker: false,
                                            marker: false,
                                            circle: false
                                        }} />
                                </FeatureGroup>

                                {

                                    this.state.ListaGPSSuministros.map((row, index) => {
                                        return (
                                            row.idpersonalasignado == null ?
                                                <Marker draggable eventHandlers={{ click: () => { this.AgregarMarcador(index, row) } }} key={index} title="" position={[row.lat_inicio, row.lng_inicio]} icon={row.seleccionado ? IconoWorkSelect : IconoWorkFree} >
                                                    <Tooltip opacity={0.8}>
                                                        <div>
                                                            <small className="text-primary">Suministro :</small><span>{row.suministro}</span>
                                                        </div>
                                                        <div>
                                                            <small className="text-primary">Tipo :</small><span>{row.tipotrabajo}</span>
                                                        </div>
                                                        <div>
                                                            <small className="text-primary">Asignado :</small><span className="text-danger">Sin Asignar</span>
                                                        </div>

                                                    </Tooltip>
                                                </Marker>
                                                : <Marker draggable eventHandlers={{ click: () => { this.AgregarMarcador(index, row) } }} key={index} title="" position={[row.lat_inicio, row.lng_inicio]} icon={row.marker ? this.state.ListaPines[row.marker] : this.state.ListaPines[0]}>
                                                    <Tooltip opacity={0.8}>
                                                        <div>
                                                            <small className="text-primary">Suministro :</small><span>{row.suministro}</span>
                                                        </div>
                                                        <div>
                                                            <small className="text-primary">Tipo :</small><span>{row.tipotrabajo}</span>
                                                        </div>
                                                        <div>
                                                            <small className="text-primary">Asignado :</small><span>{row.nombretrabajador}</span>
                                                        </div>
                                                    </Tooltip>
                                                </Marker>
                                        );
                                    })
                                }

                            </MapContainer>
                        </div>
                    </div>
                </div>


                {/* Ventanas Modales */}
                {/* Asignar Tecnico */}
                <Modal
                    show={this.state.MostrarElegirTrabajador}
                    onHide={() => { this.CerrarModalElegirTrabajador() }}
                    keyboard={false}
                    centered >

                    <Modal.Header>
                        <Modal.Title>Reasignar Trabajador</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="container">
                            <div className="row">
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <small className="text-primary">Reasignar a trabajador</small>
                                    <input ref={this.inputReasignarTrabajador} required type="" className="form-control " list="listaTrabajadores" placeholder="Elija el nombre del trabajador"></input>
                                    <datalist id="listaTrabajadores">
                                        {
                                            this.state.ListaTrabajadoresActivos.map((row, index) => {
                                                return (<option key={index} value={row.nombretrabajador} />)
                                            })
                                        }
                                    </datalist>
                                    <small className="text-secondary">Si no encuentra el trabajador en esta lista probablemente no este agregado a la Base de Trabajadores o su estado sea "INACTIVO"</small>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col  text-end">
                                    <button onClick={() => { this.AsignarTrabajador(); }} className=" btn btn-outline-primary " >
                                        <i className="fas fa-exchange-alt p-1"></i>
                                        <span>Asignar Trabajos</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer>
                        <div className="">
                            <button onClick={() => { this.CerrarModalElegirTrabajador() }} className="btn btn-outline-secondary mx-1">Cerrar</button>
                        </div>
                    </Modal.Footer> */}

                </Modal>

                {/* Modal Asignar GPS */}
                <Modal
                    show={this.state.MostrarCambiarGPS}
                    onHide={() => { this.CerrarModalCambiarGPS() }}
                    keyboard={false}
                    centered>
                    <Modal.Header>
                        <Modal.Title>{`Asignar GPS [${this.state.suministro.suministro}]`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-3">
                                    <label>Latitud(<span className="text-danger">*</span>)</label>
                                </div>
                                <div className="col-9">
                                    <input ref={this.inputLat} className="form-control form-control-sm"></input>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-3">
                                    <label>Longitud(<span className="text-danger">*</span>)</label>
                                </div>
                                <div className="col-9">
                                    <input ref={this.inputLng} className="form-control form-control-sm"></input>
                                </div>
                            </div>
                            <div className="my-2">
                                <small className="text-secondary">Ingrese las coordenadas GPS del suministro</small>
                            </div>
                            <div className="mt-2">
                                <div className="text-end">
                                    <button onClick={() => { this.GuardarGPSSuministro() }} className="btn btn-sm btn-primary">Asignar</button>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>


                {/* Liberar Suministros  */}
                <Modal
                    show={this.state.MostrarLiberarSuministros}
                    onHide={() => { this.CerrarModalLiberarSuministros() }}
                    keyboard={false}
                    centered
                >
                    <Modal.Header >
                        <Modal.Title>{`¿Liberar Suministros?`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <label>
                                    ¿Estas seguro de liberar <strong>{this.state.asignacion.asignado}</strong> suministros asignados a <strong>{this.state.asignacion.nombretrabajador}</strong>?
                                </label>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="container">
                            <div className="text-end">
                                <button onClick={() => { this.LiberarTrabajo() }} className="btn btn-sm btn-primary">Liberar</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>


            </Fragment>

        );
    }
}

export default programacionMapa;