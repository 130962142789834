import React, { Component, createRef, Fragment } from 'react';
import NavBar from './../dashboard/navBar'
import TituloPagina from './../dashboard/headerTitle'

// import socketIOClient from 'socket.io-client';

import { MapContainer, TileLayer, Marker, Popup, Polyline, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { IconoRojo, IconoPinAzul, IconoPinVerde, IconoPinRojo } from './../../config/icono'

import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// Floating Button
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

import { ValidarUsuario, ObtenerUsuario,ValidarPermisos,listarPermisos,LIST_SCREENS } from '../utils/utils'
import config from './../../config/config';
import SpinnerCarga from './../utils/spinner'



class monitoreoTrabajadorMapa extends Component {
    constructor(props) {
        super(props)
        this.state = {
            LoadData: false,
            fechaprogramacion: '',
            sizecontent: 0,
            widthcontent: 0,
            mostrarEtiqueta: false,
            agruparMarcadores: true,
            ListaTrabajadores: [],
            ListaProgramacion: [],
            ListaFiltro: [],
            ListaRecorrido: [],
            ListaTrabajos: [],
            TrabadadorActual: null,
            dibujarMapa: false,
            centro: {
                lat: -16.398821,
                lng: -71.536862
            },
            zoomMapa: 12,
            centroInicial: {
                lat: -16.398821,
                lng: -71.536862
            },
            MostrarFloatingButton: 'none'

        }
        // Referencias
        this.inputfechaProgramacion = createRef();
        this.inputBuscarFiltro = createRef();

        //Variable controlador  Socket
        // this.socket=socketIOClient(config.endPoint,{transports: ['websocket', 'polling', 'flashsocket']});

    }
    async componentDidMount() {
        if (ValidarUsuario()) {
            listarPermisos().then(async(permisos)=>{
                if(ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_MONITOREO,permisos)){
                    await this.setState({ fechaprogramacion: this.props.match.params.fecha });
                    // this.inputfechaProgramacion.current.value = this.state.fechaprogramacion;        
                    this.setState({ sizecontent: window.innerHeight });
                    this.CambioSizeWindows();
        
                    this.BuscarProgramacionFecha();
                    this.BuscarListaTrabajadores();
                    // this.SocketConect();
                }
                else{
                    this.setState({ abrirToast: true, mensajeToast: "No tiene permisos para acceder a este Recurso", tipotoast: 'warning' });                    
                    setTimeout(() => {                    
                        this.props.history.push('/');    
                    }, 1000);
                }
            });
       
        }
        else {
            this.props.history.push('/login');
        }
    }

    // SocketConect = () => {
    //     //Escuchar Servidor Socket
    //     this.socket.open();
    //     //Variable controlador  Socket
    //     this.socket.on("connect", () => {
    //         console.log("Conectado con Servidor socket");
    //     });

    //     this.socket.on("ActualizarTrabajosSuministro", (Data) => {
    //         //Actualizar la Lista de Trabajos
    //         console.log(`socket : ActualizarTrabajosuministro ${Data}`);            
    //         this.BuscarProgramacionFecha();
    //     });
    // }
    // componentWillUnmount(){
    //     this.socket.close();
    // }

    BuscarProgramacionFecha =async () => {
        if (ValidarUsuario()) {
            await this.setState({ListaProgramacion:[],ListaFiltro:[]});
            
            let url = `${config.endPoint}api/trabajosuministro/listar/ultimaUbicacion/${this.state.fechaprogramacion}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {

                if (data.content.length > 0) {
                    if (data.content[0].ultima_ubicacion) {
                        await this.setState({
                            centro: {
                                lat: data.content[0].ultima_ubicacion.lat,
                                lng: data.content[0].ultima_ubicacion.lng
                            }
                        });
                    }
                    else {
                        await this.setState({ centro: this.state.centroInicial });
                    }
                    await this.setState({ ListaProgramacion: data.content, ListaFiltro: data.content, zoomMapa: 12, LoadData: true });
                }
                else {
                    await this.setState({ ListaProgramacion: [], ListaFiltro: [], LoadData: true, centro: this.state.centroInicial, zoomMapa: 12 });
                }
            }).catch((error) => {
                this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
            });
        }
        else {
            this.props.history.push('/login');
        }
    }
    BuscarListaTrabajadores = () => {
        if (ValidarUsuario()) {
            let urlTrabajadores = `${config.endPoint}api/trabajosuministro/listarNombres/trabajador/${this.state.fechaprogramacion}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(urlTrabajadores, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {

                if (data.content) {
                    this.setState({ ListaTrabajadores: data.content });
                }
            });
        }
        else {
            this.props.history.push('/login');
        }
    }

    KeyEnterInputBuscar = async (ev) => {
        // Mostrar Icono o Marker en la Posicion del Elemento
        if (ev.key === "Enter") {
            // Buscar el Trabajador segun Nombre
            await this.setState({ LoadData: false, ListaTrabajos: [], ListaRecorrido: [] });
            let nuevoArray = this.state.ListaProgramacion.filter(x => x.nombretrabajador === this.inputBuscarFiltro.current.value);
            if (nuevoArray.length > 0) {
                this.setState({ ListaFiltro: nuevoArray, zoomMapa: 15, TrabadadorActual: nuevoArray[0] });
                if (nuevoArray[0].ultima_ubicacion) {
                    let nuevoentro = {
                        lat: nuevoArray[0].ultima_ubicacion.lat,
                        lng: nuevoArray[0].ultima_ubicacion.lng
                    }
                    this.setState({ centro: nuevoentro, MostrarFloatingButton: '', LoadData: true });
                }
                else {
                    this.setState({ mensajeToast: "EL dato no contiene una ubicación.", abrirToast: true, tipotoast: 'info', LoadData: true });
                }
            }
            else {
                this.setState({ mensajeToast: "No se encontraron datos para esta busqueda", abrirToast: true, tipotoast: 'warning', LoadData: true });
            }
        }
    }
    CambioInputBuscar = () => {

    }
    componentDidUpdate() {
        // window.addEventListener('resize', this.CambioSizeWindows);
    }

    CambioSizeWindows = () => {
        this.setState({ sizecontent: window.innerHeight, widthcontent: window.innerWidth });
    }
    MapearTodosTrabajadores = async () => {
        await this.setState({ ListaProgramacion: [], ListaFiltro: [], MostrarFloatingButton: 'none', LoadData: false, ListaTrabajos: [], ListaRecorrido: [] });
        this.inputBuscarFiltro.current.value = "";
        this.BuscarProgramacionFecha();
    }
    toogleEtiqueta = async (valor) => {
        await this.setState({ mostrarEtiqueta: valor, LoadData: false });
        await this.setState({ LoadData: true });
        // this.BuscarProgramacionFecha();
    }
    toogleAgrupar = async (valor) => {
        await this.setState({ agruparMarcadores: valor, LoadData: false });
        await this.setState({ LoadData: true });
        // this.BuscarProgramacionFecha();

    }
    //*************************************************
    // OPCIONES DE TRABAJADOR FILTRADO
    //*************************************************
    MarcarRecorridoPersonalActual = async () => {
        // Buscar Todas las rutas recorridas en esta Fecha + idtrabajador
        await this.setState({ ListaRecorrido: [] });
        if (ValidarUsuario()) {
            if (this.state.TrabadadorActual) {
                let url = `${config.endPoint}api/trabajosuministro/listar/recorrido/${this.state.fechaprogramacion}/${this.state.TrabadadorActual.idpersonalasignado}`
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        let NuevaListaRecorrido = [];
                        // Llenar control de Lineas
                        for (let fila of data.content) {
                            let nlatlng = [fila.lat, fila.lng];
                            NuevaListaRecorrido.push(nlatlng);
                        }
                        this.setState({ ListaRecorrido: NuevaListaRecorrido });
                    }
                    else {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'warning' });
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
            }
            else {
                this.setState({ mensajeToast: "No se encontro Datos del Trabajador", abrirToast: true, tipotoast: 'error' });

            }
        }
        else {
            this.props.history.push('/login');
        }
    }
    MarcarSuministrosPersonalActual = async () => {

        await this.setState({ ListaTrabajos: [] });
        if (ValidarUsuario()) {
            if (this.state.TrabadadorActual) {
                let url = `${config.endPoint}api/trabajosuministro/listar/trabajos/${this.state.fechaprogramacion}/${this.state.TrabadadorActual.idpersonalasignado}`
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        this.setState({ ListaTrabajos: data.content });
                    }
                    else {
                        this.setState({ mensajeToast: data.message, abrirToast: true, tipotoast: 'warning' });
                    }
                }).catch(() => {
                    this.setState({ mensajeToast: config.MessageErrorServer, abrirToast: true, tipotoast: 'error' });
                });
            }
            else {
                this.setState({ mensajeToast: "No se encontraron Suministros Para este trabajador", abrirToast: true, tipotoast: 'error' });
            }
        }
        else {
            this.props.history.push('/login');
        }
    }

    //*************************************************
    // Funciones para toast
    //*************************************************
    CerrarToastNotification = () => {
        this.setState({ abrirToast: false });
    }

    render() {
        return (
            <Fragment>

                {/* ************************************************* */}
                {/* TOAST ALERT */}
                {/* ************************************************* */}
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.state.abrirToast} autoHideDuration={4000} onClose={this.CerrarToastNotification}>
                    <Alert onClose={this.CerrarToastNotification} variant='filled' severity={this.state.tipotoast}>
                        {this.state.mensajeToast}
                    </Alert>
                </Snackbar>


                <div style={{}} >
                    <NavBar CerrarSesion={this.CerrarSesion} />
                    <TituloPagina titulo="MONITOREO (Mapa)" regresar={() => { this.props.history.push(`/monitoreo`) }}></TituloPagina>
                    <div className="container">
                        <div className="row my-1">
                            <div className="col-md-3 ">
                                {/* <input onChange={() => { this.CambiarFecha() }} className="form-control form-control-sm p-1" type="date" placeholder="Fecha" ref={this.inputfechaProgramacion}></input> */}
                                <small className="text-primary">{`Fecha Programación : `}</small><span>{`${this.state.fechaprogramacion}`}</span>
                            </div>
                            <div className="col-md-4 mt-1 mt-md-0">
                                <input onChange={() => { this.CambioInputBuscar() }} onKeyPress={(ev) => { this.KeyEnterInputBuscar(ev) }} ref={this.inputBuscarFiltro} className="form-control form-control-sm" list="listaTrabajadoresProgramados" placeholder={`Buscar Trabajador`}></input>
                                <datalist id="listaTrabajadoresProgramados">
                                    {
                                        this.state.ListaTrabajadores.map((row, index) => {
                                            return (<option key={index} value={row.nombretrabajador} />)
                                        })
                                    }
                                </datalist>

                            </div>
                            <div className="col-md-5 text-end mt-1 mt-md-0">
                                <div className="dropdown">
                                    <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                        Opciones
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                        <li onClick={() => { this.MapearTodosTrabajadores() }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Mapear todos los Trabajadores</small></li>
                                        {/* Crear WebSocket Para Generar la conexion en tiempo Real */}
                                        <li onClick={() => { }}><small className="dropdown-item" ><i className="fas fa-sync-alt mx-2"></i>Ver en Tiempo Real</small></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li onClick={() => { this.toogleAgrupar(true) }}><small className="dropdown-item" ><i className="far fa-object-group mx-2"></i>Mostrar Agrupado</small></li>
                                        <li onClick={() => { this.toogleAgrupar(false) }}><small className="dropdown-item" ><i className="fas fa-map-marked-alt mx-2"></i>Mostrar Sin Agrupar</small></li>
                                        <li onClick={() => { this.toogleEtiqueta(true) }}><small className="dropdown-item" ><i className="fas fa-tags mx-2"></i>Mostrar Con Etiquetas</small></li>
                                        <li onClick={() => { this.toogleEtiqueta(false) }}><small className="dropdown-item" ><i className="fas fa-comment-slash mx-2"></i>Mostrar Sin Etiquetas</small></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* Aqui empieza el Mapa */}
                    {
                        this.state.LoadData ?
                            <>
                                <div className="container-fluid mt-1 " style={{ height: `80vh` }}>
                                    <div className="row "  >
                                        <div className="col overflow">
                                            <MapContainer center={this.state.centro} zoom={this.state.zoomMapa} style={{ height: `80vh` }}>
                                                {/* <TileLayer
                                                    url='https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png'
                                                    maxZoom={20}
                                                    attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                                                /> */}
                                                <TileLayer
                                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                {
                                                    this.state.agruparMarcadores ?
                                                        <MarkerClusterGroup >
                                                            {
                                                                this.state.ListaFiltro.map((row, index) => {
                                                                    return (
                                                                        row.ultima_ubicacion ?
                                                                            <Marker key={index} title="" position={[row.ultima_ubicacion.lat, row.ultima_ubicacion.lng]} icon={IconoRojo}>
                                                                                {/* <Popup>
                                                            A pretty CSS3 popup. <br /> Easily customizable.
                                                        </Popup> */}
                                                                                <Tooltip direction="bottom" offset={[0, 20]} opacity={0.9} permanent={this.state.mostrarEtiqueta} >
                                                                                    <div>
                                                                                        <i className="fas fa-user-clock mx-1"></i><strong >{row.nombretrabajador}</strong>
                                                                                    </div>
                                                                                    <div>
                                                                                        <span className="mx-1"><small className="text-primary">Tipo : </small>{`${row.Tipo} `}</span>
                                                                                        <span className="mx-1"><small className="text-primary">Avance : </small>{`${row.avance}`}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <span className="mx-1"><small className="text-primary">Ultima Ubicación : </small>{`${row.ultima_ubicacion.fecha} ${row.ultima_ubicacion.hora} `}</span>
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </Marker>
                                                                            :
                                                                            < ></>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                this.state.ListaTrabajos.map((row, index) => {
                                                                    console.log(row);
                                                                    if (row.lat_inicio !== 0) {
                                                                        return (
                                                                            <Marker key={index} title="" position={row.lat_ejecucion != null ? [row.lat_ejecucion, row.lng_ejecucion] : [row.lat_inicio, row.lng_inicio]} icon={row.estado === "PROGRAMADO" ? IconoPinAzul : (row.estado === "EJECUTADO" ? IconoPinVerde : IconoPinRojo)}>
                                                                                <Popup>
                                                                                    <div>
                                                                                        <small className="text-primary">Suministro :</small><span>{row.suministro}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <small className="text-primary">Cliente :</small><span>{row.nombreCliente}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <small className="text-primary">Dirección :</small><span>{row.direccionCliente}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <small className="text-primary">Estado :</small><span>{row.estado}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <small className="text-primary">Detalle :</small><span>{row.detalle}</span>
                                                                                    </div>
                                                                                </Popup>
                                                                            </Marker>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (<></>)
                                                                    }
                                                                })
                                                            }
                                                            {
                                                                this.state.ListaRecorrido.length > 0 ?
                                                                    <Polyline positions={this.state.ListaRecorrido} color={'rgb(47,117,181)'} ></Polyline>
                                                                    : <></>
                                                            }

                                                            {/* <Polyline positions={[[-16.406578, -71.530643], [-16.408400, -71.530010]]} color={'blue'}></Polyline> */}
                                                        </MarkerClusterGroup>
                                                        :
                                                        <>
                                                            {
                                                                this.state.ListaFiltro.map((row, index) => {
                                                                    return (
                                                                        row.ultima_ubicacion ?
                                                                            <Marker key={index} title="" position={[row.ultima_ubicacion.lat, row.ultima_ubicacion.lng]} icon={IconoRojo}>
                                                                                {/* <Popup>
                                                            A pretty CSS3 popup. <br /> Easily customizable.
                                                        </Popup> */}
                                                                                <Tooltip direction="bottom" offset={[0, 20]} opacity={0.9} permanent={this.state.mostrarEtiqueta} >
                                                                                    <div>
                                                                                        <i className="fas fa-user-clock mx-1"></i><strong >{row.nombretrabajador}</strong>
                                                                                    </div>
                                                                                    <div>
                                                                                        <span className="mx-1"><small className="text-primary">Tipo : </small>{`${row.Tipo} `}</span>
                                                                                        <span className="mx-1"><small className="text-primary">Avance : </small>{`${row.avance}`}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <span className="mx-1"><small className="text-primary">Ultima Ubicación : </small>{`${row.ultima_ubicacion.fecha} ${row.ultima_ubicacion.hora} `}</span>
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </Marker>
                                                                            :
                                                                            <></>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                this.state.ListaTrabajos.map((row, index) => {
                                                                    if (row.lat_inicio !== 0) {
                                                                        return (
                                                                            // <Marker key={index} title="" position={[row.lat_inicio, row.lng_inicio]} icon={row.estado === "PROGRAMADO" ? IconoPinAzul : IconoPinVerde}>
                                                                            <Marker key={index} title="" position={row.lat_ejecucion != null ? [row.lat_ejecucion, row.lng_ejecucion] : [row.lat_inicio, row.lng_inicio]} icon={row.estado === "PROGRAMADO" ? IconoPinAzul : (row.estado === "EJECUTADO" ? IconoPinVerde : IconoPinRojo)}>
                                                                                <Popup>
                                                                                    <div>
                                                                                        <small className="text-primary">Suministro :</small><span>{row.suministro}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <small className="text-primary">Cliente :</small><span>{row.nombreCliente}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <small className="text-primary">Dirección :</small><span>{row.direccionCliente}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <small className="text-primary">Estado :</small><span>{row.estado}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <small className="text-primary">Detalle :</small><span>{row.detalle}</span>
                                                                                    </div>
                                                                                </Popup>
                                                                            </Marker>

                                                                        )
                                                                    }
                                                                    else {
                                                                        return (<></>)
                                                                    }
                                                                })
                                                            }
                                                            {
                                                                this.state.ListaRecorrido.length > 0 ?
                                                                    <Polyline positions={this.state.ListaRecorrido} color={'rgb(47,117,181)'}></Polyline>
                                                                    : <></>
                                                            }
                                                        </>
                                                }


                                            </MapContainer>

                                        </div>

                                    </div>
                                </div>

                            </>
                            : <SpinnerCarga />
                    }

                </div>

                {/* ************************* */}
                {/* Boton Flotante  */}
                {/* ************************* */}
                <Fab alwaysShowTitle={true}
                    icon={<i className="fas fa-plus"></i>}
                    mainButtonStyles={{ backgroundColor: 'rgb(230,83,97)', outline: 'none' }}
                    style={{ bottom: 10, right: 10, display: `${this.state.MostrarFloatingButton}` }}>
                    <Action text="Marcar Suministros"
                        onClick={() => this.MarcarSuministrosPersonalActual()}
                        style={{ backgroundColor: 'rgb(25,118,210)' }}
                    >
                        <i className="fas fa-street-view "></i>
                    </Action>
                    <Action text="Marcar Recorrido"
                        style={{ backgroundColor: 'rgb(25,118,210)' }}
                        onClick={() => this.MarcarRecorridoPersonalActual()}>
                        <i className="fas fa-draw-polygon"></i>
                    </Action>
                </Fab>
            </Fragment>
        );
    }
}

export default monitoreoTrabajadorMapa;