import React, { Component, Fragment } from 'react';

class loadModal extends Component {    
    render() {
        return (
            <Fragment>
                <div hidden={false} style={{ backgroundColor: 'rgba(0,0,0,0.4)', position: 'fixed', top: 0, right: 0, left: 0, bottom: 0,zIndex:1000}}>
                    <div className="m-1" >
                        <div className="spinner-grow spinner-grow-sm text-light " role="status">
                            <span className="sr-only">Cargando...</span>
                        </div>
                        <span className="text-light mx-1">Cargando...</span>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default loadModal;