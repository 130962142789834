 const config={
    // endPoint:'http://192.168.10.14:3000/',            
    endPoint:'https://backend-siscomer-busqueda.herokuapp.com/',
    
    
    ApiKeyAmazon:'',
    storageName:'siscomer-webtoken',
    storageDateName:'siscomer-datetime-program',
    storageFilterTrabajo:'siscomer-filter-work',
    MessageErrorServer:'Error en el Servidor,ERROR_CONNECTION ',
    aplicationVersion:'1.99c-GC',
    tiempoRecargaGPs:2000,
    cantidadMaximaMArcadores:800
}
export default config;
